.vehicleCardContainer {
  /* padding-left: 3rem; */
}
.v-card {
  display: flex;
  border: none;
  gap: 1px;
  background-color: #d1823a33;
  /* background-color: #e2873233; */
  margin-left: 0.6rem;
}
.v-card > .sectionContainer:first-child {
  width: 25%;
  min-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.3rem;
}
.v-card > .sectionContainer:nth-child(3) {
  width: 31%;
}
.v-card > .sectionContainer:last-child {
  width: 18%;
  justify-content: space-around;
}
.sectionContainer {
  width: 25%;
  font-size: 9px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vehicleSectionDetails {
  font-size: 9px !important;
  display: flex;
  flex-direction: column;
  font-weight: 400;
}
.vehicleSectionDetails span:first-child {
  color: #d1823a;
  text-align: center;
}
.vehicleSectionDetails span:last-child {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowAutoWidth {
  width: auto !important;
}
@media (min-width: 375px) {
  .vehicleSectionDetails {
    font-size: 10px !important;
    font-weight: 500;
  }
}
@media (min-width: 720px) {
  .vehicleSectionDetails {
    font-size: 16px !important;
    gap: 2rem;
  }
  .v-card {
    border-radius: 20px !important;
    height: 130px;
  }
  .v-card > .sectionContainer:last-child {
    justify-content: space-evenly;
  }
  .v-card > .sectionContainer:nth-child(3) {
    gap: 1rem;
  }
}
@media (min-width: 1024px) {
  .vehicleSectionDetails {
    font-size: 20px !important;
    gap: 2rem;
  }
  .v-card {
    height: 150px;
  }
  .v-card > .sectionContainer:nth-child(3) {
    gap: 2rem;
  }
}

@media (min-width: 1440px) {
  .v-card {
    height: 185px;
  }
  .v-card > .sectionContainer:nth-child(3) {
    gap: 3rem;
  }
}
@media (min-width: 1800px) {
  .v-card > .sectionContainer:nth-child(3) {
    gap: 4rem;
  }
}
