 /* *********************** */

 .form_container{
    width: 30%;
    background-color: rgb(253, 253, 253);
    border-radius: 50px;
  }
  .singupicons{
    margin: 0 0 5px 0;
  }
  .singupicons i{
    width: 15%;
    font-size: 30px;
    /* border: 5px solid green; */
    /* background: black; */
    /* color: white; */
    /* border-radius: 5px; */
  }

  .singupicons h5{
    margin: 0 0 20px 0;
    font-size: bold;
  }
  .p_or{
    height: 1px;
  }
  .p_texts{
    height: 5px;
  }
  @media screen and (max-width: 1000px){
    .form_container{
        width: 45%;
      }
  }

  @media screen and (max-width: 700px){
    .form_container{
        width: 65%;
      }
      .p_texts{
        height: 5px;
      }
  }

  /* @media (max-width: 911px) {
    .varification-main-container{
      height: 600px;
    }
  } */