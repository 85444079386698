.wrapper {
  /* border: 2px solid red; */
  width: 100vw;
  display: grid;
  place-content: center;
  margin-bottom: 1rem;
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-weight: 500;
}
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  box-shadow: 3px 4px 5px grey;
  margin-bottom: 0;
  height: 130px;
  width: 100px;
  gap: 1rem;
  padding: 0.3rem;
}
.card span:nth-child(2){
    background-color: rgb(219, 219, 6);
    color: white;
    padding: 0.2rem 0.6rem;
    border-radius: 20px;
}
@media (min-width : 720px){
    .container {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 4rem;
        font-weight: 500;
      }
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        box-shadow: 5px 8px 5px grey;
        margin-bottom: 0;
        height: 230px;
        width: 150px;
        gap: 1.4rem;
        padding: 0.5rem;
        font-weight: 600;
        font-size: larger;
      }
      .card span:nth-child(2){
          background-color: rgb(219, 219, 6);
          color: white;
          padding: 0.3rem 0.6rem;
          border-radius: 20px;
      }
}
@media (min-width : 1024px){
    .container {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 4rem;
        font-weight: 500;
      }
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        box-shadow: 5px 8px 5px grey;
        margin-bottom: 0;
        height: 300px;
        width: 250px;
        gap: 1.4rem;
        padding: 0.5rem;
        font-weight: 700;
        font-size: x-large;
      }
      .card span:nth-child(2){
          background-color: rgb(219, 219, 6);
          color: white;
          padding: 0.3rem 0.6rem;
          border-radius: 20px;
      }
}
@media (min-width : 2200px){
    .container {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 7rem;
        font-weight: 500;
      }
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        box-shadow: 5px 8px 5px grey;
        margin-bottom: 0;
        height: 390px;
        width: 320px;
        gap: 1.4rem;
        padding: 0.5rem;
        font-weight: 800;
        font-size: xx-large;
      }
      .card span:nth-child(2){
          background-color: rgb(219, 219, 6);
          color: white;
          padding: 0.3rem 0.9rem;
          border-radius: 26px;
      }
}
