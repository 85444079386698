.product-S-btns {
    background: #D1823A;
    color: white;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    padding: 10px 12px;
    font-size: 20px;
    cursor: pointer;
    /* margin-top: 50px; #D1823A*/
  }
  .product-S-btnss {
    background: #13c403;
    color: white;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    padding: 10px 12px;
    font-size: 20px;
    cursor: pointer;
    margin-top: 116px;
  }