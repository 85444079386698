.wrapper {
  width: 100vw;
}
.container {
  width: 100%;
  padding: 0.9rem;
}
.headerContainer {
  font-size: xx-large;
  font-weight: 600;
  font-family: "Times New Roman", Times, serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cardContainer {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}
.leftArrowbtn,
.rightArrowbtn {
  position: absolute;
  top: 50%;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding: 0.4rem 0.6rem;
  text-align: center;
  font-size: larger;
  display: none;
}
.leftArrowbtn {
  left: 0;
}
.rightArrowbtn {
  right: 0;
}
.starContainer {
  text-align: center;
  font-size: xx-large;
  color: orange;
}
.card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  height: auto;
  text-align: center;
  padding: 1rem;
}
.card p:nth-child(2) {
  font-size: large;
  font-weight: 400;
}
.card p:nth-child(3) {
  font-size: large;
  font-weight: 600;
}
@media (min-width: 720px) {
  .cardContainer {
    flex-direction: row;
    gap: 1rem;
    
  }
  .leftArrowbtn,
.rightArrowbtn {
 
  display: block;
}
}
@media (min-width: 1440px) {
  .container {
    width: 100%;
    padding: 1.5rem;
  }
  .cardContainer {
    flex-direction: row;
    gap: 3rem;
    padding: 0 3rem;
  }
  .card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    height: auto;
    text-align: center;
    padding: 1rem;
  }
  .card p:nth-child(2) {
    font-size: larger;
    font-weight: 500;
  }
  .card p:nth-child(3) {
    font-size: larger;
    font-weight: 700;
  }
}

@media (min-width: 2200px) {
  .starContainer {
    font-size: 40px;
  }
  .headerContainer {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    height: auto;
    text-align: center;
    padding: 1rem;
    font-size: 30px;
  }
}
