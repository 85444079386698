/* .button1{
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
} */
.buttoncontainer{
    width: 100%;
}
.button1 button{
    margin: 10px;
    height: 100px;
    width: 5.2%;
    border-radius: 10px;
}
.button1 button p{
    margin-bottom: 0px;
}
.button2 button{
   margin: 10px;
   height: 100px;
    width: 9%;
    border: none;
    border-radius: 10px;
    background: rgb(240,150,31);
    background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
    color: white;
}
.button2 button:hover{
   background-color: rgb(240, 115, 31);
   cursor: pointer;
}

.button3 button{
   margin: 10px;
   height: 100px;
    width: 9%;
    border: none;
    border-radius: 10px;
    background: rgb(240,150,31);
    background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
    color: white;
}
.button3 button:hover{
   background-color:#eb742f;
   cursor: pointer;
}

.button4 button{
   margin: 10px;
   height: 100px;
    width: 9%;
    border: none;
    border-radius: 10px;
    background: rgb(240,150,31);
    background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
    color: white;
}
.button4 button:hover{
   background-color: #eb742f;
   cursor: pointer;
}
/* moblie view */
@media only screen and (min-width: 320px) and (max-width:600px){
   .button1 button{
       margin: 2px;
      height: 90px;
      width: 22%;
      border-radius: 10px;
   }
   .button2 button{
    display: flex;
    flex-direction: column;
    margin-left: 5% !important;
       margin: 2px;
       width: 80%;
       height: 80px;
       border: none;
       border-radius: 10px;
       background: rgb(240,150,31);
       background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
       color: white;

   }
   .button2 button span{
    margin-left: 40%;
   }
   .button3 button{
    display: flex;
    flex-direction: column;
    margin-left: 5% !important;
       margin: 2px;
       height: 80px;
       width: 80%;
       border: none;
       border-radius: 10px;
       background: rgb(240,150,31);
       background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
       color: white;
   }
   .button3 button span{
    margin-left: 40%;
   }
   .button4 button{
    display: flex;
    flex-direction: column;
    margin-left: 5% !important;
       margin: 2px;
       height: 80px;
       width: 80%;
       border: none;
       border-radius: 10px;
       background: rgb(240,150,31);
       background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
       color: white;
   }
   .button4 button span{
    margin-left: 40%;
   }
   #btn22{
        margin-left: 38%;
   }
   #btn23{
    margin-left: 32%;
}
#btn24{
    margin-left: 29%;
}
#btn25{
    margin-left: 45%;
}
#btn31{
    margin-left: 31%;
}
#btn32{
    margin-left: 35%;
}
#btn33{
    margin-left: 38%;
}
#btn35{
    margin-left: 32%;
}
#btn41{
    margin-left: 29%;
}
#btn42{
    margin-left: 36%;
}
#btn43{
    margin-left: 34%;
}

#btn44{
    margin-left: 42%;
}



}
/* tablet view */
@media only screen and (min-width: 768px) {
   .button1 button{
       margin: 5px;
      height: 100px;
      width: 10.5%;
    border-radius: 10px;
   }
   .button2 button{
       margin: 10px;
       height: 100px;
        width: 16.5%;
        border: none;
        border-radius: 10px;
        background: rgb(240,150,31);
        background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
        color: white;
   }
   .button3 button{
       margin: 10px;
       height: 100px;
        width: 16.5%;
        border: none;
        border-radius: 10px;
        background: rgb(240,150,31);
        background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
        color: white;
   }
   .button4 button{
       margin: 10px;
       height: 100px;
        width: 16.5%;
        border: none;
        border-radius: 10px;
        background: rgb(240,150,31);
        background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
        color: white;
       margin-left: 5%;
   }
}
/* For Desktop And Large Screen */
@media only screen and (min-width: 992px) {
    .button-main-container-dashboard{
           
    }
.button1{
   margin-left: 14.2%;
}
.button2{
    margin-left: 15%;
}
.button3{
    margin-left: 15%;
}
.button4{
    margin-left: 25%;
}


   .button1 button{
       margin: 10px;
       height: 100px;
       width: 9%;
       border-radius: 10px;
  }
  .button2 button{
   margin: 10px;
   height: 100px;
    width: 15%;
    border: none;
    border-radius: 10px;
    background: rgb(240,150,31);
    background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
    color: white;
}
.button3 button{
   margin: 10px;
   height: 100px;
    width: 15%;
    border: none;
    border-radius: 10px;
    background: rgb(240,150,31);
    background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
    color: white;
}
.button4 button{
   margin: 10px;
   height: 100px;
    width: 15%;
    border: none;
    border-radius: 10px;
    background: rgb(240,150,31);
    background: linear-gradient(356deg, rgba(240,150,31,1) 35%, rgba(232,162,98,0.9444152661064426) 100%);
    color: white;
}
}