.sale_type_data {
    background-color: grey;
    border-radius: 10px;
    height: 70px;
    width: 50%;
    align-items: center;
}

.sale_type_data span{
    color: white;
}

.sale_type_data:hover{
    transition: 0.1s;
    background-color: rgb(122, 120, 120);
}

#Retail{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

#Finance{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
