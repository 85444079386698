.wrapper {
  position: absolute;
  bottom: 0;
  right: 20px;
  /* Adjust as needed */
  width: 250px;
  /* Adjust as needed */
  height: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  transition: height .8s ease-in-out;
}

.minimized {
  height: 60px;
}

.maximized {
  z-index: 10;
  /* Ensure maximized chat box appears on top */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.header {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border: 2px solid blue; */
  color: white;
  background-color: rgb(119, 108, 108);
}

.header img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid white;
}

.closeBtn {
  font-size: larger;
}

.body {
  padding: 1rem 0.5rem;
  overflow-y: scroll;
  /* margin-bottom: 1rem; */
  /* height: 280px; */
}

.inputBox {
  display: flex;
  border-radius: 50px;
  border: 1px solid gray;
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* height: 50px; */
}

.inputBox input {
  border: none;
  padding: 0;
  flex: 0.9;
  /* border: 1px solid blue; */
  margin: 0 0.5rem;
}

.inputBox input:focus{
  outline: none;
}

.attachmentContainer {
  /* border: 2px solid blue; */
  display: flex;
  position: absolute;
  width: auto;
  /* gap: 2rem; */
  background-color: white;
  bottom: 3rem;
  left: 0;
  box-shadow: 2px 3px 4px gray;
  border: 1px solid gray;
  padding: 0.3rem;
}

.attachmentContainer p {
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex: 1; */
  margin: 5px;
  padding: 0.3rem 0rem;
  cursor: pointer;
}

.attachmentContainer p span:nth-child(1) {
  box-shadow: 2px 3px 4px gray;
  border: 1px solid gray;
  padding: 5px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  font-size: large;
}

.attachmentContainer p span:nth-child(2) {
  font-size: small;
  font-weight: 500;
}

.EmojiContainer {
  /* border: 2px solid blue; */
  display: flex;
  position: absolute;
  background-color: white;
  bottom: 20px;
  right: 0;
  box-shadow: 2px 3px 4px gray;
  border: 1px solid gray;
  height: 80%;
  width: 100%;
}