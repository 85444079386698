.headSummery{
    padding-left: 0.8%; 
}

.headTable{
    padding-left: 1.5%;
    padding-right: 3%;
}

.tableStyle{
    border: 1px solid #e8a262;
    border-radius: 10px;
  }
  .thThStyle{
    border: 1px solid #e8a262;
    background-color: #f6dfcf;
    text-align: center;
  }
  .thTdStyle{
    border: 1px solid #e8a262;
    text-align: center;
  }
  .bgActiveInventory{
    background-color: #D7996D !important;
  }
  .bgActiveInventory :hover{
    background-color: #d9d9d9 !important;
    color: black !important;
  }
.ExtIntClr{
  gap: 3rem !important;
  width: 100% !important;
}
  @media screen and (min-width: 1401px) {
    .bgActiveInventory{
        font-size: 20px !important;
        font-weight: 500 !important;
        /* width: 33% !important; */
    }
    .YearMakeWidth{
      width: 11rem;
      margin-left: 0.3rem;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1799px) {
  .YearMakeWidth{
    margin-left: 0.3rem;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .AddTofeatured{
    position: absolute;
    right: 10px;
    top: 0;
  }
  .ExtIntClr{
    gap: 2rem !important;
    font-size: 13px;
    width: 100% !important;
  }
  .YearMakeWidth{
    width: 7rem;
    margin-left: 0.3rem;
  }
}

/* active Inventory Card Design */
.cardMain{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 10px;
  border: 2px solid #dbd6d6;
}
.CardButton{
  max-width: 100%;
  width: 90px;
  background: #f6dfcf !important;
  /* box-shadow: 0px 3px 5px gray; */
  color: #d1823a !important;
  margin-bottom: 15px;
  border-radius: 5px;
  border-width: 0px !important;
  text-align: center;
  box-sizing: border-box;
  font-size: .8rem !important;
  font-weight: 600;
  cursor: pointer;
  border: none;
}
.CardButton:hover{
    background-color: #d9d9d9 !important;
    color: black !important;
}
.filterSearch{
  display: flex;
  flex: 1;
}
.mainFilter{
  display: flex;
}
.filterSearchRight{
  display: flex;
  justify-content: space-between;
  flex: 1;
}

@media screen and (min-width: 2100px) {
  .YearMakeWidth{
    width: 20rem;
    margin-left: 0.3rem;
  }
}