.paymentBodyTabsContainer {
  display: flex;
  font-family: "Alata";
}
.paymentBodyTabsContainer span {
  flex: 1;
  border: 2px solid #d1823a;
  text-align: center;
  box-shadow: inset 0 0 3px 3px #d1823a52;
  font-size: 10px;
  padding: 0.3rem;
  /* font-weight: 700; */
  color: #d1823a;
  cursor: pointer;
}
.paymentBodyTabsContainer span:first-child {
  border-top-left-radius: 10px;
}
.paymentBodyTabsContainer span:last-child {
  border-top-right-radius: 10px;
}
.paymentBodyTabsContainer span.activeTab {
  background-color: #d1823a;
  color: white;
}
@media (min-width: 420px) {
  .paymentBodyTabsContainer span {
    font-size: 12px;
    font-weight: 700;
  }
}
@media (min-width: 600px) {
  .paymentBodyTabsContainer span {
    font-size: medium;
  }
}
@media (min-width: 768px) {
  .paymentBodyTabsContainer {
    width: 70%;
  }
}
@media (min-width: 1440px) {
  .paymentBodyTabsContainer {
    width: 60%;
  }
  .paymentBodyTabsContainer span {
    font-size: large;
  }
}
@media (min-width: 1840px) {
  .paymentBodyTabsContainer span {
    font-size: larger;
  }
}
