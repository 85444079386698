@media (max-width: 900px) {
  .priceFeesWrapper {
    flex-direction: column;
    font-family: "Alata", sans-serif;
  }
  .buyerQuoteInfoParent {
    flex-direction: column;

    width: 100%;
  }
  .buyerQuoteInfo {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);

    width: 100%;
  }

  .buyerSaleTypeContainer {
    display: flex;
    flex-direction: row !important;
    align-self: center !important;
    align-items: center;
    gap: 2rem;

    width: 100%;
  }
  .buyerSaleTypeContainer span {
    font-size: large;
    font-weight: 600;
  }

  .buyerQuoteInfo input {
    width: 56%;
  }

  .container {
    border: 2px solid #d1823a;
    border-radius: 10px;
  }
}
/* Here 90ppx ends  */
.wrapper {
  width: 100%;
  font-family: "Alata", sans-serif;
  padding: 0.4rem;
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border: 2px solid #d1823a;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
.assetSelectContainer1 {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  align-items: center;
  flex-direction: column;
}
.selectItem {
  font-weight: 600;
  font-size: larger;
}
.assetSelector {
  display: flex;
  width: 100%;
  flex: 1;
  padding-bottom: 0;
  margin-bottom: 0;
  border-radius: 12px;
  /* overflow: hidden; */
  position: relative;
}
.assetListContainer {
  position: absolute;
  top: 3.8rem;
  width: 100%;
  background-color: #fbeadfd5;
}
.assetListContainer input {
  background-color: transparent;
}

.assetList {
  width: 100%;
}

.assetList .assetItem {
  width: 100%;
  background-color: #fbeadf;
  text-align: left;
  font-size: larger;
  font-weight: 500;
  padding: 0.4rem 1rem;
  border: 1px solid rgba(211, 205, 205, 0.897);
  margin-bottom: 0;
}
.assetList .assetItem:hover {
  color: white;
  background-color: #d1823a;
  cursor: pointer;
}
.assetSelector_field {
  width: 100%;
  box-shadow: rgb(116 111 111 / 60%) 3px 2px 6px 0px;
  border: none;
  outline: none;
}
.ptag_remove_mar {
  margin-bottom: 0px;
}
.ptag_remove_mar select {
  width: 17rem;
  box-shadow: rgb(116 111 111 / 60%) 3px 2px 6px 0px;
}
.assetSelector span:first-child {
  width: 80%;
  height: 100%;
  flex: 1;
  background-color: #fbeadf;
  /* padding: 0.6rem; */
  font-size: large;
  font-weight: 500;
}
.assetSelectorArrow {
  flex: 0.1;
  text-align: center;
  background-color: #d1823a;
  display: grid;
  place-content: center;
  color: white;
  font-weight: 500;
  font-size: 33px;
}
.buyerQuoteInfoParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.buyerQuoteInfo {
  /* display: grid; */
  display: flex;
  grid-template-columns: repeat(2, 1fr);

  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.buyerPersonalInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  /* gap: 1rem; */
}

.buyerQuoteInfo input {
  width: 100%;
  background-color: white;
  border-radius: 7px;
  box-shadow: inset 0 0 12px rgb(184, 179, 179),
    rgb(116 111 111 / 60%) 3px 2px 6px 0px;
  border: 0.1px solid rgb(151, 149, 149) !important;
  height: 35px;
  overflow: hidden;
  outline: none;
  padding-left: 5px;
}
.buyerSaleTypeContainer {
  display: flex;
  flex-direction: column;
  align-self: start;
}
.saletypeDropDownContinaer {
  display: flex;
  gap: 0.4rem;
  background-color: #d1823a;
  width: 100%;
  /* padding: 1.6rem 0.3rem; */
  /* border-radius: 10px; */
}
.saletypeDropDownContinaer span {
  color: white;
  font-size: larger;
  font-weight: 600;
}
.saletypeDropDownContinaer select {
  color: white;
  font-size: larger;

  background-color: #d1823a;
  width: 100%;
  padding: 0.3rem;
}
.priceFeesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceFeesWrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
  /* box-shadow: inset 0 0 10px #e7bb93; */
  border: 2px solid #d1823a;
  padding: 0.5rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}
.priceFeesleftContainer,
.priceFeesRightContainer {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid blue; */
}
.priceFeesleftContainer {
  border: 1px solid #d1823a;

  border-radius: 15px;
}
.priceFeesContainer p {
  font-size: 17px;
  margin-bottom: 0;
  font-weight: 700;
  width: 70%;
}
.priceFeesContainer input {
  width: 100%;
  border: 0;
  background-color: #fbeadf;
  outline: 0;
  text-align: center;
  box-shadow: 0px 5px 3px lightgrey;
  border-radius: 10px;
  padding: 0.4rem;
  font-weight: 600;
  font-size: 18px;
  color: #d1823a;
}
.priceFeesContainer input:focus {
  border: 0;
  outline: 0;
}
.tradeInCheckBox {
  display: flex;
  gap: 1rem;
}
.percCalWrapper {
  border-radius: 10px;
  border: 1px solid #d1823a;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.8rem 0.3rem;
}
.percCalContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 0;
}

.percCalContainer span {
  font-weight: 500;
  font-size: 14px;
  flex: 0.5;
}
.percCalContainer input {
  border: 0;
  background-color: #fbeadf;
  outline: 0;
  text-align: center;
  /* box-shadow: 0px 5px 3px lightgrey; */
  border-radius: 10px;
  padding: 0.4rem;
  font-weight: 600;
  font-size: 18px;
  color: #d1823a;
}
.perCalInput {
  flex: 1;
  display: flex;
  gap: 0.5rem;
}
.percCalContainer2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 0;
}
.percCalContainer2 span {
  font-weight: 500;
  font-size: 14px;
  flex: 0.5;
}
.percCalContainer2 input {
  border: 0;
  background-color: #fbeadf;
  outline: 0;
  text-align: center;
  box-shadow: 0px 5px 3px lightgrey;
  border-radius: 10px;
  padding: 0.4rem;
  font-weight: 600;
  font-size: 18px;
  color: #d1823a;
  flex: 1;
}
/* .percCalWrapper3 {
  margin: 3rem 0;
} */
.percCalContainer3 {
  display: flex;

  align-items: center;
  /* border: 1px solid #fbeadf; */
  border-radius: 10px;
  justify-content: space-around;
}
/*-------------------------- for payment------------------ */
.percCalWrapperPayment {
  border-bottom: 1px solid #d1823a;
  padding-bottom: 12px;
}
/* .percCalContainerPayment {
  display: flex;
  padding: 1rem;
  align-items: center;
 
} */
.percCalContainer3 span:first-child {
  font-weight: 700;
  font-size: 24px;
  flex: 0.6;
}
.check_payment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
}
.check_payment .check_paymentSave {
  position: absolute;
  right: 0rem;
  top: 0rem;
  font-size: xx-large;
  color: #d1823a;
  cursor: pointer;
}
.check_payment label {
  margin-left: 5px !important;
  font-weight: 600;
  font-size: 10px;
}
.check_payment_head {
  font-weight: 600;
  font-size: large;
}
.percCalContainer3 span:last-child {
  background-color: #fbeadf;
  font-size: 20px;
  color: #d1823a;
  font-weight: 700;
  border: 2px solid #d1823a;
  box-shadow: rgb(116 111 111 / 60%) 3px 2px 6px 0px;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  border-radius: 10px;
}
.generateBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.generateBtnContainer button {
  border: none;
  outline: none;
  background-color: #d1823a;
  box-shadow: rgb(116 111 111 / 60%) 3px 2px 6px 0px;
  color: white;
  font-size: x-large;
  font-weight: 700;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
}
.exportContainer {
  border: 3px solid #d1823a;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  border-radius: 10px;
}
.exportIndicator {
  position: absolute;
  background-color: white;
  padding: 0.2rem 0.5rem;
  top: -1rem;
  left: 0;
  font-size: large;
}
.exportContainer button {
  font-size: larger;
  font-weight: 600;
  border: none;
  outline: none;
  padding: 0.4rem 4.5rem;
  background-color: #d1823a;
  border-radius: 10px;
}
/* Editor poout header design */
.labelPopoutHeaderContainer {
  width: 100% !important;

  align-items: center;
  display: flex !important;
}
.labelPopoutHeaderDropDown {
  display: none;
}
.boxContainer .labelPopoutHeaderDropDown {
  display: block;
}
.labelPopoutHeaderContainer > span {
  font-size: larger;
  color: #d1823a;
  width: 50%;
  text-align: center;
}
.labelPopoutHeaderDropDown {
  /* flex: 0.5;
  width: 50%; */
  margin: 0 !important;
  /* flex: 0.5; */
}
.salesInventoryTaxContainer {
  display: none;
}
.salesInventoryTaxContainerMobile {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.salesTaxContainer,
.InventoryTaxContainer {
  display: flex;
  font-size: large;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0;
}

.salesTaxContainer > input,
.InventoryTaxContainer > input {
  width: 50%;
  box-shadow: inset 0px 0px 9px 3px lightgrey;
  border: none;
  outline: none;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
}
.salesInventoryTaxContainerMobile .salesTaxContainer > span,
.salesInventoryTaxContainerMobile .InventoryTaxContainer > span {
  width: 50%;
  font-weight: 500;
  font-size: 15px;
}
.quotePreviewGenContainer {
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.quotePreviewGenContainer div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.quotePreviewGenContainer input {
  appearance: none;
  height: 18px;
  width: 18px;
  border: 1px solid black;
  border-radius: 50%;
}
.quotePreviewGenContainer input:checked {
  background-color: #d1823a;
  border-radius: 50%;
}
.quotePreviewGenContainer label {
  font-size: medium;
}
@media (min-width: 420px) {
  .check_payment {
    grid-template-columns: repeat(3, 1fr);
  }
  .check_payment label {
    font-size: 13px;
  }
  .percCalContainer input {
    width: 50%;
  }

  .percCalWrapper {
    padding: 1rem;
  }
  .perCalInput {
    gap: 1rem;
  }
}
@media (min-width: 720px) {
  .check_payment label {
    font-size: 18px;
  }
  .check_payment_head {
    font-size: 25px;
  }
  .percCalContainer2 span {
    font-size: 20px;
  }
  .priceFeesContainer p {
    font-size: 22px;
  }
  .quotePreviewGenContainer label {
    font-size: 20px;
  }
  .ptag_remove_mar {
    font-size: 18px;
  }
  .check_payment {
    gap: 0.5rem;
  }
  .percCalWrapperPayment {
    padding: 1rem;
  }
  .priceFeesWrapper {
    padding: 1rem;
  }
  .container {
    padding: 1rem;
  }
  .percCalContainer span {
    font-size: 20px;
  }
}
@media (min-width: 1024px) {
  .salesInventoryTaxContainer {
    display: flex;
    flex-direction: row;
  }
  .salesInventoryTaxContainerMobile {
    display: none;
  }
  .labelPopoutHeaderContainer {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
  }
  .labelPopoutHeaderDropDown {
    display: block;
  }
  .boxContainer .labelPopoutHeaderDropDown {
    display: none;
  }
  .assetSelectContainer1 {
    flex-direction: row;
  }
  .quotePreviewGenContainer {
    width: 33.33%;
  }
  .assetSelector {
    width: 40%;
    flex: 0.75;
  }
  .percentSymbolCalouter {
    right: 1rem;
    left: auto !important;
    top: -7px;
  }
  .percentSymbole {
    right: 1rem;
    left: auto !important;
    top: 4px;
  }
  .percCalContainer span {
    font-size: 16px;
  }
  .percCalContainer2 span {
    font-size: 16px;
  }
  .check_payment label {
    font-size: 14px;
  }
  .inputWithPercent {
    width: 50%;
  }
}

@media (min-width: 1440px) {
  .check_payment label {
    font-size: 17px;
  }
  .container {
    margin-bottom: 2rem;
    border: 2px solid #d1823a;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .assetSelectContainer1 {
    width: 95%;
    gap: 2rem;
  }
  .percentSymbole {
    top: 10px;
  }
  .buyerQuoteInfoParent {
    justify-content: space-around;
  }
  .buyerQuoteInfo {
    width: 95%;
  }
  .buyerQuoteInfo input {
    width: 100%;
  }
  /* .container {
    gap: 2rem;
  } */
  .saletypeDropDownContinaer {
    gap: 1.4rem;
    /* padding: 0rem 1.3rem; */
  }
  .priceFeesContainer p {
    font-size: 19px;
  }
  .priceFeesContainer input {
    width: 100%;
    font-weight: 700;
    font-size: x-large;
  }
  .for_width input {
    width: 93%;
    font-weight: 500;
    font-size: 17px;
    box-shadow: 2px 2px 2px gray;
  }
  .for_width {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }
  .percentSymbold {
    position: absolute;
    /* right: 47%; */
    left: 3%;
    font-weight: 500;
    font-size: 12px;
    pointer-events: none;
  }
  .percentSymbolSalesTax {
    position: absolute;
    /* right: 47%; */
    left: 31%;
    font-weight: bold;
    font-size: 12px;
    pointer-events: none;
  }
  .percentSymbolCal {
    position: absolute;
    right: 9%;
    bottom: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 24px;
    z-index: 10;
    color: #d1823a;
  }
  .for_text_width {
    font-size: 18px !important;
  }
  .percCalContainer span,
  .percCalContainer2 span,
  .percCalContainer3 span:first-child {
    font-weight: 500;
    font-size: 17px;
    flex: 0.3;
  }
  .percentSymbolb {
    top: 0.6rem;
  }
  .assetSelectContainer1 {
    justify-content: space-between;
  }
  .buyerQuoteInfo {
    gap: 3rem;
  }
}
@media (min-width: 1800px) {
  .assetSelector span:first-child {
    /* padding: 1rem; */
  }
  .buyerPersonalInfo p,
  .selectItem {
    font-size: x-large;
  }
  .buyerQuoteInfo {
    gap: 2rem;
  }
  .saletypeDropDownContinaer span {
    font-size: x-large;
  }
  .percCalContainer span,
  .percCalContainer2 span,
  .percCalContainer3 span:first-child {
    font-size: 19px;
  }
  .percCalWrapper {
    padding: 1rem 1.5rem;
  }
}

.percCalInput {
  display: flex;
  gap: 10px;
}

.inputWithPercent {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.percentSymbol {
  position: absolute;
  left: 10px;
  font-weight: 600;
  font-size: 18px;
  pointer-events: none; /* Prevents the symbol from interfering with input */
}
.percentSymboltexCal {
  position: absolute;
  right: 3%;
  bottom: 5px;
  color: #d1823a;
  font-weight: 600 !important;
  font-size: 24px !important;
  cursor: pointer;
}
/* -------------------------------------------------------- */
/* Hide the spinner arrows for webkit browsers */
.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner arrows for Firefox */
.numberInput {
  -moz-appearance: textfield;
}
/* --------------------------------------------------------- */
.inputWithPercent input {
  padding-left: 20px;
  width: 100%;
}

.inputWithPercentb {
  /* border: 1px solid black; */
  width: 40%;
  position: relative;
}

.inputWithPercentd {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: start;
}
.percentSymbolb {
  position: absolute;

  font-weight: 600;
  font-size: 18px;
  pointer-events: none; /* Prevents the symbol from interfering with input */
  top: 0.3rem;
  left: 6px;
}
.percentSymbole {
  position: absolute;
  left: 19rem;
  color: #d1823a;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
}
.percentSymbolCalouter {
  position: absolute;
  left: 18.3rem;
  bottom: 0.3rem;
  color: #d1823a;
  cursor: pointer;
  font-weight: 600;
  font-size: 32px;
}
.trdeIn_Popout {
  position: absolute;
  top: 5rem;
  left: 0;
  border: 1px solid black;
  background: red;
  width: 100%;
  height: 10rem;
  z-index: 10;
}
.pop_tradeIn {
  display: flex;
  justify-content: space-between;
  position: absolute;
  flex-direction: row-reverse;
  top: 5rem;
  height: 10rem;
  background-color: rgb(223 218 218);
  /* border: 1px solid grey; */
  box-shadow: rgb(116 111 111 / 60%) 3px 2px 6px 0px;
  z-index: 10;
  right: 0;
}
.submit_btn {
  position: absolute;
  right: 1rem;
  top: 8rem;
}
.submit_btn button {
  background-color: #d1823a;
  box-shadow: rgb(116 111 111 / 60%) 3px 2px 6px 0px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  width: 100px;
  border: none;
}
.cross_btn {
  position: absolute;
  right: 0.5rem;
  top: 0rem;
}
.cross_btn button {
  background-color: transparent;
  color: red;
  font-size: 18px;
  font-weight: 600;
  border: none;
}
.inputWithPercentc {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex: 0.5;
}

.percentSymbolc {
  position: absolute;
  left: 10px;
  pointer-events: none; /* Prevents the symbol from interfering with input */
}

.selectSaleType {
  background-color: #d1823a;
  color: white;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 2px 4px 1px #807c78;
}
