.container{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.container button{
    background-color: #D1823A;
    color: white;
    border: none;
    width: 43%;
    height: 40px;
    border-radius: 10px;
    box-shadow: 1px 2px 3px 2px lightgrey;
    font-family: "Alata";
    font-weight: 500;
}

.container button:hover{
    background-color: lightgray !important;
    color: black !important;
}

@media (min-width: 300px) and (max-width: 600px) {
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .container button{
        width: 50%;
    }
}