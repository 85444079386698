.ticParentWrapper {
  display: flex;
  flex-direction: column;
}
.ticLeftWrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.8rem;
}
.ticModalheader {
  width: 100%;
  text-align: center;
  font-size: x-large !important;
}
.ticModalBodyWrapper {
  padding: 0 !important;
}
.ticVinWrapper {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  gap: 0.9rem;
}
.ticVinWrapper .ticVinInputContainer {
  /* border: 1px solid black; */
  display: flex;
  width: 85%;
  border-radius: 10px;
  box-shadow: 0px 1px 8px 2px lightgrey;
}
.ticVinInputContainer > button {
  border: none;
  background-color: #d1823a;
  width: 20%;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ticVinInputContainer > input {
  border: none;
  background-color: white;
  padding-left: 4px !important;
  font-size: small;
  font-weight: 500;
  padding: 0.5rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
  border: 1px solid lightgrey;
  color: black;
  font-weight: 500;
  background-color: white;
}
.ticVinWrapper > img {
  height: 20px;
  width: 20px;
}
/* Year Make Model */
.ticYearMakeModelWrapper,
.ticMileageInspectionWrapper {
  width: 100%;
  display: flex;
  gap: 0.4rem;
  margin: 1.3rem 0;
  border-bottom: 1px solid #f3b981;
  padding-bottom: 1rem;
}
.ticYearWrapper,
.ticMakeWrapper,
.ticModelWrapper,
.ticTrimWrapper,
.ticVehicleTypeWrapper,
.ticTransmissionWrapper,
.ticDriveTypeWrapper,
.ticFuelTypeWrapper,
.ticEngineCylWrapper,
.ticSeatWrapper,
.ticCityMPGWrapper,
.ticHwyMPGWrapper,
.ticExtColorWrapper,
.ticIntColorWrapper,
.ticIntTypeWrapper,
.ticMileageContainer,
.ticTradeInValueWrapper,
.ticActualValueWrapper,
.ticInspectionDateWrapper {
  box-shadow: 0px 1px 3px 0px lightgrey;
  border: 1px solid #f3b981;
  position: relative;
  border-radius: 10px;
  flex: 1;
}
.ticYearWrapper > p,
.ticMakeWrapper > p,
.ticModelWrapper > p,
.ticTrimWrapper > p,
.ticVehicleTypeWrapper > p,
.ticTransmissionWrapper > p,
.ticDriveTypeWrapper > p,
.ticFuelTypeWrapper > p,
.ticEngineCylWrapper > p,
.ticSeatWrapper > p,
.ticCityMPGWrapper > p,
.ticHwyMPGWrapper > p,
.ticExtColorWrapper > p,
.ticIntColorWrapper > p,
.ticIntTypeWrapper > p,
.ticMileageContainer > p,
.ticTradeInValueWrapper > p,
.ticActualValueWrapper > p,
.ticInspectionDateWrapper > p {
  color: #a8a0a0;
  font-size: small;
  margin-bottom: 0;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: -11px;
  left: 4px;
  padding: 0 1px;
  background-color: white;
}
/* Mileage container */
.ticMileageContainer p {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}
.ticMileageContainer p > span:first-child {
  font-size: larger;
}
.ticYearMakeModelWrapper input,
.ticDriveFuelEngineWrapper input,
.ticEngineTypeSizeDoors input,
.ticSeatCityHwyMPGWrapper input,
.ticExtIntColorType input,
.ticMileageContainer input,
.ticTradeInValueWrapper input,
.ticActualValueWrapper input,
.ticInspectionDateWrapper > input {
  width: 100%;
  border: none;
  padding: 0.5rem;
  border-radius: 10px;
  outline: none;
  padding-left: 0.3rem !important;
  font-size: smaller;
  font-weight: 500;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ticTrimBodyTypeTransmissionWrapper select,
.ticExtIntColorType select {
  width: 100%;
  border: none;
  padding: 0.5rem;
  border-radius: 10px;
  outline: none;
  padding-left: 0.3rem !important;
  font-size: smaller;
  font-weight: 500;
  background-color: white;
}
/* trim Vehicle Type Transmission  and Drive Type ,Fuel Type ,Engine Cylinder */
.ticTrimBodyTypeTransmissionWrapper,
.ticDriveFuelEngineWrapper,
.ticEngineTypeSizeDoors,
.ticSeatCityHwyMPGWrapper,
.ticExtIntColorType,
.ticTradeInActualValueWrapper {
  width: 100%;
  display: flex;
  gap: 0.4rem;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
}
.ticExtIntColorType {
  border-top: 1px solid #f3b981;
  border-bottom: 1px solid #f3b981;
  padding: 1.4rem 0 1.4rem 0;
}
/* Hide the desktop design in moble */
.ticDesktopDesignWrapper {
  display: none;
}
@media (min-width: 420px) {
  /* Year Make Model */
  .ticYearMakeModelWrapper,
  .ticMileageInspectionWrapper {
    gap: 1rem;
  }
  /* trim Vehicle Type Transmission  and Drive Type ,Fuel Type ,Engine Cylinder */
  .ticTrimBodyTypeTransmissionWrapper,
  .ticDriveFuelEngineWrapper,
  .ticEngineTypeSizeDoors,
  .ticSeatCityHwyMPGWrapper,
  .ticExtIntColorType,
  .ticTradeInActualValueWrapper {
    gap: 1rem;
  }

  .ticTrimBodyTypeTransmissionWrapper select,
  .ticExtIntColorType select {
    padding: 0.8rem;
    padding-left: 0.3rem !important;
    font-size: small;
  }
  .ticYearMakeModelWrapper input,
  .ticDriveFuelEngineWrapper input,
  .ticEngineTypeSizeDoors input,
  .ticSeatCityHwyMPGWrapper input,
  .ticExtIntColorType input,
  .ticMileageContainer input,
  .ticTradeInValueWrapper input,
  .ticActualValueWrapper input {
    padding: 0.8rem;
    padding-left: 0.3rem !important;
    font-size: small;
  }
  .ticVinInputContainer > input {
    padding: 0.8rem;
  }
  .ticVinWrapper > img {
    height: 29px;
    width: 29px;
  }
}
@media (min-width: 1024px) {
  /* Year Make Model */
  .ticYearMakeModelWrapper,
  .ticMileageInspectionWrapper {
    gap: 2rem;
  }
  /* trim Vehicle Type Transmission  and Drive Type ,Fuel Type ,Engine Cylinder */
  .ticTrimBodyTypeTransmissionWrapper,
  .ticDriveFuelEngineWrapper,
  .ticEngineTypeSizeDoors,
  .ticSeatCityHwyMPGWrapper,
  .ticExtIntColorType,
  .ticTradeInActualValueWrapper {
    gap: 2rem;
  }
  .ticVinWrapper .ticVinInputContainer {
    width: 92%;
  }
  .ticYearWrapper > p,
  .ticMakeWrapper > p,
  .ticModelWrapper > p,
  .ticTrimWrapper > p,
  .ticVehicleTypeWrapper > p,
  .ticTransmissionWrapper > p,
  .ticDriveTypeWrapper > p,
  .ticFuelTypeWrapper > p,
  .ticEngineCylWrapper > p,
  .ticSeatWrapper > p,
  .ticCityMPGWrapper > p,
  .ticHwyMPGWrapper > p,
  .ticExtColorWrapper > p,
  .ticIntColorWrapper > p,
  .ticIntTypeWrapper > p,
  .ticMileageContainer > p,
  .ticTradeInValueWrapper > p,
  .ticActualValueWrapper > p,
  .ticInspectionDateWrapper > p {
    font-size: medium;
    font-weight: 500;
  }
  .ticYearMakeModelWrapper input,
  .ticDriveFuelEngineWrapper input,
  .ticEngineTypeSizeDoors input,
  .ticSeatCityHwyMPGWrapper input,
  .ticExtIntColorType input,
  .ticMileageContainer input,
  .ticTradeInValueWrapper input,
  .ticActualValueWrapper input,
  .ticInspectionDateWrapper > input {
    padding: 0.8rem;
    padding-left: 0.9rem !important;
    font-size: medium !important;
  }
  .ticTrimBodyTypeTransmissionWrapper select,
  .ticExtIntColorType select {
    padding: 0.8rem;
    padding-left: 0.9rem !important;
    font-size: medium !important;
  }
  .ticVinInputContainer > input {
    padding-left: 10px !important;
    font-size: medium;
  }
  .ticVinInputContainer > button {
    font-weight: 500;
  }
  .ticLeftWrapper {
    padding: 2rem 2.5rem;
    padding-bottom: 1rem;
  }
}
@media (min-width: 1440px) {
  .ticMobiledesignWrapper {
    display: none;
  }
  /* Desktop design starts here */
  .ticDesktopDesignWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .ticDesktopDesignContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  /* Desktop vin wrapper */
  .ticDesktopVinWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }
  .ticDesktopVinContainer {
    display: flex;
    align-items: center;
    border-radius: 7px;
    width: 30%;
  }
  .ticDesktopVinContainer > span {
    background-color: #d1823a;
    color: white;
    padding: 0.5rem 1rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 500;
  }
  .ticDesktopVinContainer > input {
    background-color: #eb892a4a;
    border: none;
    outline: none;
    padding: 0.5rem;
    width: 20%;
    font-weight: 500;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ticDesktopVinContainer > input::placeholder {
    font-weight: 500;
    color: black;
  }
  .ticDesktopVinWrapper > img {
    height: 30px;
    width: 30px;
  }
  /* Row 1 style */
  .ticDesktopRow1Wrapper {
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    padding-top: 0;
    gap: 1rem;
    justify-content: center;
  }
  .ticDesktopRow1Wrapper:last-child {
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    padding-top: 0;
    gap: 1rem;
    justify-content: center;
    border-top: 1px solid #d1823a;
    border-bottom: 1px solid #d1823a;
    padding: 1rem;
    margin: 1rem 0;
  }
  /* Simple Input Field style */
  .SimpleInputContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    flex: 0.2;
  }
  .SimpleInputContainer span {
    color: darkgrey;
    font-weight: 500;
  }
  .SimpleInputContainer > input {
    background-color: #eb892a4a;
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 0.5rem;
    font-weight: 500;
    width: 100%;
  }
  /* Arrow Input Container */
  .ArrowInputContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    flex: 0.2;
  }
  .ArrowInputContainer span {
    color: darkgrey;
    font-weight: 500;
  }
  .ArrowInputContainer div {
    display: flex;
  }
  .ArrowInputContainer input {
    background-color: #eb892a4a;

    outline: none;
    border: none;
    padding: 0.5rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 500;
  }
  .ArrowInputContainer > div span {
    background-color: #d1823a;
    padding: 0.5rem;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: large;
    cursor: pointer;
  }
  .ticModalOptionWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    font-weight: 500;
    font-size: large;
  }
  .ticModalOptionWrapper .ticModalOptionContainer {
    display: flex;

    gap: 0.5rem;
    font-weight: 500;
    font-size: large;
    align-items: center;
  }
  .ticModalOptionContainer > span {
    height: 14px;
    width: 14px;
    border: 1px solid #b1a8a8;
  }
}
@media (min-width: 1800px) {
  .ticDesktopRow1Wrapper {
    padding: 1rem 5rem;
    gap: 2rem;
  }
}
