/* FormsComponent.css */
.forms-container {
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  max-width: 100% !important;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border: 1px solid #d1823a;
  box-shadow: inset 0 0 4px 4px #d1823a;
  padding: 1rem 0;
}

.forms-header {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
  /* border: 1px solid #ddd; */
  /* padding-bottom: 10px; */
  position: absolute;
  top: -68px;
}

.forms-header > * {
  background-color: #e8a262 !important;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.forms-header h3 {
  margin: 0;
  color: white;
  padding: 3%;
}
.formsHeader {
  font-size: 30px !important;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-top: 10px;
}

.form-actions .btn {
  margin-left: 10px;
}

.form-check {
  margin-bottom: 10px;
}

.form-check-label {
  font-weight: 700;
  font-size: 11px;
}

.form-option {
  background-color: rgb(233, 233, 233);
  padding: 5px;
  border-radius: 10px;
  border-bottom: 1px solid black;
  box-shadow: 0px 3px 5px rgb(179, 179, 179);
}

.test > * {
  width: 100%;
}

.state-drp {
  height: 50vh;
  overflow-y: scroll;
}
.FormsDesktopBackBtnContainer {
  width: 100%;
  display: flex;
  align-items: center;
}
.FormsDesktopBackBtnContainer button {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: #e8a262;
  color: white;
  border: none;
  flex: 0.1;
}
.FormsDesktopBackBtnContainer span {
  flex: 0.9;
  text-align: center;
  font-weight: 500;
}

/* forms preview modal */
.previewModalBtnContainer {
  width: 100%;
  display: flex;
  gap: 0.4rem;
  /* margin-left: 6rem; */
  /* border: 1px solid red; */
  justify-content: center;
  /* padding-left: 8rem; */
  margin-bottom: 0.4rem;
  align-items: center;
}
.previewModalBtnContainer button:first-child {
  border-radius: 10px;
  height: 50px;
  width: 50px;
  color: white;
  background-color: rgb(172, 172, 172);
  display: grid;
  place-content: center;
}
.previewModalBtnContainer > span {
  border: 2px solid black;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-content: center;
  font-weight: 700;
  box-shadow: 2px 4px 7px 0px lightgrey;
  font-size: larger;
}
.previewModalBtnContainer > span:hover {
  background-color: rgb(236, 233, 233);
  transform: scale(1.1);
  transition: transform ease-in 0.1s;
  cursor: pointer;
}
.previewModalBtnContainer button {
  border: 1px solid #e8a262;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  border-radius: 10px;
  background-color: #e8a262;
  color: white;
  /* width: 233px; */
  font-weight: 500;
  font-size: larger;
}
.previewModalBtnContainer button:hover {
  transition: all ease-in-out 0.2s;
  background-color: grey;
}
.boxShadowInset {
  box-shadow: inset 0 0 30px #dd8a3f;
}
/* Outer check box container design */
.formsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.outerCheckBoxChild .form-check-input {
  display: none !important;
}
.outerSelectAllLabel {
  font-size: medium;
  color: #8d8a8a;
}
.outerSelectAllContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
.outerCheckBoxElContainer {
  display: flex;
  flex-direction: column;
  /* align-items: end; */
  gap: 1.2rem;
}
.outerCheckBoxEl {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
}
.outerCheckBoxEl > span {
  position: absolute;
  right: 0.3rem;
  color: red;
  cursor: pointer;
}
.outerCheckBoxChild {
  display: flex !important;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  padding-left: 30px !important;
  width: 100%;
  background-color: rgb(252, 232, 207);
  padding: 13px;
}
.outerCheckBoxChild input {
  background-color: lightgrey !important;
  position: relative;
  border: none;
  /* display: none; */
}
.outerCheckBoxChild input {
  background-color: lightgrey !important;
  position: relative;
  border: none;
}

.outerBtnContainer {
  display: flex;
  margin-top: 1.5rem;
  gap: 0.4rem;
}
.outerBtnContainer button {
  color: white;
  background-color: #dd8a3f !important;
  border: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.OuterAllformsBtnContainer,
.allFormsPopoutAddBtnContainer {
  display: none;
}

/* Custom checkbox */
.custom-checkbox {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  /* border: 2px solid #eee7e7d8; Border color */
  border-radius: 4px; /* Rounded corners */
  display: inline-block;
  position: relative;
  margin-right: 20px; /* Space between checkbox and label */
  background-color: white;
  margin-bottom: -4px;
}

.custom-checkbox.OrangeCheckBox {
  background-color: white;
}

.custom-checkbox.OrangeCheckBox::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 3px;
  width: 6px; /* Adjust size */
  height: 12px; /* Adjust size */
  border: solid #d1823a; /* Checkmark color */
  border-width: 0 3px 3px 0; /* Create a checkmark */
  transform: rotate(45deg); /* Rotate to form a checkmark */
}

.outerBtnContainer button span:nth-child(2) {
  width: 72px;
}
.allFormsHeaderItemsContainer {
  display: flex;
  gap: 0.4rem;
  margin-bottom: 1rem;
}
.allFormsHeaderItemsContainer .allFormsHeaderItem {
  flex: 1;
}
.allFormsHeaderItemsContainer .allFormsHeaderItem:nth-child(2) {
  position: relative;
}
.allFormsHeaderItemsContainer .allFormsHeaderItem:nth-child(2) span {
  position: absolute;
  right: 1rem;
  top: 23%;
}
.allFormsHeaderItemsContainer .allFormsHeaderItem button,
.allFormsHeaderItemsContainer .allFormsHeaderItem input {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  height: 50px;
  font-size: small;
}
.innerBtn {
  height: 58px;
  width: 50%;
  border-radius: 10px;
  font-size: larger;
}
.allFormsModalHeader {
  width: 100%;
  text-align: center;
  font-size: larger;
  color: #d1823a;
  font-weight: 500;
}
/* Loader CSS */
.pdfLoaderWrapper {
  width: 100%;
  border: 1px solid #d1823a;
  height: 0.3rem;
}
.pdfLoaderWrapper .pdfLoaderContainer {
  background-color: #d1823a;
  height: 100%;
  animation: 2.5s ease-in pdfLoader;
  position: relative;
}
.pdfLoaderContainer > span {
  position: absolute;
  right: 0;
  top: 0;
  font-size: small;
  font-weight: 500;
}
.allFormsPopupBodySoldDesktop {
  padding: 0 !important;
}
@keyframes pdfLoader {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@media (min-width: 425px) {
  .outerBtnContainer {
    gap: 1rem;
    justify-content: center;
  }

  .outerBtnContainer button {
    gap: 1rem;
  }
  .outerBtnContainer button span:nth-child(2) {
    /* border: 1px solid black; */
    width: 100px;
  }
}

@media (min-width: 576px) {
  .modal-dialog.modal-70w {
    max-width: 70vw !important;
  }
  .modal-dialog.modal-80w {
    max-width: 80vw !important;
  }
}
@media (min-width: 720px) {
  .formsContainer {
    padding: 1rem 2rem;
  }

  .previewModalBtnContainer button {
    width: 20%;
  }
  .outerCheckBoxElContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .outerBtnContainer {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  .formsHeader {
    font-size: 35px !important;
  }
  .innerBtn {
    width: 25%;
  }
  .outerBtnContainer button:last-child {
    color: white;
    background-color: #dd8a3f !important;
    border: none;
    display: none !important;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
  }
  .OuterAllformsBtnContainer,
  .allFormsPopoutAddBtnContainer {
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .OuterAllformsBtnContainer button,
  .allFormsPopoutAddBtnContainer button {
    color: white;
    background-color: #dd8a3f !important;
    border: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    font-size: larger;
    font-weight: 500;
  }
  .allFormsPopupBodySoldDesktop {
    padding: 1rem !important;
  }
}
@media (min-width: 1024px) {
  .form-check-label {
    font-weight: 700;
    font-size: 15px;
  }
  .formsContainer {
    padding: 0 3rem;
  }
  .outerCheckBoxChild {
    padding-left: 60px !important;
  }
  .outerBtnContainer button {
    font-size: x-large;
    padding: 1rem 3rem !important;
  }
  .outerBtnContainer button:hover {
    font-weight: 700;
    color: white !important;
  }
  .outerBtnContainer button span:nth-child(2) {
    width: 180px;
  }
  .innerBtn {
    font-size: large !important;
  }
  .allFormsHeaderItemsContainer .allFormsHeaderItem button,
  .allFormsHeaderItemsContainer .allFormsHeaderItem input {
    font-size: large;
  }
  .allFormsModalHeader {
    font-weight: 700;
    font-size: x-large;
  }
  .OuterAllformsBtnContainer button {
    gap: 0.4rem;
    padding: 0.3rem 1rem;
    font-size: x-large;
  }
  .allFormsPopupBodySoldDesktop {
    padding: 1rem !important;
  }
}
@media (min-width: 1440px) {
  .previewModalBtnContainer {
    gap: 1.4rem;
  }
  .previewModalBtnContainer button {
    font-size: x-large;
  }
  .formsContainer {
    padding: 0 10rem;
    padding-bottom: 3rem;
  }
  .outerCheckBoxElContainer {
    gap: 1rem;
  }
  .outerSelectAllLabel {
    font-size: larger;
  }
  .formsHeader {
    font-size: 43px !important;
  }
  .allFormsPopupBody {
    padding: 0 4rem !important;
  }
  .allFormsModalHeader {
    font-size: xx-large;
  }
  .allFormsPopupBodySoldDesktop {
    padding: 1rem !important;
  }
}
@media (min-width: 1800px) {
  .formsContainer {
    padding: 0 24rem;
    padding-bottom: 4rem;
  }
  .form-check-label {
    font-size: 20px;
  }
  .allFormsPopupBody {
    padding: 0 15rem !important;
  }
  .innerBtn {
    font-size: x-large !important;
    font-weight: 500;
  }
  .allFormsPopupBodySoldDesktop {
    padding: 1rem !important;
  }
}

.footer-btn-add-forms {
  background-color: #e8a262;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.footer-btn-add-forms:hover {
  background-color: gray;
  color: white;
}
