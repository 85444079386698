/* RightContent.css */
.right-content {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
}

.vehicle-info h2 {
    font-size: 1.5em;
    font-weight: bold;
}

.vehicle-info p {
    color: #666;
}

.sale-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.sale-price,
.sell-type {
    text-align: center;
}

.price-tag,
.type-tag {
    background-color: #e8a262;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
}

.buttons {
    margin: 20px 0;
}

/* RightContent.css */
.arrow-icon {
    fill: white;
    margin-right: 5%;
    min-width: 40%;
}

.buyer-info-btn,
.forms-btn {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #e8a262 !important;
    color: #fff;
    font-weight: bold;
    border-radius: 0px !important;
}

.ttl-right .form-control {
    width: 40% !important;
    padding: 5px !important;
}

.form-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
}

.form-content .form-group {
    margin-bottom: 15px;
}

.form-content label {
    font-weight: bold;
    color: #333;
}

.form-content .total-due,
.form-content .balance-due {
    font-weight: bold;
    color: #d9534f;
}


.ttl-right {
    border: 3px solid #e8a262;
    border-radius: 5px;


}

.sale-p {
    background-color: #d49a9b !important;
    /* border-color: red !important; */
}

.total-due{
    background-color: #e8a262 !important;
    border-color: rgb(255, 123, 0)!important;
}

.client-icon,
.dealer-icon {
    width: 20px;
    height: 20px;
    text-align: center;
    padding-bottom: 5px;
    font-size: .9rem;
    position: absolute;
    top: 0;
    left: 0;
   
}

.client-icon{
    background-color: #fe867c;
}

.dealer-icon {
    background-color: #6c9a64; 
}

.bg-light-orange {

    background-color: #fbeee3 !important;
}