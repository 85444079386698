.tabsMainContainer{
    border: none;
}
.tabsMainContainer .tab-content{
   border: none !important;
}

.tab_header {
    border: none;
    height: 60px;
    width: 60px;
    cursor: pointer;
    font-size: 16px;
    background-color: grey;
    color: white;
    border-radius: 50%;
    font-weight: normal;
    transition: all 0.3s ease;
  }
  
  .tab_header.active {
    font-weight: bold;
    background-color: #D1823A;
    color: white;
    border-radius: 50%;
  }
  
  /* .tab_header:hover {
    color: black;
    background-color: lightgrey;
  } */
  
  .tab_headers_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .tab_button {
    border-right: 1px solid #ccc;
    padding-right: 15px;
    margin-right: 15px;
  }
  
  .tab_button:last-child {
    border-right: none;
  }
  
  .tab_button_container {
    display: flex;
    justify-content: space-between;
  }

  .horizontalLine {
    width: 5rem; /* Width of the line */
    height: 2px; /* Height of the line */
    background-color: #ccc; /* Color of the line */
    align-self: center; /* Center the line vertically with respect to buttons */
  }
.backNextButton button{
  background-color: #D1823A;
  color: white;
  border-radius: 6px;
  border: none;
  width: 100px;
  height: 35px;
  box-shadow: lightgrey 0px 3px 5px 1px;
}
.TabText{
  position: absolute;
  top: 100%;
  font-size: 8px;
  font-weight: 600;
}
.MOdalXl{
  width: 90%;
}
/* styles.module.css */
.MOdalXl {
  max-width: 1200px; /* Default size */
}

@media (max-width: 768px) {
  .MOdalXl {
    max-width: 90% !important; /* Smaller size for mobile */
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .MOdalXl {
    max-width: 90% !important; /* Medium size for tablets */
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .tab_header {
    border: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
    font-size: 16px;
    background-color: grey;
    color: white;
    border-radius: 50%;
    font-weight: normal;
    transition: all 0.3s ease;
  }
}