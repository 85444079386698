.wrapper {
  width: 100%;
  padding: 1rem 0;
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container .buildBtnContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
}
.buildBtnContainer button {
  background-color: maroon;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  color: white;
  font-size: 20px;
}
.bqTableContainer {
  width: 100%;
}
.bqTableContainer thead {
  width: 100%;
}

.bqTableContainer thead > tr > * {
  background-color: #d1823a;
  border-right: 1px solid white;
  text-align: center;
  color: white;
  padding: 0.3rem;
  font-weight: 400;
  flex: 1;
}
.bqTableContainer thead > tr > th:first-child {
  border-top-left-radius: 7px;
}
.bqTableContainer thead > tr > th:last-child {
  border-top-right-radius: 7px;
}
.bqTableContainer tbody > tr > td {
  width: auto;
  text-align: center;
  background-color: antiquewhite;
  padding: 0.3rem 0;
  border-left: 1px solid white;
  border-top: 1px solid white;
  font-weight: 500;
}
.bqTableContainer tbody > tr:nth-child(2n+1) > td {
  width: auto;
  text-align: center;
  background-color: rgb(222 216 216);
  padding: 0.3rem 0;
  color: black;
  border-left: 1px solid white;
  border-top: 1px solid white;
  font-weight: 500;
}
.bqTableContainer tbody > tr > td > button {
  border: none;
  background-color: #d1823a;
  border-radius: 7px;
  color: white;
  padding: 0.3rem 0.7rem;
}
.bqTableContainer .hidden {
  color: transparent;
}
.bqTableContainer .hidden button {
  display: none;
}
.bqTableContainer .hidden:last-child > td {
  background-color: #d1823a;
  border: none;
}
.bqTableContainer .hidden:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}
.bqTableContainer .hidden:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}
