.wrapper {
  width: 100%;
  margin-bottom: 1rem;
}
.container {
  width: 100%;
  /* border: 1px solid #d1823a; */
  background-color: #ffa0474a;
  padding: 1rem 0.5rem;
  border-radius: 10px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1823a;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.header .subHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.header .subHeader > span:first-child {
  color: rgb(88 75 75 / 84%);
  font-weight: 500;
  font-size: small;
}
.header .subHeader span:last-child {
  font-weight: 500;
  font-size: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contentLine {
  display: flex;
  justify-content: space-between;
  font-size: smaller;
  font-weight: 500;
}
.contentLine p {
  margin-bottom: 0;
}
.contentLine span {
  font-size: small;
  font-weight: 700;
}
.contentLine button {
  background-color: #d1823a;
  color: white;
  border: none;
  padding: 0.1rem 0.3rem;
  border-radius: 3px;
}
@media (min-width: 400px) {
  .contentLine button {
    width: 30%;
  }
}
@media (min-width: 500px) {
  .header .subHeader > span:first-child {
    font-size: large;
  }
  .header .subHeader > span:last-child {
    font-size: 24px;
  }
  .header .subHeader > span:last-child span {
    font-size: larger;
  }
  .contentLine {
    font-size: medium;
  }
  .contentLine span {
    font-size: 20px;
  }
  .contentLine button {
    width: 30%;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  }
}
