.custom-datepicker {
    /* Custom styles for the datepicker */
}

.react-datepicker__header {
    background-color: #f0f0f0;
    /* Change header background color */
}

.react-datepicker__day--selected {
    background-color: #007bff;
    /* Change selected day background color */
    color: white;
    /* Change text color */
}

/* custom-datepicker.css */
.custom-datepicker {
    font-family: Arial, sans-serif;
    /* Custom font */
}

.custom-datepicker .react-datepicker__header {
    background-color: #4CAF50;
    /* Custom header background color */
    border: none;
    /* Remove default border */
}

.custom-datepicker .react-datepicker__current-month {
    color: #fff;
    /* Custom month text color */
}

.custom-datepicker .react-datepicker__day--selected {
    background-color: #FF5722;
    /* Custom selected day background color */
    color: #fff;
    /* Custom selected day text color */
}

.custom-datepicker .react-datepicker__day--hovered {
    background-color: #FFC107;
    /* Custom hovered day background color */
}

.custom-datepicker .react-datepicker__day--today {
    font-weight: bold;
    /* Highlight today's day */
}

.custom-datepicker .react-datepicker__time-container {
    background-color: #e0f2f1;
    /* Custom time picker background color */
}

.custom-datepicker .react-datepicker__time {
    color: #fcc07bda;
    /* Custom time text color */
}

.custom-datepicker .react-datepicker__time-box {
    border-radius: 5px;
    /* Custom time box border radius */
}

.custom-datepicker .react-datepicker__triangle {
    display: none;
    /* Hide the triangle */
}

/* custom-datepicker.css */

/* Container styles */
.custom-datepicker {
    font-family: 'Arial', sans-serif;
    /* Customize font */
}

/* Header styles */
.custom-datepicker .react-datepicker__header {
    background-color: #4CAF50;
    /* Header background color */
    border: none;
    /* Remove default border */
    color: #fff;
    /* Header text color */
}

.custom-datepicker .react-datepicker__current-month {
    font-size: 1.2em;
    /* Increase month font size */
    font-weight: bold;
    /* Bold month text */
}

/* Day styles */
.custom-datepicker .react-datepicker__day {
    color: #333;
    /* Default day text color */
}

.custom-datepicker .react-datepicker__day--selected {
    background-color: #FF5722;
    /* Background color for selected day */
    color: #fff;
    /* Text color for selected day */
}

.custom-datepicker .react-datepicker__day--hovered {
    background-color: #FFC107;
    /* Background color for hovered day */
    color: #000;
    /* Text color for hovered day */
}

/* Weekday styles */
.custom-datepicker .react-datepicker__day--weekend {
    color: #F44336;
    /* Color for weekend days */
}

/* Today’s day styles */
.custom-datepicker .react-datepicker__day--today {
    font-weight: bold;
    /* Bold font for today's date */
    border: 1px solid #2196F3;
    /* Border for today's date */
    border-radius: 50%;
    /* Round border for today's date */
}

/* Time picker styles */
.custom-datepicker .react-datepicker__time-container {
    background-color: #e0f2f1;
    /* Background color for time picker */
}

.custom-datepicker .react-datepicker__time {
    color: #fcc07bda;
    /* Text color for time picker */
}

.custom-datepicker .react-datepicker__time-box {
    border-radius: 5px;
    /* Rounded corners for time box */
}

/* Arrow styles */
.custom-datepicker .react-datepicker__triangle {
    display: none;
    /* Hide the triangle */
}

.custom-datepicker .react-datepicker__week,
.custom-datepicker .react-datepicker__day-names {
    display: flex !important;
    justify-content: space-around !important;
}






/* custom-calendar.css */

/* General Calendar Styles */
.custom-calendar {
    font-family: 'Arial', sans-serif !important;
    max-height: 50vh;
    /* Change the font */
}

/* Header Styles */
.custom-calendar .rbc-header {
    /* background-color: #4CAF50; */
    /* Header background color */
    /* color: #fff; */
    /* Header text color */
    font-weight: bold;
    /* Bold header text */
}

/* Event Styles */
.custom-calendar .rbc-event {
    background-color: #FF5722;
    /* Event background color */
    color: #fff;
    /* Event text color */
    border-radius: 5px;
    /* Rounded corners for events */
}

/* Selected Event Styles */
.custom-calendar .rbc-event.selected {
    background-color: #fcc07bda;
    /* Color for selected events */
}

/* Day Styles */
.custom-calendar .rbc-day-slot {
    border: 1px solid #ddd;
    /* Border around day slots */
}

.custom-calendar .rbc-day-bg {
    background-color: #e0f2f1;
    /* Background color for days */
}

/* Current Day Highlight */
.custom-calendar .rbc-current-time {
    background-color: #FFC107;
    /* Highlight current time */
    color: #000;
    /* Color of the text for current time */
}

/* Time Slot Styles */
.custom-calendar .rbc-time-slot {
    border: 1px solid #ddd;
    /* Border around time slots */
}

/* Toolbar Styles */
.custom-calendar .rbc-toolbar {
    background-color: #f0f0f0;
    /* Background color for toolbar */
    padding: 10px;
    /* Padding around toolbar */
}

.custom-calendar .rbc-toolbar button {
    background-color: #fcc07bda;
    /* Background color for toolbar buttons */
    color: #fff;
    /* Color for toolbar button text */
    border: none;
    /* Remove button border */
    padding: 5px 10px;
    /* Button padding */
    border-radius: 3px;
    /* Rounded corners for buttons */
}

/* Today Button */
.custom-calendar .rbc-toolbar .rbc-btn {
    font-size: 16px;
    /* Font size for buttons */
}






/* Custom Calendar Styles */

/* Toolbar Styles */
.custom-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 5px; */
    background-color: #ffdaafda !important;
    margin-bottom: 4%;
    /* Toolbar background color */
}

.toolbar-btn {
    background-color: #ffdaafda !important;
    /* Button background color */
    color: #ffdaafda !important;
    /* Button text color */
    border: none;
    padding:0px !important;
    border-radius: 3px;
    cursor: pointer;
}

.toolbar-btn svg {
    color: #ff9b29da;
    font-size: 2rem;
}

.toolbar-label {
    font-size: .8em;
    text-align: center;
    text-wrap: nowrap;
    color: black;
    /* Label text color */
}






/* Day View Styles */
.custom-day-view {
    margin: 10px;
}

.days-header,
.days-content,
.rbc-month-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border-bottom: 1px solid gray;
}

.rbc-month-header>* {
    text-align: center;
}

.day-header,
.day-content {

    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    /* Border around days */
}

.day-header {
    background-color: #f0f0f0;
    /* Background color for header */
    font-weight: bold;
    /* Bold font for day names */
}

.day-content {
    background-color: #e0f2f1;
    /* Background color for day content */
}

.day-date {
    font-size: 1.2em;
    /* Font size for day dates */
}


.rbc-header span {
    font-size: .75rem;
    background-color: white;
    padding: 0px 1px;
}

.rbc-row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.rbc-row>* {
    text-align: center;
}

.rbc-off-range button {
    color: gray;
}

.rbc-date-cell button,
.rbc-off-range button {
    border: none;
    background-color: white !important;
    font-size: .7rem;

    text-align: center;
}

.rbc-button-link {
    padding: 0px !important;
}

.rbc-month-view {
    padding-bottom: 8%;
    border-bottom: 1px solid gray;
}

.rbc-current button {
    background-color: #21ecf3 !important;
    border-radius: 50% !important;
    padding: 1px 3px !important;
}

.rbc-now button {
    background-color: #2196F3 !important;
    border-radius: 50% !important;
    padding: 1px 3px !important;
}