.CalculatorSubParent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem;
  margin-bottom: 2rem;
}
.autoLoanContainerHeaderDesktop {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
  display: none;
}
.mlcMobileResult {
  text-align: center;
  border-bottom: 1px solid black;
  margin: 1rem 4rem;
  font-size: x-large;
  font-weight: 500;
}
.mlcMobileResult span {
}
.mlcApprovedBtnContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mlcApprovedBtnContainer span {
  font-weight: 500;
  /* margin-bottom: 2rem; */
  font-size: x-large;
}
.mlcApprovedBtnContainer button {
  border-radius: 22px;
  padding: 0.1rem 2rem;
  font-weight: 600;
  font-size: large;
  background-color: #d1823a;
  border: none;
  padding: 0.3rem 0.9rem;
  color: white;
  box-shadow: 3px 5px 3px lightgrey;
  font-size: x-large;
}
.CalculatorSecondSubHalfPart {
  display: none;
}
.CalculatorSubHalfPart:nth-child(1) {
  box-shadow: 0px 5px 14px #b5adad;
  background-color: white;
  /* padding: 1rem; */
}
.mlcApprovedBtnContainerMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.mlcApprovedBtnContainerMobile span {
  font-size: large;
  font-weight: 500;
}
.mlcApprovedBtnContainerMobile button {
  background-color: #d1823a;
  color: white;
  border: none;
  padding: 0.3rem 1.4rem;
  border-radius: 22px;
  box-shadow: 3px 5px 5px lightgrey;
}
.mlcApproxCreditScoreContainer {
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid lightgrey;
}
.mlcMobileHeader {
  text-align: center;
}
@media (min-width: 720px) {
  .mlcMobileResult {
    margin: 1rem 14rem;
  }
  .CalculatorSubHalfPart:nth-child(1) {
    padding: 0 2rem;
  }
}

@media (min-width: 1024px) {
  .mlcMobileResult {
    display: none;
  }
  .autoLoanContainerHeaderDesktop {
    display: block;
    font-size: xx-large;
  }
  .CalculatorSecondSubHalfPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding-bottom: 2rem;
  }
  .mlcApproxCreditScoreContainer {
    border-bottom: none;
  }
  .mlcApprovedBtnContainerMobile {
    display: none;
  }
  .mlcMobileHeader {
    display: none;
  }
}

@media (min-width: 1440px) {
  /* .CalculatorParent {
    max-width: 1600px !important;
  } */
  .CalculatorSubParent {
    flex-direction: row;
    padding: 2rem 8rem !important;
    gap: 5rem;
  }
  .CalculatorSubHalfPart:nth-child(1) {
    flex: 0.5;
    box-shadow: 0px 5px 14px #b5adad;
    background-color: white;
    padding: 1rem;
  }
  .CalculatorSubHalfPart:nth-child(2) {
    margin-top: 10rem;
    flex: 0.5;
  }
}
@media only screen and (min-width: 991px) {
  .clc-col {
    width: 1400px;
  }

  .calc-first-col {
    padding-right: 78px !important;
  }

  .calc-second-col {
    padding-left: 78px !important;
  }
}

.input-container-percentage {
  position: relative;
  display: inline-block;
  width: 100%;
}

.form-control {
  padding-right: 30px;
  /* Add padding to ensure text doesn't overlap with the symbol */
}

.percentage-symbol {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust the distance from the right edge */
  transform: translateY(-50%);
  pointer-events: none;
  /* Make sure the symbol doesn't interfere with input */
  color: #000;
  /* Set the color of the percentage symbol */
}

.est-monthly-heading-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dfc19b;
  height: 13%;
  margin-left: 4%;
  border-radius: 6px;
}

.txt-btn-car-details-home button {
  border: none;
  border-radius: 5px;
  border: 1px solid gray;
  width: 90px;
  height: 50px;
}

.txt-btn-car-details-home button p {
  font-size: 15px;
  margin: 0px;
}

.text-box-cal {
  background-color: #dfc19b;
  text-align: center;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  border-radius: 6px;
}

.text-box-cal p {
  padding-top: 11px;
}

:root {
  --font-family-inter: "Inter", sans-serif;
}

.text {
  font-size: 128px;
  font-family: var(--font-family-inter);
  font-weight: normal;
  line-height: normal;
}

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ellipse-1046-3 {
  position: absolute;
  left: 12px;
  top: 10px;
  width: 302px;
  height: 293px;
  z-index: 100;
}

.ellipse-1047-4 {
  position: absolute;
  left: 38px;
  top: 33px;
  width: 252px;
  height: 244px;
  border: 14px solid rgba(232, 162, 98, 1);
  z-index: 100;
}

.ellipse-1048-5 {
  position: absolute;
  left: 206px;
  top: 43px;
  width: 84px;
  height: 112px;
  border: 14px solid rgba(154, 120, 89, 1);
  z-index: 100;
}

.ellipse-1049-6 {
  position: absolute;
  left: 38px;
  top: 33px;
  width: 252px;
  height: 244px;
  border: 14px solid rgba(212, 196, 180, 1);
  z-index: 100;
}

.group-1000002437-2 {
  position: absolute;
  left: 12px;
  top: 10px;
  width: 302px;
  height: 293px;
  z-index: 100 !important;
}

.frame-1479-1 {
  position: absolute;
  left: 2353px;
  top: -1456px;
  width: 326px;
  height: 322px;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 100;
}
