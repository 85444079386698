.wrapper {
  padding: 1rem 0;
  font-family: "Alata", serif;
}

.container {
  /* border: 2px solid orange; */
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 3rem;
  grid-auto-flow: column dense;
  /* gap: 0.6rem; */
}
.modalContainer {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 3rem;
  grid-auto-flow: column dense;
  border: #d1823a 1px solid;
  border-radius: 10px;
  padding: 4px;
  /* gap: 0.6rem; */
}
.pricefeesInputRelative {
  position: relative;
}
.pricefeesInputRelative .box_b_none_edit_add_button_big_Screen {
  display: none;
  position: absolute;
  top: 3rem;
  right: 40%;
}
.inputContainer {
  margin-bottom: 0.4rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.inputContainer:nth-child(-n + 10) {
  grid-column: 1;
}

.inputContainer:nth-child(n + 11):nth-child(-n + 20) {
  grid-column: 1;
}
/* .inputContainer:nth-child(n+11):nth-child(-n+20) {
  grid-column: 2;
} */

.inputContainer .headerInputLabel {
  flex: 1;
}

.inputContainer .headerInputLabel:focus {
  border: 1px solid grey;
}

.inputContainer .inputLabelContainer {
  width: 40%;
  flex: 1;
  font-weight: 500;
  font-size: 14px;
  display: flex !important;
  align-items: center;
  gap: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  justify-content: space-between !important;
  margin-bottom: 0 !important;
}
.inputContainer .inputLabelContainerPopup {
  width: 40%;
  flex: 1;
  font-weight: 500;
  font-size: 11px;
  display: flex !important;
  align-items: center;
  gap: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  justify-content: space-between !important;
  margin-bottom: 0 !important;
}
.labelContainer {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.inputLabelContainerPopup .labelContainer span:first-child {
  border: 0.1px solid #d1823a;
  background-color: #f7dece;
  padding: 0.1rem 0.2rem;
  border-radius: 4px;
}

.editDeleteContainer {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #777472f7;
  font-size: larger;
}
.editDeleteContainer span:nth-child(2),
.editDeleteContainer span:nth-child(3) {
  cursor: pointer;
}

.inputContainer .inputField {
  flex: 0.35;
  border: none;
  border: none;
  box-shadow: 2px 2px 2px gray;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  padding: 7px;
  padding-left: 10px;
  box-shadow: inset 0px 0px 8px 1px gray, 3px 3px 3px #c9c4c4;
  border-radius: 10px;
}

.inputContainer input:focus {
  outline: none;
  border: none;
}
.box_b_none_edit_input_containerPopout {
  position: relative;
  flex: 0.3;
}
.box_b_none_edit_input_container {
  position: relative;
  flex: 0.3;
}
.box_b_none_edit_input_container .box_b_none_edit_dollar_absolute_sign {
  position: absolute;
  left: 0.4rem;
  top: 23%;
  font-size: 12px;
  color: black;
  font-weight: 700;
}
.box_b_none_edit_input_containerPopout .box_b_none_edit_dollar_absolute_sign {
  position: absolute;

  font-size: 12px;
  color: black;
  font-weight: 700;
  left: 0.3rem;
  top: 22%;
}
.labelOrderIndexInput {
  height: 21px;
  width: 21px !important;
  padding: 0 !important;
  font-size: small;
}
.labelOrderIndexInput:focus {
  border: 1px solid black !important;
}

@media (min-width: 420px) {
  .box_b_none_edit_input_container .box_b_none_edit_dollar_absolute_sign {
    left: 0.5rem;
    top: 25%;
  }
  .inputContainer .inputLabelContainer {
    font-size: 15px;
    white-space: initial;
  }
  .box_b_none_edit_input_container {
    flex: auto;
    width: 29%;
  }
  .inputContainer .inputLabelContainer {
    width: 60%;
    flex: auto;
  }
  .inputContainer .inputField {
    font-size: 15px;
  }
}
@media (min-width: 1024px) {
  .wrapper {
    /* padding: 1rem 0 0 3rem; */
  }
  .container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    grid-auto-flow: column dense;
    /* gap: 0.6rem; */
    row-gap: 0.4rem;
  }

  .inputContainer:nth-child(n + 11):nth-child(-n + 20) {
    grid-column: 2;
  }
  .inputLabelContainerPopup .labelContainer span:first-child {
    padding: 0.1rem 0.4rem;
  }

  .pricefeesInputRelative {
    /* border-bottom: #d1823a 1px solid; */
    /* padding-bottom: 1rem;
    margin-bottom: 1rem; */
  }

  .pricefeesInputRelative .box_b_none_edit_add_button_big_Screen {
    display: block;
    position: absolute;
    top: 4rem;
    right: 40%;
  }
  .modalContainer {
    border: none;
    padding: 0;
  }
  .inputContainer .inputLabelContainer {
    font-size: 13px;
  }
  .inputContainer .inputField {
    font-size: 13px;
  }
  .box_b_none_edit_input_container .box_b_none_edit_dollar_absolute_sign {
    left: 0.8rem;
    top: 22%;
    font-weight: 500;
  }
}
@media (min-width: 1440px) {
  .labelContainer {
    gap: 0.5rem;
  }
  .inputContainer .inputLabelContainer {
    font-size: 18px;
    border-radius: 13px;
  }
  .inputContainer .inputLabelContainerPopup {
    font-size: 18px;
  }
  .inputContainer .inputField {
    color: darkslategrey;
    font-size: 17px;
  }
  .container {
    column-gap: 3rem;
  }
}
@media (min-width: 1800px) {
  .inputContainer .inputLabelContainer {
    font-size: 19px;
    border-radius: 13px;
    font-weight: 500;
  }
  .inputContainer .inputLabelContainerPopup {
    font-size: 20px;
  }
  .inputContainer .inputField {
    font-size: 20px;
    color: dimgrey;
    font-weight: 700;
  }
}
