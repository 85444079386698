.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  position: relative;
  padding-top: 3rem;

  background: linear-gradient(
    to top,
    #d1823a 0%,
    #da8c43b4 80%,
    #ac7a1ee6 80%,
    #a3b5b5b2 100%
  );
}
.QueryVisibilityTrackerContainer {
  position: absolute;
  font-size: x-large;
  top: 1rem;
  right: 1rem;
}
.dataContainer {
  /* border: 1px solid black; */
  width: 165%;
  height: 95%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 3rem;
  background-color: white;
}

.dataHeaderContainer {
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  justify-content: center;
  align-items: center;
}
.dataHeaderContainer img {
  height: 100px;
  width: 100px;
}
.dataHeaderContainer span {
  font-size: large;
  font-weight: 600;
}
.loginText {
  color: rgb(99, 95, 95);
  text-align: center;
  width: 100%;
  font-weight: 500;
}
.NavBtnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.NavBtnContainer button {
  background-color: #d1823a;
  border: none;
  border-radius: 5px;
  padding: 1.4rem 0.56rem;
  font-weight: 500;
  font-size: large;
}
