.wrapper {
  width: 100vw;
}

.container {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 1rem;
  padding-right: 3rem;
  margin-bottom: 0.5rem;
}

.container a {
  color: white;
  border-radius: 5px;
  background-color: #E8A262;
  padding: 0.5rem 0.9rem;
}

.container button {
  color: white;
  border-radius: 5px;
  background-color: #E8A262;
  padding: 0.5rem 0.9rem;
}

.linkOrange:hover {
  color: black;
  background-color: lightgray !important;
  box-shadow: 5px 5px 5px rgb(173, 173, 173) !important;
}

.user_action_mobile_items {
  position: absolute;
  z-index: 9999;
  background-color: white;
  top: 40px;
  right: -10%;
  gap: 3px !important;
  padding: 3px;
  font-size: large;
  width: 250%;
  border-radius: 5px;
}

.user_action_mobile_items p {
  background-color: #E8A262;
  padding: 3px;
  align-items: center;
  margin: 3px !important;
  border-radius: 5px;
}

.user_action_mobile_items a {
  color: white;
  text-decoration: none;
}