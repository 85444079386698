/* .modal-dialog {
  width: 10vw !important;
}
.modal-minimised {
  width: 4vw !important;
  z-index: -1 !important;
  pointer-events: none !important;
}
.modal-backdrop.show {
  opacity: 0.2 !important;
} */

/*  */
.main-container {
  padding: 0%;
  margin: 0%;
}

.main-input-fields {
  width: 95%;
  height: 90%;
  /* display: flex; */
}

.input-contant-container {
  height: 90%;
  min-width: 90%;
  /* border: 3px solid #e8a262; */
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

#btn-buyer-payment {
  display: inline-block;
  /* border: 1px solid #e8a262; */
  /* width: 98% !important; */
}

/* #btn-buyer-payment .nav-tabs {
    box-shadow: 0px 4px 3px rgb(179, 179, 179);
} */

#btn-buyer-payment .nav-tabs .nav-item {
  width: 150px !important;
  /* height: 100px !important;  */
  border: 1px solid #e8a262;
}

#btn-buyer-payment .nav-tabs .nav-item a {
  width: 100% !important;
}

#btn-buyer-payment .nav-tabs .nav-item .nav-link {
  box-sizing: border-box;
  border: none !important;
  color: #e8a262;
  font-weight: 700;
  height: inherit;
  /* padding-top: 30%; */
  text-align: center;
}

#btn-buyer-payment .nav-tabs .nav-item .nav-link.active {
  background-color: #e8a262;
  color: white !important;
}

.nav-link.disabled {
  color: gray !important;
  pointer-events: none;
  cursor: default;
}

@media screen and (max-width: 600px) {
  #btn-buyer-payment {
    display: flex;
    flex-direction: column;
  }

  #sell-add-input {
    width: 200px;
  }

  #btn-buyer-payment button {
    width: 40px;
    height: 50px;
    font-size: 7px;
    background-color: white;
    color: #d1823a;
    border: 1px solid #d1823a;
  }

  #btn-buyer-payment button:hover {
    background-color: #d1823a;
    color: white;
  }
}

#btn-gob-ttl {
  /* float: right; */
  display: flex;
  margin-right: 2%;
  margin-bottom: 2%;
  justify-content: flex-end;
}

#btn-gob-ttl button {
  background-color: #e8a262;
  color: white;
  border: none;
  margin-left: 10px;
  height: 40px;
  width: 200px;
}

#btn-gob-ttl button svg {
  min-height: 40px !important;
}

.input-user-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.form-label {
  font-weight: bold;
  font-size: 15px;
}

.form-border,
.form-select {
  border: none !important;
  border-bottom: 2px solid #e8a262 !important;
  /* border-radius: 0px !important; */
}

.trade-foot {
  padding: 2%;
  border: 3px solid #e8a262;
  border-radius: 10px;
}

.footer {
  height: 130px;
  width: 100%;
  background-color: rgba(230, 230, 230, 0.932);
  border-top: 1px solid #e8a262;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

/* Style for form input fields */
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* border-radius: 0.25rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Style for form select dropdowns */
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-bottom: 2px solid #e8a262;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Style for form group */
.form-group {
  padding: 2% 5%;
}

/* Style for form row */
.form-row {
  margin-right: -5px;
  margin-left: -5px;
}

/* Style for form group columns */
.form-group .col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.input-user-container .form-group label {
  font-weight: 700;
}

/* Style for form group inputs */
.input-user-container .form-group input,
.input-user-container .form-group select {
  border: none;
  border-bottom: 2px solid #e8a262 !important;
  border-radius: 0px !important;
  /* box-shadow: 0px 4px 3px rgb(179, 179, 179); */
}

.num-state {
  box-sizing: border-box;
  display: flex;
}

/* Style for form button */
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Style for button with icon */
.btn span {
  display: flex;
  align-items: center;
}

/* Style for checkbox container */
.checkbox-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  padding-left: 1.25rem;
}

/* Style for checkmark */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: #fff;
  border: 1px solid #ced4da;
}

/* Style for active checkmark */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style for active checkbox */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style for active checkmark icon */
.checkbox-container .checkmark:after {
  left: 0.3rem;
  top: 0.1rem;
  width: 0.4rem;
  height: 0.7rem;
  border: solid #007bff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Style for Trad In Modal */
/* .custom-modal {
    max-width: 700px !important;
}

.custom-modal .modal-content {

    width: 100%;
}

.custom-modal .modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.custom-modal .modal-body {
    padding: 2rem;
} */

.custom-modal .form-label {
  font-weight: bold;
  color: #333;
}

.custom-modal .form-control {
  /* border: none; */
  background-color: #f8f9fa;
  padding: 0.3rem !important;
  font-size: 1rem;
  color: #333;
}

.custom-modal .form-control[readonly] {
  background-color: #fff;
}

.custom-modal .form-select {
  padding: 0.3rem !important;
  font-size: 1rem;
}

.custom-modal .btn-primary {
  background-color: #e8a262;
  border-color: #e8a262;
}

.custom-modal .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.custom-modal .btn {
  margin-right: 0.5rem;
}

.half-border {
  border: none !important;
  border-bottom: 2px solid #e8a262 !important;
  border-radius: 0px !important;
}

#mileageIn {
  /* background-color: #f2caa6 !important; */
  background-color: rgba(240, 150, 31, 0.22) !important;
}

.tab-content {
  min-width: 102.18%;
}

.bg-input input {
  background-color: rgba(240, 150, 31, 0.22) !important;
  border-radius: 0px;
  /* padding-left: 20% !important; */
  font-size: 15px;
  width: 91%;
  font-weight: bolder;
  letter-spacing: 2px;
}

@media screen and (min-width: " 2500px") {
  .tab-pane {
    min-width: 60vw !important;
  }
}

.modal-80vw {
  width: 80vw !important;
}