.iiWrapper {
  width: 100%;
  display: grid;
  place-content: center;
}
.iiContainer {
  width: 100%;
  display: grid;
  place-content: center;
  gap: 1rem;
}
.iiTitle {
  display: flex;
  align-items: center;
  font-size: large;
  font-weight: 500;
  color: #646161;
  border-bottom: 2px solid #d6cece;
  padding: 1rem 0;
}
.iiTitle > span:first-child {
  flex: 0.4;
  margin-top: -0.1rem;
}
.iiInsuranceInfo {
  width: 100%;
}
.iiInsuranceInfo .iiInsuranceInfoContent {
  width: 100%;
  border: 2px solid #d1823a;
  padding: 0.6rem;
  border-radius: 10px;
  display: grid;
  gap: 1.5rem;
}
.iiInsuranceInfo .iiInsuranceHeader {
  width: 100%;
  margin-bottom: 0rem;
  font-weight: 700;
  font-size: 23px;
}
.iiFullWidthInput > span {
  font-weight: 500;
  font-size: 15px;
  color: #646161;
}
.iiFullWidthInput input {
  background-color: #f9bd855c;
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 3px 3px 3px grey;
  padding: 0.5rem;
}
.iiCheckBoxContainer {
  display: flex;
  gap: 0.3rem;
  align-items: start;
  margin-bottom: 0;
}
.iiCheckBoxContainer input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px !important;
  height: 20px !important;
  border: 1px solid black;
  /* Border color when unselected */
  border-radius: 15%;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iiCheckBoxContainer input[type="checkbox"]:checked::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin: 2px;
  background-color: black;
  /* Color of the dot when selected */
}
.iiCheckBoxContainer label {
  font-size: 17px;
  font-weight: 600;
}
.iiCoverageSubDataContaniner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}

@media (min-width: 370px) {
  .iiCoverageSubDataContaniner {
    flex-direction: row;
  }
}
@media (min-width: 420px) {
  .iiCoverageSubDataContaniner {
    flex-direction: row;
  }
}
@media (min-width: 720px) {
}

@media (min-width: 1024px) {
  .iiTitle {
    display: none;
  }
  .iiInfoContainer .iiInsuranceInfoContent {
    display: flex;
    justify-content: space-between;
  }
  .iiAgentInfoContent > div {
    display: flex;
    justify-content: space-between;
  }
  .iiCoverageSubDataWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .iiCoverageSubDataWrapper .iiCheckBoxContainer {
    width: 50%;
  }
  .iiCoverageSubDataWrapper .iiCoverageSubDataContaniner {
    width: 50%;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
  }
  .iiCoverageSubDataContaniner .iiFullWidthInput {
    width: 40%;
  }
  .iiAgentInfoContent > div:last-child .iiFullWidthInput:first-child,
  .iiAgentInfoContent > div:last-child .iiFullWidthInput:nth-child(2) {
    width: 25%;
  }
}

@media (min-width: 1440px) {
  .iiFullWidthInput > span {
    font-size: 17px;
  }
  .iiInsuranceInfo .iiInsuranceHeader {
    font-size: 28px;
  }
  .iiCoverageSubDataContaniner .iiFullWidthInput > span {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
  .iiInfoContainer .iiInsuranceInfoContent {
    padding: 0.5rem 3rem;
  }
  .iiInsuranceInfo .iiInsuranceInfoContent {
    padding: 0.5rem 3rem;
    gap: 2.3rem;
  }
  .iiCoverageSubDataWrapper .iiCheckBoxContainer {
    gap: 2rem;
    color: black;
    font-size: 24px;
  }
}
