.orange-color {
    /* color: rgb(232, 162, 98); */
    color: #e8a362;
}

.bgBtn {
    background-color: #E8A262 !important;
    border: 2px solid #E8A262 !important;
    box-shadow: 0px 5px 5px gray;
}

.bgBtn:hover {
    background-color: lightgray !important;
    border: 2px solid lightgray !important;
    color: black !important;
    box-shadow: 0px 5px 5px gray;
}

.tabs {
    border: 1px solid;
    border-radius: 10px;
    padding: .5%;
    font-weight: 700;
}

.tabs.active {
    background-color: #E8A262;
}

.label {
    font-weight: 700;
    margin-bottom: 15px;
}

.inText {
    background-color: rgb(233, 233, 233);
    box-shadow: 0px 5px 5px rgb(147 147 147 / 80%);
    border-style: none !important;
    border-bottom: 1px solid #E8A262 !important;
    margin-bottom: 10px;
}

.inText:focus {
    /* border: none; */
    outline: none;
}

.btnDrp {
    color: black !important;
    background-color: white !important;
    border: 1px solid #E8A262 !important;
    border-radius: 0px !important;
    box-shadow: 0px 5px 5px rgb(147 147 147 / 80%);
    margin-bottom: 10px;
}

.btnDrp :hover {
    color: black !important;
}

.bgOrange {
    border-radius: 10px !important;
    background-color: #E8A262 !important;

}

.formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: rgb(233, 233, 233);
    position: relative;
}

.formGroup>* {
    background-color: rgb(233, 233, 233) !important;
    padding: 5px !important;
    border: none !important;
}

.formGroup label {
    width: 15%;
    margin: 0px 5px;
}

.formGroup input {
    text-align: center;
}

.formGroup input:focus {
    border-width: 0px;
    box-shadow: none;
}

.formGroup .locationDetectionIcon {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

/*  settings pop-up for inventory tax maker */
.mainSettingContent{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.settingContentUpper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.settingContentTextInput{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.leftText{
width: 33%;
}

.rightInput{
    width: 40%;
    display: flex;
}

.rightInput input{
    border: none;
    height: 3rem;
    border-radius: 8px;
    letter-spacing: 2px;
    font-size: large;
    font-weight: 600;
    background-color: #F6DFCF;
    box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
}
.rightInputTextArea {
  width: 40%;
  display: flex;
}
.rightInputTextArea textarea{
  border: none;
  height: 8rem;
  border-radius: 8px;
  letter-spacing: 2px;
  font-size: large;
  font-weight: 600;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 4%;
  width: 100%;
  background-color: #F6DFCF;
  box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
}

.rightInput span {
    background-color: #d1823a;
    padding: 0.5rem;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: large;
    cursor: pointer;
  }
.footerbtnSave{
    display: flex;
    justify-content: center !important;
    align-items: center;
}
.footerbtnSave button{
    border: none;
    background-color: #d1823a;
    color: white;
    box-shadow:0px 6px 6px -3px rgba(199, 186, 186, 0.8);
    width: 200px;
    height: 40px;
    border-radius: 5px;
}
.pdfContainerMain{
  border: 2px solid #d1823a;
  box-shadow: inset 0px 0px 13px 2px #d1823a;
  padding-top: 1rem;
}
.pdfContainerFrame{
    padding-top: 1rem !important;
    height: 90%;
}
.headerTaxPdf{
    padding-left: 15%;
    padding-right: 15%;
}
  @media (min-width: 300px) and (max-width: 600px) {
    .settingContentTextInput{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    .leftText{
        width: 100%;
        }
        
   .rightInput{
            width: 100%;
            display: flex;
        }
  }

  /* for signature */
  .selectSignContainerMain{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }
  .selectSignContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .selectSignContainerLeft{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .selectSignContainerRight{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  .inputNameLeft{
    display: flex;
    border-top: 1px solid #d1823a;
    box-shadow: inset 3px 3px 4px 0px #938e8e;
    border-left: 1px solid #d1823a;
    flex-direction: row;
    width: 27rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .inputNameLeft input {
    background-color: transparent;
    border: none;
    outline: none;
    height: 3rem;
    font-weight: 600;
    font-size: 18px;
  }
  .selectSigIdCode{
    color: #d1823a;
  }

  /* for check box radio button */
.checkBoxLeft input[type="radio"] {
    -webkit-appearance: none; /* Removes the default radio button */
    -moz-appearance: none; /* Removes the default radio button */
    appearance: none; /* Removes the default radio button */
    width: 24px; /* Set size */
    height: 24px; /* Set size */
    border-radius: 50%; /* Make it round */
    border: 2px solid #d1823a; /* Border color */
    position: relative;
    cursor: pointer;
    background-color: white; /* Background color */
    transition: background-color 0.3s, transform 0.3s; /* Add smooth transition */
  }
  
  /* When the radio button is checked */
  .checkBoxLeft input[type="radio"]:checked {
    background-color: #d1823a; /* Fill color when checked */
    transform: scale(1.2); /* Slightly enlarge when checked */
  }
  
  /* Adding custom checkmark style */
  .checkBoxLeft input[type="radio"]:checked::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white; /* Inner circle for checked state */
  }

  @media (min-width: 500px) and (max-width: 900px) {
    .inputNameLeft{
        display: flex;
        border-top: 1px solid #d1823a;
        box-shadow: inset 3px 3px 4px 0px #938e8e;
        border-left: 1px solid #d1823a;
        flex-direction: row;
        width: 17rem;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .settingsParentContainer{
        display: flex !important;
        flex-direction: column !important;
      }
      .rightInputTextArea {
        width: 100%;
        display: flex;
      }
      .mainSettingContentLeft{
        border-right: none !important;
      }
}
@media (min-width: 300px) and (max-width: 400px) {
    .inputNameLeft{
        width: 10rem;
      }
      .settingsParentContainer{
        display: flex !important;
        flex-direction: column !important;
      }
      .rightInputTextArea {
        width: 100%;
        display: flex;
      }
      .mainSettingContentLeft{
        border-right: none !important;
      }
}
@media (min-width: 401px) and (max-width: 450px) {
    .inputNameLeft{
        width: 13rem;
      }
      .settingsParentContainer{
        display: flex !important;
        flex-direction: column !important;
      }
      .rightInputTextArea {
        width: 100%;
        display: flex;
      }
      .mainSettingContentLeft{
        border-right: none !important;
      }
}
@media (min-width: 451px) and (max-width: 500px) {
    .inputNameLeft{
        width: 15rem;
      }
      .settingsParentContainer{
        display: flex !important;
        flex-direction: column !important;
      }
      .rightInputTextArea {
        width: 100%;
        display: flex;
      }
      .mainSettingContentLeft{
        border-right: none !important;
      }
}

.drawSignature,
.drawSecondSignature {
  border: 1px solid #000;
  cursor: crosshair;
  margin-bottom: 10px;
}
.selectSignContainerDrawMain{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.settingsParentContainer{
  display: flex;
  flex-direction: row;
}
.mainSettingContentLeft{
  display: flex;
  flex-direction: column;
  border-right: 2px solid #d1823a;
  flex: 1;
}

.containerEmail{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.containerEmail input{
  background-color: white;
  border: none;
  height: 2rem;
  border-radius: 5px;
  box-shadow: 1px 2px 3px 2px grey;
}
.containerEmaildltbtn{
  border: none;
  color: red;
  font-size: 1.5rem;
  background-color: transparent;
}
.containerEmailAddbBtn{
  border: none;
  color: green;
  font-size: 1.5rem;
  background-color: transparent;
}
