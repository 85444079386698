.input-field-message {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-field-message input {
  height: 40px !important;
  margin-right: 10px;
}

.input-field-message>.iconButton {
  border: 1px solid;
  border-radius: 50%;
  font-size: 25px;
}

/* .input-field-message svg {
          border: 1px solid grey;
          height: 30px;
          box-shadow: 0px 10px 50px 9px rgba(0, 0, 0, 0.1);
          width: 30px;
          padding: 2%;
          border-radius: 50%;
        } */

.input-field-message #send {
  border: 1px solid grey;
  /* height: 30px; */
  box-shadow: 0px 10px 50px 9px rgba(0, 0, 0, 0.1);
  width: 90%;
  height: 80%;
  padding-left: 3px;
  /* padding: 2%; */

}