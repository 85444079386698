  .carDetails {
    flex-direction: column;
    /* border: 1px solid red; */
    gap: 0;
  }

  @media (min-width: 720px) {
    .profileWrapper {
      /* border: 2px solid blue; */
    }

    .profileContainer {
      display: flex;
      flex-direction: column;
    }

    .imageNameContainer {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 2rem;
      position: relative;
      padding: 0.3rem;
    }

    .imageNameContainer img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      /* border: 1px solid black; */
      background-color: azure;
    }

    .imageNameContainer .profileName {
      font-size: xx-large;
      font-weight: 700;
    }

    .profileDialogContainer {
      position: absolute;
      font-size: medium !important;
      font-weight: normal !important;
      top: 3.5rem;
      left: 50%;
      border: 1px solid gray;
      box-shadow: 2px 4px 4px gray;
      border-radius: 5px;
      backdrop-filter: blur(4px);
    }

    .profileDialogContainer p {
      display: flex;
      gap: 0.4rem;
      font-size: medium;
      align-items: center;
      margin: 0;
      width: 100%;
      padding: 0.3rem 0.5rem;
    }

    .profileDialogContainer p:hover {
      background-color: rgba(247, 105, 11, 0.493);
    }

    .aboutCarContainer {
      /* border: 1px solid red; */
      background-color: rgba(221, 223, 224, 0.863);
      display: flex;
      justify-content: center;
      padding: 0 1rem;
    }

    .aboutCarContainer img {
      height: 60px;
      width: 90px;
      border-radius: 10px;
    }

    .aboutCarContainer .carName {
      color: orange;
      font-size: larger;
      font-weight: 700;
      margin: 0;
    }

    .aboutCarContainer .carProfile {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .carDetails {
      display: flex;
      /* border: 1px solid red; */
      flex: 1;
      /* gap: 2rem; */
      flex-direction: column;
      /* align-items: space-between; */
      /* justify-content: center; */
    }

    .aboutCarContainer .carPerformance {
      display: flex;
      flex-direction: column;
    }

    .aboutCarContainer .carPerformance p {
      margin: 0;
      color: black !important;
      font-weight: normal !important;
    }
  }

  @media (min-width: 1024px) {
    .profileContainer {
      flex-direction: row;
    }

    .imageNameContainer {
      /* border: 2px solid black; */
      position: relative;
      flex: 1;
    }

    .imageNameContainer img {
      /* border: 2px solid black; */
      position: absolute;
      top: -50%;
      left: -1rem;
    }

    .imageNameContainer img.imageExpanded {
      height: 70px;
      width: 70px;
    }

    .aboutCarContainer {
      background-color: white;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;
    }
  }

  @media (min-width: 1440px) {
    .imageNameContainer {
      flex: 0.5;
    }

    .imageNameContainer img {
      left: 0.5;
      top: -2.6rem;
      left: -1rem;
      height: 90px;
      width: 90px;
    }

    .imageNameContainer img.imageExpanded {
      height: 120px;
      width: 120px;
    }

    .carDetails {
      flex-direction: column;
      /* border: 1px solid red; */
      gap: 0;
    }

    .aboutCarContainer .carName {
      font-size: xx-large;
      font-weight: 600;
    }

    .carPerformance {
      width: 100%;
      font-size: larger;
      font-weight: 500;
    }

    .aboutCarContainer img {
      height: 90px;
      width: 130px;
      border-radius: 10px;
    }
  }