
.compare-vehicle-main{
    border: 1px solid black;
    width: 95%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    /* height: 500px; */
}

#c-v-add-form-btn{
    float: right;
    margin-bottom: 50px;
    margin-left: 40%;
}
#c-v-form-fav-btn{
    float: right;
    margin-right: 40px;
}

#c-v-add-form-btn button{
  margin-top: 40px;
  border: none;
  background-color: #d1823a;
  color: white;
  border-radius: 5px;
  height: 40px;
  width: 150px;
}
#c-v-form-fav-btn button{
    margin-top: 40px;
    border: none;
    background-color: #d1823a;
    color: white;
    border-radius: 5px;
    height: 40px;
    width: 200px;
}

.compare-vehcile-main-container{
    display: inline-flex;
    justify-content: center;
    /* grid-template-columns: 1fr 0.3fr 0.4fr 0.4fr 0.5fr 0.8fr; */
    margin-left: auto;
    margin-right: auto;
    /* height: 230px; */
    width: 100%;
    border-top-right-radius: 10px;
    /* border: 1px solid black; */
    margin-top: 20px;
    padding: 0 !important
}
.compare_vehicle_header{
    display: flex;
    /* border: 2px solid red; */
    justify-content: space-between;
    padding: 0 5rem;
}
.compare_vehilce_card_container{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.c-v-image-card span h5{
    padding: 1rem 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
}
.c-v-specification span h5{
    padding: 1rem 6rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
    font-weight: 700;
}
.c-v-price span h5{
    padding: 1rem 5.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
    font-weight: 700;
}
.c-v-odometer span h5{
    padding: 1rem 5.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
    font-weight: 700;
}
.c-v-listed span h5{
    padding: 1rem 5.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
    font-weight: 700;
}
.c-v-estmonth h5{
    padding: 1rem 4.5rem;
    text-align: center;
    display: flex;
    gap : 0.4rem;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
    font-weight: 700;
}
.c-v-cont-direction span h5{
    padding: 1rem 6rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
    gap: 0.4rem;
    font-weight: 700;
}
/*  .c-v-specification span h5, .c-v-price span h5, .c-v-odometer span h5, .c-v-estmonth span h5, .c-v-listed  span h5, .c-v-cont-direction  h5 */
.dropdown_car_select{
    font-weight: 600;
    /* border : 2px solid black; */
    padding: 0.3rem !important;
    margin: 0.3rem 0;
}
.data_content_compare_card_1{
    padding-top: 1.5rem;
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0 !important;
}
.data_content_compare_card_1 span{
    /* border: 2px solid black; */
    font-weight: 600;
}
.data_content_compare_card_2{
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 1.5rem;
    gap: 2rem;
 
}
.child_container_comapre_card_2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap : 0.4rem;
    
    
}
.child_container_comapre_card_3{
    /* border: 2px solid black; */
    padding-top: 1.5rem;
    text-align: center;
    font-weight: 800 !important;
}
.child_container_comapre_card_4{
    /* border: 2px solid black; */
    padding-top: 1.5rem;
    text-align: center;
    font-weight: 800 !important;
    font-size: larger;
}
.child_container_comapre_card_5{
    padding-top: 1.6rem;
    text-align: center;
    
    font-size: larger;

}
.child_container_comapre_card_5 h5{
    font-weight: 800 !important;
}
.child_container_comapre_card_6{
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 0.5rem;
    width: 100% !important;
}
.child_container_header{
    display: flex;
    /* border: 2px solid red; */
    justify-content: space-between;
    padding-left: 1rem;
}
.child_address h6{
    font-weight: 700;
    /* border :2px solid green; */
    text-decoration: underline;
    padding-left: 1rem;
    font-size: large;
}
.messenger_contact{
    /* padding-left: 1rem; */
    display: flex;
    gap : 0.4rem

}
.messenge_input_send{
    /* border: 2px solid black; */
    display: flex;
    
}
.messenge_input_send input{
    background-color: rgba(128, 128, 128, 0.342);
    flex: 0.9;
    outline: none;
    border: none;
}
.messenge_input_send input::placeholder{
    font-weight: 500;
}
.messenge_input_send input:focus{
    outline: none;
    border: none;
}
.messenge_input_send button{
    flex: 0.1;
}
.finance_section{
    display: flex;
    justify-content: space-between;
}
.finance_section p{
    background-color: #d1823a;
    color: white;
    font-weight: 600;
    display: flex;
     gap: 0.3rem;
     padding: 0.3rem;
     border-radius: 6px;
}
.c-v-image-card{
    border: 1px solid black;
    /* border-left: none; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.c-v-specification{
    border: 1px solid black;
    border-left: none;


}

.c-v-price{
    border: 1px solid black;
    border-left: none;
    
}

.c-v-odometer{
    border: 1px solid black;
    border-left: none;
  
}

.c-v-estmonth{
    border: 1px solid black;
    border-left: none;
  
}

.c-v-listed{
    border: 1px solid black;
    border-left: none;
}

.c-v-cont-direction{
    /* width: 272px; */
    border: 1px solid black;
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#c-v-call{
border: none;
background-color: #4c8a42;
border-radius: 5px;
color: white;
height: 30px;
display: flex;
gap: 0.7rem;
padding: 0 0.8rem;
align-items: center;
font-weight: 600;
}

