.container{
    display: flex;
    flex-direction: column;
}
.upperButton{
    margin-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.upperButton button{
    width: 20%;
    background: #f6dfcf !important;
    color: #d1823a !important;
    border: 2px solid #d1823a;
    box-shadow: 0px 5px 3px lightgrey;
    border-radius: 10px;
    height: 40px;
    text-align: center;
    box-sizing: border-box;
    font-size: 1rem !important;
    font-weight: 600;
    cursor: pointer;
}
.headindateFeatured{
    display: flex;
    justify-content: space-around;
    font-family: "Alata";
}
.headindateFeatured span:first-child{
   color: #d1823a;
}
.imageContainer{
    width: 100%;
    height: 300px;
}
.imageContainer img{
    width: 100%;
    height: 100%;
}
.milCar{
    display: flex;
    justify-content: end;
    gap: 5px;
    padding-right: 0.6rem;
    color: #d1823a;
    font-family: "Alata";
    font-size: 1rem !important;
    font-weight: 600;
}
.nameVinYearmake{
    font-family: "Alata";
    display: flex;
    flex-direction: column;
    font-size: 1rem !important;
    font-weight: 600;
    gap: 0.5rem;
    padding-left: 0.6rem;
    padding-bottom: 1rem;
}
.Formatdate{
    border-top: 2px solid lightgrey;
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: "Alata";
    font-size: 1rem !important;
}
.Formatdate span:first-child{
    color: #807979;
}
.FormatdateIns{
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: "Alata";
    font-size: 1rem !important;
}
.FormatdateIns span:first-child{
    color: #807979;
}
.liveSocial{
    border-top: 2px solid lightgrey;
    font-size: 1rem !important;
    font-weight: 600;
    padding-top: 1rem;
    padding-left: 0.6rem;
}
.liveSocialIcons{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}
@media (min-width: 300px) and (max-width: 430px) {
    .upperButton button{
        font-size: 0.8rem !important;
    }
  }