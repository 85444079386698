.social_item_container{
    display: flex; 
    border: 1px solid rgb(100, 97, 97);
    align-items: center !important;
  padding: 0.5rem 1.8rem;
  border-radius: 20px;
}
.social_item_container:hover{
  background-color: rgb(211, 208, 208);
  transition: transform ease-in-out 0.2s;
transform: scale(1.05);
cursor: pointer;
}
.social_item_container img{
 margin-right: 18%;


}

.social_item_container p{
    align-self: center;
    margin: 0;
    font-weight: 800;
    font-size: small;
}

@media (min-width:375px){
  .social_item_container{
    padding: 0.5rem 2rem;
  }
  .social_item_container img{
    margin-right: 27%;
   
   }
   
   .social_item_container p{
       align-self: center;
       margin: 0;
       font-weight: 700;
       font-size: small;
   }
}
@media (min-width:720px){
  .social_item_container{
    padding: 0.5rem 2rem;
  }
  .social_item_container img{
    margin-right: 22%;
   
   }
   
   .social_item_container p{
       align-self: center;
       margin: 0;
       font-weight: 800;
       font-size: 15px;
   }
}

@media (min-width:1024px){
  .social_item_container{
    padding: 0.5rem 1rem;
  }
  .social_item_container img{
    margin-right: 13%;
   
   }
   
   .social_item_container p{
       align-self: center;
       margin: 0;
       font-weight: 700;
       font-size: small;
   }
}
@media (min-width:1440px){
  .social_item_container{
    padding: 0.5rem 1.9rem;
  }
  .social_item_container img{
    margin-right: 20%;
   
   }
   
   .social_item_container p{
       align-self: center;
       margin: 0;
       font-weight: 500;
       font-size: 20px;
   }
}

