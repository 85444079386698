.dealer_business_information_wrapper{
    /* border: 2px solid blue; */
   
    /* margin-left: 1rem; */
    transition: all ease-out 0.1s;
    display: flex;
    justify-content: center;
}
/* .dealer_business_information_wrapper.content_expanded{
    min-width: 85vw;
    max-width: 85vw;
}
.dealer_business_information_wrapper.content_collapsed{
    min-width: 93vw;
    max-width: 93vw;
} */
.dealer_business_information_container{
    /* border : 2px solid black; */
    width: 98%;
}
.savedLocationWrapper{
    /* border: 2px solid red; */
}
.savedLocationContainer{
/* border: 2px solid blue; */
}
.addLocation_container{
    padding: 0.3rem 6rem;
    display: flex;
    justify-content: end;
}
.addLocation_button{
    background-color: rgba(59, 59, 241, 0.877);
    color: white;
    padding: 0.3rem 0.5rem;
    border: none;
}