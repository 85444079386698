.wrapper {
  /* height: 95vh; */
  width: 100%;
  /* position: relative; */
  overflow: auto;
}
.container {
  width: 100%;
  margin-bottom: 3rem;
}
.header {
  display: flex;
  align-items: center;
  font-size: large;
  padding: 0.5rem;
  font-size: larger;
  font-weight: 500;
  box-shadow: 0px 4px 8px -1px rgb(192, 187, 187);
  margin-bottom: 2rem;
}
.header a {
  color: black;
  flex: 0.5;
}
.header span {
  font-size: large;
}
.dataContainer {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid #d1823a;
  padding: 1rem 0;
  margin-bottom: 1rem;
}

.dataContainer .dataContent input {
  flex: 0.3;
  background-color: #e77e1c57;
  border: none;
  outline: none;
  font-weight: 500;
  padding: 0.3rem 0.2rem;
  text-align: center;
  border-radius: 10px;
  font-size: larger;
  font-weight: 700;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
}
.dataContainer .dataContent input[type="date"] {
  flex: 0.3;
  background-color: #e77e1c57;
  border: none;
  outline: none;
  font-weight: 500;
  padding: 0.3rem 0.2rem;
  text-align: center;
  border-radius: 10px;
  font-size: small;
  font-weight: 500;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
}
.dataContainer .dataContent select {
  flex: 0.3;
  background-color: #e77e1c57;
  border: none;
  outline: none;
  font-weight: 500;
  padding: 0.3rem 0.2rem;
  text-align: center;
  border-radius: 10px;
  /* font-size: larger; */
  /* font-weight: 700; */
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
}
.dataContainer .dataContent select option {
  width: 100%;
  background-color: #e77e1c57;
  border: none;
  outline: none;
  font-weight: 500;

  text-align: center;

  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
}
.dataContainer .dataContent:nth-child(1) {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #d1823a;
}
.dataContainer .dataContent:nth-child(1) input {
  background-color: #d1823a;
  color: white;
}
.dataContainer .dataContent:nth-child(5) input {
  background-color: #d1823a;
  color: white;
}
.dataContainer .dataContent input:focus {
  border: none;
  outline: none;
}
.dataContainer .dataContent {
  display: flex;
  align-items: center;
  padding: 0 0.9rem;
  margin-bottom: 0;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.dataContainer .dataContent2 {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 0.9rem;
  margin-bottom: 0;
  justify-content: space-between;
}
.dataContainer .dataContent2 textarea {
  padding: 0 0.9rem;
  background-color: #e77e1c57;
  border: none;
  outline: none;
  height: 50px;
  width: 100%;
}

.dataContainer .dataContent span,
.dataContainer .dataContent2 span {
  flex: 0.5;
  font-size: small;
  font-weight: 500;
}
.checkBoxContainer {
  font-size: small;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}
.checkBoxContainer input {
  margin-right: 1rem;
}
.saveBtnContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #d1823a;
  color: white;
  font-size: large;
  border: none;
  font-weight: 700;
  padding: 0.5rem;
}
@media (min-width: 420px) {
  .dataContainer .dataContent span,
  .dataContainer .dataContent2 span,
  .checkBoxContainer {
    font-size: medium;
  }
}

@media (min-width: 550px) {
  .dataContainer .dataContent span,
  .dataContainer .dataContent2 span,
  .checkBoxContainer {
    font-size: large;
  }
}
