.wrapper {
    margin-left: 1rem;
    transition: all ease-out 0.1s;
    /* margin-top: -0.5rem; */
}

.wrapper.content_expanded {
    min-width: 80vw;
    max-width: 80vw;
}

.wrapper.content_collapsed {
    min-width: 91vw;
    max-width: 91vw;

}

.container {
    width: 100%;
}




.mailNavbar {
    background-color: #013243;
}

.navIcon {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navIcon>svg {
    color: white;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.navIcon>span {
    color: white;
    font-size: .7rem;
    cursor: pointer;
}

.navIcon.active>svg {
    color: #ff5454;
}

.searchBar {
    width: 90%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 50px;
}

.search {
    background-color: inherit;
    border: none;
    width: 95%;
    color: white;
    font-weight: 600;
    padding-left: 5px;
}

.search:focus {
    outline: none;
}

.pro {
    position: relative;
    cursor: pointer;
}

.pro>span {
    font-weight: 600;
    color: white;
}

.sidebar {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    height: 87vh;
}

.emailPro select {
    background-color: white;
    padding: 0px;
    margin-bottom: 15%;
    color: gray;
}

.emailPro select:focus {
    outline: none;
}

.compose button {
    border: none !important;
    background-color: #fcc07bda !important;
    padding: 7px 15px !important;
    margin-bottom: 5%;
    font-weight: 600;
    box-shadow: 5px 5px 5px rgba(102, 102, 102, 0.5);
}

.compose button:hover {
    font-weight: 600;
    box-shadow: 3px 3px 3px rgba(102, 102, 102, 0.5);
}

.mailCategory,
.settingsCat {
    display: flex;
    flex-direction: column;
    color: gray;
    list-style-type: none;
    padding-left: 0px;
}

.mailCategory li,
.settingsCat li {
    margin-bottom: 5px;
    padding: 7px;
    cursor: pointer;
}

.mailCategory .active,
.settingsCat .active {
    color: black !important;
}

.mailCategory .open,
.mailCategory .spamOpen {
    color: black !important;
}

.mailCategory .active .catIcon>* {
    color: #fcc07bda;
}

.mailCategory .open .catIcon>* {
    color: #fcc07bda;
}

.mailCategory li:hover {
    background-color: rgb(241, 241, 241);
}

.catIcon {
    margin: 0px 10px 0px 0px;
}

.innerList {
    display: flex;
    flex-direction: column;
    color: gray;
    list-style-type: none;
    padding-left: 0px;
}

.innerList li {
    margin-left: 20px;
    padding: 3px;
    margin-bottom: 2px;
}

.folder span::after {
    content: '\f078';
    /* Unicode for Font Awesome down arrow */
    font-family: 'Font Awesome 5 Free';
    /* Specify the Font Awesome font family */
    font-weight: 900;
    /* Font Awesome solid icons require this weight */
    display: inline-block;
    /* Keeps it inline with the text */
    margin-left: 8px;
    /* Space between text and icon */
    vertical-align: middle;
    /* Aligns the icon with the text */
    font-size: 12px;
}

.folder {
    position: relative;
    color: gray !important;
    /* Ensure innerList is positioned correctly */
}

.innerList {
    max-height: 0;
    /* Start with max-height of 0 to hide it */
    overflow: hidden;
    /* Hide overflowing content */
    transition: max-height 0.3s ease-out !important;
    /* Smooth transition for height */
    padding: 0;
    /* Reset padding, adjust as needed */
    margin: 0;
    /* Reset margin, adjust as needed */
}

.folder.open .innerList {
    max-height: 200px;
    /* Adjust based on expected content height */
    padding: 10px 0;
    /* Optional: adjust padding to fit your design */
}

.folder.spamOpen .innerList {
    max-height: 200px;
    /* Adjust based on expected content height */
    padding: 10px 0;
    /* Optional: adjust padding to fit your design */
}

/* Optionally, you can add hover effects or other styles */
.innerList li:hover {
    background-color: #f0f0f0;
    /* Background color on hover */
}







.unread {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 50%;
    margin-left: 20px;
    font-weight: 600;
    color: white;
    background-color: #ff5454;
}

.subNav {
    height: 10vh;
    color: gray;
}

.refreshIcon {
    height: 25px;
    width: 25px;
    color: gray;
}

.refreshIcon:hover {
    color: black;
}

.subNavRight {
    display: flex;
    flex-wrap: nowrap;
    justify-content: end;
    align-items: center;
}

.subNavRight>* {
    display: flex;
    align-items: center;
}

.count {
    text-wrap: nowrap;
    font-weight: 700;
}

.select {
    margin: 10px;
}

.deleteIcon {
    padding: 10px;
    color: gray;
}

.deleteIcon:hover {
    color: black;
}

.filter select {
    padding: 5px;
    font-size: .8rem;
    color: gray;
    background-color: white;
}

.filter select:focus {
    outline: none;
    border: none;
    color: black;
}

.mailCard {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 50px;
    margin-bottom: 5px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    gap: .5rem;
}

.mailCard:hover {
    background-color: rgba(211, 211, 211, 0.5);
}

.nameSec {
    width: 20%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    /* border: 1px solid black; */
}

.pro img {
    box-sizing: border-box;
    border-radius: 50%;
    width: 30px;
}

.name {
    font-weight: 700;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.subject {
    font-size: .95rem;
    width: 20%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    /* border: 1px solid black; */
}

.email {
    font-size: .95rem;
    width: 30%;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.content {
    font-size: .93rem;
    width: 40%;
    color: gray;
    text-wrap: nowrap;
    overflow: hidden;
    /* Hides the overflowed text */
    text-overflow: ellipsis;
    flex-shrink: 1;
}

.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    /* border: 1px solid black; */
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 16.6%;
    height: 100%; */
    font-size: 1.3rem;
    margin: 3%;
}

.icon>* {
    color: gray;
    cursor: pointer;
    width: 95%;
}

.icon>*:hover {
    color: black;

}

.iconsJunk {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
}

.smallFont {
    font-size: .8rem;
    color: gray;
    text-wrap: nowrap;
}

.docxSec {
    position: absolute;
    bottom: 0;
    width: 65%;
    column-gap: .8rem;
}


.document {
    height: 20vh;
    width: 18vh !important;
    /* border: 1px solid black; */
    border-radius: 10px;
    box-shadow: 5px 5px 5px gray;
}

.document>* {

    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
    overflow-y: hidden !important;
    border-radius: inherit;
    object-fit: cover;
}





/* Adding custom indentation */
.customTextarea::before {
    content: '\00a0\00a0\00a0\00a0';
    /* Equivalent to 4 spaces for the tab indent */
    display: block;
    height: 0;
    margin-top: -1px;
}

/* Optional: Adjust margin for better visual alignment */
.customTextarea::after {
    content: '';
    display: block;
    height: 1px;
    margin-top: 1px;
}



.settingsCat {}