/* .container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
} */

.formGroup {
  display: flex;
  /* align-items: ; */
  /* background-color: #fce8cf; */
  position: relative;
  flex-direction: column;
  gap: 0 !important;
  margin-bottom: 5px !important;
}

.formGroup > * {
  /* background-color: #fce8cf !important; */
  padding: 7px;
  /* border: none !important; */
}

.formGroup label {
  width: 35%;
  margin: 0px 10px;
  /* border: 1px solid green; */
  /* border: 2px solid red; */
  padding: 0 !important;
}

.formGroup input:focus {
  box-shadow: none;
}
.formGroup input {
  background-color: #E3E3E3;
  padding: 5px;
}
#lighOranegBackgroundColor {
  background-color: #E3E3E3 !important;
  padding: 7px !important;
}
/* #greyBackground {
  background-color: transparent !important;
} */

.formGroup .locationDetectionIcon {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.inlineFields {
  display: flex;
  /* gap: 10px; */
  width: 100%;
}

.inlineFields input {
  /* flex: 1; */
  padding: 7px !important;
  border: none;
  background-color: #E3E3E3 !important;
  width: 100% !important;
}

.idType {
  display: flex;
  padding: 0px !important;
  width: 100%;

  box-shadow: 0px 3px 5px 1px lightgrey;
}

.idType button {
  width: 40px;
  padding: 7px !important;
  border-radius: 0px;
}

.idType input {
  flex: 1;
  padding: 7px !important;
  border: none;
  background-color: #E3E3E3 !important;
}
.addressInput {
  /* border:  1px solid blue; */
  width: 100%;
  /* padding-left: 0; */
}
