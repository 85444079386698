.wrapper {
  width: 100vw;
}
.container {
  width: 100%;
  padding: 0.9rem;
  background-color: rgba(223, 217, 217, 0.548);
}
.container img {
  max-width: 100%;
  margin-bottom: 2rem;
}
.textContainer {
  display: flex;
  flex-direction: column;
}
.textContainer .header,
.textContainer .contactNumber {
  text-align: center;
  font-size: x-large;
  font-weight: 500;
  margin-bottom: 0;
}
.textContainer .contactNumber {
  color: rgb(68, 152, 185);
  text-decoration: underline;
}
.days {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
}
.textContainer p {
  margin-bottom: 0;
}
.days p span {
  margin-right: 1rem;
  text-align: left;
}
@media (min-width: 1024px) {
  .container {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
  }
  .container img {
    max-width: 70%;
    min-height: 100%;
  }
  .container .textContainer {
    flex: 1;
    font-size: medium;
  }
  .textContainer p {
    margin-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .textContainer {
    padding: 2rem;
  }
  .textContainer .header,
  .textContainer .contactNumber {
    font-size: xx-large;
    font-weight: 700;
  }

  .days p span {
    margin-right: 1rem;
    text-align: left;
    font-size: larger;
  }
}

@media (min-width: 2200px) {
  .textContainer {
    padding: 7rem;
  }
  .container {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
  }
  .container img {
    min-width: 80%;
    min-height: 100%;
  }
  .days {
    margin-top: 5rem;
  }
  .textContainer {
    padding: 2rem;
  }
  .textContainer .header,
  .textContainer .contactNumber {
    font-size: 50px;
    font-weight: 700;
  }

  .days p {
    font-size: 25px;
  }
  .days p span {
    font-size: 25px;
  }
}
