.home_grand_parent_wrapper {
  position: relative;
}

.complete_user_details_modal {
  position: fixed;
  top: 40%;
  left: 40%;
}

/*------Filter section home start--------*/

.form-select.select-filter {
  background-color: lightgray !important;
  border-radius: 10px !important;
  border: none !important;
}

/*------Filter section home end--------*/

/*------slider home start--------*/

/* ======================================================================================================================= */
/* Styles for screens larger than 768px (tablets) */
@media (min-width: 769px) {
  /* .balloon-slider .slider-wrapper {
        animation: transition 1s linear infinite;
    } */

  /* Add your tablet-specific styles here */
}

/* Styles for screens smaller than or equal to 768px (tablets) */
@media (max-width: 768px) {
  /* .balloon-slider .slider-wrapper {
        animation: transition 5s linear infinite;
        display: flex;
        flex-wrap: wrap;
    }

    .balloon-slider .balloon-item {
        width: 180%;
    } */

  /* Add your mobile-specific styles here */
}

/*------------------------------------------------------------slider home End---------------------------------------------*/

/* ----------------------------------------I want start-----------------------------------------------------*/

/* .sectionMain{
    width: 100%;
    height: 20vh;
    margin-left: 400px;
} */

.form_5 {
  position: relative;
  z-index: 1;
  /* background: #ffffff; */
  margin: 0 auto 100px;
  padding: 45px;
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); */
  border-radius: 20px;
}

.form_5 {
  outline: 0;
  /* background: #f2f2f2; */
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  z-index: 10;
}

.form_5 .search_btn {
  outline: 0;
  background: #c57a40;
  width: 100%;
  height: 41px;
  border: 0;
  /* padding: 5%; */
  color: #ffffff;
  font-size: 14px;
  border-radius: 10px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
}

.buttonSell2 {
  outline: 0;
  background: #5a4a3c !important;
  width: 100%;
  border: 0;
  /* padding: 15px; */
  padding: 10px 5px;
  /* color: #ffffff; */
  font-size: 18px;
  border-radius: 10px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  border: 1px solid #c57a40;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
  text-decoration: none;
}

.button-with-bg-color {
  outline: 0;
  background: #c57a40;
  width: 100%;
  border: 0;
  padding: 15px 70px 15px 70px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 10px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  position: relative;
  display: inline-block;
  border: 1px solid #c57a40;
  margin-bottom: 5px;
  text-decoration: none;
}

.btnallyrs {
  padding: 11px;
}

select {
  word-wrap: normal;
  width: 100%;
  padding: 15px;
  /* border-radius: 10px; */
  background-color: #f2f2f2;
  border: 0px;
}

.BtnA {
  /* padding: 0 0 0px 0px; */
  height: 35px;
}

/* ----------------------------------------I want end-----------------------------------------------------*/

/* --------------------------------------------Digital Contracting start-------------------------------------------------------------------- */
.box1 {
  background: #e6e7e8;
  padding: 50px 0px;
  width: 100%;
  display: inline-block;
}

@media (min-width: 992px) {
  .p-lg-5 {
    padding: 3rem !important;
  }
}

.bg-secondary-soft {
  background-color: rgba(208, 212, 217, 0.1) !important;
}

.rounded {
  border-radius: 5px !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.file-upload .square {
  height: 250px;
  width: 250px;
  margin: auto;
  vertical-align: middle;
  border: 1px solid #e5dfe4;
  background-color: #fff;
  border-radius: 5px;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(208, 212, 217, 0.5) !important;
}

/* --------------------------------------------Digital Contracting End-------------------------------------------------------------------- */

/*------------------------------------------------------------Vehicle Slider and image start---------------------------------------------------*/

/* @import url("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"); */

/* .logo-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

/* .logo-slider {
    display: flex;
    overflow: hidden;
    width: 100%;
} */
/* .bbb_viewed_slider {
    display: flex;
    justify-content: center;
    align-items: center;
  }
.owl-item img {
    max-width: 100%;
    height: auto;
}

.owl-carousel-btns {
    margin-top: 40px;
    display: flex;
    justify-content: center;
   align-items: center;
    
}

.bbb_viewed_next,
.bbb_viewed_prev {
   height: 40px;
   width:40px;
   display: flex;
   justify-content: center;
   align-items: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50%;
    margin-right: 10px;
}
.bbb_viewed_next:hover,
.bbb_viewed_prev:hover{
	background: #c57a40;
	color: #fff;
}
.prev-button {
    margin-right: 20px;
}

.slide-track .slide{
	animation: slide 20s infinite linear;
}
@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
} */

/* -----------------------------------------auto matic slider start------------------------------------------------ */

/* Default styles for auto slider container and items */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

.auto_slider_container {
  max-width: 1000px;
  width: 100%;
  display: flex;
  margin: auto;
  overflow: hidden;
}

.auto_slider {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation 30s linear infinite;
}

.auto_slider li {
  font: 16px/1.2 "Roboto";
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  width: 100%;
  background: #ddd;
  margin: 0px 15px;
  box-sizing: border-box;
  border-radius: 25px;
}

@keyframes rotation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* ----------------------------------auto matic slider end-------------------------------------- */

/* .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
} */

/* .logo-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

/* .logo-slider {
    display: flex;
    overflow: hidden;
    width: 100%;
} */

.owl-item {
  /* max-width: 300px; */
  /* height: auto; */
  /* border: 2px solid red; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  padding-bottom: 1rem;
  position: relative;
}

.p_U {
  /* margin: 0 0 0 70px; */
  width: 67%;
  box-sizing: border-box;
  position: absolute;
  top: 120px;
}

.p_U p {
  margin-bottom: 0 !important;
}

/* .owl-carousel-btns {
    margin-top: 40px;
    display: flex;
    justify-content: center;
   align-items: center;
    
} */

/* .bbb_viewed_next,
.bbb_viewed_prev {
   height: 40px;
   width:40px;
   display: flex;
   justify-content: center;
   align-items: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50%;
    margin-right: 10px;
} */

/* .bbb_viewed_next:hover,
.bbb_viewed_prev:hover{
	background: #c57a40;
	color: #fff;
} */

/* .prev-button {
    margin-right: 20px;
} */

/* .slide-track {
	display: flex;
    justify-content: space-around;
} */
/* .slide-track .slide{
	animation: slide 20s infinite linear;
} */

/* @keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
} */

/*------------------------------------------------------------Vehicle Slider and image start End---------------------------------------------------*/

/*-------------------------------------------------Featured Dealers Near Me Start-----------------------------------------------------------------*/

/* .img-over-color:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(34,39,50,1) 0%, rgba(0,212,255,0) 80%);
}

.card-product {
    background-color: #222732 !important;
    border: 1px solid rgb(255 255 255);
    color: #fff !important;
    border-radius: 15px;
} */
/* .container {
    max-width: 100%;
} */
.mt-3 {
  margin-top: 0rem !important;
}

/* *, ::after, ::before {
    box-sizing: border-box;
} */
/* user agent stylesheet */
/* div {
    display: block;
} */
.card-product {
  opacity: 1;
  background-color: transparent;
  background-color: #222732 !important;
  border: 1px solid rgb(255, 255, 255);
  color: #fff !important;
  border-radius: 15px;
  /* height: 500px; */
}

.dealer-section-dms .dealer-section-content {
  display: flex;
  align-items: center;
}

.dealer-section-dms .dealer-section-content h1 {
  font-weight: 600;
}
.biggerScreenSlider {
  display: none;
}
.searchFilterInputParent {
  width: 100%;
}
.searchLoanParent {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.searchParent {
  flex: 1;
}
.loanParent {
  flex: 0.6;
}

@media screen and (max-width: 480px) {
  .bg-color {
    height: 200px;
    /* margin-top: -25%; */
    background-image: linear-gradient(#d1d1e1, #fff);
  }

  .icon-img {
    object-fit: contain;
  }

  .form {
    padding: 20px !important;
  }

  .message {
    flex-direction: column;
  }

  .fab {
    margin-right: 10px;
  }

  .image-left {
    display: grid !important;
    justify-content: center !important;
  }
}

@media screen and (min-width: 720px) {
  .biggerScreenSlider {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .smallScreenSlider {
    display: none;
  }
  .dropDownFilterParent {
    display: flex;
    gap: 1rem;
    /* padding */
  }
  .zipCode_container span {
    right: 0.5rem;
  }
  .buttonSell2 {
    width: auto;
    font-size: medium;
    padding: 0 10px;
  }
  .searchFilterInputParent {
    font-size: medium;
  }
  .searchInventoryButtonContainr {
    font-size: smaller;
  }
}
@media screen and (min-width: 1024px) {
  .biggerScreenSlider {
    grid-template-columns: repeat(4, 1fr);
  }
  .buttonSell2 {
    width: auto;
    font-size: medium;
    padding: 0 60px;
  }
  .dropDownFilterParent div {
    flex: 0.2;
  }
}
@media screen and (min-width: 1440px) {
  .biggerScreenSlider {
    grid-template-columns: repeat(5, 1fr);
  }
  .searchLoanParent {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1.5rem;
  }
  .searchParent {
    flex: 1;
  }
  .loanParent {
    flex: 0.6;
  }
  .buttonSell2 {
    width: auto;
    font-size: large;
    font-weight: 500;
    padding: 0 100px;
  }
  .form_5 {
    padding: 15px 80px;
  }
  .dropDownFilterParent {
    display: flex;
    gap: 2rem;
  }
  .searchInventoryButtonContainr {
    font-size: medium;
  }
}
@media (min-width: 1800px) {
  .buttonSell2 {
    width: auto;
    font-size: large;
    font-weight: 500;
    padding: 0 170px;
  }
  .form_5 {
    padding: 15px 180px;
  }
  .searchInventoryButtonContainr {
    font-size: large;
  }
}
@media screen and (min-width: 1500px) {
  .card-img-top-1 {
    height: 745px;
    /* height: 78vh; */
  }
}

@media screen and (max-width: 1500px) and (min-width: 1000px) {
  .image-left img {
    height: 100px;
  }

  .card-img-top {
    height: 185px;
  }

  .card-img-top-1 {
    height: 540px;
  }
}

.card-body {
  padding: 0rem 1rem 1rem 1rem;
}

.card-space {
  padding: 0.6rem;
  border-top: 1px solid #cccccc29;
}

.doller-price {
  font-size: 18px;
  font-weight: 700;
}

.chip {
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  /* padding: 0 8px; */
  border-radius: 5px;
  background-color: #e4e4e4;
  margin-right: 4px;
  width: 32%;
  text-align: center;
}

.color-blk {
  color: #000;
}

.color-wht {
  color: #fff;
}

.color-blk {
  text-decoration: none;
}

@media (max-width: 992px) {
  .card-product-img {
    max-height: 200px;
    /* Adjust as needed */
  }
}

@media (max-width: 768px) {
  .card-product-img {
    max-height: 150px;
    /* Adjust as needed */
  }
}

/* @media (max-width:1000px) and (min-width:2000px) {
    
} */

/*-------------------------------------------------Featured Dealers Near Me End-----------------------------------------------------------------*/

/* CSS to change arrow direction and remove focus active color */

/*---------------------------------------------------------------------calculator start 2--------------------------------------------------------------------------------------------*/

.MainCal {
  /* width: 50%; */
  justify-content: center;
  /* text-align: center; */
}

/* .calCarBtn{
    height: 25vh;
    width: 100%;
} */
/* ------------------------------------------------------------ */
.details-padding-input {
  background-color: rgb(108 117 125);
  border: none;
  border: 2px solid white;
  color: white;
  border-radius: 5px;
  height: 50px;
}

.details-padding-input::placeholder {
  color: white;
  opacity: 1;
  /* Firefox */
}

.details-padding-input:focus {
  outline: none;
}

.calCarBtn {
  background-color: rgb(108 117 125);
  z-index: -1;
}

/* ------------------------------------------- */
input[type="range"] {
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background-color: #c57a40;
  /* background: #fff; */
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  /* background-image: url('https://i.imgur.com/XgyW4ZE.png'); */
  background-color: #c57a40;
  background-size: cover;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
  /*  rotate thumb  */
  transform: rotateZ(var(--thumb-rotate, 0deg));
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 30px;
  width: 30px;
  /* background-image: url('https://i.imgur.com/XgyW4ZE.png'); */
  background-color: #c57a40;
  background-size: cover;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
  /*  rotate thumb  */
  transform: rotateZ(var(--thumb-rotate, 0deg));
}

/* Hover, active & focus Thumb: Webkit */
input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}

input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

/* Hover, active & focus Thumb: Firfox */

input[type="range"]::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}

input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

/*-------------------------------------*/
#price {
  color: #130f31;
  font-size: 25px;
}

.loan-details {
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

#price-container {
  color: #c57a40;
  margin-top: 50px;
}

#price-container::before {
  content: "Monthly Payable:";
  font-size: 12px;
  display: block;
}

/*-------------calculator end 2 -------------*/

/* ---------------------------------------------------Sell Your Car Start--------------------------------------------------------------------*/
.form-control {
  display: block;
  width: 100%;
  padding: 0.685rem 0.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  /* cursor: pointer; */
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #c57a40;
  border: 1px solid transparent;
  padding: 0.685rem 0.75rem !important;
  font-size: 1rem;
  /* margin: 0 0 0 5px; */
  border-radius: 10px 10px 10px 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #c57a40;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #ffffff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/* ---------------------------------------------------Sell Your Car End--------------------------------------------------------------------*/

/*--------------------------------------------------------------Vehicle Report Start-------------------------------------------------------------------*/

/* .input-group .btn {
    position: relative;
    z-index: 2;
} */

.btntttt {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #c57a40;
  border: 1px solid transparent;
  padding: 0.685rem 1.75rem !important;
  font-size: 1rem;
  margin: 0 0 0 7px;
  border-radius: 10px 10px 10px 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btntttt:hover {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #c57a40;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #f3c671;
}

/*--------------------------------------------------------------Vehicle Report End-------------------------------------------------------------------*/

/* -------------------------------------------------------Our Products Start Start------------------------------------------------------------------*/
/* .box1 {
    background: #e6e7e8;
    padding: 50px 0px;
    width: 100%;
    display: inline-block;
} */

.img_e {
  /* width: 70%; */
}

.img_D {
  width: 75%;
}

.h_5 {
  /* box-shadow: 0px 7px 5px #ccc; */
  text-shadow: 0px 1px 0px #ccc;
  /* font-weight: bold; */
  /* font-weight: 500; */
  /* line-height: 1.2; */
  /* margin-bottom: 0.5rem; */
}

/*-----------------------------------------------------Our Products End-------------------------------------------------------------------*/

/*----------------------------------------------------------------CRM Featured Start---------------------------------------------------------*/

.btn-grad:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

/*----------------------------------------------------------------CRM Featured End---------------------------------------------------------*/

/*----------------------------------------------------------------Trusted Financing Partners Start---------------------------------------------------------*/
/* .client-logo { 
    
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
} */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

/* body{
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh
} */
.auto_slider_containers {
  max-width: 1000px;
  width: 100%;
  display: flex;
  margin: auto;
  overflow: hidden;
}

.auto_sliders {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation 30s linear infinite;
}

.auto_sliders li {
  font: 16px/1.2 "Roboto";
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  width: 100%;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 0px 15px;
  /* box-sixing: border-box; */
  border-radius: 15px;
}

@keyframes rotation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 600px) {
  .auto_sliders li {
    min-width: 100px;
    margin: 0px 10px;
  }
}

/*----------------------------------------------------------------Trusted Financing Partners End---------------------------------------------------------*/

/*-------------------------------------------------USA's top-rated car buying app Start--------------------------------------------------------------*/

.app-btn {
  display: flex;
}

.bg-color {
  height: 200px;
  /* margin-top: -10%; */
  background-image: linear-gradient(#d1d1e1, #fff);
}

@media screen and (max-width: 480px) {
  .bg-color {
    height: 200px;
    /* margin-top: -25%; */
    background-image: linear-gradient(#d1d1e1, #fff);
  }
}

/*-------------------------------------------------USA's top-rated car buying app End--------------------------------------------------------------*/

/* Input field for the zipcode */
.zipCode_container {
  /* border: 2px solid red; */
  position: relative;
}

.zipCode_container input::placeholder {
  color: black;
  font-size: 15px;
}

.zipCode_container span {
  color: orange;
  position: absolute;
  font-size: x-large;
  right: 0.5rem;
  bottom: 0.6rem;
}

/*  */
/* Ensure all card-product elements have the same height */
.card-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Adjust image container to ensure consistent height */
.card-product .img-over-color {
  position: relative;
  height: 200px;
  /* Adjust this value as needed */
  overflow: hidden;
}

/* Ensure the image fills the container */
.card-product .card-img-top {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Adjust card body to ensure consistent height */
.card-product .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Ensure the card space has consistent padding */
.card-space {
  padding: 0.6rem;
  border-top: 1px solid #cccccc29;
}

/* Car  image scale on hover */
.vehicleSliderImage:hover {
  transform: scale(1.2);
  transition: transform ease-in 0.3s;
}

/* -------------------------------------button animation---------------------------------------- */
.btn-change1 {
  height: 50px;
  /* width: 100px; */
  /* background:#d1823a; */
  /* border: 1px solid #d1823a !important; */
  margin: 20px 0px;
  box-shadow: 0 0 1px #ccc;
  -webkit-transition: all 0.5s ease-in-out;
  border: 0px;
  color: #fff;
  /* font-weight: 700; */
}

.btn-change1:hover {
  -webkit-transform: scale(1.1);
  background: #ff9a3c;
}

.btn-change2 {
  /* background:#d1823a; */
  border: 1px solid #d1823a !important;
  box-shadow: 0 0 1px #ccc;
  -webkit-transition: all 0.5s ease-in-out;
  border: 0px;
  color: #fff;
  padding-top: 4px !important;
}

.btn-change2:hover {
  -webkit-transform: scale(1.1);
  color: black;
  background: lightgray;
  border: 1px solid lightgray !important;
}

.btn-change4 {
  /* background:#d1823a; */
  border: 1px solid #d1823a !important;
  box-shadow: 0 0 1px #ccc;
  -webkit-transition: all 0.5s ease-in-out;
  border: 0px;
  color: #fff;
  font-weight: 700;
}

.btn-change4:hover {
  -webkit-transform: scale(1.1);
  background: lightgray !important;
  border: 1px solid lightgray !important;
  color: black !important;
}

.btn-change5 {
  box-shadow: 0 0 1px #ccc;
  -webkit-transition: all 0.7s ease-in-out;
  border: 0px;
}

.btn-change5:hover {
  -webkit-transform: scale(1.1);
}

.microphone_container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

/*--------------------------------- for microphone------------------------------ */
.speech-recognition-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 5%;
  background-color: white;
  padding: 20px;
  width: 20%;
  height: 30%;
  border-radius: 5px;
  color: black;
  text-align: center;
}

.img-mick svg {
  width: 70px;
  height: 70px;
}

.btn-close-mick button {
  border-radius: 5px;
}

.img-mick {
  margin-top: 25%;
}

.mic-icon {
  color: red;
  /* Change the color of the microphone icon to red */
  font-size: 100px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Make the icon circular */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  /* Add box shadow */
  padding: 10px;
  /* Add some padding around the icon */
  transition: transform 0.2s;
  /* Smooth transition for hover effect */
}

.mic-icon:hover {
  transform: scale(1.1);
  /* Slightly enlarge the icon on hover */
}

.mick-homefilter {
  color: rgb(15, 15, 15);
  /* Change the color of the microphone icon to red */
  font-size: 30px;
  border-radius: 50%;
  /* Make the icon circular */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  /* Add box shadow */
  padding: 10px;
  /* Add some padding around the icon */
  transition: transform 0.2s;
  /* Smooth transition for hover effect */
}

.mick-homefilter:hover {
  cursor: pointer;
  transform: scale(1.1);
  /* Slightly enlarge the icon on hover */
}

.close-icon {
  position: absolute;
  /* Position the close icon absolutely */
  top: 5px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  color: rgb(20, 20, 20);
  /* Change color of the close icon */
  font-size: 24px;
  /* Adjust size as needed */
}

.box_shadow_filter_btn {
  box-shadow: 3px 2px 3px 0px rgba(0, 0, 0, 0.6);
}

/*  */
.active2 {
  background-color: lightgrey !important;
  border-bottom-color: lightgrey !important;
  color: #d39c68 !important;
  font-weight: 600 !important;
  font-size: large !important;
  border-radius: 15px 15px 0 0 !important;
}

/* Marquee containerr here */

.mobileHeroSection {
  display: block;
}

.extraFeatureHomeContainer,
/* .marqueeContainer, */
.homeFilterContainer,
.sellYourCarContainer,
.vehicleReportContainer {
  display: none;
}
.marqueeContainer {
  display: block;
  padding: 1rem 0rem;
  border-bottom: 11px solid #f2f2f2;
}
.ourProductsHeader h2 {
  font-size: x-large;
  font-weight: 600;
}
.ourProductsHeader p {
  font-size: 11px;
  font-weight: 700;
}
.slick-next:before,
.slick-prev:before {
  display: none !important;
}
.our-solutions .box-cont img {
  /* margin-right: 1em; */
  /* padding: 10px; */
  float: left;
  width: 92% !important;
  height: 280px !important;
  /* margin: 0 10p 0 10px; */
  /* margin-left: 10px; */
}
.our-solutions {
  padding: 50px 0px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  display: inline-block;
  background: #fff;
}

.our-solutions .box-cont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background: #fff;
  /* border-radius: 6px; */
  padding: 5px;
  text-align: center;
  /* margin: 0px 0px 50px 0px; */
  width: 100%;
  /* border: 1px solid #ddd; */
  /* box-shadow: 0px 0px 5px #ccc; */
  /* box-shadow: 0px 15px 5px #ccc; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 5px; */
  border: 1px solid lightgray;
  box-shadow: 2px 2px 6px 3px lightgrey;
  transition: all 0.5s;
}
.laptopProtoTypeContainer {
  /* border: 1px solid black; */
  /* height: 437px; */
  /* padding-top: 1rem; */
  margin-bottom: 0;
  position: relative;
  padding: 0.3rem;
}
.laptopProtoTypeContainer p {
  position: absolute;
  top: 10px;
  left: 30%;
  display: flex;
  gap: 1rem;
  font-size: xx-large;
  font-weight: 500;
}
.laptopProtoTypeContainer img {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid black;
  padding-bottom: 3rem 0 2rem 0;
  border-radius: 5px;
}
.crmFeaturedLaptop,
.box1 {
  display: none;
}

.nextCarHeader {
  font-size: large;
}
.mobileProtoTypeContainer {
  max-width: 100%;
}
.mobileProtoTypeContainer img {
  max-width: 100%;
  max-height: 100%;
  /* border: 1px solid black; */
}
.btn-grad {
  background-image: linear-gradient(
    to right,
    #c57a40 0%,
    #b57249 51%,
    #b873479c 100%
  );
  /* background-image: linear-gradient(to left, #c57a40 0%, #b57249 51%, #b873479c 100%); */
  margin: 10px;
  padding: 10px 35px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  /* color: white; */
  box-shadow: 0 0 20px #eee;
  border-radius: 46px;

  border: 0px solid;
  font-weight: 600;
}
.waiting {
  display: flex;
  flex-direction: column;
}
.waiting,
.mobileProtoTypeContainer {
  flex: 1;
}

.parentNextCar {
  gap: 0.5rem !important;
}
.extraFeatureHomeContainerDesktop {
  display: none;
}

@media (min-width: 530px) {
  .nextCarHeader {
    font-size: x-large;
  }
}

@media (min-width: 720px) {
  .mobileHeroSection,
  .laptopProtoTypeContainer {
    display: none;
  }

  .extraFeatureHomeContainer,
  .marqueeContainer,
  .homeFilterContainer,
  .sellYourCarContainer,
  .vehicleReportContainer {
    display: block;
    z-index: 0 !important;
    position: relative;
  }
  .ourProductsHeader h2 {
    font-size: xx-large;
    font-weight: 600;
  }
  .ourProductsHeader p {
    font-size: larger;
    font-weight: 700;
  }
  .nextCarHeader {
    font-size: xx-large;
  }
  .btn-grad {
    padding: 15px 45px;
    font-size: larger;
    font-weight: 700;
  }
  .mobileProtoTypeContainer img {
    height: 295px;
    width: 100%;
    object-fit: contain;
  }
  .parentNextCar {
    gap: 3rem !important;
  }
}

.OurProductsLaptopSliderParent {
  display: none;
}

@media (min-width: 1024px) {
  .OurProductsMobileSliderParent,
  .crmFeaturedMobile {
    display: none;
  }
  .box1 {
    display: block;
  }
  .crmFeaturedLaptop {
    display: flex;
  }
  .OurProductsLaptopSliderParent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  .our-solutions .box-cont {
    height: 350px;
  }
  .our-solutions .box-cont {
    height: 350px;
  }
  .our-solutions .box-cont img {
    height: 300px !important;
  }
  .parentNextCar {
    gap: 6rem !important;
  }
  .mobileProtoTypeContainer img {
    height: 350px;
    width: 100%;
    object-fit: contain;
  }
  .extraFeatureHomeContainerDesktop {
    display: block;
  }
  .extraFeatureHomeContainerMobile {
    display: none;
  }
}

@media (min-width: 1440px) {
  .our-solutions .box-cont img {
    height: 281px !important;
  }
  .our-solutions .box-cont {
    height: 343px;
  }
  .OurProductsLaptopSliderParent {
    column-gap: 6rem;
    row-gap: 3rem;
    padding: 0 5rem;
  }
  .ourProductsHeader h2 {
    font-size: 45px;
    font-weight: 700;
  }
  .ourProductsHeader p {
    font-size: x-large;
    font-weight: 700;
  }
  .crmFeaturedLaptop {
    gap: 1rem;
  }
  .mmLogoContainer {
    width: 60px !important;
    height: 60px !important;
  }
}
@media (min-width: 2200px) {
  .our-solutions .box-cont img {
    height: 420px !important;
  }
  .OurProductsLaptopSliderParent {
    column-gap: 8rem;
    row-gap: 5rem;
    padding: 0 10rem;
  }
  .our-solutions .box-cont {
    height: 500px;
  }
  .nextCarHeader {
    font-size: 45px;
  }
  .mobileProtoTypeContainer img {
    height: 450px;
  }
}
