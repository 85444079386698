.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: inset 0px 0px 13px 2px #d1823a;
  font-family: "Alata";
}             
.container {
  width: 100%;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1823a;
}
.header p {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  margin-bottom: 0;
}
.header p span:first-child {
  color: grey;
  font-size: smaller;
}
.header p span:last-child {
  font-size: larger;
}
/* body content starts here */
.body {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
}
.body .bodyLeft {
  flex: 0.55;
}
.body .bodyRight {
  flex: 0.45;
  /* padding: 0 1rem; */
}
.salePrice {
  border: #d1823a 1px solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  flex: 0.45;
  margin-bottom: 1rem;
}

.salePrice span {
  font-size: medium;
  font-weight: 700;
}
.salePrice p {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  width: 63%;
  box-shadow: 4px 4px 4px lightgrey;
}
.salePrice p > input {
  background-color: #f6cfb59e !important;
  border: none;
  padding: 0.6rem;
  outline: none;
  color: #d1823a;
  font-weight: 700;
  text-align: center;
}
.salePrice p > span {
  background-color: #d1823a;
  color: white;
  padding: 0.6rem;
}
/* 2nd block */
.tradeInOwnedCashDifference {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem 1rem;
  border: 1px solid #d1823a;
  gap: 0.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.tradeInOwnedCashDifference > div {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
}
.tradeInOwnedCashDifference > div span {
  font-weight: 600;
  font-size: medium;
  font-weight: 700;
  flex: 0.5;
}
.tradeInOwnedCashDifference > div input {
  flex: 0.4;
  width: 50%;
  background-color: #f6cfb59e !important;
  border: none;
  padding: 0.6rem;
  outline: none;
  border-radius: 10px;
  box-shadow: 4px 4px 4px lightgrey;
  color: #d1823a;
  font-weight: 700;
  text-align: center;
}
/* Price Fees Container */
.priceFeesContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid #d1823a;
  border-radius: 10px;
}
.priceFeesContainer .priceFees {
  display: flex;
  align-items: center;
}
.priceFeesContainer .priceFees span {
  font-size: 14px;
  font-weight: 700;
  width: 92%;
}
.priceFeesContainer .priceFees input {
  width: 50%;
  background-color: #f6cfb59e !important;
  border: none;
  padding: 0.6rem;
  outline: none;
  border-radius: 10px;
  box-shadow: 4px 4px 4px lightgrey;
  color: #d1823a;
  font-weight: 700;
  text-align: center;
}

/* right block */
/* 1st box */
.taxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem 1rem;
  border: 1px solid #d1823a;
  gap: 0.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.taxContainer > div {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
}
.taxContainer > div span {
  font-weight: 600;
  font-size: 13px;
  font-weight: 700;
  /* flex: 0.5; */
}
.taxContainer > div input {
  flex: 0.4;
  width: 30%;
  background-color: #f6cfb59e !important;
  border: none;
  padding: 0.6rem;
  outline: none;
  border-radius: 10px;
  box-shadow: 4px 4px 4px lightgrey;
  color: #d1823a;
  font-weight: 700;
  text-align: center;
}
.taxInputContainer {
  width: 57%;
  display: flex;
  justify-content: space-between;
}
.tradeInContainerOuter {
  position: relative;
}
.tradeInContainerOuter > p {
  position: absolute;
  right: 0;
  top: 0.3rem;
  cursor: pointer;
}

.tradeRelativePopoutContainer {
  position: relative;
}
.tradeRelativePopoutContainer .tradeAbsolutePopoutContainer {
  position: absolute;
  top: 4rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  padding-bottom: 1rem;
  border-radius: 14px;
  box-shadow: 4px 4px 4px rgb(236, 234, 234);
  gap: 0;
}
.OwnedContainerPopout {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.OwnedContainerPopout > input{
  width: 100% !important;
}
.tradePopoutCloseBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0;
}
.tradePopoutCloseBtnContainer .tradePopoutCloseBtn {
  color: red;
  flex: 0.05;
  font-size: x-large;
  font-weight: 599;
  cursor: pointer;
}
.tradeInDataContainer {
  display: flex;
  gap: 1.4rem;
}

@media (min-width: 1440px) {
  .body .bodyLeft {
    flex: 0.7;
  }
  .body {
    gap: 1rem;
    padding: 1rem 0.2rem;
  }
  .salePrice p {
    width: 45%;
    height: 45px;
  }

  .tradeInOwnedCashDifference {
    gap: 0.7rem;
    padding-left: 1rem;
  }
  .firstTradeInOwnedCashDifference {
    padding-left: 2.3rem !important;
  }

  .salePrice {
    padding-left: 2.4rem;
  }
  .priceFeesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5rem;
    gap: 1.4rem;
  }
  .priceFeesContainer .priceFees span {
    font-size: 13px;
    font-weight: 500;
    width: 92%;
  }
  .priceFeesContainer .priceFees input {
    width: auto;
  }
  .taxContainer {
    /* padding-left: 2.4rem; */
  }
  .header {
    padding: 1rem 2rem;
  }
  .header p span:last-child {
    font-size: x-large;
    font-weight: 600;
  }
  .header p span:first-child {
    font-size: medium;
  }
  .salePrice span {
    font-size: 20px;
  }
  .salePrice p > input {
    padding: 0.8rem;
  }
  .tradeInOwnedCashDifference > div span {
    font-size: 16px;
  }
  .taxContainer > div span {
    font-size: 17px;
  }
  .priceFeesContainer .priceFees span {
    font-size: 13px;
  }
  .salePrice p > input {
    font-size: 22px;
  }
  .salePrice p > span {
    padding: 0.9rem;
  }
  .tradeInOwnedCashDifference > div input {
    font-size: 19px;
  }
  .priceFeesContainer .priceFees input {
    font-size: 17px;
    width: 32%;
    padding: 0.1rem;
  }
  .taxContainer > div input {
    font-size: 14px;
  }
  .tradeInOwnedCashDifference > div input {
    padding: 0.3rem;
  }
}
@media (min-width: 1800px) {
  .header p span:last-child {
    font-size: xx-large;
  }
  .header p span:first-child {
    font-size: 20px;
  }
  .salePrice span {
    font-size: 24px;
  }
  .salePrice p > input {
    padding: 1.03rem;
  }
  .tradeInOwnedCashDifference > div span {
    font-size: 21px;
  }
  .tradeInOwnedCashDifference > div input {
    font-size: 20px;
  }
  .priceFeesContainer .priceFees input {
    font-size: 16px;
  }
  .taxContainer > div input {
    font-size: 18px;
  }
  .priceFeesContainer .priceFees span {
    font-size: 14px;
  }
  .salePrice p > input {
    font-size: 25px;
  }
  .salePrice p > span {
    padding: 1.059rem;
  }
  .taxContainer > div span {
    font-size: 23px;
  }
  .priceFeesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .tradeInOwnedCashDifference {
    padding-left: 2.4rem;
    gap: 0.9rem;
  }
  .taxContainer {
    padding-left: 2.4rem;
  }
}
