/* Define the card container */
.card-addcost-container {
    width: 90%;
    margin: 5%;
    border: 2px solid #d1823a;
    padding: 1%;
    height: auto; /* Adjust this value as needed */
  }
  
  /* Pagination controls styling */
  .pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-right: 30% !important;
  }
  
  .pagination-controls button {
    background-color: #d1823a;
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .pagination-controls button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  

.v-card-addcost{
    width: 100%;
    min-height: 120px !important;
    box-sizing: border-box;
    border: 2px solid var(--orange);
    border-radius: 5px !important;
    color: black;
}

.divide-card {
  display: inline-block;
  min-width: 2px;
  height: 80%;
  margin-left: 5%;
  margin-right: 5%;
  background-color: rgb(167, 163, 163);
  border-radius: 10px;
}
