.wrapper {
  width: 100%;
  padding: 0 1rem;
}
.container {
  width: 100%;
  margin-bottom: 1rem;
}
.CRMsearchBar {
  width: 100%;
}
.CRMsearchBar button{
  background-color: #D7996D !important;
}
.tabPanelContainer {
  display: flex;
  margin-bottom: 0.4rem;
  height: 3rem;
}
.tabPanel:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  flex: 1 !important;
}
.tabPanel:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* .tabPanel:not(:nth-child(2)) {
  border: 2px solid #d1823a;
  padding: 0.5rem 2rem;
  background-color: #d1823a;
  color: white;
  border-right: 1px solid white;
  font-weight: 600;
  cursor: pointer;
  flex: 1;
  text-align: center;
} */
.tabPanel {
  border: 2px solid #d1823a;
  /* padding: 0.5rem 2rem; */
  background-color: #d7996d;
  color: white;
  border-right: 1px solid white;
  font-weight: 600;
  cursor: pointer;
  flex: 1;
  padding-top: 0.5rem;
  text-align: center;
}
.tabPanel span {
  text-wrap: inherit;
}
.tabPanel:nth-child(2) input {
  height: 100%;
  box-shadow: inset 0 0 11px #e7bb93;
  border: none;
  outline: none;
  font-weight: 600;
}
@media (min-width: 720px) {
  .parentSearch {
    /* position: absolute; */
    top: 0;
    right: 4%; 
  }
  .CRMsearchBar {
    width: 18% !important;
    position: relative;
  }
}
@media (min-width: 1024px) {
  .wrapper {
    padding: 0 4rem;
  }
}


