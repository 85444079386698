.paymentBodyWrapper {
  /* border: 1px solid black; */
}
.paymentDueDataPopupHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #d1823a; */
}
.paymentDueDataPopupHeader p {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  margin-bottom: 0;
}
.paymentDueDataPopupHeader p span:first-child {
  color: grey;
  font-size: smaller;
}
.paymentDueDataPopupHeader p span:last-child {
  font-size: larger;
}
.paymentBodyWrapper .nav-tabs {
  border: 1px solid black !important;
}
.main_container_payment {
  display: flex;
  justify-content: space-between;
}

.dataMessangerWrapper {
  padding: 1rem;
  box-shadow: inset 0px 0px 13px 2px #d1823a;
}
.dataMessangerContainer {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}
.dataMessangerContainer .dataPartContainer,
.dataMessangerContainer .MessangerPartContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.MessangerPartWrapper {
  width: 45%;
  display: flex;
  padding: 0 1rem;
  justify-content: center;
}
.currentBalance {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  position: relative;
}
.currentBalance > button{
 position: absolute;
 right: 0;
}
.currentBalance .balanceHeader {
  font-size: 15px;
  color: dimgrey;
  font-weight: 500;
}
.currentBalance input {
  background-color: #eecfa5 !important;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 12px;
  /* flex: 0.6; */
  padding: 12px !important;
  width: 25%;
  border: none;
  outline: none;
  border-radius: 10px;
  font-weight: 700;
}
.MessangerPartContainer {
  border: 1px solid #cbcbcb;
  box-shadow: 5px 1px 7px #cbcbcb;
  border-radius: 10px;
}

.MessangerHeader {
  width: 100%;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.MessangerHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
}
.MessangerHeader span:first-child {
  font-size: large;
  font-weight: 500;
}
.MessangerHeader span:last-child {
  /* border: 1px solid black; */
  height: 20px;
  width: 20px;
  display: grid;
  place-content: center;
  padding: 0.7rem;
  border-radius: 50%;
  background-color: #d1823a;
  color: white;
}

.MessangerBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: small;
  gap: 1rem;
  padding: 0.5rem;
  height: 100%;
}
.MessangerBody .LeftBody {
  width: 100%;
  padding-right: 10%;
}
.MessangerBody .LeftBody .message {
  background-color: #eeb9a542;
  box-shadow: 3px 3px 7px #bdafaf;
}

.MessangerBody .RightBody {
  width: 100%;
  padding-left: 10%;
}
.MessangerBody .RightBody .message {
  background-color: rgba(216, 212, 212, 0.445);
  box-shadow: 3px 3px 7px #bdafaf;
}
.message {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: start;

  padding: 0.4rem;
}
.message span:first-child {
  font-weight: 500;
}
.message span:last-child {
  font-size: smaller;
}
.MessangerInput {
  width: 100%;
  display: flex;
  position: relative;
  box-shadow: -1px -4px 4px #bdafaf;
}
.MessangerInput span {
  position: absolute;
  right: 0.5rem;
  color: #d1823a;
  top: 10%;
  font-size: larger;
}
.MessangerInput input {
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0.5rem;
}
/* Messanger ends here */
.mainContainer {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.mainNotesContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  /* justify-content: space-between; */
}
.mainNotesContainer input {
  flex: 0.9;
}
.mainNotesContainer .label_header {
  flex: 0.3;
  font-size: 15px;
  color: dimgrey;
}
.mainContainer .label_header {
  flex: 0.6;
  font-size: 15px;
  color: dimgrey;
}
.mainContainer input {
  background-color: #eecfa5 !important;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 12px;
  /* flex: 0.6; */
  padding: 12px !important;
}
.mainContainer input.darkOrangeInput {
  background-color: #d1823a  !important;
  color: white;
  padding-left: 1rem !important;
}
.calculatorContainer {
  display: flex;
  flex: 0.7;
  position: relative;
}
.calculatorContainer .calculator_dropdown {
  position: absolute;
  width: 100%;
  width: 28vw;
  right: 0;
  top: 3rem;
  border: 1px solid #ccc;
  background-color: #949494;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.calculatorContainer input {
  flex: 1;
  padding: 12px !important;
}
.mainContainer .label_header2 {
  flex: 0.6;
  font-size: 15px;
}
.calculatorContainer input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.calculatorContainer .arrow_amout {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input {
  background-color: #eecfa5 !important;
  font-weight: bolder !important;
  letter-spacing: 1px;

  flex: 0.6;
  padding: 12px !important;
  font-size: 12px !important;
}

.label_header {
  font-weight: 500;
}

.message_card {
  border: 1px solid grey !important;
}

.checkbox {
  margin-right: 10px; /* Adds space between checkbox and label text */
  vertical-align: middle; /* Aligns the checkbox vertically with the text */
}

.save_btn {
  color: white;
  border-radius: 5px;
  background-color: #d1823a;
  padding: 0.5rem 0.9rem;
  width: 147px;
  border: none;
  float: right;
  /* width: auto; */
  font-weight: 500;
}
.save_btn:hover {
  background-color: lightgray !important;
  color: black !important;
}

.arrow_amout {
  background-color: #d1823a;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  color: white;
}
@media (min-width: 1440px) {
  .calculatorContainer {
    flex: 0.65;
  }
  .dataMessangerContainer .dataPartContainer,
  .dataMessangerContainer .MessangerPartContainer {
    gap: 1.5em;
  }
  .dataMessangerWrapper {
    padding: 2.5rem;
  }
  .mainContainer .label_header {
    font-size: 18px;
  }
  .mainContainer .label_header2 {
    font-size: 18px;
  }
  .mainNotesContainer .label_header {
    font-size: 18px;
  }
  .currentBalance .balanceHeader {
    font-size: 18px;
  }
  .MessangerBody {
    font-size: 18px;
  }
  .input {
    font-size: 16px !important;
  }
  .mainNotesContainer .label_header {
    flex: 0.315;
  }
  .currentBalance {
    margin-bottom: 2rem;
  }
  .calculatorContainer .calculator_dropdown {
    width: 20vw;
  }
  .currentBalance input {
    font-size: 15px;
    font-weight: 700;
  }
}
@media (min-width: 1800px) {
  .dataMessangerWrapper {
    padding: 2.5rem 7rem;
  }
  .dataMessangerContainer {
    gap: 10rem;
  }
  .MessangerPartWrapper {
    width: 35%;
  }

  .dataMessangerContainer .MessangerPartContainer {
    gap: 2.5em;
  }
  .dataMessangerContainer .dataPartContainer {
    gap: 1.5rem;
    /* padding-top: 4rem; */
  }
  .dataMessangerContainer {
    margin-bottom: 2rem;
  }
  .dataPaymentContainer label {
    font-size: 18px;
  }
  .dataPaymentContainer button {
    font-size: 18px;
    font-weight: 700;
  }
  .calculatorContainer .calculator_dropdown {
    width: 15vw;
  }
}
