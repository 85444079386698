.spamStage {
    border-right: 1px solid black;
    padding-top: 5px;
}

.riscLevel {
    display: flex;
    justify-content: space-between;
}

.spamStage p {
    font-size: .8rem;
    color: gray;
}

.worn {
    color: red;
    font-size: .8rem;
    margin-bottom: 3px;
}

.fsSmall {
    font-size: .8rem;
    font-weight: 400 !important;
    color: gray;
    /* text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1; */
}

.tableCon {
    /* height: 20vh; */
    margin-top: 10px;
    border: 1px solid gray;
}

.tableHead {
    color: gray;
    padding-left: 5px;
    font-weight: 600;
    border-bottom: 1px solid gray;
    background: linear-gradient(#fff, #ffd4a3);
}

.tableCon table {
    width: 100%;
}

.tableCon tr {
    width: 100%;
    border-bottom: 1px solid gray;
}

.formRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.formRow label {
    display: flex;
    justify-content: space-between;
    text-wrap: nowrap;
}

.formRow label input[type="checkbox"] {
    margin-right: 7px;
}

.formRow input {
    width: 50% !important;
}

.saveBtn {
    color: white;
    font-weight: 600;
    background: linear-gradient(#5143de, #61b6df);
    border: none !important;
    border-radius: 5px;
    margin-top: 10px;
}

.popUps {
    width: 40vw;
    margin: 2%;
    padding: 3%;
    background-color: #e2f5ff;
    /* background-color: #ffe8cd; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.popUps input {
    width: 100%;
}