.wrapper {
  width: 100%;
}
.container {
  background-color: lightgrey;
}
.headerModal {
  width: 100%;
  text-align: center;
  color: #d1823a;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0;
}
