.wrapper {
  width: 100%;
  padding: 1rem;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tabsContainer {
  display: flex;
  width: 100%;
  box-shadow: 3px 3px 3px grey;
  border-radius: 10px;
}
.tabsContainer > button {
  background-color: #d1823a;
  border: none;
  flex: 1;
  padding: 0.4rem;
  font-size: 20px;
  border: 2px solid white;
  color: white;
}
.tabsContainer > button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.tabsContainer > button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.contentContainer{
  width: 100%;
}
