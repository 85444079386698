.car-info {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.form-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
}

.label {
  width: 25%;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.input {
  width: calc(75% - 35px); /* Adjusting width to accommodate the arrow button */
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e9ecef;
  color: #495057;
  margin-right: 10px;
}

.inputReadOnly {
  background-color: #e9ecef;
  font-weight: bold;
}

.errorText {
  margin-left: 10px;
  color: #dc3545;
}

.arrowButton {
  width: 25px;
  height: 25px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #007bff;
}

.arrowButton:hover {
  color: #0056b3;
}

.imageSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageSection img {
  max-width: 90%;
  height: auto;
  /* border-radius: 10px; */
  margin-bottom: 20px;
}

.buttonGroup {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.buttonGroup .btn {
  flex-grow: 1;
  margin: 0 5px;
}

.btnPrimary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btnSecondary {
  background-color: #6c757d;
  color: #fff;
  border: none;
}





@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    width: 100%;
    margin-bottom: 5px;
  }

  .input {
    width: 100%;
  }

  .imageSection {
    margin-top: 20px;
  }
}





