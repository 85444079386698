.crmMwrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
  }
  .crmMcontainer {
    /* border: 1px solid black; */
    width: 100%;
    background-color: rgb(246 220 199);
    border-radius: 10px;
    box-shadow: 3px 3px 3px lightgrey;
    position: relative;
  }
  .crmMNumbering {
    position: absolute;
    top: 41%;
    left: -1.4rem;
    height: 20px;
    width: 20px;
    background-color: lightgrey;
    box-shadow: 3px 3px 3px #948c8c;
    border-radius: 50%;
    padding-left: 0.4rem;
  }
  .crmMHeaderMain{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 2rem;
    border-bottom: 1px solid rgb(186, 104, 29);
  }
  .crmMHeaderRight{
    padding-left: 31% !important;
  }
  .crmMHeader {
    display: flex;
    flex-direction: row;
    flex-direction: row;
    justify-content: space-around !important;
    width: 100%;
    padding: 0.2rem 0.5rem;
  }
  .crmMHeader > span:first-child {
    color: rgb(186, 104, 29);
    font-size: 10px;
  }
  .crmMHeader > span:last-child {
    font-weight: 500;
    font-size: 11px;
  }
  .crmMPersonalWrapper {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.6rem 0.2rem;
  }
  .crmMPersonalDetails {
    display: flex;
    flex-direction: row;
    flex: 0.8;
    gap: 1.6rem;
  }
  .crmMPersonalDetails .crmMDetail {
    margin-bottom: 0;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 700;
  }
  .crmMDetail span:first-child {
    color: rgb(186, 104, 29);
    flex: 0.35;
  }
  .crmMDetail span:last-child {
    flex: 0.6;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 5px;
  }
  .crmMBtnContainer {
    margin-top: 4%;
    width: 80px;
  }
  .crmMBtnContainer button {
    font-size: 11px;
    border: none;
    background-color: rgb(186, 104, 29);
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 0.2rem;
  }
  @media (min-width: 375px) {
    .crmMHeader > span:first-child {
      font-size: 12px;
    }
    .crmMHeader > span:last-child {
      font-size: 13px;
    }
    .crmMPersonalDetails .crmMDetail {
      font-size: 13px;
    }
  }
  @media (min-width: 420px) {
    .crmMcontainer {
      /* border: 1px solid black; */
      width: 95%;
    }
    .crmMHeader > span:first-child {
      font-size: 13px;
      font-weight: 500;
    }
    .crmMHeader > span:last-child {
      font-size: 14px;
    }
    .crmMPersonalDetails .crmMDetail {
      font-size: 14px;
    }
    .crmMBtnContainer button {
      font-weight: 500;
      font-size: 13px;
    }
    .crmMBtnContainer {
      gap: 0.4rem;
    }
  }
  @media (min-width: 520px) {
    .crmMHeader > span:first-child {
      font-size: 14px;
    }
    .crmMHeader > span:last-child {
      font-size: 15px;
    }
    .crmMPersonalDetails .crmMDetail {
      font-size: 15px;
    }
    .crmMBtnContainer button {
      font-weight: 500;
      font-size: 17px;
    }
    .crmMPersonalWrapper {
      padding: 0.6rem 1.2rem;
    }
    .crmMHeader {
      padding: 0.2rem 1rem;
    }
    .crmMNumbering {
      left: -1.8rem;
    }
  }
/* message section-----pop-up */
.mibileMessageCobntainer{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/*---------------- contact pop-up design--------------- */
.contact_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }
  
  .MessangerPartWrapper {
    width: 100%;
    display: flex;
    /* padding: 0 1rem; */
    justify-content: center;
  }
  .MessangerPartWrapperb {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .MessangerPartContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .MessangerPartContainer {
    border: 1px solid #d1823a;
    /* box-shadow: 5px 1px 7px #cbcbcb; */
    border-radius: 10px;
  }
  .MessangerPartContainerb {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .MessangerPartContainerb {
    border: 1px solid #d1823a;
    border-radius: 10px;
  }
  .message_btn{
    border: none;
    background-color: #d1823a;
    color: white;
    width: 150px;
    border-radius: 5px;
  }
  .MessangerHeader {
    width: 100%;
    /* box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1); */
    background-color: #d1823a;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .MessangerHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
  }
  .MessangerHeader span:first-child {
    font-size: large;
    font-weight: 500;
  }
  .MessangerHeader span:last-child {
    /* border: 1px solid black; */
    height: 20px;
    /* width: 20px; */
    display: grid;
    place-content: center;
    padding: 0.7rem;
    border-radius: 50%;
    background-color: #d1823a;
    font-size: large;
    font-weight: 500;
    color: white;
  }
  .MessangerBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: small;
    gap: 1rem;
    padding: 0.5rem;
    height: 100%;
  }
  .MessangerBody .LeftBody {
    width: 100%;
    padding-right: 10%;
  }
  .MessangerBody .LeftBody .message {
    background-color: #eeb9a542;
    box-shadow: 3px 3px 7px #bdafaf;
  }
  
  .MessangerBody .RightBody {
    width: 100%;
    padding-left: 10%;
  }
  .MessangerBody .RightBody .message {
    background-color: rgba(216, 212, 212, 0.445);
    box-shadow: 3px 3px 7px #bdafaf;
  }
  .MessangerInput {
    width: 100%;
    display: flex;
    position: relative;
    /* box-shadow: -1px -4px 4px #bdafaf; */
    border-top: 1px solid #d1823a;
  }
  .MessangerInput span {
    position: absolute;
    right: 0.5rem;
    color: #d1823a;
    top: 31%;
    font-size: larger;
  }
  .MessangerInput input {
    border: none;
    outline: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0.5rem;
    height: 5rem;
  }
/*  left section */
.left_container_contact{
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1rem;
}
.border_box {
  display: inline-block; /* This will shrink-wrap the content */
  position: relative; /* For absolute positioning of the text */
  border: 1px solid #d1823a; /* Create the border around the box */
  padding: 10px 20px; /* Add padding for the spacing */
  border-radius: 10px;
}

.asset_text {
  position: absolute;
  top: -3px;
  left: 9%;
  color: #d1823a;
  z-index: 1;
  background-color: white;
  transform: translateX(-50%); /* Center the text horizontally */
  margin-top: -10px; /* Adjust to place the text exactly on top */
  font-weight: bold; /* Optional, for emphasis */
}
/* -------------------------------------- */
  @media (min-width: 1440px) {
    .MessangerPartContainer {
       gap: 1.5em;
     }
     .MessangerBody {
       font-size: 18px;
     }
   }
   @media (min-width: 1800px) {
     .MessangerPartWrapper {
       width: 50%;
     }
     .MessangerPartContainer {
       gap: 2.5em;
     }
   }