.fpsWrapper {
  width: 100%;
}
.fpsContainer {
  width: 100%;
  padding: 0.5rem 0;
}
.fpsTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #646161;
  border-bottom: 2px solid #d6cece;
  padding: 1rem 0;
  margin-bottom: 0;
}
.fpsTitle > span:first-child {
  flex: 0.6;
  margin-top: -0.1rem;
  font-size: larger;
}
.fpsPrintBtnContainer {
  display: flex;
  justify-content: end;
  width: 100%;
}
.fpsPrintBtnContainer button {
  background-color: maroon;
  color: white;
  border: none;
  padding: 0.3rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 3px 3px 3px grey;
}
.fpsTable {
  width: 100%;
}
.fpsTable th {
  background-color: #d1823a;
  font-size: 11px;
  border: 1px solid white;
  color: white;
  font-weight: 400;
  padding: 0.2rem;
  text-align: center;
}
.fpsTable th:first-child {
  border-top-left-radius: 13px;
}
.fpsTable th:last-child {
  border-top-right-radius: 13px;
}
.fpsTable tbody tr:nth-child(2n + 1) {
  background-color: #e9ccb1;
}
.fpsTable tbody tr:nth-child(2n) {
  background-color: rgba(235, 231, 231, 0.788);
}
.fpsTable tbody tr:last-child {
  background-color: #d1823a;
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
}
.fpsTable tbody tr:last-child td:first-child {
  border-bottom-left-radius: 13px;
}
.fpsTable tbody tr:last-child td:last-child {
  border-bottom-right-radius: 13px;
}
.fpsTable tbody td {
  text-align: center;
  border: 1px solid white;
  color: transparent;
}

@media (min-width: 1024px) {
  .fpsTable th {
    font-size: 16px;
    font-weight: 600;
  }
  .fpsTable td {
    font-size: 16px;
    font-weight: 600;
  }
  .fpsTitle {
    display: none;
  }
}
