.sell_data_form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.sell_data_form button {
    background-color: #d1823a;
    border: none;
    color: white;
    border-radius: 5px;
    width: 200px;
    height: 40px;
    font-weight: 600;
}

.sell_data_form button:hover {
    background-color: lightgray;
    color: black;
}

.sell_data_form img {
    width: 24px;
    /* Adjust size as needed */
    height: 24px;
}