.wrapper {
  border: none;
  /* border-top: 2px solid #d1823a;
  border-right: 2px solid #d1823a; */
  font-family: "Alata", serif;
}

.container {
  /* border: 2px solid red; */
  /* padding: 0.5rem 1rem; */
  padding: 1rem;
  padding-bottom: 0;
  height: 100px;
}

.detailValueContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.detailValueContainer .detailValueHeader {
  font-size: 16px;
  font-weight: 500;
}

.detailValueContainer p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  gap: 0.2rem;
  /* background-color: rgb(226, 217, 217); */
  background-color: #f8e7d4;
  padding: 0 0.5rem;
  border-radius: 3px;
  font-size: larger;
  font-weight: 500;
  /* border: 1px solid red; */
  /* width: 70%; */
  box-shadow: inset 0px 0px 6px 6px #e7c49b, 4px 4px 4px #c9c4c4;
  width: 50%;
  border-radius: 10px;
}

.detailValueContainer p span:nth-child(2) {
  padding: 0.3rem 0.1rem;
  font-size: 12px;
}

.detailValueContainer p span:nth-child(1) {
  /* background-color: #e2d9d9; */
  padding: 0px 0.3rem;
  /* width: 55%; */
  border: none;
  text-align: center;
}

.detailValueContainer p input:focus {
  border: none;
  outline: none;
}
.detailTaxPercWrapper .detailTaxPerc {
  display: flex;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
}

.detailValueContainer input {
  /* width: 30%; */
  padding-left: 0 !important;
  font-size: 12px;
  font-weight: 500;
}

.detailValueContainerMinventory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.detailValueContainerMinventory .detailValueHeader {
  font-size: 16px;
  font-weight: 500;
}

.detailValueContainerMinventory p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  gap: 0.2rem;
  background-color: #f8e7d4;
  padding: 0 0.5rem;
  border-radius: 3px;
  font-size: larger;
  font-weight: 500;
  box-shadow: inset 0px 0px 6px 6px #e7c49b, 4px 4px 4px #c9c4c4;
  /* border: 1px solid red; */
  width: 50%;
  border-radius: 8px;
}

.detailValueContainerMinventory p span:nth-child(2) {
  padding: 0.3rem 0.1rem;
  font-size: 12px;
}

.detailValueContainerMinventory p span:nth-child(1) {
  background-color: transparent;
  padding: 0.3rem;
  /* width: 30%; */
  border: none;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.detailValueContainerMinventory p input:focus {
  border: none;
  outline: none;
}

.detailValueContainerMinventory input {
  /* width: 30%; */
  padding-left: 0 !important;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
}

@media (min-width: 420px) {
  .detailValueContainer input {
    width: 60%;
    font-size: 15px;
  }
  .detailValueContainer p span:nth-child(2) {
    font-size: 15px;
  }

  .detailValueContainerMinventory input {
    width: 60%;
    font-size: 15px;
  }
  .detailValueContainerMinventory p span:nth-child(2) {
    font-size: 15px;
  }
}
@media (min-width: 720px) {
  .detailValueContainer .detailValueHeader {
    font-size: 18px;
  }
  .detailValueContainer input {
    width: 60%;
    font-size: 18px;
  }
  .detailValueContainer p span:nth-child(2) {
    font-size: 18px;
  }

  .detailValueContainerMinventory input {
    width: 60%;
    font-size: 18px;
  }
  .detailValueContainerMinventory p span:nth-child(2) {
    font-size: 18px;
  }
  .detailValueContainer p span:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
  }
  .detailValueContainerMinventory .detailValueHeader {
    font-size: 18px;
  }
  .detailValueContainer p {
    width: 40%;
    border-radius: 10px;
    box-shadow: inset 0px 0px 6px 6px #e7c49b, 3px 3px 3px grey;
  }

  .detailValueContainerMinventory p {
    width: 40%;
    border-radius: 10px;
    box-shadow: 3px 3px 3px lightgray;
    box-shadow: inset 0px 0px 6px 6px #e7c49b, 3px 3px 3px grey;
  }
  .detailValueContainerMinventory p span:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
  }
}
@media (min-width: 1024px) {
  .detailValueContainer .detailValueHeader {
    font-size: 15px;
  }
  .detailValueContainer input {
    width: 60%;
    font-size: 15px;
  }
  .detailValueContainer p span:nth-child(2) {
    font-size: 15px;
  }

  .detailValueContainerMinventory input {
    width: 60%;
    font-size: 15px;
  }
  .detailValueContainerMinventory p span:nth-child(2) {
    font-size: 15px;
  }
  .detailValueContainer p span:nth-child(1) {
    font-size: 15px;
    font-weight: 500;
  }
  .detailValueContainerMinventory .detailValueHeader {
    font-size: 15px;
  }
  .detailValueContainer p {
    width: 40%;
    border-radius: 10px;
    box-shadow: inset 0px 0px 6px 6px #e7c49b, 3px 3px 3px grey;
  }

  .detailValueContainerMinventory p {
    width: 40%;
    border-radius: 10px;
    box-shadow: 3px 3px 3px lightgray;
    box-shadow: inset 0px 0px 6px 6px #e7c49b, 3px 3px 3px grey;
  }
  .container {
    margin-bottom: 0.9rem;
  }
  .detailValueContainerMinventory p span:nth-child(1) {
    font-size: 15px;
  }
}
@media (min-width: 1440px) {
  .detailValueContainer {
    margin-bottom: 0.7rem;
  }
  .detailValueContainer .detailValueHeader {
    font-size: 18px;
  }
  .detailValueContainer input {
    width: 60%;
    font-size: 18px;
  }
  .detailValueContainer p span:nth-child(2) {
    font-size: 18px;
  }

  .detailValueContainerMinventory input {
    width: 60%;
    font-size: 18px;
  }
  .detailValueContainerMinventory p span:nth-child(2) {
    font-size: 18px;
  }
  .detailValueContainer p span:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
  }
  .detailValueContainerMinventory .detailValueHeader {
    font-size: 18px;
  }
  .detailValueContainer p {
    width: 30%;
    border-radius: 10px;
    box-shadow: inset 0px 0px 6px 6px #e7c49b, 3px 3px 3px grey;
  }

  .detailValueContainerMinventory p {
    width: 30%;
    border-radius: 10px;
    box-shadow: 3px 3px 3px lightgray;
    box-shadow: inset 0px 0px 6px 6px #e7c49b, 3px 3px 3px grey;
  }
}
@media (min-width: 1800px) {
  .detailValueContainer {
    margin-bottom: 0.7rem;
  }
  .detailValueContainer .detailValueHeader {
    font-size: 18px;
  }
  .detailValueContainer input {
    width: 60%;
    font-size: 22px;
  }
  .detailValueContainer p span:nth-child(2) {
    font-size: 22px;
  }

  .detailValueContainerMinventory input {
    width: 60%;
    font-size: 22px;
  }
  .detailValueContainerMinventory p span:nth-child(2) {
    font-size: 22px;
  }
  .detailValueContainer p span:nth-child(1) {
    font-size: 22px;
    font-weight: 500;
  }
  .detailValueContainerMinventory .detailValueHeader {
    font-size: 18px;
  }
  .detailValueContainer p {
    width: 30%;
    border-radius: 10px;
    box-shadow: inset 0px 0px 6px 6px #e7c49b, 3px 3px 3px grey;
  }

  .detailValueContainerMinventory p {
    width: 30%;
    border-radius: 10px;
    box-shadow: 3px 3px 3px lightgray;
    box-shadow: inset 0px 0px 6px 6px #e7c49b, 3px 3px 3px grey;
  }
}

