.FormWrapper {
  position: absolute;
  height: auto;
  width: 100%;

  top: 0;
  left: 0;
  background-color: white;
  padding: 1.5rem 1rem;
}
.FormContainer {
  border: 1px solid black;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 1rem 2rem;
}
.FormContainer .head {
  position: absolute;
  top: -1rem;
  left: 2rem;
  background-color: white;
  padding: 0 1rem;
  font-size: larger;
  font-weight: 600;
  color: blue;
}
.noteInfos {
  /* margin-top: 2rem; */
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.noteInfos .infoTag {
  border: 1px solid black;
  width: 100px;
  margin-bottom: 0;
  text-align: center;
  flex: 0.1;
}
.noteInfos input {
  padding: 0;
  flex: 0.15;
  border: none;
  border-bottom: 1px solid #ccc;
}
.noteInfos input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
}
.readers {
  display: flex;
  flex: 0.5;

  justify-content: space-between;
  padding: 0 0.5rem;
  gap: 1rem;
}
.readers span {
  background: rgb(202, 197, 197);
  flex: 1;
  text-align: center;
  color: white;
  font-weight: 500;
  padding: 0.3rem;
}
.colors {
  display: flex;
  flex: 0.4;

  justify-content: end;
  gap: 5rem;
}
.colors p {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-bottom: 0;
}

/* Subject Line */
.subjectInfo {
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
  gap: 2rem;
  margin-top: 3rem;
}
.subjectInfo .subjectTag {
  margin-bottom: 0;
  border: 1px solid black;
  width: 115px;
  text-align: center;
}
.subjectInfo input {
  border: none;
  border-bottom: 1px solid #ccc;
  flex: 0.65;
}
.subjectInfo input:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
}

.input-field-container {
  display: flex;
  flex-direction: column;
}

.textareaContainer {
  margin: 2rem 0;
}
.input_field_container {
  position: relative;
  width: 100%;
  height: 250px; /* Adjust based on your needs */
  overflow: hidden;
}

.input_field {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  line-height: 30px; /* Adjust based on your needs */
  background-image: linear-gradient(
    transparent,
    transparent calc(24px - 1px),
    #ccc calc(24px - 1px)
  );
  background-size: 100% 24px;
  font-family: Arial, sans-serif;
  color: black;
}

.input_field::placeholder {
  color: #999;
}
.input_field:focus {
  border: none;
  outline: none;
}
