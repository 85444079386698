.wrapper {
  /* border: 2px solid red; */
  display: flex;
  padding: 0.3rem;
  background-color: rgba(240, 233, 233, 0.712);
  /* height: 6rem; */
}

.container {
  /* border: 2px solid blue; */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.container img {
  height: 70px;
  width: 120px;
  margin: auto;
}

.vin_name_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  margin: 0 0.5rem;
  flex: 1;
  /* border: 2px solid blue; */
}

.vin_name_container {
  display: flex;
  flex-direction: column;
  margin: 0;
  /* border-left: 3px solid black;
  border-right: 3px solid black; */
  align-items: center;
  justify-content: center;
  /* margin: 0 0.5rem;
  padding: 0.5rem; */
  /* border: 2px solid blue; */
  flex: 1;
}

.price_type_container {
  display: flex;
  /* border: 1px solid green; */
  justify-content: space-around;
  height: 80%;
  /* flex: 1; */
  padding: 0 0.3rem;
  gap: 1rem;
}

.expanded_price_type_container {
  flex-direction: column;
}

.price_type_container div {
  display: flex;
  flex-direction: column;
  flex: 1;

}

.price_container span:nth-child(1),
.type_container span:nth-child(1) {
  /* font-weight: 700; */
  /* padding: 0.3rem; */
  display: grid;
  place-content: center;
}

.price_container span:nth-child(2),
.type_container span:nth-child(2) {
  /* font-weight: 700; */
  /* padding: 0.3rem; */
  display: grid;
  place-content: center;
}

.price_container span:nth-child(1) span {
  display: none;
}

.price_container span {
  font-weight: 600;
  /* border: 2px solid rgb(245, 13, 13); */
  background-color: grey;
  cursor: pointer;
  border-radius: 10px;
  padding: 0.2rem;
  color: white;
  text-align: center;
  flex: 1;
}

.type_container span {
  font-weight: 600;
  border: 2px solid rgb(245, 125, 13);
  background-color: rgba(231, 163, 117, 0.774);
  padding: 0.2rem;
  color: white;
  text-align: center;
}

/* For hiding */
.hide_header {
  display: none;
}

.inputFieldContainer {
  /* border: 2px solid yellow; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: end;
  padding-bottom: 1rem;
}

/* for 1024 size */
@media (min-width: 1024px) {
  .container {
    flex-direction: row;
  }

  .container img {
    height: 100px;
    width: 150px;
    margin: auto;
  }

  .vin_name_container {
    flex: 1;
    font-size: large;
    /* border: 2px solid red; */
    display: flex;

  }

  .expanded_price_type_container {
    flex-direction: row;
  }

  .hide_header {
    display: block;
  }

  .inputFieldContainer {
    padding-bottom: 0.5rem;
  }
}

/* for 1440px size */
@media (min-width: 1440px) {
  .container img {
    width: 150px;
    height: 100%;
    margin: auto;
  }

  .vin_name_container {
    /* flex: 0.35; */
    /* font-size: x-large; */
    margin: 0 0.5rem;
    padding: 0.5rem;
    /* border: 2px solid yellow; */
    /* height: 80%; */
    display: flex;
  }

  .vin_name_container span {
    text-align: left;
    text-wrap: nowrap;
  }

  .vin_name_container .vin_Container {
    padding: 0;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  /* .vin_name_container span:nth-child(1){
   font-size: large;
   font-weight: bolder;
  } */

  .expanded_price_type_container {
    flex-direction: row;
  }

  .hide_header {
    display: block;
  }

  .price_type_container {
    flex: 0.5;
  }

  .price_type_container {
    padding: 0 0.3rem;
    /* font-size: x-large; */
    font-weight: 500;
  }

  .price_type_container div {
    gap: 0.9rem;
    height: 2rem;
  }

  .price_container span:nth-child(1) span {
    display: block;
  }

  .inputFieldContainer {
    padding-bottom: 2rem;
  }
}

@media (min-width: 2560px) {
  .container img {
    height: 100%;
    width: 250px;
    margin: auto;
  }

  .price_type_container {
    flex: 0.35;
    /* font-size: xx-large; */
    /* font-weight: 600; */
  }
}

.clickedSpan {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  border-radius: 3px;
  padding: 0px 0.5rem;
  color: white;
  text-align: center;
  flex: 1;
  background-color: orange;
  /* Change to your desired color */
}

.clickedSpanb {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  border-radius: 3px;
  padding: 0px 0.5rem;
  color: white;
  text-align: center;
  flex: 1;
  background-color: grey;
  text-wrap: nowrap;
  /* Change to your desired color */
}

.txtbutton_saleprice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FCE8CF;
  color: black;
  font-weight: bolder;
  font-size: larger;
  height: 40px;
  /* border-radius: 5px; */
  margin-left: auto;
  margin-right: auto;
}

.yearMake {
  font-weight: 100px;
  font-size: 20px;
  /* width: 400px !important; */
}

@media (min-width:1200px) and (max-width:1500px) {
  .yearMake {
    font-weight: 100px;
    font-size: 20px;
    width: 400px !important;
  }

  .wrapper {
    /* border: 2px solid red; */
    display: flex;
    padding: 0.3rem;
    background-color: rgba(240, 233, 233, 0.712);
    height: 8rem;
  }

  .clickedSpan {
    font-weight: 200;
    cursor: pointer;
    width: 142px;
    height: 25px;
    border-radius: 10px;
    padding: 0.2rem;
    color: white;
    text-align: center;
    flex: 1;
    background-color: orange;
    /* Change to your desired color */
  }

  .clickedSpanb {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    width: 142px;
    width: 100%;
    height: 30px;
    cursor: pointer;
    border-radius: 3px;
    padding: 0px 0.5rem;
    color: white;
    text-align: center;
    flex: 1;
    background-color: grey;
    /* Change to your desired color */
  }

  .price_type_container {
    gap: 10px !important;
  }

  .price_type_container div {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
  }
}