.wrapper {
  width: 100vw;
}
.container {
  width: 100%;
  padding: 0.4rem;
}
.footer1 {
  /* display: flex; */
}
.logo {
  font-size: x-large;
  font-weight: 500;
  padding: 0.4rem;
}
.miniContainer {
  display: flex;
  justify-content: space-between;
}
.address {
  display: flex;
  flex-direction: column;
}
.address span:nth-child(1) {
  font-size: larger;
  font-weight: 600;
}
.footer2 {
  /* border: 1px solid red; */
}
.footer2 .copyRight {
  font-size: small;
  text-align: center;
  margin-bottom: 3px;
}
.footer2 .socials {
  font-size: large;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.socials img {
  height: 20px;
}

@media (min-width: 720px) {
  .container {
    padding: 1rem;
  }
  .footer1 {
    display: flex;
    justify-content: space-between;
    font-size: large;
  }
  .logo {
    flex: 0.3;
    font-size: xx-large;
  }
  .miniContainer {
    flex: 1;
    /* border: 1px solid black; */
    padding-right: 1rem;
  }
  .unorderedList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
  }
  .footer2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    align-items: baseline;
  }
  .footer2 .copyRight {
    font-size: large;
  }
}
@media (min-width: 1024px) {
  .footer1 {
    display: flex;
    justify-content: space-between;
    font-size: larger;
  }
  .logo {
    flex: 0.25;
    font-size: 35px;
    font-weight: 600;
  }
  .miniContainer {
    flex: 1;
    /* border: 1px solid black; */
    padding-right: 1rem;
  }

  .footer2 {
    justify-content: space-evenly;
  }
}
@media (min-width: 1440px) {
  .footer1 {
    font-size: x-large;
  }
  .logo {
    flex: 0.25;
    font-size: 35px;
    font-weight: 600;
  }
  .miniContainer {
    padding-right: 2rem;
  }

  .footer2 {
    justify-content: space-evenly;
    font-size: larger;
  }
}
@media (min-width: 2200px) {
  .container {
    padding: 4rem;
  }
  .footer1 {
    font-size: xx-large;
  }
  .logo {
    flex: 0.25;
    font-size: 45px;
    font-weight: 700;
  }
  .miniContainer {
    padding-right: 6rem;
  }

  .footer2 {
    justify-content: space-evenly;
    font-size: xx-large;
  }
  .footer2 .socials {
    justify-content: space-evenly;
    font-size: 30px !important;
  }
}
