.wrapper {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: auto;
}
.container {
  width: 100%;
}
.header {
  display: flex;
  align-items: center;
  font-size: large;
  padding: 0.5rem;
  font-size: larger;
  font-weight: 500;
  box-shadow: 0px 4px 8px -1px rgb(192, 187, 187);
  margin-bottom: 2rem;
}
.header span:first-child {
  flex: 0.5;
}
.header span {
  font-size: large;
}
/* Content styling here */
.dataContainer {
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.completedSignContainer,
.paymentTimeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.completedSignContainer p:first-child {
  background-color: rgb(15, 199, 15);
  height: 85px;
  width: 85px;
  border-radius: 50%;
  display: grid;
  place-content: center;
  font-size: 48px;
  color: white;
  margin-bottom: 0;
}
.completedSignContainer p:last-child {
  font-size: larger;
  font-weight: 600;
}
.paymentTimeContainer p {
  margin-bottom: 0;
}
.paymentTimeContainer p:first-child {
  font-size: 41px;
  font-weight: 500;
}
.paymentTimeContainer p:last-child {
  color: #746969;
  font-weight: 500;
}
.paymentAndSentDetailContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
}
.paymentAndSentDetailContainer .paymentContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.4rem;
}
.paymentAndSentDetailContainer .paymentContainer p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
}
.paymentAndSentDetailContainer .paymentContainer p span:first-child {
  font-size: small;
  font-weight: 500;
  color: slategray;
}
.paymentAndSentDetailContainer .paymentContainer p span:last-child {
  font-size: medium;
  font-weight: 500;
  color: slategray;
}
.sentConfirmContainer {
  width: 100%;
  padding: 2rem 3rem;
}
.sentConfirmContainer p {
  border: 2px solid rgb(15, 199, 15);
  padding: 0.3rem 0.8rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 2px 5px 6px #726767, inset 3px 2px 7px #939191;
}
