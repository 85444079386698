.dealerDashBoard_Wrapper {
  /* display: grid;
  place-items: center; */
}

.dealerDashBoard_Container {
  display: flex;
  flex-direction: column;
}

.chart_first_row {
  /* border: 2px solid red; */
  display: flex;
  gap: 3rem;
  width: 100%;
  background-color: black;
}

.chart_second_row {
  /* border: 2px solid red; */
  display: flex;
  gap: 1rem;
  width: 100%;
  margin: 2rem 0;
}

.navigation_circles_wrapper {
  /* border: 2px solid red; */
  flex: 0.7;
}

.quartely_revenue_wrapper {
  flex: 0.3;
}

.chart_third_row {
  display: flex;
  flex-direction: column;
  /* border:  2px solid black; */
  margin-bottom: 3rem;
  gap: 2rem;
  background-color: black;
}

.chart_third_row_a {
  display: flex;
  gap: 2rem;
}

.discount_wrapper {
  flex: 0.5;
  background-color: #2a2b4a;
}

.progress_wrapper {
  display: flex;
  flex: 0.5;
  /* border: 2px solid red; */
  gap: 2rem;
  padding-right: 2rem;
}

.simple_chart_content_wrapper {
  flex: 1;
}

.chart_third_row_b {
  display: flex;
  gap: 2rem;
  padding-right: 2rem;
}

.navigation_circles_container_42 {
  display: flex;
  /* border: 2px solid green; */
  justify-content: space-around;
  padding: 2rem 0;
  /* background-color: #2a2b4a; */
  width: 100% !important;
  border: 1px solid lightgray;
}

.chart_fourth_row {
  /* margin: 2rem; */
  margin-bottom: 2rem;
  margin-top: 2rem;
  width: 100%;
}

.child_naviagtion_circle:hover {
  transform: scale(1.19);
  transition: transform 0.2s ease-in-out;
}

.dashLable {
  /* text-align: center; */
  font-size: 1.2rem;
  font-weight: 600;
}

.dashValue {
  text-align: center;
  /* font-size: 1.1rem; */
  /* font-weight: 600; */
}

/* Desktop DropDown */
.dashBoardDropDownContainer {
  display: none;
}
.dashBoardDropDownContainerMobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 1rem;
  gap: 1rem;
}
/* Mobile dropdown */
.mobileDropDataHeader {
  padding: 1rem;
  display: flex;
}
.mobileDropDataHeader:first-child {
  display: flex;
  align-items: center;
}
.mobileDropDataHeader span {
  flex: 0.4;
}
.mobileDropDownItem {
  width: 100%;

  position: relative;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  height: 120px;
  padding: 1rem;
  font-weight: 500;
  box-shadow: 3px 3px 3px lightgrey;
  font-size: 13px;
  justify-content: center;
}
.mobileDropDownItem span {
  position: absolute;
  top: -10px;
  left: -3px;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
  box-shadow: 3px 3px 3px grey, inset 0px 4px 8px grey;
}
.mobileDropDataWrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid red; */
  padding: 0 1.4rem;
  gap: 1.5rem;
}
.mobileDropDataContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid lightgrey;
  width: 100%;
  font-size: 10px;
  font-weight: 700;
  padding: 1rem 0.8rem;
  box-shadow: 3px 3px 3px grey;
  position: relative;
  gap: 0.3rem;
}
.mobileDropDataContainer p {
  margin-bottom: 0;
}
.mobileDropDataContainer .dataValue {
  color: #d1823a;
  width: 30%;
}
.mobileDropDataContainer p:last-child {
  color: #d1823a;
}
.mobileDropDataContainer span {
  position: absolute;
  top: -11px;
  left: -11px;
  height: 24px;
  width: 33px;
  border-radius: 50%;
  /* border: 1px solid; */
  display: grid;
  place-content: center;
  background-color: antiquewhite;
  box-shadow: 3px 1px 3px grey, inset 1px 1px 3px grey;
  font-size: 10px;
}
.classDropDownParent {
  display: flex !important;
  gap: 25px;
  font-size: 20px;
  font-weight: 500;
}
.classDropDownParent span {
  box-shadow: 3px 3px 7px grey, inset 2px 2px 8px grey;
}
.toggleButtonDesktop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: black;
  align-items: center;
  height: 4rem;
  font-family: inherit;
  font-size: 25px;
  padding-left: 40px;
  border: none !important;
  border-bottom: 1px solid lightgrey !important;
  background-color: white !important;
  border-radius: 0 !important;
}
.ShownDropDown {
  background-color: #fdf4ed !important;
  border: 1px solid #d1823a !important;
  border-top: none !important;
  box-shadow: 0px 4px 7px grey;
  position: relative !important;
  transform: translate(0px, 0) !important;
  width: 96% !important;
  left: 2% !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.ShownDropDown .reportValues {
  font-size: x-large;
  font-weight: 500;
}
@media (min-width: 375px) {
  .dashBoardDropDownContainerMobile {
    gap: 2rem;
    padding: 0 2rem;
  }
  .mobileDropDataContainer {
    font-size: 13px;
  }
}
@media (min-width: 420px) {
  .mobileDropDataContainer {
    font-size: 16px;
  }
}
@media (min-width: 720px) {
  .classDropDownParent {
    font-size: 19px;
  }
  /* Desktop DropDown */
  .dealerDashBoard_Wrapper {
    padding: 0 2rem;
  }
  .dashBoardDropDownContainer {
    display: block;
    /* padding-left: 2rem; */
    border: 2px solid lightgrey;
    border-top: #d1823a 2px solid;
    border-radius: 15px;
    box-shadow: 1px 4px 6px grey;
    margin-bottom: 2rem;
    padding: 1rem;
  }
  .dashBoardDropDownContainer > h1 {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
  .DropDownWrapper {
    padding: 0 1rem;
  }
  .dashBoardDropDownContainerMobile {
    display: none;
  }
  .dropdown-toggle-dash {
    border: none !important;
  }
  .classDropDownParent {
    color: slategray;
  }
}
@media (min-width: 1024px) {
  .classDropDownParent {
    font-size: 23px;
  }
  .toggleButtonDesktop {
    padding: 0 2rem !important;
  }
  .dashBoardDropDownContainer {
    padding: 1rem 2rem;
  }
  .dealerDashBoard_Wrapper {
    padding: 0 5rem;
  }
}
@media (min-width: 1024px) {
  .classDropDownParent {
    font-size: 18px;
  }
  .toggleButtonDesktop {
    height: 5rem;
  }
}
@media (min-width: 1440px) {
  .classDropDownParent {
    font-size: 24px;
  }
  .dealerDashBoard_Wrapper {
    padding: 0 7rem;
  }
}
