.sidebar-accordians .accordion-item .accordion-button {
  background-color: #d1823a;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #d1823a;
  font-weight: 500;
}

/* Style for unchecked checkbox */
.form-check-input.filtercar:not(:checked) {
  background-color: #ffffff;
  /* border: 2px solid #4a4a4a; */
}

/* Style for checked checkbox */
.form-check-input.filtercar:checked {
  background-color: #d1823a;
  border: 2px solid #d1823a;
}

/* Style for checkbox label */
/* .form-check-label {
    color: #D1823A;
} */

.price-field1 {
  position: relative;
}
.price-wrap input {
  border: 0px;
}
.price-wrap input:focus-visible {
  border: 0px;
}
.price-field1 input[type="range"] {
  position: absolute;
  left: 0;
}

/* Reset style for input range */

.price-field1 input[type="range"] {
  width: 100%;
  height: 5px;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: none;
  /* -webkit-appearance: none; */
  color: #d1823a;
  background-color: #d1823a;
}

.price-field1 input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.price-field1 input[type="range"]:active,
.price-field1 input[type="range"]:focus {
  outline: 0;
}

.price-field1 input[type="range"]::-ms-track {
  width: 188px;
  height: 2px;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: none;
  background: #d1823a;
  border-color: #d1823a;
  color: #d1823a;
  border-radius: 5px;
}

/* Style toddler input range */

.price-field1 input[type="range"]::-webkit-slider-thumb {
  /* WebKit/Blink */
  position: relative;
  -webkit-appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -4px;
  background-color: #d1823a;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.price-field1 input[type="range"]::-moz-range-thumb {
  /* Firefox */
  position: relative;
  appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  background-color: #d1823a;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.price-field1 input[type="range"]::-ms-thumb {
  /* IE */
  position: relative;
  appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  background-color: #d1823a;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

/* Style track input range */

.price-field1 input[type="range"]::-webkit-slider-runnable-track {
  /* WebKit/Blink */
  width: 188px;
  height: 2px;
  cursor: pointer;
  background: #d1823a;
  border-radius: 5px;
}

.price-field1 input[type="range"]::-moz-range-track {
  /* Firefox */
  width: 188px;
  height: 2px;
  cursor: pointer;
  background: #d1823a;
  border-radius: 5px;
}

.price-field1 input[type="range"]::-ms-track {
  /* IE */
  width: 188px;
  height: 2px;
  cursor: pointer;
  background: #d1823a;
  border-radius: 5px;
}

/* Style for active state input */

.price-field1 input[type="range"]:hover::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.5px #d1823a;
  transition-duration: 0.3s;
}

.price-field1 input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.5px #d1823a;
  transition-duration: 0.3s;
}
.price-wrap .price-wrap1 {
  /* background-color: aqua; */
  margin: 0 0 0 150px;
  /* margin-right: 30px; */
  width: 30%;
  text-align: center;
  justify-content: center;
  /* padding-right: 100px; */
}
.prices-wrap2 {
  width: 50%;
  margin: 0 80px 0 0;
}
.price-wrap .price-wrap1 {
  /* background-color: aqua; */
  margin: 0 0 0 150px;
  /* margin-right: 30px; */
  width: 30%;
  text-align: center;
  justify-content: center;
  /* padding-right: 100px; */
}
.price-wrap .odo-1 {
  /* background-color: aqua; */
  margin: 0 0 0 -31px;
  /* margin-right: 30px; */
  width: 30%;
  text-align: center;
  justify-content: center;
  /* padding-right: 100px; */
}
.price-wrap .odo-wrap1 {
  /* background-color: aqua; */
  margin: 0 0 0 150px;
  /* margin-right: 30px; */
  width: 30%;
  text-align: center;
  justify-content: center;
  /* padding-right: 100px; */
}

/*----------------------------------------------side Image start-------------------------------------------------------------*/
.product-image-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.product-image-box img {
  border-radius: 5px;
  height: 170px;
}

.product-image-content {
  /* border: 1px solid black; */
  border-radius: 10px;
  padding: 0px 0px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.product_car_image {
  /* height: 65% !important; */
  width: 80% !important;
}
.color-variant {
  /* background-color: aqua; */
  /* width: 10%; */
  justify-content: center;
  padding: 0px 140px 0px;
  display: flex;
  justify-content: space-evenly;
}
.product-message {
  /* width: 100% !important; */
  background: #c3c3c3;
  font-size: 14px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 3px;
  /* margin: 0 0 0 70px; */
}

.product-message input {
  background-color: transparent;
  border: none;
  width: 100%;
}
.product-message input:focus {
  outline: none;
  border: none;
}
.product-message input::placeholder {
  font-size: large;
  font-weight: 500;
}
.messenger_icon_product_listing {
  height: 35px !important;
  width: 35px !important;
  margin-right: 1rem;
}
button.product-send-btn {
  width: 90%;
  background: #d1823a;
  border: 1px solid #d1823a;
  border-radius: 10px;
  /* margin-top: 13px; */
  font-size: 20px;
  margin: 15px 0 17px 0px;
}
/*----------------------------------------------side Image End-------------------------------------------------------------*/
.theme-color {
  color: #d1823a;
}

.product-g-btn {
  background: #62b260;
  color: white;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 10px 12px;
  font-size: 20px;
  cursor: pointer;
}
.product-T-btn {
  background: #d1823a;
  color: white;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 10px 12px;
  font-size: 20px;
  cursor: pointer;
}
.product-E-btn {
  background: #00afd9;
  color: white;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 10px 12px;
  font-size: 20px;
  cursor: pointer;
}
.product-S-btn {
  background: #322f4d;
  color: white;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 10px 12px;
  font-size: 20px;
  cursor: pointer;
}
.bbbr {
  background-color: #d1823a;
  width: 100%;
  border-radius: 10px;
  /* padding: 0  5px 5px 15px; */
  /* padding-right: 10px; */
  padding-left: 10px;
  height: 45px;
}

/*-----------------------------------------------------Exterior Interior tooltip Start--------------------------------------------- */

/* [data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 3px;
  margin-left: -29px;
  padding: 7px;
  width: 59px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsl(29, 64%, 54%);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -1px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsl(29, 77%, 54%);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
} */

/* ------------------------------------------------Responsibe code --------------------------------------- */
/* Default styles for the tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 3px;
  margin-left: -29px;
  padding: 7px;
  width: 59px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsl(29, 64%, 54%);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -1px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsl(29, 77%, 54%);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Media query for small screens (sm) */
@media screen and (max-width: 576px) {
  [data-tooltip]:before {
    /* Adjust styles for small screens */
    padding: 5px;
    width: 40px;
    font-size: 12px;
  }
}

/* Media query for medium screens (md) */
@media screen and (min-width: 576px) and (max-width: 768px) {
  [data-tooltip]:before {
    /* Adjust styles for medium screens */
    padding: 7px;
    width: 59px;
    font-size: 14px;
  }
}

/* Media query for large screens (lg) */
/* @media screen and (min-width: 992px) {
  [data-tooltip]:before {
    padding: 10px;
    width: 80px;
    font-size: 16px;
  }
} */
/* ------------------------------------------------Responsibe code --------------------------------------- */

/*-----------------------------------------------------Exterior Interior tooltip End--------------------------------------------- */

/*-------------------------More Tooltip Start ----------------------------------------*/

[data-tooltips],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltips]:before,
[data-tooltips]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltips]:hover:before,
[data-tooltips]:hover:after,
[data-tooltips]:focus:before,
[data-tooltips]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltips]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltips]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltips);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
/* [data-tooltips]:before,
[data-tooltips]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
} */

/* [data-tooltips]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
} */

/* Horizontally align top/bottom tooltips */
[data-tooltips]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

/* [data-tooltips]:hover:before,
[data-tooltips]:hover:after,
[data-tooltips]:focus:before,
[data-tooltips]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform:    translateY(-12px);
  transform:         translateY(-12px); 
} */

/* Left */
/* .tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
} */

/* .tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
} */

/* .tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform:    translateX(-12px);
  transform:         translateX(-12px); 
} */

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

/* Right */
/* .tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
} */

/* .tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
} */

/* .tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform:    translateX(12px);
  transform:         translateX(12px); 
} */

/* Move directional arrows down a bit for left/right tooltips */
/* .tooltip-left:before,
.tooltip-right:before {
  top: 3px;
} */

/* Vertically center tooltip content for left/right tooltips */
/* .tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
} */

/*-------------------------More Tooltip End ----------------------------------------*/
/* Staring the Mobile Design of Product Listing */
.productListingWrapper {
  /* border: 5px solid red; */
  padding: 1rem;
}
.productListingFilterSidebarWrapper {
  display: none;
}
.productListingContentWrapper {
  width: 100%;
}
.productListingHeader {
  display: flex;
  flex-direction: column;
}
.productListingSubHeader1 {
  font-weight: 500;
}
.productListingSubHeader2 {
  display: flex;
  justify-content: space-between;
}
.productListingSubHeader2 > select {
  width: 35%;
  font-size: small;
}
.productListingSubHeader2 > img {
  display: none;
}
.pLSubHeader2FilterBtn {
  font-size: 26px;
  background-color: #dbcfcf40;
  padding: 0.3rem 0.4rem;
  border-radius: 10px;
  cursor: pointer;
}
.pLgridParent {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}
.pLDesktopContent {
  display: none;
}
.pLMobileContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
/* Mobile Filter Popout Style */
.filters_popout_wrapper {
  display: flex;
  font-size: small;
}
.filters_popout_wrapper .filterswrapper {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightgrey;
  gap: 0.5rem;
}
.filters_popout_wrapper .filterswrapper span {
  cursor: pointer;
}
.dataWithCheckbox > label {
  display: flex;
  gap: 0.9rem;
  padding-left: 0.5rem;
}

.filters_popout_wrapper .filtersContentwrapper {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 0.3rem;
}
.filters_popout_wrapper .filtersContentwrapper .filtersObjectContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
  gap: 0.4rem;
}
.filtersObjectContainer > label {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.filtersObjectContainer > label > span {
  display: flex;
  width: 90%;
  justify-content: space-between;
}
.paginationPagesContainer {
  overflow-x: scroll;
  padding: 1rem;
}

@media (min-width: 720px) {
  .pLMobileContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media (min-width: 1024px) {
  .pLgridParent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    row-gap: 6rem;
    margin-bottom: 6rem;
  }

  .productListingWrapper {
    display: flex;
  }
  .productListingSubHeader2 > select {
    width: 15%;
    font-size: small;
  }
}
@media (min-width: 1440px) {
  .pLMobileContent {
    display: none;
  }
  .productListingSubHeader2 {
    display: flex;
    justify-content: flex-start;
  }
  .pLSubHeader2FilterBtn {
    display: none;
  }
  .productListingSubHeader2 > img {
    display: block;
  }
  .productListingSubHeader2 > img:nth-child(2) {
    margin-right: 76% !important;
  }
  .pLDesktopContent {
    display: block;
  }
  .productListingFilterSidebarWrapper {
    display: block;
    flex: 0.2;
  }
  .productListingContentWrapper {
    display: block;
    flex: 0.8;
    width: 70% !important;
  }
  .paginationPagesContainer {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  .paginationPagesContainer::-webkit-scrollbar {
    display: none;
  }
}
