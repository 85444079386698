/*
Extra small devices (phones): Less than 576px
Small devices (tablets): 576px to 767px
Medium devices (laptops): 768px to 991px
Large devices (desktops): 992px to 1199px
Extra large devices (large desktops): 1200px and above 
*/
.width-98per {
  width: 98% important;
}

.dropdown-toggle {
  font-weight: 600;
  background-color: #e8a262;
}

.dropdown-toggle:hover {
  color: black !important;
  background-color: lightgray !important;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.custom-scrollbar::-webkit-scrollbar {
  margin-left: 3px !important;
}

/* Handle */
.table-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Handle on hover */
.table-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.table-scrollbar::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  margin-left: 3px !important;
}

.res {
  width: 110px;
  height: 100px;
  padding: 5%;
  background-color: #f8e2ce;
  box-shadow: 10px 8px 3px #ebb27d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hr {
  /* padding-left: 5% !important;
    padding-right: 5%; */
  margin: 5%;
  width: 85%;
  border-bottom: 1px solid #d1823a;
}

.upper-card {
  margin-right: 0%;
}

.btn-grp {
  min-width: 145px !important;
  /* text-align: center; */
  border: none !important;
}

.selection-box {
  display: inline-block;
  box-sizing: border-box;
  height: inherit;
  padding-bottom: 2%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  position: relative;
  top: 10%;
  left: -10;
}

.card-count {
  height: 30px !important;
  width: 30px;
  text-align: center;
  border-radius: 50px;
  font-size: 1.3rem;
  font-weight: bolder;
  background-color: #dfdfdf;
  box-shadow: 0px 5px 5px rgb(177, 177, 177);
}

.selection-btn img {
  /* background-color: #cc5c01; */
  width: 40px;
  height: inherit;
}

/* .cards::after {
  content: "selected";
  background-color: #ffba10;

  border-style: dotted;
  position: relative;
  right: 10%;
  bottom: 80%;
} */

/*--------------------------image css-------------------*/

.product-actives {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 0px 0px;
}

.product-actives img {
  /* border-radius: 9px 9px 0 0; */
  /* height: 120px;
  width: 90%; */
  height: 140px !important;
  width: 180px !important;
  margin: auto;
}

.bg-card {
  background-color: #eae9e9;
  box-shadow: 0px 5px 5px #cccccc;
}

.card-head {
  font-size: 1.2rem;
  font-weight: 700;
  /* color: rgb(80, 80, 80); */
  /* margin-bottom: 15px; */
}

.card-head:hover {
  /* color: rgb(80, 80, 80); */
  color: gray;
}

.s-font {
  font-size: 0.8rem !important;
}

.b-half {
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-radius: 3px;
  padding: 3px !important;
  box-shadow: 0px 2px 3px gray;
}

.b-half>div {
  text-wrap: nowrap;
}

.orange-color {
  color: rgb(232, 162, 98);
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  margin-bottom: 5px !important;
}

.bg-orange {
  background-color: #e8a262 !important;
}

.bg-secondary {
  background-color: gray;
}

.gray-color {
  color: rgb(80, 80, 80) !important;
}

.sub-head {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important;
  font-size: 1rem !important;
  padding: 0px !important;
}

.sub-head div {
  display: inline-flex;
}

.sub-head .vin {
  width: 180px;
}

.sub-head .engine {
  display: inline-flex;
}

.vin-info .col {
  font-size: 0.9rem;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
}

.data-font>* {
  font-size: 0.9rem !important;
}

.border-end {
  border-color: rgb(161, 161, 161) !important;
  text-wrap: nowrap;
}

.social-icon {
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgba(102, 102, 102, 0.5);
}

.logoleft img {
  margin: 0 0 0 110px;
}

.product-g-btns {
  /* max-width: 80%; */
  width: 90px;
  /* width: 110px; */
  background: #e8a262 !important;
  box-shadow: 0px 3px 5px gray;
  color: white !important;
  /* width: 60%;
  margin-left: 100px; */
  margin-bottom: 15px;
  border-radius: 5px;
  border-width: 0px !important;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.8rem !important;
  font-weight: 600;
  cursor: pointer;
  border: none;
}

.product-g-btns:hover {
  background-color: #d9d9d9 !important;
  color: black !important;
}
/* ------------------------------- */
.product-g-btns-recall {
  /* max-width: 80%; */
  width: 90px;
  /* width: 110px; */
  background: #e8a262;
  box-shadow: 0px 3px 5px gray;
  color: white !important;
  /* width: 60%;
  margin-left: 100px; */
  margin-bottom: 15px;
  border-radius: 5px;
  border-width: 0px !important;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.8rem !important;
  font-weight: 600;
  cursor: pointer;
  border: none;
}

.product-g-btns-recall:hover {
  background-color: #d9d9d9 !important;
  color: black !important;
}
/* ------------------------------------------ */
.btn-sec {
  max-width: 80%;
  background: #d9d9d9 !important;
  box-shadow: 0px 3px 5px gray;
  color: #e8a262 !important;
  /* width: 60%;
  margin-left: 100px; */
  margin-bottom: 10px;
  border-radius: 5px;
  border-width: 0px !important;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.8rem !important;
  font-weight: 600;
  cursor: pointer;
  border: none;
}

.btn-sec:hover {
  color: black !important;
}

.vr {
  border: 1px solid gray;
}

/* div>.edit{
  display: flex;
  flex-direction: column !important;
  justify-content: space-around;

} */

.edit {
  /* height: auto;
  margin-bottom: 20%;
  margin-left: 10%; */

  padding: 10px !important;
  border-radius: 50% !important;
  display: flex !important;
  place-content: center;
  box-shadow: 0px 2px 3px rgb(192, 192, 192);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  cursor: pointer;
}

.edit svg {
  font-size: larger !important;
}

.edit:hover {
  background-color: lightgray !important;
}

.website2 {
  background: #cc5c01;
  /* color: #f09540; */
  color: white;
  width: 80%;
  margin-left: 60px;
  border-radius: 10px;
  text-align: center;
  padding: 1px 2px;
  /* font-size: 2px; */
  cursor: pointer;
  border: none;
}

.bg-tr {
  background-color: transparent !important;
}

.add-date {
  font-size: 0.8rem;
}

.Exteriors,
.Interiors {
  min-width: 21%;
  max-width: 50%;
  display: inline-block;
  /* align-items: center; */
  text-align: center;
  margin: 1%;
  padding: 0% 2%;
  font-size: 0.7rem;
  box-shadow: 0px 2px 5px rgb(175, 175, 175);
}

.Exteriors span,
.Interiors span {
  color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 2px;
  cursor: pointer;
  box-shadow: 0px 3px 5px gray;
}

/* .Interiords {
  background: #c40212 !important;
  color: #fff;

  
 
  margin-top: 2px;
  margin-left: 10px;
  height: 30px;
  border-radius: 10px;
  border-width: 0px !important;
  text-align: center;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  
  cursor: pointer;
  border: none;
} */

/* For docs dropdown button */
.docs-dropdown {
  position: relative;
  /* display: inline-block; */
}

.btn {
  /* Define button styles */
  /* Example styles */

  padding: 10px;
  border: none;
  cursor: pointer;
}

.docs-list,
.cost-list,
.page-count-list,
.sort-by-list {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(173, 173, 173);
  border-radius: 7px;
  z-index: 6;
  /* width: 200px; */
  /* Adjust width as needed */
  text-wrap: nowrap;
}

.docs-list label:hover,
.page-count-list li:hover,
.sort-by-list li:hover {
  background-color: #d9d9d9 !important;
}

.docs-list label {
  display: block;
  margin-bottom: 5px;
  padding: 5px;
}

.docs-list label input[type="checkbox"] {
  margin-right: 5px;
}

.docs-list button {
  height: 60%;
  /* width: 40%; */
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* padding-left: 2px ;
  padding-right: 2px !important; */
  background-color: #f8e5d3;
  box-shadow: 0px 3px 5px gray;
  color: #edaa6d;
  border: 2px solid #edaa6d;
  cursor: pointer;
  box-sizing: border-box;
}

.docs-list button+button {
  margin-left: 10px;
}

.docs-list button:hover {
  background-color: #0056b3;
  /* Example button color on hover */
}

.cost-list .dropdown-option,
.page-count-list li,
.sort-by-list li {
  display: block;
  /* border-bottom: 2px solid gray; */
  padding: 5px;
}

.cost-list .dropdown-option:not(:last-child) {
  border-bottom: 2px solid gray;
}

.cost-list .dropdown-option>* {
  margin: 5px;
}

.cost-list .dropdown-option:nth-child(odd) {
  background-color: #d9d9d9;
  /* Set the background color for odd children */
}

.page-count-list,
.sort-by-list {
  list-style: none;
}

/* Apply border-radius to the first child */
.cost-list .dropdown-option:first-child,
.docs-list label:first-child,
.page-count-list li:first-child,
.sort-by-list li:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

/* Apply border-radius to the last child */
.cost-list .dropdown-option:last-child,
.page-count-list li:last-child,
.sort-by-list li:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.cost {
  color: #cc5c01;
}

.custom-dropdown:hover .docs-list {
  display: block;
}

.bg-btn {
  background-color: #f3d0b0 !important;
  border: 1px solid #f3d0b0 !important;
}

.print,
.export {
  box-shadow: 0px 5px 5px gray;
}

.print,
.export,
.drp-btn {
  padding: 5px !important;
}

.drp-btn {
  background-color: none !important;
  border: 2px solid !important;
  box-sizing: border-box;
}

.toggle-view {
  width: 80px;
  height: 40px;
  border: 1px solid rgb(232, 162, 98);
  cursor: pointer;
}

#act-search input {
  border: 1px solid rgb(209, 130, 58);
}

.table>thead>tr>* {
  background-color: #e8a262;
  color: white;
  font-size: 0.8rem;
  text-wrap: nowrap;
  /* padding: 5px; */
  text-align: center;
  border-width: 0px !important;
}

.th-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-striped>tbody>tr {
  border-bottom: 1px solid #eba05a !important;
  border-left: 1px solid #ffe4cd !important;
  border-right: 1px solid #ffe4cd !important;
  /* box-shadow: 0px 2px 3px gray !important;
  margin-bottom: 1% !important; */
  text-align: center;
  padding: 1% !important;
}

.table-striped>tbody>tr>* {
  margin-bottom: 1% !important;
  font-size: 0.8rem;
  text-wrap: nowrap;
  padding: 5px;
  /* box-shadow: 0px 10px 3px gray !important; */
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  /* Apply your CSS styles here */
  background-color: #ffffe1;
  --bs-table-bg-type: none !important;
  margin-bottom: 0px;
}

.table-striped>tbody>tr>.vin-column,
.table-striped>tbody>tr>.ac-col {
  color: #0056b3 !important;
  cursor: pointer;
}

/* .table-hover>tbody>tr:hover>* {
  color: white;
  background-color: #979490;
} */

tfoot>tr>* {
  background-color: #e8a262 !important;
  color: white !important;
  font-size: 0.8rem;
  font-weight: 700;
  border-width: 0px !important;
}

.filter-icons {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.filter-icons span {
  display: inline-block;
  /* font-size: 15px !important; */
}

.filter-icons svg {
  font-size: 15px !important;
}

.publish-btn {
  font-size: 0.8rem !important;
  background-color: #0d294b !important;
  /* padding-top: 0px !important; */
  /* padding-bottom: 0px !important; */
}

.ac-col {
  min-width: 20px !important;
}

.vin-column {
  min-width: 140px !important;

  /* Adjust the width as needed */
}

.stock-no,
.card-head,
.vin {
  cursor: pointer;
}

.table-wrapper {
  max-height: 580px !important;
  overflow: auto;
  max-width: 100% !important;
}

thead {
  position: sticky !important;
  top: 0;
  /* Fix the header at the top */
  background: #fff;
  /* Background color for the header */
  z-index: 10;
  /* Ensure the header is above the tbody */
}

tfoot {
  position: sticky !important;
  bottom: 0;
  /* Fix the header at the top */
  background: #fff;
  /* Background color for the header */
  z-index: 10;
  /* Ensure the header is above the tbody */
}

.shadowed-row {
  box-shadow: 0px 2px 3px gray !important;
  /* Apply shadow only at the bottom */
}

td>a {
  text-decoration: none !important;
}

.table-striped>tbody>tr.selected-rows>* {
  color: white !important;
  background-color: #979490 !important;
}

.table-striped>tbody>tr td>* {
  text-align: center !important;
}

/* .tr_table td{
  justify-content: center !important;
  align-items: center !important;
} */

/* styles for Tooltip */
.table-tooltip .tooltip-inner {
  position: relative !important;
  background-color: rgb(196, 196, 196) !important;
  color: black !important;
  font-weight: bold;
  /* border: 1px solid #d35400; */
  border-radius: 4px;
  font-size: 0.6rem;
  z-index: 2050;
}

.table-tooltip .tooltip-arrow::before,
.table-tooltip .tooltip-arrow::after {
  border-top-color: rgb(196, 196, 196) !important;
}

.table-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: rgb(196, 196, 196) !important;
}

/* styles for Tooltip */
.custom-tooltip .tooltip-inner {
  position: relative !important;
  background-color: rgb(196, 196, 196) !important;
  color: black !important;
  font-weight: bold;
  /* border: 1px solid #d35400; */
  border-radius: 4px;
  font-size: 0.6rem;
  z-index: 2050;
}

.custom-tooltip .tooltip-arrow::before,
.custom-tooltip .tooltip-arrow::after {
  border-top-color: rgb(196, 196, 196) !important;
}

.custom-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: rgb(196, 196, 196) !important;
}

.inventory-info {
  display: inline-block;
  /* justify-content: center; */
  /* width: 15%; */
  margin: 0% 1%;
}

.inventory-info span {
  padding: 5px;
  font-size: 0.8rem;
  border-bottom: 2px solid rgb(173, 173, 173);
}

.selected-item {
  background-color: #ffb36bd1 !important;
  /* Adjust the color as needed */
  /* color: white; */
  /* Ensure the text is readable */
}

@media screen and (min-width: 360px) and (max-width: 600px) {
  .av-dpdn {
    width: 320px;
  }

  .res p {
    text-transform: capitalize;
    font-size: 1rem;
    height: 50%;
  }

  .bg-body {
    width: 306px;
    height: 106px;
  }

  .act-row {
    width: 252.656px;
    height: 300px;
  }

  #act-six-btn {
    margin-left: 13px;
  }

  .res {
    margin-top: 5px;
  }

  #act-search {
    width: 275px;
  }
}

/* Responsive adjustments */

@media screen and (min-device-width: 767px) and (max-device-width: 821px) {
  .res p {
    text-transform: capitalize;
    font-size: 0.8rem;
    height: 50%;
  }

  .res {
    /* width: 90%; */
    height: 80%;
  }
}

/* @media screen and (min-device-width: 822px) {
  .res p {
    text-transform: capitalize;
    font-size: .9rem !important;
    height: 50%;
  }

  .res {
    width: 90%;
    height: 80%;
  }

  .col .btn {
    font-size: 1rem;
  }
} */

@media screen and (min-device-width: 360px) and (max-device-width: 600px) {
  .av-dpdn {
    width: 320px;
  }

  .res p {
    text-transform: capitalize;
    font-size: 1rem;
    height: 50%;
  }

  /* .col .btn {
    font-size: 6px;
  } */

  .bg-body {
    width: 306px;
    height: 106px;
  }

  .act-row {
    width: 252.656px;
    height: 300px;
  }

  #act-six-btn {
    margin-left: 13px;
  }

  .res {
    margin-top: 5px;
  }

  #act-search {
    width: 275px;
  }
}

/* Small devices */
@media screen and (max-width: 767px) {
  .col .btn {
    /* font-size: 0.6rem; */
    /* Adjust font size for small devices */
    white-space: normal;
  }
}

/* mediam devices */
@media screen and (min-width: 768px) and (max-device-width: 991px) {
  .col .btn {
    /* font-size: .8rem; */
    /* Adjust font size for medium devices */
    white-space: nowrap;
  }

  .upper-card {
    margin-right: 10%;
  }

  .Exteriors span,
  .Interiors span {
    height: 65% !important;
  }
}

/* Large devices */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .col .btn {
    text-wrap: nowrap;
    box-sizing: border-box;
    /* font-size: 1rem; */
    /* Adjust font size for large devices */
    white-space: nowrap;
  }

  .upper-card {
    margin-right: 10%;
  }

  .res {
    width: 190%;
    height: 100%;
  }

  .card-count {
    height: 20px !important;
    width: 20px;
    text-align: center;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: bolder;
    background-color: #dfdfdf;
    box-shadow: 0px 5px 5px rgb(177, 177, 177);
  }

  .selection-btn img {
    width: 30px;
    height: inherit;
  }

  .product-actives img {
    height: 120px !important;
    width: 126px !important;
  }
}

/* extra larg device */
@media screen and (min-width: 1200px) {
  .col .btn {
    text-wrap: nowrap;
    box-sizing: border-box;
    /* font-size: 1.2rem; */
    /* Adjust font size for extra large devices */
    white-space: nowrap;
    box-sizing: border-box;
  }

  .Exteriors span,
  .Interiors span {
    height: 65% !important;
  }

  /* .container {
    max-width: 100% !important;
    margin: 0 !important;
  } */

  .res {
    width: 100%;
    height: 100%;
  }


}

/* Custom break points */

@media screen and (min-width: 1300px) {
  /* .sold-date {
    /* display: flex;
    justify-content: center; */
  /* width: 100% !important;

  } */

  .exteriors,
  .Interiors {
    color: white;
    /* display: flex;
    justify-content: center; */
  }

  .res {
    width: 90%;
    height: 90%;
  }

  /* .product-actives img {
    width: 90%;
    height: 150px;
  } */
}

@media screen and (min-width: 1500px) {
  /* .product-actives img {
    width: 68% !important;
    height: 145px !important;
  } */

  .card-count {
    height: 35px !important;
    width: 35px;
    text-align: center;
    border-radius: 50px;
    font-size: 1.3rem;
    font-weight: bolder;
    background-color: #dfdfdf;
    box-shadow: 0px 5px 5px rgb(177, 177, 177);
  }

  .selection-btn img {
    width: 45px;
    height: inherit;
  }
}

@media screen and (min-width: 2000px) {
  /* .product-actives img {
    width: 70% !important;
    height: 190px !important;
  } */

  .card-count {
    height: 40px !important;
    width: 40px;
    text-align: center;
    border-radius: 50px;
    font-size: 1.6rem;
    font-weight: bolder;
    background-color: #dfdfdf;
    box-shadow: 0px 5px 5px rgb(177, 177, 177);
  }

  .selection-btn img {
    width: 55px;
    height: inherit;
  }
}

@media screen and (min-width: 2500px) {
  /* .product-actives img {
    width: 70% !important;
    height: 200px !important;
  } */

  .card-count {
    height: 45px !important;
    width: 45px;
    text-align: center;
    border-radius: 50px;
    font-size: 1.8rem;
    font-weight: bolder;
    background-color: #dfdfdf;
    box-shadow: 0px 5px 5px rgb(177, 177, 177);
  }
}

@media screen and (min-width: 3000px) {
  /* .product-actives img {
    width: 75% !important;
    height: 250px !important;
  } */
}

@media screen and (min-height: 900px) {
  .table-wrapper {
    max-height: 800px !important;
    overflow: auto;
    max-width: 100% !important;
  }
}

@media screen and (min-height: 1100px) {
  .table-wrapper {
    max-height: 1000px !important;
    overflow: auto;
    max-width: 100% !important;
  }
}

@media screen and (min-height: 1300px) {
  .table-wrapper {
    max-height: 1200px !important;
    overflow: auto;
    max-width: 100% !important;
  }
}

@media screen and (min-height: 1500px) {
  .table-wrapper {
    max-height: 1400px !important;
    overflow: auto;
    max-width: 100% !important;
  }
}

@media screen and (min-height: 1700px) {
  .table-wrapper {
    max-height: 1600px !important;
    overflow: auto;
    max-width: 100% !important;
  }
}

@media screen and (min-height: 2000px) {
  .table-wrapper {
    max-height: 1900px !important;
    overflow: auto;
    max-width: 100% !important;
  }
}

@media screen and (min-height: 2200px) {
  .table-wrapper {
    max-height: 2100px !important;
    overflow: auto;
    max-width: 100% !important;
  }
}

/* ------------------------- */
.tooltip-container-active-inventory {
  position: relative;
  display: inline-block;
}

.info-icon-active-inventory {
  font-size: 15px;
  /* Adjust size as needed */
  cursor: pointer;
  margin-left: 3px;
  /* Adjust spacing as needed */
}

.tooltip-text-active-inventory {
  visibility: hidden;
  font-size: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: rgb(158, 158, 158);
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: -250%;
}

.tooltip-container-active-inventory:hover .tooltip-text-active-inventory {
  visibility: visible;
  opacity: 1;
}

.btn-eq-wd {
  width: 100px;
}

.icons-r-flt {
  width: 40px;
  height: 30px;
}

.custom-dropdown {
  left: 0; /* Align dropdown to the left of the button */
  top: 100%; /* Position it below the button */
  display: none; /* Hide by default */
}

.custom-dropdown.show {
  display: block; /* Show when toggled */
}

/* Optional: Add some styling to the dropdown items */
.dropdown-item {
  padding: 0.5rem 1rem; /* Add padding for better appearance */
  cursor: pointer; /* Change cursor to pointer */
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Change background on hover */
}


.new-filter-active select{
  background-color: rgb(232,162,98);
  /* margin-left: 5px; */
  height: 100%;
  color: white;
  /* width: 100%; */
}
.new-filter-active select:hover{
  background-color: #d9d9d9;
  color: black;
  /* width: 100%; */
}
.remove-border-radi{
  --bs-border-radius: 0 !important;
}


