.smvWrapper {
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.8rem;
  border-top: 11px solid #f2f2f2;
  border-bottom: 11px solid #f2f2f2;
  margin-bottom: 1rem;
}
.gradientDealerBackground {
  background: linear-gradient(to bottom, white, #ccc 100%, #ffffff21);
}
.smvContainer {
  width: 100%;
}
.smvTableContainer {
  width: 100%;
  /* border: 1px solid red; */
}
.smvHeader {
  text-align: center;
  font-size: x-large;
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.smvSubHeader {
  text-align: center;
  font-size: small;
  font-weight: 500;
}
.smvTableContainer .smvTabsContainer {
  display: flex;
  width: 100%;
  gap: 0.3rem;
}
.smvTabsContainer > button {
  flex: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: large;
  font-weight: 500;
  border: none;
  box-shadow: inset 0px 0px 13px rgb(34 28 28 / 48%);
  background-color: white;
  padding: 0.3rem 0.1rem;
}
.smvFormWrapper {
  height: 200px;
  width: 100%;
  background-color: #d1823a;
  display: grid;
  place-content: center;
}
.smvFormWrapper .smvFormContainer {
  display: flex;
  width: 100%;
  gap: 0.3rem;
}
.smvFormWrapper .smvFormContainer > input {
  border: none;
  outline: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  text-align: left;
  padding-left: 0;
}
.smvFormWrapper .smvFormContainer > input::placeholder {
  color: white;
  text-align: left;
}
.smvFormWrapper .smvFormContainer > button {
  color: black;
  border: none;
  border-radius: 8px;
  box-shadow: inset 0px 0px 13px rgba(0, 0, 0, 0.1);
  padding: 0.3rem;
  font-weight: 500;
}
/* Plate Section */
.smvPlateWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.smvPlateWrapper .smvPlateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.3rem;
  margin-bottom: 0.9rem;
}
.smvPlateContainer > input {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid white;
  color: white;
  padding-left: 0.1rem;
}
.smvPlateContainer > input::placeholder {
  color: white;
}
.smvPlateContainer > select {
  padding: 0.1rem;
  background-color: transparent;
  border-bottom: 1px solid white;
  color: white;
}
.smvPlateWrapper button {
  background-color: white;
  border: none;
  padding: 0.3rem 0.5rem;
  box-shadow: inset 0 0 10px rgb(110 110 110);
  border-radius: 6px;
  font-weight: 500;
}
/* mManuel */
.smvManuelContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0.8rem;
}
.smvManuelContainer .smvManuel1,
.smvManuelContainer .smvManuel2,
.smvManuelContainer .smvManuel3 {
  display: flex;
  gap: 0.5rem;
}
.smvManuel1 > select {
  background-color: transparent;
  border-bottom: 1px solid white;
  padding: 0.2rem;
  color: white;
  font-weight: 500;
  outline: none;
}
.smvManuel1 > input,
.smvManuel2 > input,
.smvManuel3 > input {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid white;
  padding: 0.2rem;
  color: white;
  font-weight: 500;
  padding-left: 0 !important;
}
.smvManuel1 > input::placeholder,
.smvManuel2 > input::placeholder,
.smvManuel3 > input::placeholder {
  color: white;
  font-weight: 500;
}
.smvManuelbtnContainer {
  display: grid;
  place-content: center;
}
.smvManuelbtnContainer button {
  background-color: white;
  border: none;
  padding: 0.3rem 0.5rem;
  box-shadow: inset 0 0 10px rgb(110 110 110);
  border-radius: 6px;
  font-weight: 500;
}

@media (min-width: 720px) {
  .smvHeader {
    font-size: xx-large;
  }
  .smvWrapper {
    padding: 1rem;
  }
  .smvTabsContainer > button,
  .smvManuelbtnContainer button {
    font-size: x-large;
    font-weight: 500;
    padding: 0.5rem 0.1rem;
  }

  .smvFormWrapper .smvFormContainer > input {
    font-size: x-large;
  }
  .smvFormWrapper .smvFormContainer > button {
    font-size: larger;
    padding: 0.5rem 1rem;
  }
  .smvFormWrapper .smvFormContainer {
    gap: 1rem;
  }
  .smvPlateContainer > input {
    font-size: x-large;
    font-weight: 500;
  }
  .smvPlateContainer > select {
    font-size: x-large;
    font-weight: 500;
  }
  .smvPlateWrapper .smvPlateContainer {
    gap: 1.3rem;
    margin-bottom: 2rem;
  }

  .smvManuelContainer .smvManuel1,
  .smvManuelContainer .smvManuel2,
  .smvManuelContainer .smvManuel3 {
    gap: 2rem !important;
  }
  .smvManuel1 > select,
  .smvManuel1 > input,
  .smvManuel2 > input,
  .smvManuel3 > input {
    font-size: x-large;
  }
  .smvFormWrapper {
    padding: 1rem;
    padding: 1rem;
    height: 260px;
  }
  .smvPlateWrapper button {
    font-size: x-large;
    padding: 0.5rem 1rem;
  }
  .smvSubHeader {
    font-size: large;
  }
  .smvHeader {
    font-size: 40px;
  }
}

@media (min-width: 1024px) {
  .smvFormWrapper .smvFormContainer {
    gap: 2rem;
  }
  .smvWrapper {
    padding: 1rem 7rem;
  }
  .smvFormWrapper .smvFormContainer > button {
    padding: 0.7rem 2rem;
    font-size: x-large;
  }

  .smvPlateContainer > input {
    font-size: x-large;
  }
  .smvPlateContainer > select {
    font-size: x-large;
  }
  .smvPlateWrapper button {
    font-size: x-large;
    font-weight: 500;
    padding: 0.4rem 1.3rem;
  }
  .smvSubHeader {
    font-size: larger;
  }
}
@media (min-width: 1440px) {
  .smvContainer {
    width: 50%;
  }
  .smvTabsContainer > button,
  .smvManuelbtnContainer button {
    padding: 0.5rem 0.9rem;
  }
  .smvSubHeader {
    font-size: x-large;
  }
}
