.dealer_login_left_wrapper {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.dealer_login_left_container {
  position: relative;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  overflow: visible;
}

.box_container {
  position: absolute;
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  border-radius: 0.3rem;
  color: white;
  box-shadow: 0px 0px 10px gray;
  transition: all 0.5s ease;
}

.box_container:hover {
  cursor: pointer;
  box-shadow: 0px 0px 20px 5px rgb(214, 211, 211);
  transform: translate(-50%, -50%) scale(1.05) !important;
}

.bg-gray {
  background-color: rgb(99, 102, 105);
}

.bg-orange {
  background-color: orange;
}

.dealer_login_left_center {
  position: absolute;
  left: 46%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 74px;
  display: flex;
  /* flex-direction: column; */
  gap: 0.5rem;
  margin: 0 1rem;
  font-weight: 900;
  align-items: center;
  text-align: center;
}

.dealer_login_left_center span:nth-child(2n + 1) {
  color: #e8a262;
}

.dealer_login_left_center span:nth-child(2n) {
  color: rgb(99, 102, 105);
}

.scale-box {
  transform: translate(-50%, -50%) scale(1.5) !important;
}

@media (min-width: 1440px) {
  .dealer_login_left_wrapper {
    display: flex;
   
  }
}
