.wrapper {
  /* border: 1px solid red; */
  padding-top: 1.5rem;
  position: relative;
}

.container {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  display: flex !important;
  justify-content: space-around !important;
  /* gap: 3rem; */
  row-gap: 1rem;
}

.addPerson {
  display: flex;
  justify-content: end;
  padding-right: 2rem;
}

.addPerson span svg {
  background-color: #e8a262;
  border-radius: 50%;
  padding: 0.3rem;
  font-size: larger;
  display: grid;
  place-content: center;
  color: white;
  width: 40px;
  height: 40px;
}