.priceFeesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.priceFeesTable {
  width: 100%;
}
.closeBtnContainer{
  display: flex;
  justify-content: end;
}
.priceFeesRow {
  display: flex;
  font-weight: 500;
  width: 100%;
  font-size: 12px;
  align-items: center;
  padding: 0 1.2rem;
}
.priceFeesRowOne > .priceFeesRow:first-child,
.priceFeesRowOne > .priceFeesRow:nth-child(3),
.dynamicLabels {
  border-bottom: 3px solid lightgray;
  margin-bottom: 1rem;
}

.priceFeesRowOne > .priceFeesRow:first-child .priceFeesKey {
  font-size: large;
  font-weight: 700;
}
.priceFeesRowOne.priceFeesRow:first-child .priceFeesValue {
  font-size: large;
  font-weight: 600;
  width: 35%;
  border: #d1823a 1px solid;
}
.priceFeesRow > .priceFeesKey {
  width: 85%;
  font-weight: 500;
}
.priceFeesRow > .priceFeesValue {
  border-radius: 12px;
  width: 31%;
  text-align: center;
  padding: 0.2rem;
  background-color: rgb(243, 223, 196);
  box-shadow: 2px 3px 7px grey;
  color: #d1823a;
  font-weight: 700;
}
.priceFeesValue.percValue {
  margin-right: 0.3rem;
  width: 50%;
}

.priceFeesRowOne {
  flex: 0.4;
  padding-top: 1rem;
}

.priceFeesRowTwo {
  flex: 1;
}
.priceFeesRowOne .priceFeesRow:nth-child(1),
.priceFeesRowTwo .priceFeesRow:nth-child(1) {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/* .priceFeesRowOne .priceFeesRow:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
} */

.priceFeesRowTwo .priceFeesRow:nth-last-child(2) {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (min-width: 420px) {
  .priceFeesRow > .priceFeesKey {
    font-size: medium;
  }
  .priceFeesRow {
    font-size: 15px;
  }
}
@media (min-width: 720px) {
  .priceFeesRowOne > .priceFeesRow:first-child .priceFeesKey,
  .priceFeesRowOne.priceFeesRow:first-child .priceFeesValue {
    font-size: 26px;
  }
  .priceFeesRow {
    font-size: 20px;
  }
  .priceFeesValue.percValue {
    margin-right: 0.3rem;
    width: 35%;
  }
  .priceFeesRow > .priceFeesKey {
    font-size: 20px;
  }
}
@media (min-width: 1440px) {
  .priceFeesTable {
    display: flex;
  }
  .priceFeesRow > .priceFeesKey {
    font-size: 13px;
  }
  .priceFeesRowOne {
    flex: 0.6;
  }

  .priceFeesRowTwo {
    flex: 0.4;
    padding-top: 1rem;
  }
  .priceFeesRow > .priceFeesValue {
    font-size: small;
    font-weight: 500;
  }
}
