.btnrgg {
  background-color: #d1823a;
  padding: 5px 7px;
  border: 2px solid #d1823a;
  padding: 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.sidebtns button {
  background-color: #d1823a;
  margin: 0 2px 0 5px;
  border: 1px solid #d1823a;
  padding: 5px 6px;
}

.btn-orange {
  background-color: #e8a262 !important;
  /* padding: 7px 16px; */
  border: 1px solid #e8a262;
  border-radius: 5px;
}

.btn-orange:hover {
  background-color: lightgray !important;
  border: 1px solid lightgray;
  box-shadow: 5px 5px 5px rgb(173, 173, 173) !important;
}

/* .sidebtns select{
    background-color: #d1823a;
    margin: 0 2px 0 5px;
} */

/* .btn-orange {
    background-color: #E8A262;
    padding: 7px 16px;
    border: 1px solid #e8a262;
    border-radius: 10px;
} */
/* dropdown */
.input-containerdrpdn {
  position: relative;
  display: inline-block;
  /* Ensures proper positioning */
  border: none;
  /* border-bottom: 2px solid black; */
  width: 100%;
  /* border: 2px solid black; */
  margin-top: 1%;
}

/* for color palet */
.btns-orange {
  /* width: 50%; */
  background-color: rgb(238, 185, 137) !important;
  /* padding: 7px 26px; */
  border: 1px solid #e8a262;
  border-radius: 5px;
  /* margin: 0 0 0 120px; */
}

.hr-line {
  width: 100%;
  height: 10px;
  margin: 1rem !important;
  background-color: rgb(238, 185, 137);
  box-shadow: 10px 10px 5px rgb(150, 150, 150);
}

.c-palate {
  width: 30px !important;
  height: 50px !important;
}

/* color palet end */
.input-field {
  padding-right: 25px;
  /* Adjust this value based on your preference */
  border: none;
}

.input-field:hover {
  cursor: pointer;
}

.input-field:focus {
  outline: none;
}

.bg-input input {
  background-color: rgb(240 150 31 / 22%);
}

.bg-inputdate {
  background-color: rgb(240 150 31 / 22%);
}

.input-fields input {
  width: 90% !important;
}

.input-fieldsdrpdn input {
  width: 96% !important;
  height: 42px;
  border: none;
  /* border-bottom:2px solid #e8a262; */
}

.input-field-year-make {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field-year-make input {
  width: 96%;
  height: 42px;
  border: none;
  /* border-bottom:2px solid #e8a262; */
}

.input-field-year-make input:focus {
  outline: none;
}

/* .input-fieldsins{
   border-bottom:2px solid #e8a262; 

} */

.input-fieldsins input {
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 8px 0;
  outline: none;
}

.vl {
  border-left: 2px solid green;
  height: 20px;
  margin-top: 10px;
  margin-left: 7px;
}

#insdays {
  /* margin-top: 8px; */
  padding-top: 8px;
  margin-left: 11px;
  width: 14%;
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 13%;
  /* Adjust this value based on your preference */
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensures the arrow doesn't interfere with input */
  color: black;
  /* Adjust color as needed */
}

/* For Seller Dropdown */
/* .input-containerdrpdns{
  width: 100%;
  border-bottom: 2px solid black;
} */
/* Dropdown End */

#adlstcustomn {
  padding-bottom: 4px;
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 2px;
  width: 85%;
  border-radius: 2px !important;
  background-color: white;
}

#add-option-btn {
  width: 121px;
  border: none;
}

#add-option-btn:focus {
  outline: none;
}

.custom-dropdown {
  /* border-bottom: 2px solid #e8a262; */
  width: 76%;
}

.custom-dropdownb {
  /* border-bottom: 2px solid #e8a262; */
  width: 100%;
}

#btnplus {
  border: none;
  width: 19%;
  height: 0.02%;
  border-radius: 2px;
  background-color: #d1823a;
  color: white;
}

#adlstcustomn:focus {
  border: none;
}

#btn-save {
  background-color: #d1823a;
  border: none;
  border-radius: 5px;
  color: #fff;
  height: 40px;
  width: 150px;
}
@media screen and (max-width: 600px) {
  #btn-save {
    background-color: #d1823a;
    border: none;
    border-radius: 5px;
    color: #fff;
    height: 40px;
    width: 51px;
  }
}
#dropdownadlst {
  width: 65%;
}

#vinadlst {
  width: 82%;
  border: 2px solid #d1823a;
  border-radius: 10px;
}

#vin-left-btn {
  background-color: #d1823a;
  color: white;
  border-radius: none !important;
  width: 70px !important;
}

#vin-decod-btn {
  background-color: #2b2f31;
  color: white;
  border-radius: none !important;
}

#locationadlst {
  width: 110%;
}

#trdropdownadlst {
  width: 100%;
}

#adlstimg {
  width: 90%;
  height: 300px;
}

#adlstimgftr {
  width: 93%;
}

#trdropdownadlstfl {
  width: 65%;
}

@media only screen and (max-width: 600px) {
  #inputadlst {
    width: 100%;
  }

  #dropdownadlst {
    width: 100%;
  }

  #vinadlst {
    width: 100%;
  }

  #locationadlst {
    width: 100%;
  }

  #trdropdownadlst {
    width: 100%;
  }

  #adlstimg {
    width: 100%;
  }

  #adlstimgftr {
    width: 100%;
  }

  #adlsttitledropdown {
    padding-left: 1rem !important;
  }

  #trdropdownadlstfl {
    width: 100%;
    padding-left: 1rem !important;
  }
}

/* #forother-input{
  width: 120% !important;
} */

/* ================================================================================================= */

/* Style for the main panel */
.main-panel {
  width: 100% !important;
  padding: 20px;
}

/* Style for the content wrapper */
.content-wrapper {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  /* border-radius: 5px; */
  padding: 20px;
}

/* Style for the container */
.container {
  margin: 0 auto;
  max-width: 1200px;
}

/* Style for the buttons */
.btnrg {
  margin-right: 10px;
}

/* .sidebtns {
    display: flex;
    align-items: center;
} */

/* Style for the Save and Reset buttons */
/* .btn {
    margin-right: 10px;
} */

.image-uploads {
  /* height: 200px; */
  width: 85%;
  /* display: flex; */
  /* justify-content: center; */
  /* text-align: center; */
  /* background: #f3ab59db; */
  /* align-items: center; */
  /* border: 1px solid #c7c7c7; */
  /* border-radius: 20px; */
  /* margin-left: 15px; */
  /* box-shadow: 2px 4px 8px 1px #cccccc; */
}

label.image-upload-fields i {
  font-size: 30px;
  color: #d1823a;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .my-profile-box img,
  .image-upload {
    /* height: 100px;  */
    width: 25%;
    margin-left: 10px;
  }

  label.image-upload-fields i {
    font-size: 50px;
  }
}

@media screen and (max-width: 480px) {
  .my-profile-box img,
  .image-upload {
    /* height: 100px;  */
    width: 40%;
    margin-left: 5px;
  }

  label.image-upload-fields i {
    font-size: 30px;
  }
}

/* ================================================================================ */
/* Default styling */
.ttft {
  width: 100%;
  margin: 0 0 70px 0;
  /* padding: 0 50px 0  60px; */
}

/* .yytyr {
  margin: 50px 50px 0 60px;
} */

.mm input[type="text"] {
  width: 90%;
  height: 110px;
  margin: 0 50px 0 60px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
  .ttft {
    width: 100%;
  }

  .mm input[type="text"] {
    width: 70%;
  }
}

/* Additional media queries can be added for different screen sizes */

.bddt {
  border: 2px solid #d1823a;
}

.bbtter {
  background-color: #d1823a;
  color: #d1823a;
}

/*  */

.ex3 {
  /* background-color: lightblue; */
  width: 100%;
  height: 600px;
  /* overflow-y: auto; */
  /* overflow-x: scroll; */
  overflow-y: scroll;
}

/* Apply padding to the container */

.cheackcolor {
  background-color: #f7c597;
  box-shadow: 10px 10px 3px rgb(218, 205, 191);
  /* width: 70%;  */
  padding: 10px;
  margin: 10px;
  /* font-size: medium; */
  font-size: 20px;
  font-weight: 500;
}

.cheacksimple {
  margin: 7px 0 7px 20px;
  /* margin: 15px; */
  font-size: 20px;
}

/*----------------------------------------------Tabs start-----------------------------------------*/

/* Tabs.css */
.rdss {
  background-color: #d1823a;
  width: -1%;
  border-radius: 15px;
}

/* Optional: Customize tab styles */
.nav-tabs .nav-item .nav-link {
  border-radius: 0;
  /* border-color: #d1823a #d1823a #d1823a; */
  border: 1px solid #e7bb93;
  color: #d1823a;
  box-shadow:none ; /* Inner shadow */
}
@media (min-width: 720px) {
  .nav-tabs .nav-item .nav-link {
    border-radius: 0;
    /* border-color: #d1823a #d1823a #d1823a; */
    border: 1px solid #e7bb93;
    color: #d1823a;
    box-shadow: inset 0 0 11px #e7bb93; /* Inner shadow */
  }
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #d1823a;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.tab-content {
  border: 3px solid #d1823a;
  padding: 10px;
}

/* Media Query for responsiveness */
@media screen and (max-width: 768px) {
  .tabs {
    flex-direction: column;
    margin-right: 21px;
  }

  .tab {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 600px) {
  .tabs {
    flex-direction: column;
    margin-right: 21px;
  }

  .Darkslogo {
    float: right;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50% !important;
    margin-right: 10% !important;
  }

  .checkmark {
    margin-top: 15%;
  }

  #adlst-responsive {
    margin-bottom: 69px !important;
  }
}

/* ------------------------selector buttom line----------------------------------------------------*/
.optSlect {
  /* background-color: #007bff; */
  border: none;
  /* border-bottom: 2px solid black; */
  /* border-right: 3px solid rgb(0, 68, 255); */
}

.optSlect select {
  /* background-color: #007bff; */
  border: none;
  border-bottom: 2px solid black;
  /* border-right: 3px solid rgb(0, 68, 255); */
}

/* .Btnsd{
  background-color: #d1823a;
  margin: 0 35px 0 395px;
  padding: 0 25px 0 0px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 700;
  font-weight: 600;
} */
.backgrd {
  background-color: #d1823a;
  /* height: 50px; */
  padding: 0px 0px 0px 0px;
  height: 60px;
  margin: 0 110px 0 113px;
  box-shadow: 1px 1px 25px 2px rgb(209, 208, 208);
  /* width: 50%; */
}

.backgrds {
  /* margin: 0 2px 0 4px; */
  background-color: #d1823a;
  width: 10%;
  margin: 0 0px 0 0px;
}

.borderr {
  border: 2px solid #d1823a;
  margin: 10px 0 10px 0;
}

.input-fieldss input {
  border: none;
  border-bottom: 2px solid #e8a262;
  border-radius: 0;
  box-shadow: none;
  padding: 8px 0;
  outline: none;
}

.input-fields-other input {
  border: none;
  /* border-bottom: 2px solid #e8a262; */
  border-radius: 0;
  box-shadow: none;
  padding: 8px 0;
  outline: none;
  width: 98% !important;
}

/*----------------------------------------------Tabs End-----------------------------------------*/
/* ===================================== */
/* FileUpload.css */

.file-upload-container {
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s ease;
}

.file-upload-content {
  text-align: center;
}

.file-icon {
  font-size: 48px;
}

.file-upload-container.dragging {
  border-color: #007bff;
}

.file-description {
  margin-top: 50%;
}

.file-description h3 {
  margin: 0;
}

.file-description button {
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.file-description button:hover {
  background-color: #0056b3;
}

/* -----------------------------Prices & Fees------------------------------------------------ */

.inputst input[type="text"],
.inputst input[type="password"] {
  width: 100%;
  /* padding: 12px 5px; */
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  /* border-bottom: 2px solid #e8a262;  */
  outline: none;
}

#purpriceselprice {
  width: 100%;
  background-color: #fce8cd !important;
  height: 38px;
  color: black;
  box-sizing: border-box;
  border: none;
  border-radius: 11px;
  outline: none;
}

#purpriceselpriceb {
  width: 100%;
  background-color: #fce8cd !important;
  height: 38px;
  color: black;
  box-sizing: border-box;
  border: none;
  border-radius: 11px;
  outline: none;
}

.cat-img-adlist {
  margin-top: 20%;
}

#milageinread {
  width: 50%;
  background-color: #eeb989 !important;
  height: 30px;
  color: black;
  margin: 0px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  outline: none;
  padding-bottom: 0px;
}

.inputst8 input[type="text"] {
  width: 100%;
  /* padding: 12px 5px; */
  height: 90px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  /* border-bottom: 2px solid #e8a262; */
  outline: none;
}

/* 3circle Tool manager import inventry autocheck  */
/* .circle-tm-ii-ac{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 107px;
}
.circle-tia{
  text-align: center;
  background-color: #eee6ed;
  color: #0c3c70;
  margin-right: 20px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.08),
0px 2px 2px 0px rgba(0,0,0,0.12),
0px 4px 4px 0px rgba(0,0,0,0.16),
0px 8px 8px 0px rgba(0,0,0,0.2);
} */
.circle-tm-ii-ac {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 107px;
}

.circle-tia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  text-align: center;
  background-color: #eee6ed;
  color: #0c3c70;
  font-weight: bolder;
  margin-right: 10px;
  margin-left: 10px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.16),
    0px 8px 8px 0px rgba(0, 0, 0, 0.2);
}

/* 3circle Tool manager import inventry autocheck end */

/* codde for tabs */
.tabs {
  border: 3px solid #d1823a;
  /* border-radius: none !important; */
}

.tab-buttons {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

#adlst-btn-invt-footer {
  border: none;
  background-color: #d1823a;
  box-shadow: 0px -13px 0px -8px #d1823a;
  color: white;
  width: 200px;
  height: 40px;
}

.slider-thumbnail {
  width: 50px;
  /* Adjust this to control the width of the thumbnails */
  height: 50px;
  /* Maintain aspect ratio */
}

#img-cat:hover {
  background-color: #d1823a;
  color: white;
}

/* #sel-dec{
  background-color: #d1823a !important;
  color: white !important;
} */
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover {
  background-color: #c57a40 !important;
  color: white;
  border-radius: 0px !important;
}

.adlst-btn-save-post {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.adlst-bottom-sav-reset {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

/* #adlst-save-btn-bootom{
  border: none;
  background-color: #d1823a;
  color: white;
  border-radius: 5px;
  width: 5%;
  height: 40px;
  font-size: larger;
  margin-right: 15px;
} */
.adlst-btn-save-post button {
  border: none;
  background-color: none !important;
}

#back-tab-btn {
  width: 100px;
  height: 40px;
}

#next-tab-btn {
  width: 100px;
  height: 40px;
}

.adlst-adv-manager-btn {
  display: flex;
  justify-content: flex-end;
}

.adlst-adv-manager-btn button {
  margin-right: 10%;
  font-size: larger;
}

#advt-manager-drpdwn {
  border: none !important;
  box-shadow: 0px 11px 9px -3px rgba(0, 0, 0, 0.1);
}

.custom-bg {
  background-color: rgba(240, 226, 202, 0.774) !important;
}

#adlstsaveresetbtnbg {
  background-color: #43494d;
  color: white;
  padding: 0px !important;
  height: 40px;
  width: 100px;
}

#adlstdltbtnrpcst {
  color: white;
  padding: 0px !important;
  height: 40px;
  width: 100px;
}

.checkmark {
  height: 15px;
  width: 15px !important;
}

/* ---------------seller section of adlisting----------------- */
#state {
  position: absolute;
  height: 500px;
  top: 200px;
  border: 2px solid red;
}

.po_relative {
  position: relative;
}

.dropdown-arrow-sp {
  position: absolute;
  top: 50%;
  right: 0;
  /* Adjust this value based on your preference */
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensures the arrow doesn't interfere with input */
  color: black;
  /* Adjust color as needed */
}

.custom_drop_down_arrow {
  position: absolute;
  top: 0%;
  right: 35px;
  color: black;
  /* Adjust color as needed */
  cursor: pointer;
}

#tyrecondition_bl {
  position: absolute !important;
  background-color: white;
  width: 90%;
  padding: 0px 0;
  border: 2px solid rgb(88, 80, 80);
}

.dropdown-bg {
  position: absolute;
  background-color: #f1efa3;
  width: 90%;
  padding: 0px 0;
  border: 2px solid rgb(88, 80, 80);
  z-index: 9999;
}

.dropdown-bgb {
  position: absolute;
  background-color: #f1efa3;
  width: 96%;
  padding: 0px 0;
  border: 2px solid rgb(88, 80, 80);
  z-index: 9999;
}

.dropbgcolor:hover {
  background-color: rgb(141, 135, 135);
  color: white;
  cursor: pointer;
}

/* dropdown-fornt size ------------------------------*/
.dropdown-option {
  cursor: pointer;
}

.dropdown-option-text {
  font-weight: bolder;
  font-size: 16px;
  /* Adjust font size as needed */
}

.input-containerdrpdn.input-fields.bg-input.empty .input-field {
  background-color: yellow;
  /* Or any other color you prefer */
}

/* .input-field{
  font-size: 24px;
  font-weight:600 ;
} */
/* --------------------------- */
.tyrecondition_el {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
}

.tyrecondition_el:hover {
  background-color: #d1833a8a;
  color: white;
  cursor: pointer;
}

.parentContainer {
  display: flex;
  gap: 0;
  overflow: hidden;
  padding: 10px 0;
}

.child2 {
  margin-right: 10%;
  flex: 1;
  width: 30%;
}

.child2 input {
  background-color: #fce8cd;
  width: 100%;
}

.child1 {
  width: 30%;
  flex: 1;
}

.child1 input {
  background-color: #fce8cd;
  width: 100%;
}

.input-containerdrpdn-sp {
  position: relative;
  display: inline-block;
  border: 2px solid black;
  /* Ensures proper positioning */
  border: none;
  /* border-bottom: 2px solid black; */
  width: 100%;
  margin-top: 3%;
}

.inputContainer {
  border-bottom: 2px solid black;
  display: flex;
  align-items: center;
}

/*  */
/* .input-containerdrpdn-inline input {
  border-bottom: 2px solid #e8a262;
} */

.label-inline {
  margin-right: 10px;
  width: 40% !important;
}

.input-field-inline {
  width: 45% !important;
}

.input-containerdrpdn-inline {
  display: flex;
  align-items: center;
  width: 80%;
}

.input-containerdrpdncat {
  position: relative;
  display: inline-block;
  /* Ensures proper positioning */
  border: none;
  border-bottom: 2px solid black;
  width: 100%;
  /* border: 2px solid black; */
  margin-top: 3%;
}

.input-containerdrpdncat input {
  width: 100% !important;
}

.dropdown-arrowcat {
  position: absolute;
  top: 0;
  right: 5px;
}

.input-containerdrpdnsf {
  position: relative;
  display: inline-block;
  /* Ensures proper positioning */
  border: none;
  border-bottom: 2px solid #e8a262;
  margin-left: 124px;
  width: 44%;
  /* border: 2px solid black; */
  margin-top: 3%;
}

.input-containerdrpdnsf input {
  width: 100% !important;
}

.dropdown-arrowsf {
  position: absolute;
  top: 0;
  right: 5px;
}

/* vehicle specification */
row2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.row2 .col-md-4 {
  width: 100% !important;
}

.submitNewVehicleButton:hover {
  background-color: #f3ba85 !important;
  color: white !important;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.newCarItemsContainer .childNewCarItem:nth-child(6n + 1),
.newCarItemsContainer .childNewCarItem:nth-child(6n + 2),
.newCarItemsContainer .childNewCarItem:nth-child(6n + 3) {
  background-color: #f3ba85;

  box-shadow: 15px 10px 2px -1px rgb(218, 205, 191);
  padding: 10px;
  font-weight: 600;
  font-size: larger;
  display: flex;
  justify-content: start;
  gap: 10px;
  padding-left: 10px;
}

.newCarItemsContainer .childNewCarItem:nth-child(6n + 4),
.newCarItemsContainer .childNewCarItem:nth-child(6n + 5),
.newCarItemsContainer .childNewCarItem:nth-child(6n + 6) {
  background-color: white;
  box-shadow: 15px 7px 4px 0px rgb(218, 205, 191);
  padding: 10px;
  font-weight: 600;
  font-size: larger;
  display: flex;
  justify-content: start;
  gap: 10px;
  padding-left: 10px;
}

.newCarItemsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(27.33%, 1fr));

  gap: 3%;
  padding: 0;
  row-gap: 20px;
  padding-left: 60px;
}

@media (max-width: 425px) {
  .newCarItemsContainer {
    display: flex;
    flex-direction: column;
  }

  .parent_add_more_option_btn {
    flex-direction: column;
  }

  .tab-content {
    border: 3px solid #d1823a;
    padding: 0px !important;
  }

  .row2 > * {
    padding-left: 0 !important;
  }

  .childNewCarItem {
    font-weight: 200;
    font-size: large;
    margin-left: 20px;
    width: 300px;
  }

  .add_more_option_btn {
    color: #f3ba85;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    border-bottom: 3px solid #f3ba85;
    cursor: pointer;
    margin-left: 20px;
  }

  .formVehicleOption {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .form-check-label {
    font-size: 15px;
    font-weight: 500;
  }

  .searchFormContainer {
    width: 70%;
    margin-top: 20px;
  }

  .searchFormContainer input {
    width: 70%;
    font-size: 23px;
    width: 100%;
  }
}

@media (min-width: 426px) and (max-width: 1439px) {
  .tabletView {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }

  .tabletViewText {
    font-size: smaller !important;
  }

  .tabletViewchild {
    width: 400px;
  }

  .cheackcolor,
  .cheacksimple {
    width: 180px !important;
    font-size: smaller !important;
    font-weight: 400 !important;
    /* background-color: white !important; */
    box-shadow: 10px 10px white !important;
  }

  .childNewCarItem {
    border: 2px solid red;
    width: 200px !important;
  }

  .seller1,
  .seller2,
  .seller3,
  .seller4 {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .seller4 span,
  .seller4 img {
    display: none !important;
  }

  .seller1 span,
  .seller2 label,
  .seller3 label,
  .seller4 label {
    height: 50px;
    width: 80px;
    font-size: small !important;
  }

  .ParentSellerTwo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .seller5,
  .seller6,
  .seller7 {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    width: 40% !important;

    padding: 0 !important;
    margin: 20px 0;
  }

  .seller5 #trdropdownadlst,
  .seller6 #trdropdownadlst,
  .seller7 #trdropdownadlst {
    height: 50px;
    width: 80px;
  }

  .childSeller7 label {
    font-size: small;
  }

  .tyrecondition_el {
    border: 2px solid green;
    width: 12em !important;
    background-color: white !important;
  }

  .seller8,
  .seller9,
  .seller10,
  .seller11 {
    font-size: smaller !important;
  }

  .searchFormContainer {
    width: 70%;
    margin-top: 20px;
  }

  .searchFormContainer input {
    width: 70%;
    font-size: 23px;
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .row2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.custom_checkbox {
  /* border-color: #d1823a !important; */

  border: 3px solid #d1823a !important;
  border-radius: none;
}

.searchParent {
  position: relative;
}

/* .tab-content {
  > .tab-pane {
    display: none;
    /* width: 50vw; 
  }
  > .active {
    display: block;
    width: 85rem;
  }
} */

.searchOptionParent {
  display: flex;
  justify-content: center;
}

.searchFormContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 10px;
  border: 2px solid red;
  width: 50%;
  border-radius: 30px;
  background-color: #f7c597;
  margin-bottom: 20px;

  border: 2px solid #e2d2c3;
  padding: 8px 20px;
}

.searchFormContainer input {
  background-color: #f7c597;
  flex: 1;
  border: none;
  padding: 5px;
  border-radius: 30px;
  font-size: 23px;
}

.searchFormContainer input:focus {
  border: none;
  /* No border */
  outline: none;
  /* No outline */
}

.searchFormContainer input::placeholder {
  padding: 0 35%;
  font-size: 23px;
  font-weight: 500;
  color: rgba(143, 138, 138, 0.842);
}

.searchResultContainer {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  width: 46%;
  top: 70px;
  left: 27%;
  gap: 10px;
  z-index: 9999 !important;
  background-color: white;
}

.searchresultItem {
  width: 100% !important;
  border-radius: 30px;
}

.searchItem {
  background-color: rgb(212, 209, 209);
  padding: 10px 25px;
  border-radius: 30px;
}

.colorbg {
  background-color: rgb(212, 209, 209);
  margin-top: 0px !important;
  margin-bottom: 0px;
  height: 120px;
  width: 80%;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
}

.colorbg p {
  font-size: larger;
  font-weight: bolder;
}

#date-r-c input {
  /* border-bottom: 2px solid #e8a262 !important; */
  padding: 12px !important;
  border-radius: 0 !important;
  width: 100% !important;
}

.input-containerdlr {
  position: relative;
  display: inline-block;
  width: 35% !important;
}

input[type="text"] {
  padding-left: 20px;
  /* Adjust padding to make space for the placeholder */
  box-sizing: border-box;
  /* Ensure padding does not affect width */
  width: 100%;
  /* Make input take full width of the container */
}

input[type="password"] {
  padding-left: 20px;
  /* Adjust padding to make space for the placeholder */
  box-sizing: border-box;
  /* Ensure padding does not affect width */
  width: 100%;
  /* Make input take full width of the container */
  border: none;
  /* border-bottom: 2px solid #e8a262;  */
  outline: none;
}

.persistent-placeholder {
  width: 45% !important;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Make sure the placeholder doesn't interfere with input */
  color: #161515;
  /* Placeholder text color */
  font-size: 16px;
  /* Match input font size */
}

/*----------------------------- dropdown------------------------------ */
.zoomed {
  transform: scale(1);
  /* or any other scaling factor */
  transition: transform 0.5s ease;
  /* add transition for smooth animation */
  z-index: 9999;
  margin-left: auto;
  margin-right: auto;
}

.zoomedb {
  transform: scale(1);
  /* or any other scaling factor */
  transition: transform 0.5s ease;
  /* add transition for smooth animation */
  margin-left: auto;
  margin-right: auto;
}

.disc {
  border: 1px solid red !important;
  width: 300px !important;
  height: 50px !important;
  overflow-y: scroll;
}

/* repare-cost table design */
/* Style the container div */
.table-container-repare-cost {
  max-height: 200px;
  overflow-y: auto;
  position: relative;
  /* Add relative positioning */
}

/* Style the scrollbar */
.table-container-repare-cost::-webkit-scrollbar {
  width: 10px;
  /* height: 50px; */
}

/* Track */
.table-container-repare-cost::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Light grey */
}

/* Handle */
.table-container-repare-cost::-webkit-scrollbar-thumb {
  background: #d1823a;
  height: 10px !important;
  cursor: pointer;
}

/* Handle on hover */
.table-container-repare-cost::-webkit-scrollbar-thumb:hover {
  background: #d1823a;
  /* Dark grey */
}

/* ----------------------------vehicle specification scroll- bar design ------------------------------*/

.scrool-bar-container {
  max-height: 200px;
  overflow-y: auto;
  position: relative;
  /* Add relative positioning */
}

/* Style the scrollbar */
.scrool-bar-container::-webkit-scrollbar {
  width: 10px;
  /* height: 50px; */
}

/* Track */
.scrool-bar-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Light grey */
}

/* Handle */
.scrool-bar-container::-webkit-scrollbar-thumb {
  background: #d1823a;
  height: 550px !important;
  cursor: pointer;
  border-radius: 10px;
}

/* Handle on hover */
.scrool-bar-container::-webkit-scrollbar-thumb:hover {
  background: #d1823a;
  /* Dark grey */
}

/* ----------------------------------------------------- */
#total-repare-footer {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: #fce8cd;
  padding-left: 19px;
  padding-top: 6px;
}

.save-button-tab {
  padding: 8px 16px;
  /* Adjust padding as needed */
  background-color: #007bff;
  /* Change background color as needed */
  color: #fff;
  /* Change text color as needed */
  border: none;
  cursor: pointer;
}

.save-button-tab:hover {
  background-color: #0056b3;
  /* Change hover background color as needed */
}

.arrow-adlst {
  display: inline-block;
  background-color: #d1823a;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  color: white;
  width: 34px !important;
  height: 42px !important;
  align-items: center;
  position: relative;
  right: 2%;
}

.arrow-adlst svg {
  height: 100%;
  margin-left: 8px;
}

.popup-vin {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  padding-left: 14px;
  width: 10%;
}

.popup-vin button {
  width: 100px;
}

.popup-vin p {
  padding-top: 13px;
  margin-bottom: 0px;
}

#adlst-reset-btn {
  border: none;
  margin-left: 15px;
  width: 50px;
  height: 50px;
  background-color: white !important;
  border-radius: 5px;
}

#adlst-reset-btn img {
  width: 35px;
  height: 35px;
}

#adlst-reset-btn-seller {
  border: none;
  margin-left: 15px;
  width: 50px;
  height: 50px;
  background-color: white !important;
  border-radius: 5px;
}

#refresh-btn-seller {
  display: flex;
  justify-content: end;
}

#adlst-reset-btn-seller img {
  width: 35px;
  height: 35px;
}

/*
Extra small devices (phones): Less than 576px
Small devices (tablets): 576px to 767px
Medium devices (laptops): 768px to 991px
Large devices (desktops): 992px to 1199px
Extra large devices (large desktops): 1200px and above 
*/

@media only screen and (max-height: 1000px) {
  .tab-content {
    /* min-height: 80vh !important; */
    border: 2px solid #ffa500;
  }
}

@media only screen and (min-height: 1001px) and (max-height: 1300px) {
  .tab-content {
    /* min-height: 60vh !important; */
    /* border: 3px solid #d13a3a; */
  }
}

@media only screen and (min-height: 1301px) {
  .tab-content {
    /* min-height: 55vh !important; */
    /* border: 3px solid #98db2d; */
  }
}

@media only screen and (min-height: 1550px) {
  .tab-content {
    /* min-height: 50vh !important; */
    /* border: 3px solid #00ffdd; */
  }
}

@media only screen and (min-height: 1750px) {
  .tab-content {
    /* min-height: 45vh !important; */
    /* border: 3px solid #002fff; */
  }
}

@media only screen and (min-height: 1900px) {
  .tab-content {
    /* min-height: 40vh !important; */
    /* border: 3px solid #b700ff; */
  }
}

@media only screen and (min-height: 2000px) {
  .tab-content {
    /* min-height: 37vh !important; */
    /* border: 3px solid #ff0077; */
  }
}

/* @media (min-width: 576px) {
  .modal-dialog.modal-90w {
    max-width: 95vw !important;
  }
} */

/* ------------------------meadia query for vehicle specfication section---------------------------------- */
@media (min-width: 1024px) and (max-width: 1440px) {
  .vehiicle_specification_parent {
    /* border: 2px solid black; */
    /* display: flex !important;
    gap: 1rem; */
  }

  .vehicle_specification_left_child {
    /* border: 2px solid blue; */
    /* For empty and 1 image */
    /* max-width: 50% !important; */
    /* max-width: 35% !important; */
  }

  /* .vehicle_specification_left_impo{
    border: 2px solid blue;
    min-width: 45% !important;
  } */

  .vehicle_specification_right {
    /* border: 2px solid red; */
    /* max-width: 45% !important;
    min-width: 45% !important;
    padding: 0 0.3rem; */
  }

  .vehicle_specification_right_double {
    /* border: 2px solid red; */
    /* max-width: 49% !important;
    min-width: 49% !important;
    padding: 0 0.3rem; */
  }
}

@media (min-width: 1440px) {
  .vehiicle_specification_parent {
    /* border: 2px solid black; */
    /* display: flex   !important; */
  }

  .vehicle_specification_left_child {
    /* border: 2px solid blue; */
    /* max-width: 50%; */
  }

  .vehicle_specification_right_child {
    /* border: 2px solid red; */
    /* max-width: 50% !important;
    padding: 1rem; */
  }

  .vehicle_specification_right_double {
    /* border: 2px solid red; */
    /* max-width: 40%;
    padding: 1rem; */
  }
}

.input-containerdlr-amount {
  position: relative;
  display: inline-block;
  width: 90%;
}

.input-containerdlr-description {
  position: relative;
  display: inline-block;
  width: 98%;
}

#method-maintanance {
  margin-right: 1rem !important;
}

.payment-input {
  margin-right: 10px;
}

/* Carousel.css */
.modal-dialog {
  width: 95vw !important;
}

.first-row {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.first-row img {
  width: 100%;
  height: 100%;
}

.zoom-img {
  box-sizing: border-box;
  height: 310px !important;
  /* width: 370px !important; */
  box-sizing: border-box;
  /* border: 1px solid gray; */
  box-shadow: 3px 3px 5px gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.zoom-img .rotate-img {
  height: 88% !important;
  width: 100% !important;
}

.rotate-img {
  width: inherit !important;
  height: inherit !important;
}

.parent-small-img {
  width: 130px !important;
}

.small-img {
  height: 150px !important;
  box-shadow: 2px 2px 3px gray;
  margin: auto;
}

.small-img img {
  height: 85% !important;
  width: 100% !important;
}

.parent-all-img {
  width: 130px !important;
  height: 130px !important;
}

.all-small-img {
  height: 100% !important;
  box-shadow: 2px 2px 3px gray;
  margin: auto;
}

.all-small-img img {
  height: 100% !important;
  width: 100% !important;
}

.icons {
  /* position: absolute;
  top: 50%;
  right: 20px; */
  /* transform: translateY(-50%); */
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 5px;
}

.icon {
  width: 10% !important;
  height: max-content;
  cursor: pointer;
  font-size: 10px;
  color: white;
  /* background-color: #969696; */
  /* border-radius: 45%; */

  margin: 0px 5px;
}

.small-video {
  height: 130px !important;
  box-shadow: 2px 2px 3px gray;
  margin: auto;
}

.small-video video {
  height: 80% !important;
  width: 100% !important;
}

.zoom-video {
  height: 400px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  /* border: 1px solid gray; */
  box-shadow: 3px 3px 5px gray;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.nav-button {
  cursor: pointer;
  font-size: 24px;
  color: black;
  margin: 0 10px;
}

.thumbnail {
  width: 100%;
  height: auto;
  /* margin: 0 5px; */
  cursor: pointer;
  opacity: 0.6;
}

.thumbnail.active {
  opacity: 1;
  /* border: 2px solid #000; */
}

.btn-next,
.btn-prev {
  width: 40px !important;
  padding: 0px !important;
  border: 2px solid green;
  border-radius: 50px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  background-color: #e4e4e4a1;
}

.btn-next {
  left: 5px;
}

.btn-prev {
  right: 5px;
}

.image_uploads_section {
  display: flex;
  align-items: flex-end;
}

.uploader-container {
  position: relative;
  top: -55px;
  width: 100% !important;
  /* left: 15px; */
}

#eye_icons svg {
  width: 30px;
  height: 30px;
}

.dragging {
  /* border: 2px dashed rgb(207, 36, 36); */
}

.main-img-con {
  position: relative;
  margin-bottom: -25px;
}

.loader-image {
  position: relative;
  top: -40px;
  width: 90% !important;
  height: 30px !important;
}

.progress-bar {
  height: 15px;
  border-radius: 10px;
  background-color: #efefefb5 !important;
}

.progress {
  height: 15px !important;
  background-color: #e8a262 !important;
}

.progress-text {
  font-size: smaller;
}

.slick-next:before,
.slick-prev:before {
  display: inline-block;
  font-size: 45px;
  color: #ebebeb !important;
}

.progress-text {
  display: flex;
  justify-content: space-between;
}

.default-img {
  border: 2px dashed rgb(124, 124, 124);
}

/* .loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  position: absolute;
  top: 30%;

} */

/* .loader {
  position: relative;
  width: 100px;
  height: 100px;
} */

/* .loader-progress {

  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 15px solid #ccc;
  border-top: 15px solid #ffaa5a;
  animation: spin 2s linear infinite;
  transform: rotate(126deg);
} */

.loader-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: #8a8a8a;
}

.loader-caption {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #8a8a8a;
}

@keyframes spin {
  0% {
    transform: rotate(126deg);
  }

  100% {
    transform: rotate(486deg);
    /* 360deg + 126deg */
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the loader class */
/* .loader {
  animation: rotate 1.5s linear infinite;
  height: 25px;
  width: auto;
} */

.loading {
  display: inline-block;
}

.loading span {
  font-size: 0.8rem;
}
