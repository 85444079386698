.dealer_next_step_wrapper {
  /* border: 2px solid #ff0000; */
  display: flex;
  justify-content: center;
  background-color: #7c8083;
  color: white;
  margin: 2rem 0;
  padding: 1rem;
}
.dealer_next_step_container {
  width: 95%;
  /* border: 2px solid black; */
}
.dealer_next_step_header {
  /* font-size: ; */
  text-align: center;
  font-size: larger;
  font-weight: 500;
}
.request_register_container {
  font-size: small;
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-weight: 500;
}
.request_register_container span:nth-child(2) {
  background-color: #e8a262;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
}
.dealer_inquiry_input_container p {
  display: flex;
  flex-direction: column;
}
.dealer_inquiry_input_container span {
  font-weight: 500;
}
.dealer_inquiry_input_container input {
  background-color: rgba(180, 194, 206, 0.836);
  border: none;
}
.dealer_inquiry_input_container input:focus {
  border: none;
  outline: none;
  color: white;
  font-weight: 400;
}
.dealer_next_step_container button {
  border: none;
  background-color: rgb(206, 9, 9);
  color: white;
  padding: 0.3rem 0.9rem;
}
@media (min-width: 720px) {
  .dealer_next_step_wrapper {
    padding: 2rem;
  }
  .dealer_next_step_header {
    text-align: center;
    font-size: xx-large;
    font-weight: 600;
  }
  .request_register_container {
    font-size: medium;
    font-weight: 500;
  }
  .dealer_inquiry_input_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;
    /* padding: 0 4rem; */
    margin-top: 2rem;
  }
  .dealer_inquiry_input_container span {
    font-size: small;
  }
  .dealer_inquiry_input_container input {
    width: 93%;
  }
  .dealer_next_step_container {
    padding: 2rem 4rem;
    padding-top: 1rem;
  }
}
@media (min-width: 1024px) {
  .request_register_container {
    padding: 0 3rem;
  }
  .dealer_inquiry_input_container span {
    font-size: medium;
  }

  .dealer_inquiry_input_container input {
    width: 85%;
  }
  .dealer_next_step_container {
    padding: 2rem 6rem;
    padding-top: 1rem;
  }
  .dealer_next_step_container button {
    border: none;
    background-color: rgb(206, 9, 9);
    color: white;
    padding: 0.3rem 0.9rem;
    margin: 2rem 0;
  }
}
@media (min-width: 1440px) {
    .dealer_next_step_header {
        text-align: center;
        font-size: 50px;
        font-weight: 600;
      }
  .dealer_inquiry_input_container p {
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    position: relative;
    width: 75%;
  }
  .request_register_container {
    padding-right: 9rem;
  }
  .dealer_inquiry_input_container span {
    font-size: medium;
  }

  .dealer_inquiry_input_container input {
    width: 100%;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .dealer_next_step_container {
    padding: 2rem 6rem;
    padding-top: 1rem;
  }
}
@media (min-width: 1700px) {
    .dealer_next_step_header {
        text-align: center;
        font-size: 60px;
        font-weight: 600;
      }
  .dealer_inquiry_input_container p {
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    position: relative;
    width: 100%;
  }
  .request_register_container {
    padding: 0 13rem;
  }

  .dealer_inquiry_input_container span {
    font-size: medium;
  }

  .dealer_inquiry_input_container input {
    /* border: 2px solid black; */
    width: 75%;
  }
  .dealer_next_step_container {
    padding: 2rem 6rem;
    padding-top: 1rem;
    border: 2px solid white;
    padding-left: 15rem;
  }
}
