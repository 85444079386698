.bgGradient {
  /* height: 60rem; */
  /* background: linear-gradient(to right, #e7964a 0%, #ffecda 100%); */
  background-color: white;

  overflow: hidden;
  position: relative;
  /* padding-top: 1rem; */

  box-shadow: 5px 5px 5px lightgrey;
}
.bgGradient h1 {
  text-align: center;
  font-weight: 700;
}

/* .cylenderContainer {
    margin: 15% 0px 0px 25%;
    position: relative;
}

.cylender {
    background-color: #edb47f;
    height: 50px;
    transform: rotate(30deg);
    border-radius: 20px;
}

.cylender1 {
    width: 45%;
} */

/* .cylender2 {
    width: 30%;
    
}

.cylender3 {
    width: 45%;
    margin-top: 75px;
} */

.loadValContainer {
  width: 275px;
  height: 275px;
  /* background-color: white; */
  /* border-radius: 50%; */
  /* padding: 10px; */
  display: flex;
  border-radius: 50%;
  box-shadow: 1px 6px 7px #54474757;
  background-color: white;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 45px;
  font-weight: 600;
}
.loadValContainer span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputConPercent {
  position: relative;
  display: inline-block;
  width: 100%;
}

.formRow1 {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  /* padding: 0 1.5rem; */
  /* margin-top: 1rem; */
}
.formRow1Child {
  flex: 1 !important;
  margin-bottom: 0.3rem;
}
.label {
  /* font-size: 24px !important; */
  font-size: 17px !important;
  /* font-weight: 700; */
  font-weight: 600;
  /* color: white !important; */
  color: darkslategrey !important;
}
.formControl {
  color: darkgrey;
}

@media (max-width: 400px) {
  .label {
    font-size: 11px !important;
  }
  .txtBtnCarHome2 button p {
    font-size: 8px !important;
    margin: 0px;
    border-radius: 10px;
  }
}
.formControl {
  font-weight: bold;
  letter-spacing: 2px;
  /* padding: 7px !important; */
  padding: 3px !important;
  /* font-size: 25px !important; */
  font-size: 12px !important;
  text-align: center !important;
  /* box-shadow: 0px 6px 6px -3px rgba(0.1, 0.1, 0, 0.8); */
  border: none;
  border-bottom: 1px solid lightgrey;
  /* height: 4rem; */
  width: 100%;
  height: 2rem;
  border-radius: 5px;
}

.formControl:focus {
  /* border: none; */
  outline: none;
}

.percentSymbol {
  position: absolute;
  top: 50%;
  left: 10px;
  /* Adjust the distance from the right edge */
  transform: translateY(-50%);
  pointer-events: none;
  /* Make sure the symbol doesn't interfere with input */
  color: #000;
  /* Set the color of the percentage symbol */
}

.minMax {
  font-size: small;
}

.inputRange {
  margin-top: 5px;
  margin-bottom: 15px;
}

.loanEmi {
  width: 100%;
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 0px;
}

.estMonthlyHeadingHome {
  width: 50%;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 6px 6px -3px rgba(0.1, 0.1, 0, 0.8);
  height: 13%;
  /* margin-left: 4%; */
  border-radius: 6px;
}

.loanTermsParent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 4px;
}
.txtBtnCarHome {
  flex: 1;
}
.txtBtnCarHome button {
  border: none;
  border-radius: 5px;
  border: 1px solid white;
  /* width: 90px;
  height: 50px; */
  /* width: 35px; */
  width: 100%;
  height: 32px;
  font-size: 14px;
}

.txtBtnCarHome button p {
  font-size: 15px;
  margin: 0px;
}
.txtBtnCarHome {
  box-shadow: 0px 6px 6px -3px rgb(200 193 193 / 80);
}
.txtBtnCarHome2 {
  flex: 1;
  border-radius: 10px;
}
.txtBtnCarHome2 button {
  border: none;
  border-radius: 10px;
  /* border: 1px solid white; */
  /* width: 90px;
  height: 50px; */
  width: 100%;
  /* height: 32px; */
  font-size: 12px;
}

.txtBtnCarHome2 button p {
  font-size: 12px;
  margin: 0px;
}
.txtBtnCarHome2 {
  box-shadow: 0px 6px 6px -3px rgb(200 193 193 / 80);
}
.statement {
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  color: #c77f20;
}

.tooltip_container_b {
  position: relative;
  display: inline-block;
  top: 4px;
}

.tooltip_container_b:hover .tooltip_text_b {
  visibility: visible;
  opacity: 1;
}

.tooltip_text_b {
  visibility: hidden;
  font-size: 10px;
  width: 250px;
  /* Adjust width as needed */
  background-color: rgb(112, 109, 109);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -125px;
  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip_container_b:hover .tooltip_text_b {
  visibility: visible;
  opacity: 1;
}

.apply_btn_cal {
  background-color: #d9d9d9;
  width: 12%;
  height: 40px;
  font-weight: bold;
  padding-top: 0.4rem;
  text-align: center;
  border-radius: 5px;
}
.approxCreditScore {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

@media (min-width: 550px) {
  .txtBtnCarHome {
    flex: 1;
    border-radius: 10px;
  }
  .txtBtnCarHome button {
    border: none;
    border-radius: 5px;
    border: 1px solid white;
    /* width: 90px;
    height: 50px; */
    width: 100%;
    height: 32px;
    font-size: 14px;
  }
  .formControl {
    font-weight: 500;
    letter-spacing: 2px;
    /* padding: 7px !important; */
    padding: 5px !important;
    font-size: 20px !important;
    /* height: 4rem; */
    height: 3rem;
    border-radius: 8px;
    color: darkgrey;
  }
  .txtBtnCarHome button {
    border: none;
    border-radius: 5px;
    border: 1px solid white;

    width: 100%;
    height: 48px;
    font-size: 19px;
    font-weight: 600;
  }
  .txtBtnCarHome2 button {
    border: none;
    border-radius: 5px;
    border: 1px solid white;

    width: 100%;
    height: 48px;
    font-size: 19px;
    font-weight: 600;
    border-radius: 10px;
  }
  .formRow1 {
    /* margin-top: 1.5rem; */
  }
}
@media (min-width: 1024px) {
  .formRow1 {
    flex-direction: row;
    gap: 4rem;
  }
}

@media (min-width: 1440px) {
  .formRow1 {
    flex-direction: column;
    gap: 0rem;
    padding: 0 1.5rem;
    margin-top: 0rem;
  }
  .bgGradient {
    /* height: 60rem; */
    /* background: linear-gradient(to right, #e7964a 0%, #ffecda 100%); */
    background-color: linear-gradient(to bottom, #e7964a 50%, white 50%);
    background: linear-gradient(to bottom, #e7964a 50%, white 50%);

    border-bottom: 24px solid lightgrey;
    box-shadow: 5px 5px 5px lightgrey;
  }
  .label {
    font-size: 18px !important;
    font-weight: 500;
    color: #6c6767 !important;
  }
  .loanTermsParent {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
  }
  .approxCreditScore {
    gap: 10px;
  }
  .CalculatorParent {
    min-width: 1428px !important;
  }
}
