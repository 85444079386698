.dealer-sidebar {
  transition: width 0.2s ease;
  overflow: hidden;
  border: 1px solid orange;
}
.dealer-sidebar.collapsed {
  width: 80px; /* Adjust as needed */
}

.dealer-sidebar.expanded {
  width: 250px; /* Adjust as needed */
}
.side_bar_item_wrapper {
  padding: 0.6rem 0;
  border-bottom: 1px solid orange;
  width: 100%;
  cursor: pointer;
  background-color: inherit;
  transition: all 0.3s ease;
  /* transition: all ease-in-out 500ms; */
}
.side_bar_item_wrapper:hover {
  background-color: #ebd6c3;
}
.side_bar_item_wrapper.clicked {
  background-color: #ebd6c3; /* Change background color on click */
}

.side_bar_item_container {
  display: flex;
  gap: 0.5rem;
  padding-left: 15px;
  /* justify-content: center; */
  align-items: center;
  background-color: inherit;
  position: relative;
}
.no_padding{
    padding-left: 5px;
    padding-right: 5px; /* Add this */
}
.small-icon{
    height: 30px;
    width: 30px;
}
.large-icon{
    height: 45px;
    width: 45px;
    margin: auto;

}

.nested_item_wrapper {
  /* border: 2px solid black; */
  width: 100%;
  box-sizing: border-box;
  margin-bottom: -0.65rem;
  background-color: #fff;
}
.nested_item_container {
  /* border: 2px solid red; */
  width: 100% !important;
  box-sizing: border-box;
  padding-left: 1rem;
}


/* New from claude */
/* Add these new styles */
.nested-icon {
    margin-left: auto;
  }
  
  .collapsed-nested-icon {
    position: absolute;
    right: 5px;
    font-size: 12px;
  }

  /* chatgpt */

  .collapsed_children_icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .collapsed-child-icon-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    padding:0.8rem  ;
    margin-left: 10px;
  border-bottom: 1px solid orange;
  background-color: white;
  }
  
  .collapsed-child-icon-wrapper:hover .collapsed-child-name {
    display: inline-block;
    background-color: #ebd6c3; 
    background-color: white; 
    width: 100%;
  }
  
  .collapsed-child-name {
    display: none;
    margin-left: 5px;
    font-size: 14px;
  }