.login_feature_wrapper {
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background-color: #515558;
}
.login_feature_container {
  /* border: 2px solid white; */
  width: 80%;
}

.login_feature_container button {
  background-color: rgb(243, 223, 196);
  border: none !important;
  border-radius: 10px;
  padding: 3px 0;
  width: 100%;
  font-weight: 500;
  font-size: large;
  position: relative;
  margin: 1rem 0;
}
.login_feature_container button.blink_now {
  animation: blink 800ms linear infinite;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.login_feature_container .animated_car_icon {
  height: 40px;
  position: absolute;

  left: 5%;
}
.login_feature_container button.blink_now .animated_car_icon {
  /* Move the image across the button */
  animation: car_move 0.7s linear infinite;
}
@keyframes car_move {
  0% {
    left: 5%;
  }
  25% {
    left: 25%;
  }
  50% {
    left: 50%;
  }
  75% {
    left: 75%;
  }
  100% {
    left: 90%;
  }
}
.dealer_auth_image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  padding: 2rem;
}
.dealer_social_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  /* border: 2px solid blue; */
}
.dealer_social_container div {
  width: 100%;
}
.welcome_message {
  font-weight: 500;
  font-size: 30px;

  width: 100%;
  /* letter-spacing: 2px; */
}
.dealer_social_child {
  display: flex;
  border: 2px solid red;
}
.sign_in_message {
  font-weight: 500;
  font-size: larger;

  width: 100%;
  letter-spacing: 1px;
  /* margin: 0; */
}
.email_username_label {
  font-weight: 500;
  font-size: x-large;

  width: 100%;
  /* letter-spacing: 3px; */
  margin-bottom: 0.7rem;
}
.forgot_pass_dealer_login {
  display: flex;
  justify-content: end;
}
.forgot_pass_dealer_login a {
  color: white;
}
.dealer_email_container {
  border-bottom: 1px solid white;
  padding: 7px 0;
  display: flex;
}

.dealer_email_container input.input_field_dealer_login {
  border: none;
  background-color: transparent;

  padding: 3px 0px;
  text-align: left;
  color: white !important;
}
.dealer_email_container span {
  font-size: xx-large;
}
.dealer_email_container .input_field_dealer_login:focus {
  border: none;
  outline: none;
}
.dealer_email_container .input_field_dealer_login::placeholder {
  color: white !important;
}

.submit_button_container {
  display: flex;
  justify-content: center;
}
.submit_button_container button {
  background-color: #d1823a;
  color: white;
  padding: 0.5rem;
  font-weight: 600;
  margin-top: 0.5rem;
  width: 100%;
  border-radius: 0.4rem;
  font-size: x-large;
}

.input_and_icon {
  display: flex;
  align-items: center;
}
.error_pass_set {
  font-size: small;
  color: red;
}

.login_feature_container .input_and_icon {
  width: 100%;
  border-radius: 7px;
  margin-bottom: 1rem;
  padding-right: 5px;
  border-bottom: 2px solid white;
  padding-bottom: 3px;
}

.input_and_icon input {
  width: 80%;
  margin-right: 5%;
  border: none;
  padding: 4px 6px;
  margin: 0.4rem 0;
  font-size: larger;
}

.input_and_icon input:focus {
  border: none;
  outline: none;
}
.input_and_icon input::placeholder {
  font-size: larger;
  color: white;
}
.input_and_icon img {
  width: 10%;
}

@media (min-width: 2560px) {
  .input_and_icon input {
    width: 80%;
    margin-right: 5%;
    border: none;
    padding: 4px 6px;
    margin: 0.4rem 0;
    font-size: x-large;
  }

  .input_and_icon input::placeholder {
    font-size: x-large;
  }
  .input_and_icon img {
    width: 15%;
  }
}

.forget_password {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
}

.forget_password .dealer_forget_pass_btn {
  border-bottom: 1px solid black;
  padding-bottom: 0px;
  font-size: smaller !important;
  background-color: #515558;
  color: white !important;
  display: inline;
}
.error_message {
  color: red;
  text-align: left;
}
.login_feature_container button {
  background-color: rgb(243, 223, 196);
  border: none !important;
  border-radius: 15px;
  padding: 3px 0;
  font-weight: 500;
  font-size: large;
  position: relative;
}
.login_feature_container button.blink_now {
  animation: blink 800ms linear infinite;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.login_feature_container .car_image_move {
  height: 40px;
  position: absolute;

  left: 5%;
}
.login_feature_container button.blink_now .car_image_move {
  /* Move the image across the button */
  animation: car_move 0.7s linear infinite;
}
@keyframes car_move {
  0% {
    left: 5%;
  }
  25% {
    left: 25%;
  }
  50% {
    left: 50%;
  }
  75% {
    left: 75%;
  }
  100% {
    left: 90%;
  }
}
