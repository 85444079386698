.modal_100vw {
  max-width: 100vw !important;
}
.bodyWrapper {
  background-color: rgb(235, 231, 231);
}
.buyerImageContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
}
.ImageContainer {
  width: 100%;
  height: 13rem;
  display: flex;
  place-content: center;
  /* border: 1px solid black; */
  background-color: white;
}
.ImageContainer img {
  max-width: 60%;
  max-height: 100%;
  min-width: 100%;
  object-fit: contain;
  /* border: 1px solid blue; */
}
.buyerContainer {
  background-color: white;
  padding: 0.3 0.7rem;
  width: 100%;
}
.buyerInfo {
  padding: 0.4rem;
}

.buyerInfo:nth-child(1) {
  margin-bottom: 1rem;
}
.buyerHeader {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.buyerHeader2 {
  padding-left: 1rem;
}
.buyerHeader span {
  margin-right: 0.3rem;
  font-size: x-large;
}
.buyerDetails {
  display: flex;
  flex-direction: column;
  padding: 0 0.7rem;
  gap: 0.2rem;
}

.buyerDetails > span {
  margin-bottom: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 500;
  font-weight: 600;
  display: flex;
  gap: 1.3rem;
}
.buyerDetails > span span:first-child {
  flex: 0.8 1;
  text-align: left;
  padding-left: 2rem;
  color: grey;
}
.buyerDetails > span span:last-child {
  flex: 1;
  font-size: 14px;
  /* text-align: ; */
}
/* Price fees starts here */
.priceFeesContainer {
  display: flex;
  justify-content: center;
  font-size: 15px;
}
.priceFeesTable {
  width: 94%;
  border: 1px solid black;
  border-radius: 10px;
}
.priceFeesRow {
  display: flex;
  font-weight: 500;
}
.priceFeesTable .priceFeesKey {
  flex: 0.77;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  text-align: left;
  padding-left: 0.7rem;
  margin-bottom: 0;
}
.priceFeesTable .priceFeesValue {
  flex: 0.25;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  color: #d1823a;
  text-align: center;
  margin-bottom: 0;
}
.priceFeesMessageContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.MessageContainer {
  border: 2px solid lightgrey;
  border-radius: 15px;
  box-shadow: 5px 5px 5px lightgrey;
  width: 95%;
}
.MessageContainer .MessageHeader {
  box-shadow: 5px 5px 2px lightgrey;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
}
.MessageHeader p {
  margin-bottom: 0;
}
.MessageBox {
  height: 30rem;
  padding: 2rem 1rem 1rem 1rem;
  overflow-x: scroll;
}
.chatBody {
  text-align: left;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 1rem;
  box-shadow: 3px 3px 3px rgb(219, 213, 213);
  margin-bottom: 1rem;
}
.chatBody span:nth-child(1) {
  font-weight: 500;
  font-size: large;
}
.MessageBox .leftMessage {
  padding-right: 15%;
}
.MessageBox .leftMessage .chatBody {
  background-color: #e6a569;
}
.MessageBox .rightMessage {
  padding-left: 15%;
}
.MessageBox .rightMessage .chatBody {
  background-color: rgb(219, 213, 213);
}
.MessageInput {
  display: flex;
  align-items: center;
  box-shadow: 0 -10px 7px rgba(218, 210, 210, 0.5);
  padding: 1rem;
}
.MessageInput input {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: medium;
}
.formsButtonContainerOuter {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 1rem 0;
}
.formsButtonContainerOuter p {
  font-weight: 600;
  font-size: large;
}
.formsButtonContainerOuter button {
  color: white;
  background-color: #e6a569;
  border: none;
  padding: 0.4rem 1.5rem;
  font-size: large;
  border-radius: 11px;
  font-weight: 600;
  width: 62%;
}
.formsButtonContainerInner {
  display: none;
}
.titleWrapper {
  font-size: small;
  display: flex;
  flex-direction: column;
  /* width: 144%; */
  justify-content: center;
  /* border: 2px solid black; */
  align-items: center;
}
.titleWrapper .carDetails {
  display: flex;
  gap: 2.5rem;
}
.titleWrapper .carDetails span:nth-child(2) {
  color: #d1823a;
}
.downContainer {
  display: none;
}
/* Message Box */
.msgWrapper {
  display: none;
  /* border: 1px solid black; */
  width: 100%;
  padding: 0 1rem;
  /* flex: 0.3; */
  background-color: white;
  height: 100%;
}
.msgContainer {
  border: 1px solid lightgrey;
  width: 100%;
  border-radius: 10px;
  height: 100%;
}
.msgContainer .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 1rem;
  border-bottom: 1px solid lightgrey;
  box-shadow: 1px 3px 5px lightgrey;
}
.msgContainer .header span:last-child {
  background-color: #d1823a;
  border-radius: 50%;
  padding: 0.1rem 0.4rem;
}

.msgLeft {
  padding-right: 1rem;
}
.msgLeft .message {
  border: 1px solid grey;
  background-color: rgba(211, 211, 211, 0.589);
  border-radius: 0 10px 10px 10px;
}
.msgRight {
  padding-left: 1rem;
}
.msgRight .message {
  border: 1px solid #d1823a;
  background-color: #ffecdb;
  border-radius: 10px 0 10px 10px;
}
.msgBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 80%;
  /* border-bottom: 1px solid black; */
  padding: 0.5rem;
  gap: 1rem;
  overflow: scroll;
  overflow-x: hidden;
}
.msgBody .message {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.3rem;
}
.msgBody .message span:first-child {
  font-weight: 500;
}
.messageFooter {
  position: relative;
  box-shadow: 0px -3px 3px lightgrey;
  /* height: 20%; */
}
.messageFooter input {
  width: 100%;
  border: none;
}
.messageFooter span {
  position: absolute;
  right: 0.3rem;
  border-radius: 50%;
  background-color: #d1823a;
  color: white;
  /* font-size: x-large; */
  top: 0.2rem;
  padding: 0.1rem;
}
.mobileSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.mobileBtnContainer {
  display: flex;
  gap: 0.3rem;
  width: 100%;
  justify-content: space-around;
  background-color: white;
  padding: 0.5rem 0.2rem;
}
.mobileBtnContainer button {
  font-size: 11px;
  background-color: #d1823a;
  color: white;
  border-radius: 6px;
  padding: 0.3rem;
  font-weight: 500;
  border: none;
  width: 34%;
  font-weight: 500;
  border: none;
}
.formsModalBodyContainers {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.addToFormsPreviewContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.spmTitleWrapper {
  width: 100%;
}

@media (max-width: 424px) {
  .buyerDetails {
    padding: 0 0.3rem;
  }

  .priceFeesContainer {
    font-size: small;
  }
  .titleWrapper {
    width: 100%;
  }
}
@media (min-width: 420px) {
  .buyerDetails > span {
    font-size: 15px;
  }
  .buyerDetails > span span:first-child {
    flex: 0.6 1;
    text-align: left;
    padding-left: 2rem;
    color: grey;
  }
  .formsButtonContainerOuter {
    flex-direction: row;
    padding: 0.3rem 1rem;
  }
  .formsButtonContainerOuter button {
    padding: 0.4rem 0.5rem;
    font-size: small;
    border-radius: 11px;
    font-weight: 600;
    width: 62%;
  }
  .mobileBtnContainer button {
    font-size: 15px;
  }
}

@media (min-width: 720px) {
  .buyerDetails > span {
    font-size: 17px;
  }
  .buyerDetails > span span:first-child {
    flex: 0.6 1;
    padding-left: 4rem;
  }
  .buyerDetails > span span:last-child {
    font-size: 17px;
  }
  .buyerHeader {
    padding-left: 1.5rem;
  }

  .priceFeesContainer {
    font-size: medium;
  }
  .titleWrapper {
    font-size: medium;
    display: flex;
    flex-direction: row;
    /* width: 144%; */
    justify-content: space-between;
    /* border: 2px solid black; */
    align-items: center;
  }
  .saleType {
    flex: 0.45;
  }
  .titleWrapper .carDetails {
    display: flex;
    gap: 2.5rem;
    flex: 1;
    justify-content: space-between;
  }
  .mobileBtnContainer {
    padding: 0.5rem 1.6rem;
    gap: 1.7rem;
  }
}
@media (min-width: 1024px) {
  .buyerDetails {
    column-gap: 2.5rem;
  }
  .buyerDetails > span {
    font-size: medium;
  }
  .buyerHeader {
    font-size: 1.4rem;
  }
  .buyerInfo {
    padding: 0.4rem 1.4rem;
  }
  .buyerDetails {
    padding: 0 1.5rem;
  }
  .priceFeesMessageContainer {
    flex-direction: row;
  }
  .priceFeesMessageContainer > div {
    flex: 1;
  }
  .titleWrapper {
    /* width: 190%; */
  }
}
@media (min-width: 1440px) {
  .tabsWrapper {
    width: 100%;
    height: 100%;
  }
  .downContainer {
    display: flex;
    padding: 1rem;
    background-color: white;
    border-top: 1px solid lightgrey;
    height: 100%;
    width: 100%;
  }

  .tabsWrapper .tabsContent {
    border: #d1823a 2px solid;
    border-radius: 10px;
    border-top-left-radius: 0;
    overflow-y: scroll;
    /* height: 100%; */
    box-shadow: inset 0 0 16px #d1823a;
    height: 76vh;
  }
  .tabsContainer .tabsBtn {
    border: none;
    border: #d1823a 2px solid;
    border-bottom: none;
    width: 200px;
    padding: 0.3rem 0.5rem;
    background-color: white;
    font-weight: 500;
  }
  .tabsContainer .tabsBtn:first-child {
    border-top-left-radius: 10px;
  }
  .tabsContainer .tabsBtn:last-child {
    border-top-right-radius: 10px;
  }
  .tabsContainer .tabsBtn.active {
    background-color: #d1823a;
    color: white;
  }
  .titleWrapper {
    /* width: 190%; */
  }
  .titleWrapper {
    font-size: larger;

    /* width: 160%; */
  }
  .buyerImageContainer {
    flex-direction: row;
    gap: 0;
    padding: 0;
    /* height: 60vh; */
  }
  .buyerHeader {
    padding-left: 0rem;
  }

  .buyerHeader {
    margin-bottom: 0.5rem;
  }
  .buyerContainer {
    flex: 1;
    height: 85vh;
  }
  .ImageContainer {
    flex: 0.3;
    padding: 1.2rem 0.7rem;

    height: auto;
  }

  .ImageContainer img {
    max-height: 17rem;
    border-radius: 10px;
    object-fit: fill;
  }
  .buyerDetails > span {
    font-size: medium;
    font-weight: 500;
    color: dimgray;
  }
  .buyerDetails {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 1.5rem;
  }
  .buyerInfo:nth-child(1) {
    margin-bottom: 1.2rem;
  }
  .buyerInfo {
    padding: 0.8rem 0.7rem;
    padding-right: 1.3rem;
    /* border: 1px solid black; */
  }
  .buyerDetails {
    padding: 0 0.7rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .buyerDetails > span {
    gap: 0;
    align-items: center;
  }
  .buyerDetails > span:nth-child(3n + 1) {
    grid-column: 1;
  }
  .buyerDetails > span:nth-child(3n + 2) {
    grid-column: 2;
  }
  .buyerDetails > span:nth-child(3n + 3) {
    grid-column: 3;
  }
  .buyerDetails > span span:first-child {
    flex: none;
    padding-left: 0rem;
    font-size: 15px;
  }
  .buyerDetails > span span:last-child {
    font-size: 13px;
  }
  .priceFeesTable {
    display: flex;
    border: none;
    gap: 2rem;
  }

  .priceFeesMessageContainer {
    padding-top: 1rem;
    border-top: 1px solid lightgrey;
  }
  .MessageWrapper {
    flex: 0.4 !important;
  }
  .priceFeesContainer {
    flex: 1;
  }
  .priceFeesRow {
    display: flex;
    font-weight: 500;
    border: 1px solid black;

    font-size: 16px;
    height: 30px;
    box-shadow: 1px 9px 13px lightgrey;
  }
  .priceFeesTable .priceFeesKey {
    flex: 0.77;

    /*  border-bottom: 1px solid black;
    text-align: left;
    padding-left: 0.7rem;
    margin-bottom: 0; */
    border: none;
    border-right: 1px solid black;
  }
  .priceFeesTable .priceFeesValue {
    /* flex: 0.25;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    color: #d1823a;
    text-align: center;
    margin-bottom: 0; */
    border: none;
  }
  .formsButtonContainerOuter {
    display: none;
  }

  .formsButtonContainerInner {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    padding: 1rem 0;
    margin-top: 2rem;
  }
  .formsButtonContainerInner p {
    font-weight: 600;
    font-size: large;
  }
  .formsButtonContainerInner button {
    color: white;
    background-color: #e6a569;
    border: none;
    padding: 0.4rem 1.5rem;
    font-size: large;
    border-radius: 20px;
    font-weight: 600;
  }
  .PreviewContentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ImageMessagesContainer {
    display: flex;
    flex-direction: column;
  }
  .bodyContainer {
    display: flex;
    flex-direction: row;
  }
  .mobileSectionContainer {
    display: none;
  }
  .msgWrapper {
    display: block;
    /* border: 1px solid black; */
    width: 100%;
    padding: 0 1rem;
    /* flex: 0.3; */
    background-color: white;
    height: 100%;
  }
}
@media (min-width: 1850px) {
  .buyerContainer {
    padding-left: 1rem;
  }
  .buyerHeader {
    font-size: 1.9rem;
  }
  .buyerDetails {
    row-gap: 0.5rem;
  }
  .buyerDetails > span {
    font-size: large;
  }
  .ImageContainer {
    flex: 0.35;
    height: auto;
  }

  .ImageContainer img {
    /* max-height: 100%; */
    border-radius: 14px;
    object-fit: inherit;
  }
  .titleWrapper {
    font-size: 37px;
  }
  .buyerDetails > span span:first-child {
    font-size: 17px;
  }
  .buyerDetails > span span:last-child {
    font-size: 17px;
  }
}
@media (min-width: 2400px) {
  .buyerDetails > span {
    font-size: x-large;
  }
  .buyerDetails {
    row-gap: 1.2rem;
  }
  .ImageContainer {
    object-fit: contain;
    height: 24rem;
  }
  .titleWrapper {
    font-size: 52px;
  }
}
