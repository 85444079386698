.header_container {
  display: flex;
  padding: 0 1rem 1rem 1rem;
  background-color: white;
  
}

.logo_container_dealer {
  /* border: 2px solid red; */
  /* background: black; */
  height: 120px;
  width: 400px;
  flex: 0.5;
  display: flex;
  align-items: center;
}
.logo_container_dealer img {
  height: 100%;
  width: 100% !important;
}
.logo_container_dealer a{
font-size: 40px;
color: #d1823a;
font-weight: 700;

}
.logo_container_dealer a span:nth-child(2){
  color: black

}
.logo_container_dealer a span{
  margin-right: 0.5rem;

}
.header_category {
  display: flex;
  /* border: 2px solid blue; */
  flex: 0.30;
  padding: 0 1rem;
  font-size: xx-large;
  gap: 1rem;
  align-items: center !important;
  padding-top: 0.5rem;
}
.sub_category {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0rem;
}
.header_category p:nth-child(2) {
  color: #d1823a;
  font-size: 700;
  margin-bottom: -0.5rem;
}

.dealer_header_menu {
  display: flex;
  /* border: 2px solid green; */
  flex: 0.5;
  justify-content: end;
}
.dealer_header_menu a{
  color:#d1823a;
  display : flex;
  flex-direction: column;
  
}
.dealer_header_menu p{
    display: flex;
    flex-direction: column;
    height: 100%;
    /* border: 1px solid black; */
    color: #d1823a;
    flex: 0.3;
    align-items: center;
    justify-content: center;
    font-size: xx-large;
    font-weight: 700;
    /* background-color: #d1823a; */
}
.dealer_header_menu p:nth-child(1){
    border-bottom-left-radius: 1.4rem;
}
.dealer_header_menu p span:nth-child(1){
    font-size: 40px;
}
.dealer_header_menu p span:nth-child(2){
    font-weight: 700;
}
