.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 10px; adjust the gap between cards */
  gap: 1rem;
  row-gap: 7rem;
  margin-bottom: 6rem;
}

/* .grid-container:hover {
  box-shadow: 5px 5px 5px 5px grey;
} */

.card {
  background: rgb(231, 231, 231);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  width: 100%;
  /* border: 2px solid blue !important; */
}
.boxShadow:hover {
  box-shadow: 2px 2px 2px 5px grey;
}

.wrapper_card_product {
  /* border: 2px solid red !important; */
  border: 1px solid black;
}

.wrapper_card_product:hover {
  background-color: rgb(219, 214, 214);
}

.card_container-b {
  /* border : 1px solid gray; */
  display: flex;
  padding: 0 !important;
}

.wrapper_card_product:hover .card_container-b {
  background-color: rgb(219, 214, 214);
}

.card_left-c {
  /* flex: 0.2 !important; */
  /* border: 2px solid green; */
  /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}

.product-image-box-c {
  /* border: 2px solid black; */
  position: relative;
  flex: 1;
  display: flex;
  /* width: 280px;
    height: 160px; */
  flex-direction: column;
  width: 100%;
  height: 28rem;
  max-width: 100% !important;
  border-radius: 20px 20px 0 0;
}

.card_left-c img {
  /* border: 3px solid pink; */
  width: 100% !important;
  max-width: 100% !important;
  border-radius: 5px;
  border-radius: 20px 20px 0 0;
  /* flex: 1 ; */
  height: 400px;
  object-fit: cover;
}

.card_center {
  flex: 0.8 !important;
  /* border: 2px solid red; */
  padding-top: 0.6rem;
  display: flex;
}

.center_primary {
  /* border: 2px solid black; */
  flex: 70% !important;
}

.center_secondary {
  /* border: 2px solid black; */
  flex: 30% !important;
  padding-bottom: 0.3rem;
}

.center_secondary h5 {
  /* border: 2px solid blue; */
  /* text-align: right; */
}

.call_seller_button_container {
  /* border: 2px solid black; */
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: end;
  gap: 0.4rem;
}

.product-g-btn,
.product-S-btn {
  width: 70%;
  border-radius: 3px;
  text-align: center;
}

.contact_message_section {
  /* border: 2px solid red; */
  /* margin-top: 2rem; */
  display: flex;
  justify-content: center;
}

.input_contact_message_section {
  display: flex;
  margin: 0;
  gap: 0.6rem;
  margin-top: 0 !important;
}

.input_contact_message_section input {
  background-color: rgb(201, 197, 197);
  border: none;
  outline: none;
  border-radius: 0.4rem;
}

.input_contact_message_section input::placeholder {
  font-weight: 500;
}

.input_contact_message_section button {
  border: none;
  background-color: #d1823a;
  color: white;
}

.card_right {
  flex: 0.2 !important;
  /* border: 2px solid blue; */
  /* padding-top: 0.5rem; */
}

.product_share_like-c {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5.2rem;
  right: 3%;
  color: white;
  display: grid;
  place-content: center;
}

.product_share_like-c span {
  background-color: black;
  border-radius: 50%;
  padding: 0.2rem 0.35rem;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: x-large;
}

.interior_exterior_color_container {
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
  /* gap: 0.3rem; */
  margin: 0.6rem 0;
}

.interior_exterior_color_container span {
  /* border: 1px solid gray; */
  /* padding: 0.1rem 0.8rem; */
  /* border-radius: 15px; */
  font-weight: 500;
}

.product-g-btn,
.product-T-btn,
.product-E-btn,
.product-S-btn {
  /* border: 2px solid red; */
  display: flex;
  justify-content: start;
  /* gap:1rem; */
  gap: 0.5rem;
  font-size: medium;
  font-weight: 500;
  padding: 0.3rem 1rem;
}

.product_listing_messenger {
  /* border: 2px solid red; */
  display: flex;
  justify-content: start;
  align-items: center;
}

.product_listing_messenger span:nth-child(2) {
  font-weight: 500;
}

.product-message {
  width: 90%;
  /* margin-left: 10%; */
  /* border: 2px solid red; */
}

.year_make_model_text {
  font-size: xx-large;
  font-weight: 700;
  /* border: 2px solid blue; */
}

.vin_extras_container {
  /* border: 2px solid blue; */
  display: flex;
  gap: 2rem;
  margin: 0 !important;
  padding: 0;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
}

.seller_section_product_listing {
  margin-top: 1rem;
}

.seller_section_product_listing label {
  align-items: center !important;
  /* margin-top: -0.4rem; */
}

.seller_report_icons_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.seller_report_icons {
  width: 40px;
}

.social_media_container {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  right: -30px;
}

.tyre_condition_container {
  /* border: 2px solid red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  column-gap: 3px;
  font-size: small;
}

.tyre_fl_container,
.tyre_fr_container,
.tyre_bl_container,
.tyre_br_container {
  border-radius: 50%;
  border: 4px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bottom_items_container {
  border: 1px solid darkslategray;
  display: flex;
  /* border: 2px solid red; */
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px;
}

.wrapper_card_product:hover .bottom_items_container {
  background-color: rgb(219, 214, 214);
}

@media screen and (max-width: 1500px) {
  .product-g-btn,
  .product-S-btn {
    padding: 0.3rem 0.8rem;
    width: 70% !important;
  }

  .center_primary h4 {
    font-size: 1.4rem;
  }

  .center_secondary h5 {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 1300px) {
  .product-g-btn,
  .product-S-btn {
    padding: 0.3rem;
    width: 86%;
  }

  .center_primary h4 {
    font-size: 1.3rem;
  }

  .center_secondary h5 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1100px) {
  .product-g-btn,
  .product-S-btn {
    padding: 0.1rem;
    width: 90%;
  }
}

.overlay-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  /* Change as needed */
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); Optional for better visibility */
}

.top-text {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  top: 0px;
  /* Adjust as needed */
  font-size: largeer;
  /* Adjust as needed */
  font-family: "Alata";
  width: 100%;
  /* backdrop-filter: blur(4px); */
  background-color: rgba(17, 17, 17, 0.55);
  border-radius: 20px;
  /* padding: 0.5rem 0.9rem; */
}

.bottom-text {
  bottom: 0px;
  /* Adjust as needed */
  width: 100%;
  font-size: 1.5em;
  /* Adjust as needed */
}

.grid-card-mi {
  text-wrap: nowrap;
  font-size: 0.9em;
  font-family: "Alata";
  background-color: rgba(41, 39, 39, 0.66);
  margin-bottom: 21px;
  padding: 0.7rem;
  border-radius: 0 20px 0 25px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.gird-card-bottom-txt {
  display: flex;
  justify-content: space-between;
  /* padding: 0.9em; */
  font-size: 0.5em;
  font-family: "Alata", sans-serif;
  background-color: black;
  backdrop-filter: blur(4px);
  /* border-top-right-radius: 10px; */
  height: 130px;
}

.gird-card-bottom-txt p {
  margin: 0px;
}

#grid-card-price {
  color: #d1823a;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 0;
}

#grid-card-check-avlt {
  background-color: #d1823a;
  color: white;
  font-weight: bold;
  font-family: none;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 17px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.grid-int-color {
  /* backdrop-filter: blur(4px); */
  padding: 5px;
  /* font-size: 0.6em; */
  background-color: rgb(4 4 4 / 47%);
  border-top-right-radius: 5px;
  border-radius: 0 27px 0 0;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
  padding-right: 1.5rem;
}
