.complete_user_details_modal {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(47 44 44 / 66%); /* Semi-transparent background */
  z-index: 1000; /* High z-index to ensure it's on top */
 
}
.modal_wrapper {
  /* border: 2px solid red; */
  background-color: rgb(221,216,216,1);
  border-radius: 1rem;
  
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
  z-index: 1001;
}
.modal_form_container {
  /* border: 2px solid green; */
  padding: 1rem;
}
.modal_form_container h4{
    /* border : 2px solid red; */
    text-align: center;
}
.profile_image_container {
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 0.6rem;
}
#imagechoosebutton {
  display: none;
}
.profile_image_modal {
  border: 1px solid gray;
  border-radius: 50% !important;
}
.first_last_name_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem 0;
}

.first_last_name_container input {
  padding: 0.3rem;
  flex: 1;
  /* border: 2px solid red; */
}
.save_button_container {
  display: flex;
  justify-content: end;
}
.save_button_container button {
  padding: 0.3rem 0.8rem;
  font-weight: 500;
  background-color: #d1823a;
  color: white;
  border: 2px solid #d1823a;
  border-radius: 0.2rem;
}

.button {
  background-color: #4caf50; /* Green */
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.button:disabled,
.button.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  border: #666;
}
