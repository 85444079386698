.OTPContainer {
  width: 100%;
}
.OTPContainer {
  width: 100%;
}
.OTPContainer .header {
  text-align: center;
  font-weight: 700;
  font-size: 33px;
  margin-bottom: 2rem;
}
.OTPContainer .inputField {
  border-radius: 10px;
  border: none;
  padding: 0.7rem;
  font-size: larger;
  font-weight: 500;
  outline: none;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 9px lightgray;
}
