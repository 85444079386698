.wrapper {
  height: 90vh;
  width: 100%;
  position: relative;
  overflow: auto;
}
.container {
  width: 100%;
}
.header {
  display: flex;
  align-items: center;
  font-size: large;
  padding: 0.5rem;
  font-size: larger;
  font-weight: 500;
  box-shadow: 0px 4px 8px -1px rgb(192, 187, 187);
  margin-bottom: 2rem;
}
.header a {
  color: black;
  flex: 0.5;
}
.header span {
  font-size: large;
}
/* Content starts here */
.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.5rem;
  gap: 1rem;
  margin-bottom: 3rem;
}
.paymentReceipient {
  border: 1px solid #d1823a;
  box-shadow: inset 0 0 11px 3px #d1823a;
  text-align: center;
  font-size: 23px;
  padding: 0.5rem;
  font-weight: 600;
  width: 100%;
  border-radius: 10px;
}
.paymentTransactionContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}
.paymentContainer,
.TransactionContainer {
  display: flex;
  align-items: center;
}
.paymentContainer span:first-child,
.TransactionContainer span:first-child {
  font-size: small;
  font-weight: 500;
}
.paymentContainer span:last-child,
.TransactionContainer span:last-child {
  font-size: large;
  font-weight: 500;
  color: #d1823a;
}

.deletePrintContainer {
  display: flex;
  justify-content: end;
  width: 100%;
  padding-right: 1rem;
  gap: 1rem;
  align-items: center;
}
.deletePrintContainer span {
  font-size: 24px;
  color: red;
}
.deletePrintContainer button {
  background-color: #d1823a;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: large;
  font-weight: 500;
  padding: 0.1rem 1.7rem;
}
.cardParentWrapper {
  border-bottom: 1px solid grey;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  min-width: 100%;
  max-width: 100%;
}
.historyTrackerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.5rem;
}
.historyTrackerContainer label {
  font-size: larger;
  font-weight: 600;
}
.historyTrackerContainer input {
  margin-right: 1rem;
}
.historyTrackerContainer button {
  background-color: #d1823a;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: large;
  font-weight: 500;
  padding: 0.1rem 1.7rem;
}
.printSendEnvelopeContainer {
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0;
  gap: 0.4rem;
  /* padding: 0 1rem; */
}
.printSendEnvelopeContainer button {
  background-color: #d1823a;
  color: white;
  border: none;
  /* border-radius: 5px; */
  font-weight: 500;
  padding: 0.5rem 0.3rem;
  width: 50%;
}
@media (min-width: 500px) {
  .contentContainer {
    padding: 0.3rem 2.5rem;
  }
  .paymentReceipient,
  .paymentTransactionContainer {
    width: 96%;
  }
}
@media (min-width: 650px) {
  .contentContainer {
    padding: 0.3rem 2.5rem;
  }
  .paymentReceipient,
  .paymentTransactionContainer {
    width: 92%;
  }
}
