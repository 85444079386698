.cardWrapper {
  border: 2px solid rgb(170, 164, 164);
  border-radius: 5px;
  padding: .5rem;
}

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap !important;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dateContainer span:nth-child(1) {
  border-radius: 50%;
  background-color: rgba(20, 72, 245, 0.911);
  padding: .7rem 0.8rem;
  color: white;
  font-weight: 500;
  font-size: smaller;
}

.dateContainer span:nth-child(2) {
  font-weight: 500;
  text-wrap: nowrap;
}

.subjectNote {
  font-weight: 600;
  font-size: large;
  margin-bottom: 0;
  text-wrap: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.NoteReaders {
  display: flex;
  gap: 0.4rem;
}

.NoteReaders span {
  background-color: rgb(207, 195, 195);
  text-align: center;
  color: white;
  font-weight: 500;
  padding: 0.3rem;
  font-size: small;
}

.NoteFeatures {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.NoteFeatures p {
  background-color: red;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-bottom: 0;
}

.NoteFeatures span {
  text-align: center;
  font-size: larger;
  background-color: rgb(207, 195, 195);
  display: grid;
  place-content: center;
  padding: 0.3rem;
  color: white;
  border-radius: 50%;
}