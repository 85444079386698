.settingHead {
    color: #fcc07b;
    font-weight: 700;
    border-bottom: 2px solid lightgray;
}

.subHead {
    font-weight: 700;
}

.form label {
    font-size: .9rem;
    transition: background-color 0.3s, color 0.3s;
}

/* Style for the checked radio button's label */
.form input[type="radio"]:checked {
    background-color: #fcc07b;
    color: white;
    border-color: #ffac4d;
}

/* Create the inner dot */
.form input[type="radio"]:checked::before {
    content: "";
    display: block;
    width: 13px;
    height: 13px;
    /* margin: 2px; */
    border-radius: 50%;
    background-color: #ffac4d;
    /* Color of the dot when selected */
}

.saveBtn {
    color: white;
    font-weight: 600;
    background: linear-gradient(#5143de, #61b6df);
    border: none;
    border-radius: 5px;
    float: right;
    margin-top: 10px;
}

/* Style for the focused radio button's label */
/* input[type="radio"]:focus {
    border-color: #ffac4d;
    background-color: #fcc07b;
    color: #fcc07b;
    outline: none;
} */

.pop,
.smtp {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: 1rem;
}

.pop>p,
.smtp>p {
    width: 100%;
    margin-bottom: 0px;
    font-weight: 700;
}

.pop label,
.smtp label {
    display: flex;
    justify-content: space-between;
    color: gray;
}

.pop input,
.pop textarea,
.smtp input {
    width: 60% !important;
    border: 1px solid !important;
    color: black;
    border-radius: 0px;
}

.pop input[type="checkbox"] {
    width: 3% !important;
    margin-right: 15px;
}

.pop select {
    width: 60%;
    background: white;
    padding: 2px 10px;
    border: 1px solid;
}

.password {}


.connecting {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13vw !important;
    height: 13vw !important;
    max-width: 15vw !important;
    max-height: 15vw !important;
    padding: 5px;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 50%;
    color: white;
    background: linear-gradient(#2091fb, #e146c8);
    text-align: center;
}





.details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details p {
    color: gray;
    margin-top: .5rem;
    margin-bottom: 0px;
}

.details span {
    font-size: small;
}