.card_wrapper{
  border: 2px solid green !important;
  width : 100% !important;
  padding : 0 4rem;
  display: flex;
  flex-direction: column;
}
.card{
  box-shadow: none !important;
  border: none !important;
}
.fav-cart-main-cointer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* height: auto;card_wrapper
    width:900px; */
    /* width: 48%; */
    border: 2px solid black !important;
    border-radius: 10px;
    margin-top: 40px;
}
.sort_container{
/* border: 4px solid green; */
display: flex;
justify-content: end;
gap: 1rem;
margin-top: 1rem;
}
.per_page_container{
  display: flex;
  align-items: center;
  flex: 0.1;
  /* border: 4px solid blue; */
}
.per_page_container  span{
  flex: 0.5;
}
.per_page_container  select{
  flex: 0.4;
  /* height: 90% !important; */
  border: 2px solid black;
  padding: 2px !important;
  border-radius: 0 !important;
}
.sort_page_container{
  /* border: 2px solid red; */
  height: 100% !important;
  display: flex;
  align-items: center;
}
.sort_page_container select{
  /* border: 2px solid red; */
  border: 2px solid black;
  padding: 2px !important;
  border-radius: 0 !important;
}
.page_num_container{
  /* border : 2px solid red; */
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center !important;
  padding: 1rem 0;
}
.page_num_container p{
  border: 2px solid orange;
  padding: 0.2rem 0.6rem;
  font-size: larger;
  cursor: pointer;
}
.page_num_container p:hover{
  background-color: orange;
  color: white;
  
  
}
.selected_page{
  background-color: orange;
  color: white;
}
@media only screen and (max-width:600px){
    .fav-cart{
        margin-top: 20px;
    }
    
    .fav-cart-main-cointer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* height: auto;
        width:900px; */
        width: 100%;
        border: 2px solid black;
        border-radius: 10px;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px){
    .fav-cart-main-cointer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        /* height: auto;
        width:900px; */
        width: 100%;
        border: 2px solid black;
        border-radius: 10px;
    }
}
@media only screen and (min-width:1025px){
    .fav-cart-main-cointer{
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
        /* flex-direction: row; */
        /* flex-wrap: nowrap; */
        /* height: auto;
        width:900px; */
        /* margin-left: 100px; */
        /* width: 100% !important; */
        /* border: 2px solid black; */
        /* border-radius: 10px; */
    }
}

.fav-heading{
    /* height: 150px; */
    /* width: 300px; */
}

.fav-price{
    /* height: 150px; */
    /* width: 280px; */
}

#fav-button1 button{
   
    
    
    border: none;
    color: white;
    background-color: rgb(28, 173, 28);
    border-radius: 5px;
    
}
#fav-button2 button{
  
    border: none;
    color: white;
    background-color: #d1823a;
    margin-top: 10px;
    border-radius: 5px;
}

/*---------------------------------------------- code for tool tip -------------------------------------------------*/

/* Default styles for the tooltip */
[data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  
  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
  }
  
  /* Position tooltip above the element */
  [data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: 3px;
    margin-left: -29px;
    padding: 7px;
    width: 100px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    background-color: hsl(29, 64%, 54%);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
  }
  
  /* Triangle hack to make tooltip look like a speech bubble */
  [data-tooltip]:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -1px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid hsl(29, 77%, 54%);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }
  
  /* Show tooltip content on hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  
  /* code for placeholder column */
  #input-send{
    height: 30px;
    font-size: 10px;
    border: none;
    background-color: rgb(241, 237, 237);
    border-radius: 5px;
    margin-right: 10px;
  }

  #send-button{
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #d1823a;
    width: 50px;
  }

  /* .card{
    width: 19rem;
  } */

.fav-cart{
    /* margin-right: 39px; */
}

.fav-heading{
    /* margin-left: 30px; */
}

  @media only screen and (max-width:600px){
    /* .card{
        width: 18rem;
      } */
      .fav-cart{
        margin-right: 0px;
      }
      .fav-button-container{
        margin-bottom: 10px;
      }
  }