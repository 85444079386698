.container {
  width: 100%;
  background-color: rgba(238, 238, 238, 0.863);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 1rem;
  /* gap: 1rem; */
  font-size: larger;
  color: grey;
  font-weight: 500;
  padding: 0.7rem 1rem;
}
.socialChildBTN2 {
  width: 100%;
  border: none;
  background-color: rgba(238, 238, 238, 0.863);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 1rem;
  /* gap: 1rem; */
  font-size: larger;
  color: grey;
  font-weight: 500;
  padding: 0.7rem 1rem;
}
.container > span:first-child,
.socialChildBTN2 > span:first-child {
  flex: 0.25;
  display: flex;
  justify-content: start;
  padding-left: 1.2rem;
}
.container img,
.socialChildBTN2 img {
  aspect-ratio: 3/2;
  object-fit: contain;
  height: 29px;
  width: 20px;
}
.container > span:last-child,
.socialChildBTN2 > span:last-child {
  flex: 1;
  text-align: left;
}
.container:hover,
.socialChildBTN2:hover {
  background-color: rgba(212, 206, 206, 0.863);
  box-shadow: 2px 3px 5px lightgray;
  transition: 0.3s box-shadow ease-in-out;
}
@media (min-width: 420px) {
  .container img,
  .socialChildBTN2 img {
    flex: 0.25;
  }
  .container > span:first-child,
  .socialChildBTN2 > span:first-child {
    flex: 0.35;
  }
}
@media (min-width: 1440px) {
  .container span,
  .socialChildBTN2 span {
    font-size: larger;
  }
}
@media (min-width: 1800px) {
  .container span,
  .socialChildBTN2 span {
    font-size: x-large;
  }
}
@media (min-width: 2400px) {
  .container span,
  .socialChildBTN2 span {
    font-size: xx-large;
  }
  .container > span:first-child,
  .socialChildBTN2 > span:first-child {
    flex: 0.23;
  }
  .container img,
  .socialChildBTN2 img {
    height: 35px;
    width: 32px;
  }
}
