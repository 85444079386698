.dealer_facilities_wrapper {
  background-color: #515558;
}
.dealer_facilities_container {
  display: flex;
  flex-direction: column;
}
.dealer_products_header {
  color: white;
  font-weight: 600;
  font-size: large;
  text-align: center;
  padding: 2rem 0;
}
.dealer_product_card_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dealer_product_card_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  border: 2px solid white;
  padding: 1.4rem 3rem;
  border-radius: 0.6rem;
  margin: 1rem 0;
  width: 85%;
}
.dealer_product_card_container p {
  font-size: larger;
  text-align: center;
}
.dealer_product_card_container img {
  height: 150px;
  width: 125px;
  margin-bottom: 1rem;
}
.dealer_product_card_container span {
  background-color: red;
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
}
@media (min-width: 720px) {
  .dealer_products_header {
    color: white;
    font-weight: 700;
    font-size: larger;
  }
  .dealer_card_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .dealer_product_card_container {
    height: 300px;
  }
}
@media (min-width: 1024px) {
  .dealer_products_header {
    color: white;
    font-weight: 700;
    font-size: x-large;
  }
  .dealer_card_container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
  }

  .dealer_product_card_wrapper {
    flex: 0 0 calc(33.333% - 90px);
    margin: 10px;
    box-sizing: border-box;
  }

  .dealer_card_container .center-item:nth-last-child(2),
  .dealer_card_container .center-item:nth-last-child(1) {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }
  .dealer_product_card_container {
    height: 370px;
  }
  .dealer_product_card_container p {
    font-size: xx-large;
    text-align: center;
    font-weight: 700;
  }
}

@media (min-width : 1440px){
  
  .dealer_products_header {
    color: white;
    font-weight: 700;
    font-size: xx-large;
  }
  .dealer_card_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .dealer_product_card_wrapper {
    flex: 0 0 calc(33.333% - 90px);
    margin: 10px;
    box-sizing: border-box;
  }

  .dealer_card_container .center-item:nth-last-child(2),
  .dealer_card_container .center-item:nth-last-child(1) {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }
  .dealer_product_card_container {
    height: 370px;
  }
  .dealer_product_card_container p {
    font-size: xx-large;
    text-align: center;
    font-weight: 700;
  }
}
