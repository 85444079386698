.groups-container {
    display: flex;
}

.groups,
.equipments {
    border-right: 2px solid #f6d9bf;
}

.groups-head ,.equipments-head{
font-weight: 600;
margin-bottom: 20px;
padding-left: 10px;
    /* font-size: 1rem; */
}

.equipments-scroll{
    max-height: 57.5vh;
    overflow-y: scroll;
}

.equipments-scroll::-webkit-scrollbar {
    width: 7px;
    margin-left: 5px;
    /* width of the scrollbar */
}

.equipments-scroll::-webkit-scrollbar-thumb {
    background-color: var(--orange);
    /* color of the thumb */
    border-radius: 6px;
    /* roundness of the thumb */
}

.equipments-scroll::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    /* color of the track */
}

.groups,
.equipments,
.selected-equipments {
    flex: 1;
    /* margin: 10px; */
    padding: 0;
}

.equipments .selected {
    background-color: #f6d9bf;
}

.selected-equipments .selected-equipment {
    background-color: #f6d9bf;
}

.group,
.equipment,
.selected-equipment {
    padding: 2px 8px;
    margin: 0px 8px 8px 0px;
    /* background-color: #f8e5d4; */
    border: 1px solid #f6d9bf;
    /* border-radius: 5px; */
    box-shadow: 0px 2px 5px rgb(219, 219, 219);
    cursor: pointer;
    /* text-align: center; */
    position: relative;
}

.group.selected {
    background-color: #f6d9bf;
    font-weight: bold;
}

.group.selected .arrow {
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 50%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #f6d9bf;
}

.save-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #f6d9bf;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button:hover {
    background-color: #f6d9bf;
}

.selected-equipment.active {
    background-color: #d1823a;
    color: white;
  }