.btn-change3 {
    /* background:#d1823a; */
    /* border: 1px solid #d1823a !important; */
    box-shadow: 0 0 1px #ccc;
    -webkit-transition: all 0.5s ease-in-out;
    border: 0px;
    color: #111010;
  }
  
  .btn-change3:hover {
    /* -webkit-transform: scale(1.1); */
    background: #837f7c;
    color: white;
  }

  /* .nearByCarcardWrapper:hover{
 transform: scale(1.05);
 transition: transform ease-out 0.3s;
  } */

  .grid-int-color-b {
    /* backdrop-filter: blur(4px); */
    padding: 5px;
    /* font-size: 0.6em; */
    background-color: rgb(4 4 4 / 47%);
    border-top-right-radius: 5px;
    border-radius: 0 27px 0 0;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    left: 0;
    bottom: 8.1rem;
    color: white;
    padding-right: 1.5rem;
  }