* {
  margin: 0;
  padding: 0;
}
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
}
.header {
  display: flex;
  align-items: center;
  font-size: large;
  padding: 0.5rem;
  font-size: larger;
  font-weight: 500;
  box-shadow: 0px 4px 8px -1px rgb(192, 187, 187);
  margin-bottom: 2rem;
}
.header a {
  color: black;
  flex: 0.5;
}
.header span {
  font-size: large;
}
.dataContainer {
  padding: 0 1rem;
}
.dataHeader {
  display: flex;
  font-size: small;
  font-weight: 500;
  padding: 0 0.3rem;
  border-bottom: 1px solid #d1823a;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem;
}
.subHeader1,
.subHeader2 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.subHeader2 {
  flex: 0.5;
}

.subHeader1 p {
  margin-bottom: 0;
}
.subHeader1 p:first-child span,
.subHeader2 span:first-child {
  color: #d1823a;
}
.salePriceContainer {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 0.8rem 0.4rem;
  border: 1px solid #d1823a;
  border-radius: 10px;
  padding-right: 2rem;
  gap: 1rem;
  margin-bottom: 0.5rem;
}
.salePriceContainer span {
  flex: 1;
  font-weight: 700;
}
.salePriceContainer span:first-child {
  display: grid;
  place-content: center;
}
.salePriceContainer span:last-child {
  border: #d1823a 1px solid;
  text-align: center;
  padding: 0.3rem;
  border-radius: 5px;
  color: #d1823a;
  background-color: #f3d2b591;
  font-weight: 700;
  box-shadow: 3px 3px 3px;
}

.tradeInDifferenceContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.9rem;
  gap: 0.8rem;
  border: 1px solid #d1823a;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}
.tradeInDifferenceContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tradeInDifferenceContainer div span {
  font-weight: 700;
  font-size: 13px;
}
.tradeInDifferenceContainer div input {
  width: 35%;
  background-color: #f3d2b591;
  border: none;
  outline: none;
  padding: 0.2rem;
  box-shadow: 3px 3px 3px;
  border: #d1823a 1px solid;
  color: #d1823a;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
}
.pricTaxContainer {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1rem;
  border: #d1823a 1px solid;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.pricTaxContainer > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pricTaxContainer > div span {
  font-size: 12px;
  font-weight: 700;
}
.pricTaxContainer > div input {
  width: 20%;
  background-color: #f3d2b591;
  border: none;
  outline: none;
  padding: 0.2rem;
  box-shadow: 3px 3px 3px;
  border: #d1823a 1px solid;
  color: #d1823a;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
}
.pricTaxContainer > div input:last-child {
  width: 30%;
}

.pricFeesContainer {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  border: 1px solid #d1823a;
  padding: 0.7rem 0.3rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.pricFeesContainer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricFeesContainer > div span {
  font-weight: 700;
  font-size: 10px;
}
.pricFeesContainer > div input {
  width: 35%;
  background-color: #f3d2b591;
  border: none;
  outline: none;
  padding: 0.2rem;
  box-shadow: 3px 3px 3px;
  border: #d1823a 1px solid;
  color: #d1823a;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
}

@media (min-width: 375px) {
  .subHeader1 p:last-child,
  .subHeader2 span:last-child {
    font-size: 14px;
  }
  .dataHeader {
    padding: 0 0.6rem;
  }
  .pricFeesContainer > div span {
    font-size: 11px;
  }
  .pricFeesContainer {
    padding: 0.7rem;
  }
  .pricTaxContainer > div span {
    font-size: 13px;
  }
}
@media (min-width: 415px) {
  .pricFeesContainer > div span {
    font-size: 13px;
  }
  .tradeInDifferenceContainer div span {
    font-size: 18px;
  }
}
@media (min-width: 550px) {
  .salePriceContainer span:last-child {
    font-size: 21px;
  }
  .subHeader1 p:last-child,
  .subHeader2 span:last-child {
    font-size: 18px;
  }
  .salePriceContainer span:first-child {
    font-size: large;
  }
  .pricFeesContainer > div span {
    font-size: 16px;
  }
  .pricTaxContainer > div span {
    font-size: 15px;
  }
  .pricFeesContainer > div input {
    font-size: 21px;
  }
}
@media (min-width: 750px) {
  .subHeader1 p:last-child,
  .subHeader2 span:last-child {
    font-size: 23px;
  }
  .pricFeesContainer > div span {
    font-size: 20px;
  }
  .tradeInDifferenceContainer div span {
    font-size: 21px;
  }
  .tradeInDifferenceContainer {
     padding: 1rem 2.9rem;
  }
}
