.dnfWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dnfContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-bottom: 11px solid #f2f2f2;
}
.dnfbtnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 21px;
  gap: 0.6rem;
}
.dnfbtnContainer button {
  width: 40%;
  border: none;
  background-color: white;
  padding-bottom: 0.4rem;
  font-weight: 500;
  font-size: small;
}

.dnfFilterWraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  gap: 1.5rem;
  box-shadow: 0px 2px 14px lightgrey;
}
.dnfFilterContainer {
  display: flex;
  flex-direction: column;
  /* border: 1px solid lightgrey; */
  width: 100%;
  border-radius: 10px;
  gap: 1rem;
}
.dnfInputContainer {
  display: flex;
  padding-left: 1rem;

  width: 100%;
  border: 1px solid lightgrey;
  padding: 0.5rem 0.9rem;
  border-radius: 10px;
}
.dnfInputContainer span {
  flex: 0.05;
  text-align: center;
  font-size: 21px;
}
.dnfInputContainer input {
  flex: 1;
  border: none;
  outline: none;
  font-size: small;
  font-weight: 500;
}
.dnfRelativeContainer {
  width: 100%;
  position: relative;
  border: 1px solid lightgrey;
  border-radius: 10px;
  cursor: pointer;
}
.dnfRelativeContainer:hover {
  transition: 0.2s box-shadow ease-in-out;
  box-shadow: 5px 5px 5px lightgrey;
}
.dnfRelativeContainer > p {
  font-size: small;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0.9rem;
  color: #625c5cbf;
  font-weight: 500;
  margin-bottom: 0;
}
.dnfRelativeContainer > p input {
  font-size: small;
  text-align: center;
  display: flex;
  justify-content: space-between;

  color: #625c5cbf;
  font-weight: 500;
  margin-bottom: 0;
}
.dnfSelectAbsoluteContainer {
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: white;
  overflow-y: scroll;
  height: auto;
  max-height: 30vh;
  display: flex;
  flex-direction: column;
  border: 2px solid #d1823a;
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
}
.dnfSelectAbsoluteContainer p {
  width: 100%;
  margin-bottom: 0;
  padding: 0.5rem;
  text-align: center;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
}
.dnfFilterContainer select {
  background-color: white;
  padding-right: 10%;
}
.dnfFilterContainer select option {
  background-color: white;
}
.dnfFilterContainer > select {
  border-bottom: 1px solid lightgrey;
}

.dnfgeoZipContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.dnfgeoZipContainer input {
  border: none;

  color: #625c5cbf;
  font-weight: 500;
  outline: none;
  font-size: small;
  padding: 0;
}
.dnfgeoZipContainer > input {
  /* border-right: 1px solid lightgrey; */
  padding-left: 1rem;
}
.dnfRelativeContainerZip {
  border: 1px solid lightgrey;
  width: 50%;
}
.dnfContinueBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 1rem; */
}
.dnfContinueBtn button {
  border: none;
  background-color: #d1823a;
  color: white;
  padding: 0.4rem 2rem;
  font-size: large;
  font-weight: 500;
  border-radius: 10px;
}
/* Sell Vehicle Style */
.dnfSellVehicleContainer {
  width: 100%;
  padding: 4px;
  border-radius: 5px;
}
.dnfSellVehiclebtnContainer {
  display: flex;
  width: 100%;
  gap: 0.3rem;
  border: 1px solid lightgrey;
  padding: 0.1rem;
  border-radius: 5px;
}
.dnfSellVehiclebtnContainer button {
  flex: 1;
  font-size: 11px;
  background-color: slategrey;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  padding: 0.3rem 0.3rem;
}
.dnfSellContinuebtnContainer {
  display: grid;
  place-content: center;
}
.dnfSellContinuebtnContainer button {
  background-color: #d1823a;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 0.2rem 1.7rem;
  font-weight: 500;
  font-size: large;
}
.dnfSellVehicleContent1 {
  margin: 1rem 0;
}
.dnfSellVehicleContent1 input {
  outline: none;
  border-radius: 5px;
  border: 1px solid lightgrey;
}
.dnfSellVehicleContent2 {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.dnfSellVehicleContent2 input {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 0.3rem !important;
  font-weight: 500;
}
.dnfSellVehicleContent2 select {
  background-color: white;
  padding: 0.35rem;
  border: 1px solid lightgrey;
  color: darkgrey;
  border-radius: 6px;
  margin: 1rem 0;
  font-weight: 500;
}
.dnfSellVehicleContent3 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
}
.dnfSellVehicleContent3Line1 {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0;
}
.dnfSellVehicleContent3Line1 select {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 0.1rem;
  color: darkslategray;
}
.dnfSellVehicleContent3Line1 input {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding-left: 0.4rem !important  ;
  font-size: small;
  padding: 0.4rem;
  font-weight: 500;
  outline: none;
}
.dnfYearMakeContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 375px) {
  .dnfSellVehiclebtnContainer button {
    font-size: 14px;
  }
  .dnfSellVehicleContent1 input {
    padding: 0.3rem;
    font-weight: 500;
  }
  .dnfSellContinuebtnContainer button {
    font-size: larger;
  }
}
@media (min-width: 420px) {
  .dnfSellVehiclebtnContainer button {
    font-size: 16px;
  }
  .dnfSellVehicleContent1 input {
    padding: 0.3rem;
    font-weight: 500;
  }
  .dnfSellContinuebtnContainer button {
    font-size: 20px;
  }
  .dnfbtnContainer button {
    font-size: medium;
  }
}
@media (min-width: 720px) {
  .dnfYearMakeContainer {
    display: flex;
  }
  .forMibileRightBorder {
    border: 1px solid lightgrey;
  }
  .dnfContainer {
    padding: 1rem 7rem;
  }
  .dnfbtnContainer button {
    font-size: larger;
  }
  .dnfFilterWraper {
    padding: 1.5rem;
  }
  .dnfSellVehiclebtnContainer button {
    font-size: 20px;
  }
  .dnfInputContainer input {
    font-size: large;
  }
  .dnfRelativeContainer > p {
    font-size: large;
  }
  .dnfgeoZipContainer input {
    font-size: large;
  }
  .dnfRelativeContainer > p input {
    font-size: large;
  }
  .dnfContinueBtn button {
    padding: 0.4rem 2rem;
    font-size: x-large;
    border-radius: 15px;
  }
  .dnfSellVehiclebtnContainer {
    display: flex;
    width: 100%;
    gap: 0.3rem;
    border: 1px solid lightgrey;
    padding: 0.1rem;
    border-radius: 5px;
  }
  .dnfSellVehiclebtnContainer {
    padding: 0.5rem;
  }
  .dnfSellVehicleContent1 input {
    padding: 0.7rem;
    font-size: larger;
    font-weight: 500;
  }
  .dnfSellVehicleContent2 input {
    padding: 0.5rem !important;
  }
  .dnfSellVehicleContent2 select {
    padding: 0.55rem;
  }
  .dnfSellVehicleContent2 {
    margin: 0.5rem 0;
  }
  .dnfSellVehicleContent2 {
    gap: 1rem;
  }
  .dnfSellContinuebtnContainer button {
    font-size: 23px;
  }
  .dnfSellVehicleContent3Line1 input {
    font-size: 20px;
  }
  .dnfSellVehicleContent3Line1 select {
    font-size: 20px;
    font-weight: 500;
  }
  .dnfSellVehicleContent3 {
    gap: 1rem;
  }
  .dnfSellVehicleContent3Line1 {
    gap: 1rem;
  }
}
@media (min-width: 1024px) {
  .dnfYearMakeContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .dnfbtnContainer button {
    font-size: x-large;
  }
  .dnfInputContainer input {
    font-size: 27px;
  }
  .dnfRelativeContainer > p {
    font-size: 24px;
  }
  .dnfgeoZipContainer input {
    font-size: 24px;
  }
  .dnfRelativeContainer > p input {
    font-size: 24px;
  }
  .dnfSellVehiclebtnContainer {
    padding: 0.5rem 2rem;
  }
  .dnfSellVehiclebtnContainer {
    gap: 1.9rem;
  }
  .dnfSellVehicleContent2 input {
    padding: 0.7rem !important;
  }
  .dnfSellVehicleContent2 select {
    padding: 0.75rem;
  }
  .dnfSellVehicleContent2 {
    gap: 2rem;
  }
  .dnfSellVehicleContent3 {
    gap: 2rem;
  }
}

@media (min-width: 1140px) {
  .dnfFilterWraper {
    width: 55%;
  }
  .dnfbtnContainer button {
    width: 24%;
  }
}
@media (min-width: 1800px) {
  .dnfFilterWraper {
    width: 66%;
  }
  .dnfRelativeContainer > p {
    font-size: 24px;
  }
  .dnfRelativeContainer > p input {
    font-size: 24px;
  }
  .dnfgeoZipContainer input {
    font-size: 24px;
  }
  .dnfInputContainer input {
    font-size: 28px;
  }
  .dnfInputContainer span {
    font-size: 28px;
  }
  .dnfbtnContainer button {
    font-size: 24px;
  }
}
@media (min-width: 2300px) {
  .dnfContainer {
    padding: 1rem 18rem;
  }
}
