.container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  font-family: "Alata", serif;
}
.header {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  width: 55% !important;
}
.inputField {
  padding: 10px;
  height: 35px;
  border: none;
  width: 48% !important;
  padding-left: 35px !important;
  box-shadow: 4px 4px 4px #c9c4c4;
  text-align: left;
  font-weight: 500;
  /* font-size: 40%; */
  background-color: #f8e7d4;
  /* background-color: transparent; */
  border-radius: 10px;
  font-size: 12px !important;
}
.inputFieldDOut {
  width: 39% !important;
}
.inputField:focus {
  outline: none;
  border: none;
}

.container svg {
  /* position: relative;
  left: 70px; */
  /* bottom: 5px; */
}

.inputField::placeholder {
  color: black;
}

.mi_logo {
  /* position: relative;
  left: 70px; */
  /* bottom: 5px; */
}
.absolute_mi_logo {
  position: absolute;
  right: 0;
  background-color: #e8a262;
  color: white;
  height: 100%;
  padding: 0.1rem 0.4rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: grid;
  place-content: center;
  font-size: larger;
  cursor: pointer;
}

.dollar_logo_container {
  position: absolute;
  right: 32%;
  height: 100%;
  padding: 0.5rem;
  display: grid;
  place-content: center;
  font-size: 14px;
  font-weight: 700;
}
.dOutHeaderInfoIcon {
  /* border: 1px solid black; */
  color: black;
  font-size: small;
  cursor: pointer;
}
.dOutUploadIcon {
  color: black;
  font-size: 20px;
  cursor: pointer;
  margin-left: 0.3rem;
}
@media (min-width: 370px) {
  .inputField {
    padding-left: 39px !important;
  }
  .inputFieldDOut {
    width: 41% !important;
  }
}
@media (min-width: 420px) {
  .header {
    font-size: 15px;
  }
  .inputField {
    font-size: 15px !important;
    box-shadow: 4px 4px 4px #c9c4c4;
  }
  .dollar_logo_container {
    right: 33%;
  }
}

@media (min-width: 720px) {
  .header {
    font-size: 18px;
  }
  .inputField {
    font-size: 21px !important;
    width: 45% !important;
    text-align: center;
  }
  .dollar_logo_container {
    right: 33%;
    font-size: larger;
    font-weight: 500;
  }
  .inputFieldDOut {
    width: 41% !important;
  }
}
@media (min-width: 1024px) {
  .header {
    font-size: 15px;
  }

  .inputField {
    font-size: 15px !important;
    width: 48% !important;
    padding-left: 0px !important;
  }

  .dollar_logo_container {
    right: 36%;
    font-size: medium;
  }
  .inputFieldDOut {
    width: 37% !important;
  }
}
@media (min-width: 1440px) {
  .header {
    font-size: 18px;
  }
  .inputField {
    width: 46% !important;
    font-size: 22px !important;
  }
  .inputFieldDOut {
    width: 39% !important;
  }
  .dollar_logo_container {
    right: 38%;
  }
}
@media (min-width: 1800px) {
  .header {
    font-size: 19px;
  }
  
}
