 .wrapper {
   /* border: 2px solid red; */
   display: flex;
   justify-content: center;
   align-items: center;
   margin-left: 1rem;
   transition: all 0.5s ease-in-out;

 }

 .wrapper.expanded {
   width: 100%;
 }

 .wrapper.collapsed {
   width: 93vw;
 }

 .container {
   width: 100% !important;
   /* border: 2px solid #e8a262; */
   border-radius: 3px;
 }

 /* Basic Info styling starts here */
 .infoWrapper {
   display: flex !important;
   justify-content: center;
   align-items: center;
   width: 100% !important;
   height: 36vh;
   /* border: 2px solid gray; */
   border-top-right-radius: 20px;
   border-bottom-right-radius: 20px;
   border-bottom-left-radius: 20px;
   /* padding-top: 1rem; */
   /* gap: 2rem; */
   /* border: 1px solid red; */
 }

 .infoContainer {
   display: flex;
   width: 100%;
   /* border: 2px solid green; */
 }

 .infoInputContainer {
   display: flex;
   /* align-items: stretch; */
   flex: 0.7;
 }

 .infoInputContainer .line1,
 .infoInputContainer .line2,
 .infoInputContainer .line3 {
   flex: 1;
   display: flex;
   justify-content: space-between;
   /* gap: 2rem; */
 }

 .infoImageContainer {
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: 100%;
   padding-left: 4rem;
   padding-bottom: 0.5rem;
   /* position: relative; */
   /* top: -13%; */
 }

 .infoImageContainer div {
   display: flex;
   justify-content: space-around;
   align-items: center;
   /* height: 18%; */
 }

 .infoImageContainer div>span {
   /* border: 2px solid black; */
   /* font-size: large; */
   /* padding-right: 4rem; */
   /* position: absolute; */
   /* background-color: rgba(185, 175, 175, 0.719); */
   display: inline-block;
   /* right: 25%; */
   /* border-radius: 45%; */
   font-weight: 700;
   padding: 2px;
 }

 /* .infoImageContainer div>span>* {
   height: 30px;
   width: 25px;
   color: gray !important;
   cursor: pointer;
 } */

 .icon {
   position: absolute;
   right: 10px;
   top: 15px;
   color: gray;
   width: 30px;
   height: 30px;
   /* padding: 2px; */
   /* outline: white; */
   z-index: 3;
   background: white;
   border-radius: 50%;
   font-variant: stacked-fractions;
   display: flex !important;
   align-items: center;
   justify-content: center;
   /* border: 1px solid white; */
 }

 .uploadIcon {
   position: absolute;
   right: 10px;
   bottom: 2px;
   color: gray;
   width: 30px;
   height: 30px;
   padding: 2px;
   padding-left: 3px;
   outline: white;
   background: white;
   border-radius: 50%;
   font-variant: stacked-fractions;
   display: flex !important;
   align-items: center;
   justify-content: center;
   z-index: 3;
   /* border: 1px solid white; */
 }

 .infoImageContainer .proImg {
   box-sizing: border-box;
   height: 180px;
   width: 100%;
   /* padding: 5%; */
   border-radius: 10px;
   /* height: 250px; */
 }

 /* Permission styling starts here */
 .permissionWrapper {
   /* border: 2px solid blue; */
   display: flex;
   flex-wrap: nowrap !important;
   /* padding: 3rem 0; */
 }

 .permissionWrapper .permissions {
   /* border: 2px solid red; */
   flex: 1;
   display: flex;
   flex-direction: column;
   padding: 1.5rem;
   font-size: larger;
   font-weight: 700;
   /* padding-top: 4rem; */
 }

 .selectTeams {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   /* Create 3 equal columns */
   /* gap: 1rem; */
   margin-left: 1rem;
   margin-top: 1rem;
   padding: 10px;
 }

 /* Hide the default radio button */
 .selectTeams .dealerPermissionRadio {
   appearance: none;
   -webkit-appearance: none;
   width: 30px !important;
   height: 30px !important;
   border: 2px solid #e7a162;
   /* border: 2px solid black; */
   /* Border color when unselected */
   border-radius: 50%;
   outline: none;
   cursor: pointer;
   background-color: #e4b89181;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 /* Style for the selected radio button */
 .dealerPermissionRadio:checked {
   border-color: #e7a162;
   /* Border color when selected */
 }

 /* Create the inner dot */
 .dealerPermissionRadio:checked::before {
   content: "";
   display: block;
   width: 15px !important;
   height: 15px !important;
   margin: 2px;
   border-radius: 50%;
   background-color: #e7a162;
   /* Color of the dot when selected */
 }

 .selectTeams label {
   display: flex;
   margin-bottom: 5px;
   overflow-wrap: anywhere;
 }

 .permissionWrapper .roles {
   /* border: 2px solid black; */
   /* flex: 1; */
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: xx-large;
   font-weight: 800;
 }

 .permissionWrapper .roles p {
   border: 2px solid black;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: xx-large;
   font-weight: 800;
   height: 300px;
   width: 390px;
 }

 /* Notification Styling starts here */
 .NotificationWrapper {
   /* border: 2px solid blue; */
   padding-left: 1rem;
 }

 .NotificationHeader {
   font-size: xx-large;
   font-weight: 700;
 }

 .NotificationContainer {
   /* padding: 0px 6rem; */
   display: flex;
   justify-content: space-between;
   flex-wrap: nowrap;
 }

 .NotificationCard {
   border: 1px solid black;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: .6rem;
   font-size: large;
   font-weight: 600;
   border-radius: 10px;
   margin-top: 1rem;
 }

 .NotificationCard:nth-child(2) {
   /* margin-top: 2rem; */
 }

 .NotificationCard p {
   margin-bottom: 0;
 }

 .NotificationCard p:nth-child(2) {
   /* border: 1px solid black; */
   /* width: 100px; */
   /* padding: 0.3rem; */
   border-radius: 30px !important;
   /* padding-right: 0.5rem; */
 }

 .NotificationCard p:nth-child(2) span:nth-child(1) {
   border: none;
   /* border-right: 1px solid black; */
   padding: 0.2rem 0.7rem;
   background-color: #e7a162;

   /* padding: 0.2rem; */
   border-radius: 10px;
   height: 100%;
   margin-right: 0.5rem;
   color: white;
 }

 /* SecuritySettings styling starts here */
 .NotificationCardContainer {
   padding-left: 2rem;
   /* border: 2px solid black; */
 }

 .toggleContainer {
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .toggleButton {
   position: relative;
   width: 90px;
   height: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #d9534f !important;
   /* Red for "No" */
   border: none;
   border-radius: 30px !important;
   color: white;
   font-weight: bold;
   transition: all 0.3s ease;
   cursor: pointer;
 }

 .toggleButton.toggled {
   background-color: #e7a162 !important;
 }

 .toggleButton::before {
   content: '';
   position: absolute;
   top: 7px;
   left: 5px;
   width: 21px;
   height: 21px;
   background-color: white;
   border-radius: inherit;
   transition: all 0.3s ease;
 }

 .toggleButton.toggled::before {
   left: calc(100% - 26px);
 }




 .Securitycontainer {
   /* border: 2px solid red; */
   padding-left: 2rem;
   padding-right: 6rem;
 }

 .SecurityHeader {
   font-size: xx-large;
   font-weight: 700;
 }

 .faHeader {
   font-size: x-large;
   font-weight: 600;
   padding-left: 4rem;
 }

 .faItems {
   /* border: 2px solid blue; */
   padding-left: 10rem;
   display: inline-flex;
   flex-direction: column;
 }

 .faItems label {
   border: 1px solid #e7a162;
   padding: 0.8rem 2rem;
   font-size: larger;
   font-weight: 500;
   display: flex;
   margin-bottom: 1rem;
   border-radius: 10px;
 }

 .faItems label input {
   margin-right: 1rem;
 }

 .faItems .faRadio {
   appearance: none;
   -webkit-appearance: none;
   width: 30px;
   height: 30px;
   border: 2px solid #e7a162;
   /* Border color when unselected */
   border-radius: 15%;
   outline: none;
   cursor: pointer;
   background-color: #e4b89181;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 /* Style for the selected radio button */
 .faRadio:checked {
   border-color: #e7a162;
   /* Border color when selected */
 }

 /* Create the inner dot */
 .faRadio:checked::before {
   content: "";
   display: block;
   width: 15px;
   height: 15px;
   margin: 2px;
   border-radius: 50%;
   background-color: #e7a162;
   /* Color of the dot when selected */
 }

 .NotificationCard .viewContainer {
   border: 1px solid black;
   /* padding: 0.3rem; */
   border-radius: 10px;
   /* padding-right: 0.5rem; */
   padding-right: 0 !important;
 }

 .NotificationCard .viewContainer span {
   border: none;
   padding: 0.2rem 0.7rem;
   background-color: #e7a162;

   border-radius: 10px;
   height: 100%;
   margin-right: 0 !important;
   color: white;
 }

 .linkOrange {
   border-width: 0px;
   color: white;
   font-weight: 700;
   background-color: #E8A262 !important;
   text-decoration: none;
   padding: 10px 20px;
   border-radius: 5px;
 }

 .linkOrange:hover {
   background-color: lightgray !important;
   color: black;
   box-shadow: 5px 5px 5px rgb(173, 173, 173) !important;
 }

 .error {
   color: red;
   /* Set the text color to red */
   margin-top: 0.5rem;
   /* Optional: Add some spacing above */
   font-size: 0.875rem;
   /* Optional: Adjust font size */
 }

 .spinner {
   border: 4px solid rgba(0, 0, 0, 0.1);
   border-radius: 50%;
   border-top: 4px solid #3498db;
   width: 24px;
   height: 24px;
   animation: spin 1s linear infinite;
 }

 @keyframes spin {
   0% {
     transform: rotate(0deg);
   }

   100% {
     transform: rotate(360deg);
   }
 }

 .profile {
  width: 35%;
}