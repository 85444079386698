.wrapper {
  width: 100%;
  font-family: Alata;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container header {
  font-size: 26px;
  color: #d1823a;
  font-weight: 700;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 1rem;
}
.contactbtnContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 0.5rem;
  width: 80%;
  justify-content: center;
  font-size: 20px;
}
.contactbtnContainer button:first-child {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  font-weight: 500;
  font-size: 21px;
}
.contactbtnContainer button:last-child {
  background-color: black;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 0.3rem 0.5rem;
  font-weight: 500;
}
.offerContent {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.offerContent .offerInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 0.6rem;
  margin-bottom: 0rem;
}
.offerInputContainer > input {
  border: 1px solid grey;
  outline: none;
  background-color: rgb(241, 239, 239);
  border-radius: 10px;
  padding-left: 0.5rem !important;
}
.offerInputContainer > input::placeholder {
  padding-left: 0.2rem;
  font-weight: 500;
  color: lightgrey;
}

.offeringData {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 22px;
  font-weight: 500;
  padding: 0.6rem;
  text-align: center;
  margin-bottom: 3rem;
}
.offeringData > input {
  border: 1px solid grey;
  outline: none;
  background-color: rgb(241, 239, 239);
  border-radius: 10px;
  padding-left: 0.5rem !important;
  color: black;
  font-weight: 500;
}
.agreeSubmitContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
}
.agreeSubmitContainer label {
  font-size: 14;
  font-weight: 500;
  margin-bottom: 0.5rem;
  padding: 0.6rem;
}
.agreeSubmitContainer label > input {
  margin-right: 0.3rem;
  appearance: none;
  height: 20px;
  width: 20px;
  border: 1px solid black;
  position: relative;
  margin-bottom: -0.2rem;
}
.agreeSubmitContainer label > input:checked::before {
  content: "\2713";
  color: #d1823a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.agreeSubmitContainer > button {
  background-color: #d1823a;
  border: none;
  color: white;
  padding: 0.5rem;
  font-size: 25px;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media (min-width: 1000px) {
  .agreeSubmitContainer > button {
    position: static;
    width: 23%;
    box-shadow: 4px 4px 4px lightgrey;
    border-radius: 10px;
  }
  .agreeSubmitContainer {
    width: 100%;
    flex-direction: row;
  }
  .agreeSubmitContainer label {
    font-size: 18px;
  }
  .container header {
    display: none;
  }
  .offerContent .offerInputContainer {
    margin-bottom: 0.8rem;
  }
  .contactbtnContainer {
    padding: 0.4rem 1rem;
    font-size: 25px;
    gap: 1.3rem;
  }
  .offerInputContainer > input {
    background-color: white;
    box-shadow: 4px 4px 4px lightgrey;
    padding: 0.5rem;
  }

  .offerContent .offerInputContainer {
    gap: 1.8rem;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 1rem;
  }
  .offeringData > input {
    background-color: white;
    box-shadow: 4px 4px 4px lightgrey;
    width: 50% !important;
    padding: 0.5rem;
  }
  .offeringData {
    align-items: center;
  }
  .offerContent,
  .offeringData {
    font-weight: 700;
  }
  .contactbtnContainer button:first-child {
    font-weight: 700;
  }
  .contactbtnContainer button:last-child {
    font-weight: 700;
    padding: 0.3rem 1.5rem;
  }
}
