/* .container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
} */

.formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    background-color: #fce8cf;
}

.formGroup>* {
    background-color: #fce8cf !important;
    padding: 7px !important;
    border: none !important;
}

.formGroup label {
    width: 35%;
    margin: 0px 10px;
}

.formGroup input:focus{
    box-shadow: none;
}

.inlineFields {
    display: flex;
    /* gap: 10px; */
    width: 100%;
}

.inlineFields input {
    flex: 1;
    padding: 0px !important;
    border: none;
    background-color: #fce8cf !important;
}

.idType {
    display: flex;
    padding: 0px !important;
    width: 100%;

}

.idType button {
    width: 40px;
    padding: 7px !important;
    border-radius: 0px;
}

.idType input {
    flex: 1;
    padding: 0px !important;
    border: none;
    background-color: #fce8cf !important;
}