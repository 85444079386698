/* Footer Styles */
footer {
  background-color: #212529;
  color: #ffffff;
  padding-top: 4rem;
}

footer a {
  color: #ffffff; /* Change link color */
}

footer a:hover {
  color: #ffc107; /* Change link hover color */
}

footer img {
  max-width: 100%;
  height: auto;
  /* height: 20px; */
}

.text-reset {
  text-decoration: none; /* remove Underline */
}
/* icons icon */
i {
  font-size: 15px;
}
.fab {
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 6px;
  border: solid 1px rgb(215, 236, 215);
  padding-top: 2px;
  margin: 0 18px 20px 0;
  text-align: center;
}

/* Copyright Section */
footer section:last-child {
  background-color: rgba(0, 0, 0, 0.05);
}

footer section:last-child p {
  margin-bottom: 0; /* Remove default margin */
}

.mobileLinksParent1 a {
  border-bottom: 1px solid black;
  font-size: large;
  font-weight: 500;
}
.mobileLinksParent1Header {
  font-size: x-large;
  color: #c57a40;
}
.mobileLinksParent2 {
  display: none;
}
.footerBase {
  color: black;
  font-size: small;
  text-align: center;
  font-weight: 500;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .mobileLinksParent1 {
    flex: 1 !important;
  }
  .mobileLinksParent2 {
    display: block;
    flex: 1 !important;
  }
  .mobileLinksParent2 a {
    font-size: large;
    font-weight: 500;
    color: black !important;
  }
  .mobileLinksParent1 .mobileLinksParent1Header {
    text-transform: uppercase;
    height: 3rem;
    margin-bottom: 1.5rem;
  }

  .mobileLinksParent1 a {
    border-bottom: 0;
  }
  .mobileLinksParent1Header {
    height: 3rem;
  }
  .footerBaseParent {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .footerBase {
    margin-bottom: 1.3rem;
    font-size: medium;
    width: 100% !important;
  }
}
@media (min-width: 1024px) {
  .mobileLinksParent1Header {
    height: 1.5rem;
  }
  .mobileLinksParent1 .mobileLinksParent1Header {
    height: 2.1rem;
    margin-bottom: 1rem;
  }
  .flexParent {
    padding-left: 5rem !important;
  }
}

@media (min-width: 1440px) {
  .mobileLinksParent2 a,
  .mobileLinksParent1 a {
    font-size: large;
  }
  .flexParent {
    padding-left: 10rem !important;
  }
  .footerBase {
    font-size: large;
  }
}

/* Media Query for Responsive Design */
@media (max-width: 767px) {
  footer section {
    /* text-align: center; Center align content for small screens */
  }

  footer section:last-child .col-lg-6 {
    float: none; /* Remove float for small screens */
    text-align: center; /* Center align content for small screens */
    margin-top: 1rem; /* Add some space at the top for better spacing */
  }

  footer img {
    margin-top: 1rem; /* Add some space at the top for better spacing */
  }
}

/*--------bottom to top start--------------*/
.myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #c57a40;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.myBtn:hover {
  background-color: #000;
}

.logo_home_footer a {
  font-size: 26px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 42px;
}

.logo_home_footer a span {
  margin-right: 0.5rem;
}

.logo_home_footer a span:nth-child(1), /* DEALERS */
.logo_home_footer a span:nth-child(3) {
  /* CENTER */
  color: #d1823a; /* Orange color */
}

.logo_home_footer a span:nth-child(2) {
  /* AUTO */
  color: #ffffff; /* White color */
}

/*---------------end-------------*/
