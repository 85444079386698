/* .container-car-details{
    margin: 20px;
  }                                            */

/*--------------------------2024 Ford Focus Start-----------------------*/
.product-detail-sideBox {
  /* background-color: lightgrey; */
  padding: 5px 15px;
  box-shadow: 0px 2px 2px 2px #b8b8b8;
  border-radius: 12px;
  height: 67%;
}

.product-detail-sideBox-cal {
  background-color: #c77f20;
  padding: 5px 15px;
  box-shadow: 0px 2px 2px 2px #b8b8b8;
  border-radius: 12px;
  height: 79%;
}

.product-detail-sideBox-b {
  background-color: white;
  padding: 5px 15px;
  box-shadow: 0px 2px 2px 2px #b8b8b8;
  border-radius: 12px;
  /* height: 35%; */
}

.product-detail-boxes-btn {
  background: #d1823a;
  color: white;
  border: none;
  /* border-radius: 10px; */
  padding: 2px 32px;
}

.product-detail-boxes {
  border: 1px solid #e7a9702e;
  padding: 1px;
  height: 27px;
  background: #e7a9702e;
  color: #d1823a;
  font-weight: 700;
}

.product-detail-boxess {
  border: 1px solid #e7a9702e;
  padding: 1px;
  /* border-radius: 10px; */
  background: #2221202e;
  color: #ffff;
  font-weight: 700;
}

.product-detail-boxeMils {
  border: 2px solid #e7a9702e;
  font-weight: 700;
}

.theme-color {
  color: #0a0a0a;
}

.theme-colors {
  color: #ee831e;
}

.product-detail-boxes-btn2 {
  background: #322f4d;
  border: none;
  padding: 3px 6px;
  width: 100%;
  color: white;
  /* border-radius: 10px; */
  /* box-shadow: 0px 2px 4px 1px #797979; */
}

.product-detail-boxes-content span {
  font-size: 15px;
  /* font-weight: bold; */
}

.product-detail-boxes-btn3 {
  background: #62b260;
  padding: 2px;
  /* border-radius: 10px; */
  text-align: center;
}

.product-detail-boxes-btn3 a {
  text-decoration: none;
  color: white;
}

.product-detail-boxes-btn4 {
  background: #d1823a;
  color: white;
  padding: 2px;
  border-radius: 5px;
  text-align: center;
}

.product-detail-boxes-btn4:hover {
  background-color: lightgray;
  color: black !important;
}

.product-detail-boxes-btn4 a {
  text-decoration: none;
  color: inherit;
  /* Inherit color from parent */
}

.product-detail-boxes-btn4:hover a {
  color: black !important;
}

.product-detail-boxes-btn4 a:hover {
  color: black !important;
}

/*--------------------------2024 Ford Focus End-----------------------*/

/* -----------------------------Get in Touch With United Motors Start----------------------------- */

.product-detail-sideBoxs {
  padding: 18px 15px;
  box-shadow: 0 2px 2px 2px #dfdfdf;
  /* border-radius: 12px; */
  /* width:100px; */
  margin: 0 20px 0 88px;
  /* height: 500px; */
}
.offerPopoutHeader {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #d1823a;
}

@media screen and (min-width: 768px) {
  .product-detail-sideBoxs {
    padding: 18px 15px;
    box-shadow: 0 2px 2px 2px #dfdfdf;
    /* border-radius: 12px; */
    /* width:100px; */
    /* margin: 0 20px 0 50px; */
    /* height: 500px; */
  }
}

.product-detail-boxes-userImg img {
  border-radius: 50%;
  margin-top: 0px;
}

.bg-liniar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.bg-liniar div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  font-weight: 800;
  font-size: 2rem;
  border-radius: 50%;
  background: linear-gradient(rgb(0, 255, 234), rgb(0, 110, 255));
}

.bg-liniar span {
  font-weight: 700;
  font-size: 1.5rem;
}

.phone-number-card-nav {
  background-color: #39ec39;
  justify-content: center;
  align-items: center;
  color: black;
  width: 70px;
  height: 30px;
  font-size: 20px;
  border-radius: 5px;
  bottom: 33px;
  left: 69px;
  padding-left: 17px;
}

.phone-number-card {
  position: relative;
}

.phone-number-card span {
  background-color: #69b369;
  color: white;
  width: 200px;
  font-size: 20px;
  border-radius: 5px;
  position: absolute;
  padding-left: 17px;
  bottom: 33px;
  left: 69px;
}

.phone-number-card-b {
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-number-card-b span {
  background-color: #69b369;
  color: white;
  font-size: 20px;
  width: 200px;
  border-radius: 5px;
  padding-left: 17px;
  bottom: 33px;
  left: 69px;
}

/* .three-dot {
  padding: 40px;
}

.dots {
  width: 25%;
  border: 1px solid #c5c5c5;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 16px;
  box-shadow: 0 1px 3px 2px #c5c5c5;
}

.three-dot .dots .dot {
  width: 8px;
  display: inline-block;
  height: 8px;
  background: #d1823a;
  border-radius: 50%;
} */

/* -----------------------------Get in Touch With United Motors End----------------------------- */

/* background: '#dddddd', border: '1px solid #dddddd', padding: '8px', textAlign: 'center', borderRadius: '10px', boxShadow: '1px 1px 3px 1px #999999' */

/* .details input {
  width: 100%;
} */

/* .detail {
  display: flex;
  justify-content: space-between;
} */
/*----------------Ship From and image Start--------------------*/
.product-detail-boxes-btn5 {
  background: #00000000;
  border-radius: 10px;
  padding: 7px 10px;
  border: 2px solid #d1823a !important;
}

.product-detail-boxesZIp {
  border: 1px solid #c9c9c9;
  padding: 25px 0px 15px 80px;
  box-shadow: 0 2px 2px 2px #e6e5e5;
  /* border-radius: 15px; */
}

.imgss img {
  margin-left: 70px;
  width: 100%;
  height: 190px;
}

.imgss img {
  margin-left: 70px;
  width: 100%;
  height: 190px;
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 767.98px) {
  .imgss img {
    margin-left: 57px;
    /* margin-right: 25px; */
    /* margin: 0 0 0 0; */
    margin-top: 45px;
    height: 150px;
    width: 300px;
    /* justify-content: center;
    text-align: center;
    align-items: center; */
  }
}

/*----------------Ship From and image End--------------------*/

/*----------------Image start----------------------*/
.similar-slider {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  /* Hide scrollbar */
  cursor: grab;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.similar-slider::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for WebKit browsers */
}

.similar-slider {
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.similar-arrow {
  border: 1px solid rgba(131, 131, 131, 0.5) !important;
  height: 21%;
  border-radius: 50% !important;
  /* background-color: gray; */
  box-shadow: 4px 4px 4px gray;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px;
  margin: 0px 10px;
}

.similar-arrow:active {
  /* border: none !important; */
  box-shadow: 2px 2px 2px gray;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
}

.similar-car {
  flex: 0 0 auto;
  /* Prevent items from shrinking or growing */
  width: 18%;
  /* Show 5 items at a time (100% / 5 = 20%) */
  margin: 1%;
  /* Margin between items */
}

.col-item {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background: #fff;
}

.col-item-name {
  font-size: 0.9rem;
  font-weight: 600;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.col-item .img-fluid {
  margin: 0 auto;
  width: 100%;
  height: 150px !important;
}

.col-item .car-info {
  display: flex;
  justify-content: space-between;
}

.col-item .photo img {
  margin: 0 auto;
  width: 100%;
}

.col-item .info {
  padding: 10px;
  border-radius: 0 0 5px 5px;
  margin-top: 1px;
}

/*----------------Image End----------------------*/

.costs {
  /* background-color: #f0f0f0;  */
  padding: 114px 0 0 0;
  margin: 10px 0 0 0;
  /* width: 100%;  */
  /* height: 200px;  */
}

.cc {
  color: #333;
}

.rr {
  color: #ff0000;
}

.tt {
  color: #00ff00;
}

/* ----------------------------------------------------------- */
#add-more-show-vehicleOptions {
  text-align: right;
  padding-right: 25%;
}

#add-more-show-vehicleOptions button {
  width: 10%;
  border: none;
  background-color: white;
  height: 30px;
  box-shadow: 0px 0px 15px 13px rgba(0, 0, 0, 0.1);
  color: black;
}

.gallery {
  display: flex;
  align-items: center;
  width: 85%;
}

.large-video-container video {
  width: 51.12vw;
  height: 600px;
  background-color: grey;
  object-fit: fill;
  margin-right: -6px;
}

.large-image-container {
  width: 51.12vw;
  height: 610px;
  margin-right: -7px;
}

.large-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .large-image-container {
    width: 48.5vw !important;
  }

  .large-video-container video {
    width: 48.5 !important;
  }

  .category-image-car-details span {
    justify-content: center;
    gap: 5px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    background-color: #d1823a;
    color: white;
    width: 78.3% !important;
    height: 92px !important;
    font-size: 15px;
  }
}

​ .thumbnail-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid red; */
}

.thumbnails {
  display: flex;
  gap: 17px;
  overflow: hidden;
}

.videos {
  display: flex;
  gap: 17px;
  overflow: hidden;
}

.videos video {
  width: 125px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}

.thumbnail img {
  width: 110px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}

.large-image-container {
  position: relative;
  /* Necessary for absolute positioning of arrows */
  padding-bottom: 5px;
}

.large-video-container {
  position: relative;
  /* Necessary for absolute positioning of arrows */
  /* width: 53.1rem; */
}

.left-arrow-large,
.right-arrow-large {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  /* Ensure arrows are on top of the image */
  background: transparent;
  border: none;
  cursor: pointer;
}

.left-arrow-large {
  left: 10px;
  color: white;
  font-size: 150px;
}

.left-arrow-large:hover {
  font-size: 170px;
}

.right-arrow-large {
  right: 10px;
  color: white;
  font-size: 150px;
}

.right-arrow-large:hover {
  font-size: 170px;
}

.arrow {
  /* background-color: rgb(182, 179, 179);
    border-radius: 38%;
    border: 1px solid rgb(228, 220, 220); */
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  font-size: 2rem;
  cursor: pointer;
}

.left-arrow {
  margin-right: 10px;
  padding-bottom: 6px;
  margin-top: 19px;
}

.right-arrow {
  margin-left: 10px;
  padding-bottom: 6px;
  margin-top: 19px;
}

#data-car-details-img {
  height: 50px;
  width: 50px;
  /* border-radius: 50%; */
  /* border: 1px solid black; */
  margin-top: 15px;
}

#data-car-details-img-check {
  height: 50px;
  width: 50px;
  /* border-radius: 50%; */
  /* border: 1px solid black; */
  margin-top: -2px;
}

#data-car-details-img {
  height: 30px;
  width: 30px;
  /* border-radius: 50%; */
  /* border: 1px solid black; */
  margin-top: -2px;
}

/* ================================================== */
.highlight-section-first {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 50% !important;
  /* justify-content: space-around; */
  /* border-right: 2px solid grey; */
}

#bi-img-vector {
  position: absolute;
  width: 143px;
  /* left: 298px; */
  top: 66px;
  /* border: 1PX solid; */
}

#bi-img {
  position: absolute;
  top: 125px;
  /* left: 277px; */
}

#bi-text {
  position: absolute;
  top: 183px;
  left: 246px;
}

#br-img-vector {
  position: absolute;
  width: 143px;
  /* left: 298px; */
  bottom: 32px;
  /* border: 1PX solid; */
}

#br-img {
  position: absolute;
  bottom: -25px;
  /* left: 277px; */
}

#br-text {
  position: absolute;
  left: 245px;
  bottom: -71px;
}

#fl-img-vector {
  position: absolute;
  width: 143px;
  /* right: 285px; */
  top: 66px;
  /* border: 1PX solid; */
}

#fl-img {
  position: absolute;
  top: 125px;
  /* right: 265px; */
}

#fl-text {
  position: absolute;
  top: 183px;
  right: 245px;
}

#fr-img-vector {
  position: absolute;
  width: 143px;
  /* right: 285px; */
  bottom: 32px;
  /* border: 1PX solid; */
}

#fr-img {
  position: absolute;
  bottom: -25px;
  /* right: 265px; */
}

#fr-text {
  position: absolute;
  bottom: -69px;
  right: 255px;
}

.svg-text-icon {
  height: 20px;
  width: 20px;
}

/* @media (min-width:1000px) and (max-width:1500px) {
    .highlight-img img {
      width: 150px;
      height: 250px;
    }
    #bi-img-vector {
      position: absolute;
      width: 143px;
      left: 298px;
      top: 66px;
    }
  
    #bi-img {
      position: absolute;
      top: 125px;
      left: 277px;
    }
  
    #bi-text {
      position: absolute;
      top: 183px;
      left: 246px;
    }
  
    #br-img-vector {
      position: absolute;
      width: 143px;
      left: 298px;
      bottom: 32px;
    }
  
    #br-img {
      position: absolute;
      bottom: -25px;
      left: 277px;
    }
  
    #br-text {
      position: absolute;
      left: 245px;
      bottom: -71px;
    }
  
    #fl-img-vector {
      position: absolute;
      width: 143px;
      right: 285px;
      top: 66px;
    }
  
    #fl-img {
      position: absolute;
      top: 125px;
      right: 265px;
    }
    #fl-text {
      position: absolute;
      top: 183px;
      right: 245px;
    }
    #fr-img-vector {
      position: absolute;
      width: 143px;
      right: 285px;
      bottom: 32px;
    }
  
    #fr-img {
      position: absolute;
      bottom: -25px;
      right: 265px;
    }
    #fr-text {
      position: absolute;
      bottom: -69px;
      right: 255px;
    }
    .svg-text-icon{
      height: 20px;
      width: 20px;
    }
}​ */
/* ================================================== */

.highlight-img img {
  width: 233px;
  height: 385px;
}

.highlight-img {
  padding-left: 14px;
}

.highlighta-a {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.highlighta-img img {
  height: 50px;
  width: 50px;
}

.highlight-section-second {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.highlight-section-contant {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.highlight-second-text {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 2500px) and (max-width: 3000px) {
  .highlight-section-first {
    width: 35% !important;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2499px) {
  .highlight-section-first {
    width: 40% !important;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1999px) {
  .highlight-section-first {
    width: 50% !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1699px) {
  .highlight-section-first {
    width: 60% !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1499px) {
  .highlight-section-first {
    width: 70% !important;
  }

  #bi-img-vector,
  #br-img-vector {
    width: 120px;
    /* Increase size for larger screens */
  }

  #fl-img-vector,
  #fr-img-vector {
    width: 110px;
    /* Increase size for larger screens */
  }
}

@media screen and (min-width: 1000px) and (max-width: 1299px) {
  .highlight-section-first {
    width: 70% !important;
  }

  #bi-img-vector,
  #br-img-vector {
    width: 120px;
    /* Increase size for larger screens */
  }

  #fl-img-vector,
  #fr-img-vector {
    width: 110px;
    /* Increase size for larger screens */
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .highlight-section-first {
    width: 100% !important;
  }

  #bi-img-vector,
  #br-img-vector {
    width: 100px !important;
  }

  #fl-img-vector,
  #fr-img-vector {
    width: 90px !important;
  }
}

/*----------------------- for calculator------------------ */
.est-monthly-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #dfc19b; */
  width: 92% !important;
  text-align: center;
  margin-left: 4%;
  border-radius: 10px;
}

.btn-car-details button {
  border: none;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 0px 6px 6px -3px rgba(0.1, 0.1, 0, 0.8);
  width: 65px;
  height: 40px;
}

/* .btn-car-details button:hover {
    background-color: grey;
    color: white;
  } */

.txt-btn-car-details button {
  border: none;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 0px 6px 6px -3px rgba(0.1, 0.1, 0, 0.8);
  width: 65px;
  height: 40px;
}

.txt-btn-car-details button p {
  font-size: 10px;
  margin: 0px;
}

.header-style {
  /* text-wrap: nowrap; */
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: grey !important;
  z-index: 999;
  height: 0px;
  width: 100%;
  padding: 10px 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 999;
  height: 86px;
  padding: 10px 0;
  opacity: 0;
  transform: translateY(-100%);
  transform: translateZ(0);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.sticky-nav.visible {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-bottom: 2px soliod grey;
}

.hidden-nav {
  display: none;
}

.title-style {
  margin-left: -8px;
}

.title-style-nav {
  margin-left: 31px;
  font-size: 20px;
}

.price-container-style {
  display: flex;
  justify-content: space-between;
}

.price-style {
  font-size: 26px;
  /* margin-left: 15px; */
}

.monthly-payment-style {
  text-wrap: nowrap;
  font-size: 25px;
  font-weight: bolder;
  color: dark;
}

#nav-text-input input {
  background-color: rgb(235, 231, 231);
  border: none;
  border-radius: 4px;
}

#nav-text-input-b input {
  background-color: rgb(235, 231, 231);
  border: none;
  width: 100%;
  border-radius: 5px;
}

#nav-btn-send button {
  border: none;
  border-radius: 3px;
  color: white;
  background-color: #d1823a;
}

.edit-btn-car-details {
  height: 100px;
  height: 40px;
  display: inline-block;
  float: right;
  /* margin-right: 46px; */
}

.edit-btn-car-details button {
  border: none;
  background-color: #d1823a;
  color: white;
  border-radius: 3px;
  padding: 5px 10px;
}

.edit-btn-car-details button:hover {
  background-color: lightgray;
  color: black !important;
}

.category-image-car-details {
  width: 20%;
  margin-left: -3%;
  margin-top: 0px;
  gap: 1.8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.cat-small-image-container {
  background-color: grey;
  padding: 2%;
  margin-left: 0px;
}

.category-image-car-details span {
  justify-content: center;
  gap: 5px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  background-color: #d1823a;
  color: white;
  width: 162px;
  height: 92px !important;
  font-size: 15px;
}

@media screen and (min-width: 1500px) {
  .category-image-car-details span {
    justify-content: center;
    gap: 5px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    background-color: #d1823a;
    color: white;
    width: 78.2% !important;
    height: 92px !important;
    font-size: 15px;
  }
}

.empty-thumbnail {
  width: 125px;
  height: 100px;
  /* background-color: #f0f0f0; Or any color you prefer */
  /* border: 1px solid black; */
}

/* ------------------------- */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.info-icon {
  font-size: 30px;
  /* Adjust size as needed */
  cursor: pointer;
  margin-left: 5px;
  /* Adjust spacing as needed */
}

.tooltip-text {
  visibility: hidden;
  font-size: 10px;
  width: 250px;
  /* Adjust width as needed */
  background-color: rgb(112, 109, 109);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -125px;
  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* .img-sidebar{
  filter: blur(0.5px);
  -webkit-filter: blur(0.9px);
  } */

.cat-text-blur {
  background-color: rgba(0, 0, 0, 0.3);
  /* Semi-transparent black */
  backdrop-filter: blur(1px);
  /* Adjust the blur intensity */
  padding: 5px;
  /* Optional: Add some padding for better appearance */
  margin-bottom: 0rem !important;
  width: 100%;
  justify-content: center;
}

.cat-text-blur p {
  margin-bottom: 0rem !important;
}

.product_share_like_car_details {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 1rem;
  right: 1%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.product_share_like_car_details span {
  background-color: black;
  border-radius: 55%;
  padding: 0.05rem 0.35rem;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.carDetailInfoList {
  font-size: 1rem;
  font-weight: 700;
  /* margin: 3px 0px; */
  /* line-height: 15px; */
}

.btn-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.checkAvailInput {
  color: black !important;
  background-color: lightgray;
  border-radius: 5px;
  padding: 5px;
  border: none;
  margin: 5px;
  flex: 1;
}

.load_valContainer {
  width: 200px;
  height: 200px;
  /* background-color: white; */
  /* border-radius: 50%; */
  /* padding: 10px; */
  display: flex;
  position: relative !important;
}

.loan_emi {
  width: 100%;
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 28%;
  left: 0px;
}

.cal_input_div input {
  font-weight: bold;
  letter-spacing: 2px;
  padding: 7px !important;
  font-size: 25px !important;
  text-align: center !important;
  box-shadow: 0px 6px 6px -3px rgba(0.1, 0.1, 0, 0.8);
  border: none;
  border-radius: 5px;
}

.percentage-symbol-b {
  position: absolute;
  top: 50%;
  left: 10px;
  font-size: 19px;
  font-weight: bold;
  /* Adjust the distance from the right edge */
  transform: translateY(-50%);
  pointer-events: none;
  /* Make sure the symbol doesn't interfere with input */
  color: #000;
  /* Set the color of the percentage symbol */
}

.label-head {
  font-size: 19px !important;
  font-weight: 700;
}

.tyre_cond_car {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 6px 6px -3px rgba(0.1, 0.1, 0, 0.8);
}

.tyre_cond_circlea {
  width: 10px; /* Adjust the size as needed */
  height: 10px; /* Adjust the size as needed */
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; /* Space between circle and text */
  vertical-align: middle; /* Aligns circle with text */
}
.tyre_cond_circleb {
  width: 10px; /* Adjust the size as needed */
  height: 10px; /* Adjust the size as needed */
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; /* Space between circle and text */
  vertical-align: middle; /* Aligns circle with text */
}
.tyre_cond_circlec {
  width: 10px; /* Adjust the size as needed */
  height: 10px; /* Adjust the size as needed */
  background-color: blue;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; /* Space between circle and text */
  vertical-align: middle; /* Aligns circle with text */
}
.tyre_cond_circled {
  width: 10px; /* Adjust the size as needed */
  height: 10px; /* Adjust the size as needed */
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; /* Space between circle and text */
  vertical-align: middle; /* Aligns circle with text */
}
