.wrapper {
    /* border: none;
    border-top: 2px solid #d1823a;
    border-right: 2px solid #d1823a; */
    padding: .2rem 0;
  } 
  .container {
    /* border: 2px solid red; */
    padding: 0rem 1rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: .3rem;
  }