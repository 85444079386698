@media (max-width: 900px) {
    .priceFeesWrapper {
      flex-direction: column;
    }
    .buyerQuoteInfoParent {
      flex-direction: column;
  
      width: 100%;
    }
    .buyerQuoteInfo {
      display: flex !important;
      flex-direction: column;
      width: 100%;
    }
    .buyerQuoteInfo input {
      width: 50% !important;
    }
    .buyerSaleTypeContainer {
      display: flex;
      flex-direction: row !important;
      align-self: center !important;
      align-items: center;
      gap: 2rem;
      margin-top: 1rem;
    }
    .buyerSaleTypeContainer span {
      font-size: large;
      font-weight: 600;
    }
    .priceFeesContainer input {
      width: 40% !important;
    }
    .buyerQuoteInfo input {
      width: 56%;
    }
    .buyerQuoteInfo {
      padding: 0 1rem;
    }
    .container {
      padding: 1rem 0;
    }
  }
  .wrapper {
    width: 100%;
  }
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .assetSelectContainer1 {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 85%;
    align-items: center;
  }
  .selectItem {
    font-weight: 600;
    font-size: larger;
  }
  .assetSelector {
    display: flex;
    width: 100%;
    flex: 1;
    padding-bottom: 0;
    margin-bottom: 0;
    border-radius: 12px;
    overflow: hidden;
  }
  .assetSelector span:first-child {
    width: 80%;
    height: 100%;
    flex: 1;
    background-color: #fbeadf;
    padding: 0.6rem;
    font-size: large;
    font-weight: 500;
  }
  .assetSelector span:last-child {
    flex: 0.1;
    text-align: center;
    background-color: #d1823a;
    display: grid;
    place-content: center;
    color: white;
    font-weight: 500;
    font-size: 33px;
  }
  .buyerQuoteInfoParent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .buyerQuoteInfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  
    gap: 1rem;
  }
  .buyerPersonalInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* gap: 1rem; */
  }
  .buyerPersonalInfo p {
    font-weight: 600;
    font-size: larger;
  }
  .buyerQuoteInfo input {
    width: auto;
    background-color: #f2be98;
    box-shadow: 0px 5px 3px lightgrey;
    border: none;
    border-radius: 6px;
    overflow: hidden;
    height: 40px;
  }
  .buyerSaleTypeContainer {
    display: flex;
    flex-direction: column;
    align-self: start;
  }
  .saletypeDropDownContinaer {
    display: flex;
    gap: 0.4rem;
    background-color: #d1823a;
    padding: 1.6rem 0.3rem;
    border-radius: 10px;
  }
  .saletypeDropDownContinaer span {
    color: white;
    font-size: larger;
    font-weight: 600;
  }
  .priceFeesContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .priceFeesWrapper {
    display: flex;
    justify-content: center;
    gap: 1rem;
    box-shadow: inset 0 0 10px #e7bb93;
    padding: 1rem;
    border-radius: 20px;
    margin-bottom: 2rem;
  }
  .priceFeesleftContainer,
  .priceFeesRightContainer {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* border: 1px solid blue; */
  }
  .priceFeesleftContainer {
    border: 1px solid #fbeadf;
    padding: 0.3rem 1rem;
    border-radius: 15px;
  }
  .priceFeesContainer p {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
  }
  .priceFeesContainer input {
    width: auto;
    border: 0;
    background-color: #fbeadf;
    outline: 0;
    text-align: center;
    box-shadow: 0px 5px 3px lightgrey;
    border-radius: 10px;
    padding: 0.4rem;
    font-weight: 600;
    font-size: 18px;
    color: #d1823a;
  }
  .priceFeesContainer input:focus {
    border: 0;
    outline: 0;
  }
  .tradeInCheckBox {
    display: flex;
    gap: 1rem;
  }
  .percCalWrapper {
    border-radius: 10px;
    border: 1px solid #fbeadf;
    padding: 1rem;
  }
  .percCalContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
  }
  
  .percCalContainer span {
    font-weight: 500;
    font-size: 14px;
    flex: 0.3;
  }
  .percCalContainer input {
    border: 0;
    background-color: #fbeadf;
    outline: 0;
    text-align: center;
    box-shadow: 0px 5px 3px lightgrey;
    border-radius: 10px;
    padding: 0.4rem;
    font-weight: 600;
    font-size: 18px;
    color: #d1823a;
  }
  .perCalInput {
    flex: 1;
    display: flex;
    gap: 0.5rem;
  }
  .percCalContainer2 {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
  }
  .percCalContainer2 span {
    font-weight: 500;
    font-size: 14px;
    flex: 0.5;
  }
  .percCalContainer2 input {
    border: 0;
    background-color: #fbeadf;
    outline: 0;
    text-align: center;
    box-shadow: 0px 5px 3px lightgrey;
    border-radius: 10px;
    padding: 0.4rem;
    font-weight: 600;
    font-size: 18px;
    color: #d1823a;
    flex: 1;
  }
  .percCalWrapper3 {
    margin: 3rem 0;
  }
  .percCalContainer3 {
    display: flex;
    padding: 1rem;
    align-items: center;
    border: 1px solid #fbeadf;
    border-radius: 10px;
    justify-content: space-around;
  }
  .percCalContainer3 span:first-child {
    font-weight: 500;
    font-size: 14px;
    flex: 0.5;
  }
  .percCalContainer3 span:last-child {
    background-color: #d1823a;
    font-size: xx-large;
    color: white;
    font-weight: 700;
    padding: 1.2rem 3rem;
    text-align: center;
    border-radius: 15px;
  }
  .generateBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .generateBtnContainer button {
    border: none;
    outline: none;
    background-color: #d1823a;
    color: white;
    font-size: xx-large;
    font-weight: 700;
    padding: 1rem 2rem;
    border-radius: 15px;
  }
  .exportContainer {
    border: 3px solid #d1823a;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    border-radius: 10px;
  }
  .exportIndicator {
    position: absolute;
    background-color: white;
    padding: 0.2rem 0.5rem;
    top: -1rem;
    left: 0;
    font-size: large;
  }
  .exportContainer button {
    font-size: larger;
    font-weight: 600;
    border: none;
    outline: none;
    padding: 0.4rem 4.5rem;
    background-color: #d1823a;
    border-radius: 10px;
  }
  
  @media (min-width: 1440px) {
    .container {
      margin-bottom: 2rem;
    }
    .assetSelectContainer1 {
      width: 52%;
      gap: 2rem;
    }
    .buyerQuoteInfoParent {
      justify-content: space-around;
    }
    .buyerQuoteInfo {
      width: 100%;
    }
    .buyerQuoteInfo input {
      width: 100%;
    }
    .container {
      gap: 2rem;
    }
    .saletypeDropDownContinaer {
      gap: 1.4rem;
      padding: 0rem 1.3rem;
    }
    .priceFeesContainer p {
      font-size: 17px;
    }
    .priceFeesContainer input {
      width: 70%;
      font-weight: 700;
      font-size: x-large;
    }
    .percCalContainer span,
    .percCalContainer2 span,
    .percCalContainer3 span:first-child {
      font-weight: 600;
      font-size: 17px;
      flex: 0.3;
    }
  }
  @media (min-width: 1800px) {
    .assetSelector span:first-child {
      padding: 1rem;
    }
    .buyerPersonalInfo p,
    .selectItem {
      font-size: x-large;
    }
    .buyerQuoteInfo {
      gap: 2rem;
    }
    .saletypeDropDownContinaer span {
      font-size: x-large;
    }
  }
  
  .percCalInput {
    display: flex;
    gap: 10px;
  }
  
  .inputWithPercent {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .percentSymbol {
    position: absolute;
    left: 10px;
    font-weight: 600;
    font-size: 18px;
    pointer-events: none; /* Prevents the symbol from interfering with input */
  }
  
  .inputWithPercent input {
    padding-left: 20px; /* Adjust the padding to leave space for the symbol */
  }
  
  .inputWithPercentb{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .percentSymbolb {
    position: absolute;
    left: 7rem;
    font-weight: 600;
    font-size: 18px;
    pointer-events: none; /* Prevents the symbol from interfering with input */
  }
  
  .inputWithPercentc {
    position: relative;
    display: inline-flex;
    align-items: center;
    flex: 1;
  }
  
  .percentSymbolc {
    position: absolute;
    left: 10px;
    pointer-events: none; /* Prevents the symbol from interfering with input */
  }
  
  .buyer_btn_ls{
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .buyer_btn_ls button{
    color: white;
    border-radius: 5px;
    background-color: #d1823a;
    padding: 0.5rem 0.9rem;
    width: 147px;
    font-size: 13px;
    border: none;
    height: 40px;
    /* width: auto; */
    font-weight: 500;
  }

  .buyer_btn_ls button:hover{
    background-color: lightgray !important;
    color: black !important;
  }