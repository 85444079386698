.wrapper {
  border: 2px solid orange;
  margin-left: 1rem;
  margin-top: 0rem;
  /* position: relative; */
}

.wrapper {
  transition: width ease-in-out 0.5s;
}

.MiniChatBoxContainer {
  display: flex;
  position: fixed;
  flex-direction: row-reverse;
  /* Change to row-reverse */
  bottom: 0;
  gap: 10px;
  right: 13vw;
  /* border: 2px solid red; */
}

.activeProCon {
  width: 10vw;
  position: relative;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  /* overflow-y: scroll; */
}

.activePros {
  width: 70px;
  height: 70px;
  padding: 10px;
  /* height: 50%; */
}

.activePros img {
  height: 100%;
  width: 100%;
  border: 1px solid;
  border-radius: 50%;
}

@media (min-width: 720px) {
  .wrapper.content_expanded {
    min-width: 65vw;
    max-width: 65vw;
  }

  .wrapper.content_collapsed {
    min-width: 84vw;
    max-width: 84vw;
  }

}

@media (min-width: 1024px) {
  .wrapper {
    margin-top: 1rem;
  }

  .wrapper.content_expanded {
    min-width: 72vw;
    max-width: 72vw;
  }

  .wrapper.content_collapsed {
    min-width: 89vw;
    max-width: 89vw;
  }

}

@media (min-width: 1440px) {
  .wrapper {
    margin-top: 1.4rem;
    position: relative;
  }

  .wrapper.content_expanded {
    min-width: 81vw;
    max-width: 81vw;
  }

  .wrapper.content_collapsed {
    min-width: 92vw;
    max-width: 92vw;
  }
}

@media (min-width: 1440px) {
  .wrapper {
    margin-top: 1.4rem;
  }

  .wrapper.content_expanded {
    min-width: 81vw;
    max-width: 81vw;
  }

  .wrapper.content_collapsed {
    min-width: 92vw;
    max-width: 92vw;
  }
}