@media (min-width: 1024px) {
  .fastParent {
    padding: 0 1rem;
  }
}
@media (min-width: 1440px) {
  .fastParent {
    /* padding: 0 5rem; */
  }
}
