.priceFeesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.priceFeesTable {
  width: 100%;
}
.priceFeesRow {
  display: flex;
  font-weight: 500;
  width: 100%;
  font-size: 12px;
  align-items: center;
  /* padding: 0 1.2rem; */
}
.priceFeesRowOne > .priceFeesRow:first-child,
.priceFeesSecondBlock {
  border: #d1823a 1px solid;
  border-radius: 10px;
  padding: 0.7rem;
  margin-bottom: 1rem;
}
.priceFeesRowOne > .priceFeesRow {
  padding: 0.5rem 1rem;
}
.priceFeesRowOne > .priceFeesRow p {
  margin-bottom: 0;
}
.priceFeesSecondBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}
.priceFeesSecondBlock > .priceFeesRow p {
  margin-bottom: 0;
}
/* .priceFeesRowOne > .priceFeesRow:first-child,
  .priceFeesRowOne > .priceFeesRow:nth-child(3),
  .dynamicLabels {
    border-bottom: 3px solid lightgray;
    margin-bottom: 1rem;
  } */

.priceFeesRowOne > .priceFeesRow .priceFeesKey {
  font-size: medium;
  font-weight: 500;
}
.priceFeesRowOne.priceFeesRow:first-child .priceFeesValue {
  font-size: large;
  font-weight: 600;
  width: 35%;
  border: #d1823a 1px solid;
}
.priceFeesRow > .priceFeesKey {
  width: 85%;
  font-weight: 500;
}
.priceFeesRow > .priceFeesValue {
  border-radius: 12px;
  width: 31%;
  text-align: center;
  padding: 0.2rem;
  background-color: rgb(243, 223, 196);
  box-shadow: 2px 3px 7px grey;
  color: #d1823a;
  font-weight: 700;
}
.priceFeesValue.percValue {
  margin-right: 0.3rem;
  width: 50%;
}

.priceFeesRowOne {
  flex: 0.4;
  /* padding-top: 1rem; */
}
.priceFeesDynamcRowContainer {
  border: #d1823a 1px solid;

  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  grid-auto-flow: column dense;
  padding: 0.5rem;
}
.priceFeesDynamcRowContainer > .priceFeesRow p {
  margin-bottom: 0;
  font-size: 11px;
}
.priceFeesDynamcRowContainer > .priceFeesRow {
  grid-column: 1;
}

.priceFeesDynamcRowContainer > .priceFeesRow:nth-child(n + 11) {
  grid-column: 2;
}

.priceFeesRowTwo {
  flex: 1;
}

/* .priceFeesRowOne .priceFeesRow:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  } */

.priceFeesRowTwo .priceFeesRow:nth-last-child(2) {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (min-width: 420px) {
  .priceFeesRow > .priceFeesKey {
    font-size: medium;
  }
  .priceFeesRow {
    font-size: 15px;
  }
}
@media (min-width: 720px) {
  /* .priceFeesRowOne > .priceFeesRow:first-child .priceFeesKey,
  .priceFeesRowOne.priceFeesRow:first-child .priceFeesValue {
    font-size: 26px;
  } */
  .priceFeesRow {
    font-size: 20px;
  }
  .priceFeesValue.percValue {
    margin-right: 0.3rem;
    width: 35%;
  }
  .priceFeesRow > .priceFeesKey {
    font-size: 20px;
  }
}
@media (min-width: 1440px) {
  .priceFeesTable {
    display: flex;
    padding: 0.5rem;
    gap: 1rem;
   
  }
  .priceFeesRow > .priceFeesKey {
    font-size: medium;
    font-weight: 500;
  }
  .priceFeesRowOne {
    flex: 0.5;
  }

  .priceFeesRowTwo {
    flex: 0.5;
    /* padding-top: 1rem; */
  }
  .priceFeesRow > .priceFeesValue {
    font-size: small;
    font-weight: 500;
  }
}
