.wrapper {
  width: 100vw;
}
.container {
  width: 100%;
  padding: 0.9rem;
}
.header {
  text-align: center;
  font-size: larger;
  font-weight: 600;
}
.textContainer span {
  font-size: large;
  font-weight: 500;
}
@media (min-width: 720px) {
  .header {
    text-align: center;
    font-size: x-large;
    font-weight: 700;
  }
  .DataContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .textContainer span {
    font-size: larger;
    font-weight: 600;
  }
}
@media (min-width: 1400px) {
  .header {
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
  }
 
  .textContainer span {
    font-size: x-large;
    font-weight: 600;
  }
  .textContainer p {
    font-size: larger;
    font-weight: 600;
  }
}
@media (min-width: 2200px) {
  .header {
   
    font-size: 45px;
    font-weight: 800;
  }
  .DataContainer {
   /* border: 1px solid black; */
   padding: 2rem;
   gap: 2rem;
  }
  .textContainer span {
    font-size: 35px;
    font-weight: 700;
  }
  .textContainer p {
    font-size: xx-large;
    font-weight: 500;
  }
}
