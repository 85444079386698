.draft-cart-main-cointer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* height: auto;
    width:900px; */
    width: 48%;
    border: 2px solid black;
    border-radius: 10px;
    margin-top: 40px;
}

@media only screen and (max-width:600px){
    .draft-cart{
        /* width: 300px;
        height: 300px; */
        margin-top: 20px;
    }
    .draft-cart-main-cointer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* height: auto;
        width:900px; */
        width: 100%;
        border: 2px solid black;
        border-radius: 10px;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px){
    .draft-cart-main-cointer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 172px;
        /* height: auto;
        width:900px; */
        width: 100%;
        border: 2px solid black;
        border-radius: 10px;
    }
}
@media only screen and (min-width:1025px){
    .draft-cart-main-cointer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 172px;
        /* width:900px; */
        margin-left: 100px;
        width: 90%;
        border: 2px solid black;
        border-radius: 10px;
    }
}

.draft-heading{
    display: flex;
    flex-direction: column;
    height: 167px;
    width: 300px;
}

.draft-price{
    height: 167px;
    width: 280px;
}

#draft-button1 button{
    width: 150px;
    border: none;
    color: white;
    background-color: green;
    border-radius: 5px;
}
#draft-button2 button{
    width: 150px;
    border: none;
    color: white;
    background-color: red;
    margin-top: 3px;
    border-radius: 5px;
}

/*---------------------------------------------- code for tool tip -------------------------------------------------*/

/* Default styles for the tooltip */
[data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  
  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
  }
  
  /* Position tooltip above the element */
  [data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: 3px;
    margin-left: -29px;
    padding: 7px;
    width: 100px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    background-color: hsl(29, 64%, 54%);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
  }
  
  /* Triangle hack to make tooltip look like a speech bubble */
  [data-tooltip]:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -1px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid hsl(29, 77%, 54%);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }
  
  /* Show tooltip content on hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }


/*---------------------------------- for next and prev button------------------------- */
  .pagination-controls-draft {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 97%;
    margin-right: -34% !important;
  }
  .pagination-controls-draft button {
    background-color: #d1823a;
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .pagination-controls-draft button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }