.headerContainer {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: x-large;
}
.bodyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 9rem;
  font-family: Alata;
  gap: 0.7rem;
}
.basicDetailsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.basicDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.basicDetails:first-child {
  flex: 0.6;
}
.basicDetails label {
  font-weight: 500;
  color: #8d8787;
}
.basicDetails input,
.basicDetails textarea {
  background-color: #f0cdad;
  outline: none;
  border: none;
  box-shadow: 3px 3px 3px lightgrey;
  border-radius: 7px;
  padding: 0.3rem;
  font-size: 20px;
}
.quoteMetaDataContainer {
  display: flex;
  gap: 1rem;
}
.basicDetailsRelative {
  display: flex;
  flex-direction: column;
}
.basicDetailsRelative label {
  font-weight: 500;
  color: #8d8787;
}
.basicDetailsRelative > div {
  position: relative;
}
.basicDetailsRelative > div > span {
  position: absolute;
  right: 0;
  background-color: #d1823a;
  height: 100%;
  padding: 0.3rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 18px;
  color: white;
  cursor: pointer;
}
.basicDetailsRelative > div > input {
  background-color: #f0cdad;
  outline: none;
  border: none;
  box-shadow: 3px 3px 3px lightgrey;
  border-radius: 7px;
  padding: 0.3rem;
  font-size: 20px;
}
.saveBtnContainer {
  display: flex;
  justify-content: end;
  border-bottom: 1px solid lightgrey;
  padding: 1rem;
}
.saveBtnContainer button {
  background-color: #d1823a;
  border: none;
  color: white;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  box-shadow: 3px 3px 3px lightgrey;
}
.buildPopoutTable {
  width: 100%;
}
.buildPopoutTable thead {
  width: 100%;
}

.buildPopoutTable thead > tr > * {
  background-color: #d1823a;
  border-right: 1px solid white;
  text-align: center;
  color: white;
  padding: 0.3rem;
  font-weight: 400;
  flex: 1;
}

.buildPopoutTable thead > tr > th:first-child {
  border-top-left-radius: 7px;
}
.buildPopoutTable thead > tr > th:last-child {
  border-top-right-radius: 7px;
}
.buildPopoutTable tbody > tr > td {
  width: auto;
  text-align: center;
  background-color: antiquewhite;
  padding: 0.3rem 0;
  border-left: 1px solid white;
  border-top: 1px solid white;
  font-weight: 500;
}
.buildPopoutTable tbody > tr:nth-child(2n ) > * {
  background-color: rgb(222 216 216);
  border-right: 1px solid white;
  text-align: center;
  color: black;
  padding: 0.3rem;
  font-weight: 400;
  flex: 1;
}
.buildPopoutTable tbody > tr > td > button {
  border: none;
  background-color: #d1823a;
  border-radius: 7px;
  color: white;
  padding: 0.3rem 0.7rem;
}
.buildPopoutTable tbody > tr > td > input {
  appearance: none;
  height: 15px;
  width: 15px;
  position: relative;
  border: 2px solid rgb(151, 147, 147);
  border-radius: 5px;
  margin-right: 0.3rem;
}

.buildPopoutTable tbody > tr > td > input:checked::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d1823a;
}
.buildPopoutTable .hidden > td{
  color: transparent !important;
}
.buildPopoutTable .hidden button {
  display: none;
}
.buildPopoutTable .hidden:last-child > td {
  background-color: #d1823a;
  border: none;
}
.buildPopoutTable .hidden:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}
.buildPopoutTable .hidden:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}
.buildTableDatAction {
  display: flex;
  justify-content: space-between;
}
.buildTableDatAction button {
  border: none;
  background-color: maroon;
  border-radius: 7px;
  color: white;
  padding: 0.3rem 0.7rem;
  box-shadow: 3px 3px 3px lightgrey;
}
.buildTableDatAction p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex: 0.2;
}
.buildTableDatAction p > span:first-child {
  background-color: #d1823a;
  flex: 0.5;
  padding: 0.4rem;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  color: white;
  border-right: 1px solid white;
}
.buildTableDatAction p > span:last-child {
  background-color: #ecbd91;
  padding: 0.4rem;
  flex: 1;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  text-align: center;
}

.genbtnContainer {
  display: flex;
  justify-content: center;
}
.genbtnContainer > button {
  border: none;
  background-color: #d1823a;
  border-radius: 7px;
  color: white;
  padding: 0.3rem 0.7rem;
  box-shadow: 3px 3px 3px lightgrey;
  font-size: 23px;
  width: 45%;
}
