.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container .iphoneImage {
  height: 100%;
  width: 100%;
}
.container .dynamicIsland {
  position: absolute;
  height: 50px;
  top: 7px;
}
.container .iframeContainer {
  position: absolute;
  width: 90.5%;
  height: 96%;
  top: 0.8rem;
  border-radius: 33px;
  border: 1px solid black;
  overflow: hidden;
}
.container .iframeContainer iframe {
  width: 106%;
  height: 102%;
}

.container iframe::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.container iframe {
  -ms-overflow-style: none; /* For IE and Edge */
  scrollbar-width: none; /* For Firefox */
}

@media (min-width: 420px) {
  .container .iframeContainer {
    border-radius: 42px;
    top: 1rem;
  }
  .container .dynamicIsland {
    height: 66px;
    top: 15px;
  }
}
@media (min-width: 720px) {
  .container {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .container {
    width: 38%;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 27%;
  }
}
@media (min-width: 1800px) {
  .container {
    width: 21%;
  }
}
