.wrapper {
    /* border: 2px solid blue; */

    /* margin-left: 1rem; */
    transition: all ease-out 0.1s;
}

.wrapper.content_expanded {
    /* min-width: 85vw;
    max-width: 85vw; */
    /* border: 1px solid red !important; */
}

.wrapper.content_collapsed {
    min-width: 93vw;
    max-width: 93vw;
}

.dealer_business_information_container {
    /* border : 2px solid black; */
    width: 100%;
}

.content_expanded {
    /* min-width: 85vw;
    max-width: 85vw; */
}

.btnOrange {
    border-width: 0px;
    background-color: #E8A262 !important;
}

.btnOrange:hover {
    color: black !important;
    background-color: lightgray !important;
}

.linkOrange {
    border-width: 0px;
    color: white;
    font-weight: 700;
    background-color: #E8A262 !important;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
}

.linkOrange:hover {
    background-color: lightgray !important;
    color: black;
    box-shadow: 5px 5px 5px rgb(173, 173, 173) !important;
}

.profile {
    width: 35%;
}

.dropOrange button {
    border-width: 0px;
    background-color: #E8A262 !important;
    /* text-decoration: none;
    padding: 10px 20px; */
}

.rowIcon,
.editIcon,
.deleteIcon,
.restore {
    cursor: pointer;
    height: 30px !important;
    width: auto;
}

.editIcon {
    background-color: #e8a36281;
}

.deleteIcon {
    background-color: rgba(255, 0, 0, 0.5);
}

.restore {
    background-color: rgba(0, 128, 0, 0.5);
}

.activeBtn {
    padding: 4px 15px;
    background-color: rgba(0, 128, 0, 0.5);
    /* border: 1px solid rgba(0, 128, 0, 0.7); */
    border-radius: 20px;
    cursor: pointer;
}

.activeBtn:hover {
    /* background-color: rgba(0, 128, 0, 0.5);
    border: 1px solid rgba(0, 128, 0, 0.7); */
    background-color: rgb(52 129 52 / 62%);
    cursor: pointer;
    /* border: 1px solid rgb(43 124 43 / 70%); */
}

.inactiveBtn {
    padding: 4px 15px;
    background-color: rgba(255, 0, 0, 0.5);
    /* border: 1px solid rgba(255, 0, 0, 0.7); */
    cursor: pointer;
    border-radius: 20px;
}

.inactiveBtn:hover {
    /* background-color: rgba(255, 0, 0, 0.5);
    border: 1px solid rgba(255, 0, 0, 0.7); */
    background-color: rgb(215 19 19 / 62%);
    cursor: pointer;
    /* border: 1px solid rgb(179 25 25 / 70%); */
}

.active {
    color: green;
    font-weight: 600;
    /* padding: 10px; */
    /* background-color: rgba(0, 128, 0, 0.5); */
    /* border: rgba(0, 128, 0, 0.7); */
}

.inactive {
    color: red;
    font-weight: 600;
    /* padding: 10px; */
    /* background-color: rgba(255, 0, 0, 0.5); */
    /* border: rgba(255, 0, 0, 0.7); */
}


.createUserBtn svg{
    color: #E8A262;
    font-weight: 900;
}
.createUserBtn svg:hover{
    color: grey;
    font-weight: 900;
}