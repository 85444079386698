.wrapper {
  width: 100%;
  /* border: 1px solid blue; */
  margin-bottom: 3rem;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container .leftContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.leftContainer .leftContainerPartOne {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
/* Sale Price Style  */
.salePriceWrapper {
  width: 100%;
  background-color: white;
  display: grid;
  border-radius: 10px;
}
.salePriceContainer {
  width: 100%;
  position: relative;
  border: 1px solid #d1823a;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0.5rem;
  align-items: center;
}
.salePriceContainer input {
  background-color: white;
  border: none;
  outline: none;

  width: 60%;
  border-radius: 10px;
  box-shadow: inset 0 0 20px #d1823a;
  padding: 0.2rem;
}
.salePriceContainer .fieldContainerLabel {
  position: absolute;
  top: -1rem;
  top: -0.85rem;
  font-weight: 500;
  color: #d1823a;
  background-color: white;
}
.salePriceContainer > p {
  margin-bottom: 0;
  font-weight: 500;
}
/* Down Container */
.totalDownWrapper {
  width: 100%;
  display: grid;
  border-radius: 10px;
}
.totalDownContainer {
  width: 100%;
  position: relative;
  border: 1px solid #d1823a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0.5rem 0.7rem;
  align-items: center;
  gap: 0.3rem;
}
.totalDownWrapperRelative {
  position: relative;
}
.totalDownContainer .fieldContainerLabel {
  position: absolute;
  top: -1rem;
  top: -0.8rem;
  font-weight: 500;
  color: #d1823a;
  background-color: white;
  left: 7px;
}
.downContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.downContainer > .threeDotsIconContainer {
  position: absolute;
  right: 0;
  width: 10% !important;
  border: none;
  top: 0.2rem;
  cursor: pointer;
}
.downContainer input {
  background-color: white;
  border: none;
  outline: none;
  flex: 0.6 1;
  border-radius: 10px;
  box-shadow: inset 0 0 20px #9d9a9a;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: small;
  font-weight: 500;
  text-align: center;
}
.downContainer > p {
  margin-bottom: 0;
  font-weight: 500;
  flex: 1.3;
  font-size: small;
}
/* Taxes container style */

.totalTaxesWrapper {
  width: 100%;
  display: grid;
  border-radius: 10px;
}
.totalTaxesContainer {
  width: 100%;
  position: relative;
  border: 1px solid #d1823a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0.5rem 0.7rem;
  align-items: center;
  gap: 0.5rem;
}
.totalTaxesContainer .fieldContainerLabel {
  position: absolute;
  top: -1rem;
  top: -0.8rem;
  font-weight: 500;
  color: #d1823a;
  background-color: white;
  left: 7px;
}
.taxesContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.taxesContainer > p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: small;
}
.taxesContainer .taxesInputContainer {
  width: 50%;
  font-size: small;
  display: flex;
  box-shadow: inset 0 0 20px #d1823a;
  border-radius: 10px;
  background-color: white;
  position: relative;
}

.taxesInputContainer > .threeDotsIconContainer {
  position: absolute;
  right: 0;
  width: 10% !important;
  border: none;
  top: 0.2rem;
  cursor: pointer;
}
.TTLAndOtherFees {
  position: relative;
}
.TTLAndOtherFees > .threeDotsIconContainer {
  position: absolute;
  right: 0;
  width: 10% !important;
  top: 0.2rem;
  border: none;
  cursor: pointer;
}
.taxesContainer .taxesInputContainer > input {
  background-color: white;
  border: none;
  outline: none;
  margin-bottom: 0;
  font-weight: 500;
  flex: 1.3;
  font-size: small;
  background-color: transparent;
  padding: 0.4rem 0.3rem;
}
.taxesContainer span {
  border: 1px solid #d1823a;
  border-radius: 7px;
  width: 50%;
  text-align: center;
}
.gapFontStyle {
  color: black;
}
.totalDownWrapperRelative .ServiceContractWrapper {
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  border: 1px solid black;
  border-radius: 10px;
}
.ServiceContractWrapper .CrossBtnContainer {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-bottom: 0;
}
/* Service Contract */
.ServiceContractContainer {
  width: 100%;
  position: relative;
  border: 1px solid #d1823a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0.5rem 0.7rem;
  align-items: center;
  gap: 0.5rem;
}
.ServiceContractContainer .fieldContainerLabel {
  position: absolute;
  top: -1rem;
  top: -0.8rem;
  font-weight: 500;
  color: #d1823a;
  background-color: white;
  left: 7px;
}
.monthMilesPriceContainer {
  display: flex;
  gap: 0.3rem;
}
.monthMilesPriceContainer > p {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-bottom: 0;
}
.monthMilesPriceContainer > p > span {
  font-size: small;
  font-weight: 500;
}
.monthMilesPriceContainer > p > input {
  background-color: white;
  border: none;
  outline: none;
  flex: 0.6 1;
  border-radius: 10px;
  box-shadow: inset 0 0 20px #9d9a9a;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: small;
  font-weight: 500;
}
.leftContainerPartTwo {
  /* background-color: lightgrey; */
  /* padding: 1.3rem 0.4rem; */
  border-radius: 10px;
  width: 100%;
}
.allTaxesWrapper {
  background-color: #ebeaea;
  padding: 1.3rem 0.4rem;
  border-radius: 10px;
  display: none;
}
.leftContainerPartTwo .allTaxesContainer {
  /* border: 1px solid #d1823a; */
  border-radius: 10px;
  position: relative;
  display: none;
}
.allTaxesContainer .fieldContainerLabel {
  position: absolute;

  top: -0.85rem;
  font-weight: 500;
  color: #d1823a;
  background-color: #ebeaea;
  left: 0.7rem;
  font-size: large;
}
.editBtnContainer {
  display: flex;
  justify-content: end;
  margin-bottom: 0;
  padding: 0.3rem 1rem;
}
.editBtnContainer button {
  background-color: #d1823a;
  border: none;

  box-shadow: inset 0 0 8px 2px #938d8d;
  border-radius: 8px;
  padding: 0.4rem 1.4rem;
  color: white;
  font-weight: 500;
}
.SubTotalContainer {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  padding: 0.4rem 0.4rem;
  font-size: 20px;
  border-radius: 10px;
}
.SubTotalContainer span:first-child {
  font-weight: 700;
}
.SubTotalContainer span:last-child {
  border: 1px solid #d1823a;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
  width: 50%;
  text-align: center;
}
/* Total Taxes and Fees */
.totalTaxesAndFeesContainer {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  align-items: center;
  padding: 0.4rem;
  font-size: 16px;

  border-radius: 5px;
}

.totalTaxesAndFeesContainer span:last-child {
  border: 1px solid #d1823a;
  border-radius: 5px;
  padding: 5px;
  width: 40%;
  text-align: center;
}
.SubTotalContainer,
.totalTaxesAndFeeWrapper {
  background-color: white;
  /* margin: 1rem 0; */
  box-shadow: 4px 4px 4px #ebeaea;
  border-radius: 10px;
  width: 100%;
}
.totalTaxesAndFeesContainer {
  padding: 0.3rem;
}
.lineBtwSubTotalTaxes {
  height: 1px;
  border-top: #d1823a 1px solid;
  margin-bottom: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.rightContainer {
  width: 100%;
}
/* Final box container */
.finalBoxContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;

  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  background-color: white;
}

.paymentScheduleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  border: 1px solid #d1823a;
  border-radius: 10px;
  padding: 0.9rem 0.7rem;
  font-size: small;

  margin-bottom: 1rem;
}
.paymentScheduleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.paymentScheduleContainerInner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border-radius: 10px;
  padding: 0.9rem 0.7rem;
  font-size: small;
  gap: 0.5rem;
  /* margin-bottom: 1rem; */
  width: 100%;
}
.viewBtnContainer {
  display: flex;
  justify-content: end;

  margin-bottom: 0;
}
.viewBtnContainer button {
  background-color: #edc29c;
  box-shadow: inset 0 0 20px #df791b;
  border: none;
  padding: 0.2rem 1rem;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: large;
}
.paymentScheduleContainer .fieldContainerLabel {
  position: absolute;

  top: -0.85rem;
  font-weight: 500;
  color: #d1823a;
  background-color: white;
  left: 0.7rem;
  font-size: large;
}
.paymentScheduleContainerInner > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-weight: 500;
  cursor: pointer;
}
.paymentScheduleContainer > div p {
  margin-bottom: 0;
}
.paymentScheduleContainer > div .paymentScheduleCircle {
  height: 13px;
  width: 13px;
  border: 1px solid black;
  border-radius: 50%;
}
.termAPRContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  position: relative;
  border: 1px solid #d1823a;
  border-radius: 10px;
  padding: 0.9rem 0.7rem;
  font-size: small;
  gap: 1rem;
  margin: 1rem 0;
  margin-top: 0;
}
.termContainer,
.APRContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.termContainer span,
.APRContainer span {
  width: 50%;
  font-weight: 500;
}
.termContainer input,
.APRContainer input {
  width: 50%;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: #edc29c;
  box-shadow: inset 0px 0px 3px #d1823a;
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: 4px 4px 4px grey;
}
.generateFormBtnMobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.6rem;
  border: none;
  background-color: #d1823a;
  color: white;
  font-weight: 500;
  font-size: large;
}
.generateFormBtnDesktopContainer {
  display: none;
}

.stateSaleTypeContainer {
  display: flex;
  gap: 0.3rem;
  width: 100%;
  justify-content: space-between;
}
.stateSaleTypeContainer > select {
  background-color: #d1823a;
  width: 50%;
  color: white;
  padding: 0.2rem;
  border-radius: 12px;
  font-weight: 500;
  text-align: center;
}
.boxContainer .labelPopoutHeaderDropDown {
  display: block;
}
.labelPopoutHeaderContainer > span {
  font-size: larger;
  color: #d1823a;
  width: 50%;
  text-align: center;
}
.labelPopoutHeaderDropDown {
  /* flex: 0.5;
  width: 50%; */
  margin: 0 !important;
  /* flex: 0.5; */
}
.boxContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.box_b_none_edit_add_button_small_screen_container {
  border: #d1823a 1px solid;
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.salesInventoryTaxContainer {
  display: none;
}
.salesInventoryTaxContainerMobile {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0;
  width: 100%;
  /* padding: 0 1rem; */
}
.salesTaxContainer,
.InventoryTaxContainer {
  display: flex;
  font-size: large;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0;
}

.salesTaxContainer > input,
.InventoryTaxContainer > input {
  width: 50%;
  box-shadow: inset 0px 0px 11px 0px #d1823a;
  border: none;
  outline: none;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  border: 0.1px solid #d1823a;
  background-color: #f7dece;
  text-align: center;
}
.salesInventoryTaxContainerMobile .salesTaxContainer > span,
.salesInventoryTaxContainerMobile .InventoryTaxContainer > span {
  width: 50%;
  font-weight: 500;
  font-size: 13px;
}
.labelPopoutHeaderContainer {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
}

.labelPopoutHeaderDropDown {
  display: none;
}
.boxContainer .labelPopoutHeaderDropDown {
  display: flex;
  justify-content: center;
  width: 50%;
  border-radius: 12px;
}
.labelPopoutHeaderContainer > span {
  font-size: larger;
  color: #d1823a;
  width: 50%;
  text-align: center;
}
.labelPopoutHeaderDropDown {
  /* flex: 0.5;
  width: 50%; */
  margin: 0 !important;
  /* flex: 0.5; */
}
.serviceGapWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #d1823a;
  border-radius: 7px;
  gap: 0.3rem;
  padding: 0.5rem 0.7rem;
}
.paymentDoneWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
}
.paymentDoneWrapper .paymentDoneContainer {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;
}
.paymentDoneContainer p:first-child {
  font-weight: 500;
}
.paymentDoneContainer p:last-child {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: space-between;
  width: 100%;
}
.paymentDoneContainer p:last-child input {
  background-color: #edc29c;
  box-shadow: inset 0px 0px 3px #d1823a;
  border: none;
  outline: none;
  padding: 0.4rem;
  border-radius: 7px;
  font-size: 16px;
  width: 40%;
  text-align: center;
}
.paymentDoneContainer p:last-child span {
  font-weight: 500;
}
.labelPopoutHeaderDropDown {
  background-color: #d1823a;
}
.selectSaleType {
  background-color: #d1823a !important;
}
.AddMobileBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
}
.AddMobileBtnContainer button {
  background-color: #edc29c;
  border-radius: 20px;
  border: none;
  box-shadow: 4px 3px 4px grey;
  width: 36%;
  padding: 0.2rem;
  font-size: 20px;
  font-weight: 500;
}
.salesTaxPopoutWrapper {
  /* border: 1px solid red; */
  position: relative;
  margin-bottom: 3rem;
}
.salesTaxPopoutWrapper .savebtnMobileFixedPopout {
  position: fixed;
  bottom: 0;
  left: 0;
  border: none;
  width: 100%;
  text-align: center;
  background-color: #d1823a;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding: 0.3rem;
}
.selectSaleTypeDesktop {
  display: none;
}
.finTaxContainerPopoutModalBody {
  padding: 0.4rem !important;
}
.desktopSaveBtnContainer {
  display: none;
}
.labelPopoutHeadertext {
  color: #d1823a;
  width: 100%;

  text-align: center;
  display: flex;
  justify-content: center;
  gap: 0.7rem;
  align-items: center;
}
.labelPopoutHeadertext span:last-child {
  color: grey;
}

@media (min-width: 420px) {
  .taxesContainer > p,
  .downContainer > p {
    font-size: 16px;
  }
  .totalDownContainer {
    gap: 0.5rem;
  }
  .salePriceContainer input,
  .taxesContainer .taxesInputContainer > input {
    padding: 0.5rem;
  }
  .taxesContainer span {
    padding: 0.3rem;
    font-weight: 500;
  }
  .salePriceContainer input {
    width: 50%;
  }
  .salePriceContainer > p {
    font-size: large;
  }
  .monthMilesPriceContainer > p > span {
    font-size: 15px;
  }
  .totalTaxesAndFeesContainer span:first-child {
    font-size: 16px;
    font-weight: 500;
  }
  .paymentScheduleContainer > div p {
    font-size: 16px;
  }
  .termContainer span,
  .APRContainer span {
    font-size: 16px;
  }
  .salesTaxContainer,
  .InventoryTaxContainer {
    padding: 0 0.5rem;
  }
  .salesInventoryTaxContainerMobile .salesTaxContainer > span,
  .salesInventoryTaxContainerMobile .InventoryTaxContainer > span {
    font-size: 16px;
  }
}
@media (min-width: 720px) {
  .salesInventoryTaxContainerMobile {
    flex-direction: row;
    justify-content: space-around;
  }
  .salesTaxContainer,
  .InventoryTaxContainer {
    display: flex;
    font-size: large;
    align-items: center;
    gap: 0.4rem;
    margin-bottom: 0;
  }
  .downContainer > .threeDotsIconContainer {
    width: auto !important;
    right: 0.2rem;
  }
  .paymentScheduleContainer {
    padding: 1.2rem 1rem;
  }
  .finTaxContainerPopoutModalBody {
    padding: 0.7rem !important;
  }
  .selectSaleType {
    font-size: 17px;
  }
  .labelPopoutHeadertext {
    font-size: 27px;
  }
}

@media (min-width: 1024px) {
  .container .leftContainer {
    flex-direction: row;
    align-items: start;
  }
  .SubTotalContainer,
  .totalTaxesAndFeeWrapper {
    background-color: #ebeaea;
    /* margin: 0rem; */
  }
  .lineBtwSubTotalTaxes {
    display: none;
  }
  .termContainer span,
  .APRContainer span {
    font-size: 15px;
  }
  .termAPRContainer {
    gap: 1rem;
    padding: 1rem;
  }

  .totalTaxesAndFeesContainer span:first-child {
    font-size: 20px;
  }
  .totalTaxesAndFeesContainer span:last-child {
    text-align: center;
    font-size: large;
  }
  .totalTaxesAndFeesContainer span:last-child {
    font-size: 15px;
    width: 26%;
  }
  .ServiceContractContainer {
    padding: 1rem;
  }
  .ServiceContractContainer .fieldContainerLabel {
    font-size: large;
  }
  .totalDownWrapper {
    margin-top: 0;
  }
  .salePriceContainer > p {
    font-size: 23px;
  }
  .salePriceContainer .fieldContainerLabel {
    top: -0.85rem;
    font-size: 20px;
  }
  .salePriceContainer {
    padding: 1rem;
  }
  .salePriceContainer input {
    font-weight: 500;
    font-size: large;
  }
  .totalDownContainer .fieldContainerLabel {
    font-size: larger;
  }
  .totalDownContainer {
    padding: 1rem;
  }

  .taxesContainer > p,
  .downContainer > p {
    font-size: 15px;
  }
  .salePriceContainer input,
  .taxesContainer .taxesInputContainer > input {
    font-size: 20px;
    text-align: center;
  }
  .monthMilesPriceContainer > p > span {
    font-size: 17px;
  }
  .monthMilesPriceContainer {
    gap: 1rem;
  }
  .totalTaxesContainer .fieldContainerLabel {
    font-size: larger;
  }
  .paymentScheduleContainer > div p {
    font-size: 15px;
  }
  .paymentScheduleContainer > div {
    gap: 1rem;
  }
  .paymentScheduleContainer > div .paymentScheduleCircle {
    height: 18px;
    width: 18px;
  }
  .taxesContainer span {
    font-size: 20px;
  }
  .allTaxesWrapper {
    padding: 1rem 0.4rem;
  }
  .labelPopoutHeaderDropDown {
    display: block;
    border-radius: 7px;
  }
  .labelPopoutHeaderDropDown button {
    font-size: 20px;
    padding: 0 0.4rem !important;
    border-radius: 7px;
  }
  .boxContainer .labelPopoutHeaderDropDown {
    display: none;
  }
  .salesInventoryTaxContainerMobile .salesTaxContainer > span,
  .salesInventoryTaxContainerMobile .InventoryTaxContainer > span {
    font-size: 18px;
  }
  .pymntScheduleHeader {
    font-size: 24px;
    width: 100%;
    font-weight: 700;
    text-align: center;
  }
  .taxesInputContainer > .threeDotsIconContainer {
    right: 0.2rem;
  }
  .TTLAndOtherFees > .threeDotsIconContainer {
    right: 0.2rem;
  }
  .leftContainerPartTwo {
    width: 57%;
  }
  .SubTotalContainer,
  .totalTaxesAndFeeWrapper {
    background-color: white;
    margin-top: 0;
  }
  .totalTaxesAndFeesContainer {
    margin: 1rem 0;
  }
  .savebtnMobileFixedPopout,
  .AddMobileBtnContainer {
    display: none;
  }
  .selectSaleTypeDesktop {
    display: block;
    width: 25%;
    padding: 0.1rem;
    text-align: center;
    background-color: #d1823a;
    color: white;
    border-radius: 10px;
    margin-left: 1rem;
  }
  .stateSaleTypeContainer > select {
    display: none;
  }
  .finTaxContainerPopoutModalBody {
    padding: 1rem !important;
    padding-top: 0 !important;
  }
  .desktopSaveBtnContainer {
    display: flex;
    justify-content: end;
  }
  .desktopSaveBtnContainer button {
    background-color: #d1823a;
    border-radius: 7px;
    border: none;
    color: white;
    box-shadow: 3px 3px 3px lightgrey;
    padding: 0.4rem 1rem;
    font-weight: 500;
    font-size: large;
  }
  .salesTaxPopoutWrapper {
    margin-bottom: 0;
  }
  .stateSaleTypeContainer > select {
    font-size: 17px;
  }
  .labelPopoutHeaderWrapper {
    padding: 0.3rem 0.6rem !important;
  }
}
@media (min-width: 1300px) {
  .container {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
  }
  .container .leftContainer {
    background-color: #ebeaea;
  }
  .salePriceWrapper {
    background-color: #ebeaea;
    padding: 0.8rem 0.4rem;
  }
  .salePriceContainer {
    background-color: #ebeaea;
    /* margin-bottom: 1rem; */
  }
  .rightContainer {
    width: 56%;
    background-color: #ebeaea;
    border-radius: 15px;
    padding: 1rem;
  }
  .container .leftContainer {
    gap: 0.7rem;
    padding: 0.7rem;
    border-radius: 15px;
  }
  .SubTotalContainer,
  .totalTaxesAndFeeWrapper {
    background-color: #ebeaea;
    margin: 0rem;
    /* margin-bottom: 1rem; */
  }
  .salePriceContainer > p {
    font-weight: 700;
  }
  .leftContainer .leftContainerPartOne {
    gap: 0rem;
  }
  .totalDownWrapper,
  .totalTaxesWrapper {
    padding: 0.8rem 0.4rem;
  }
  .totalTaxesAndFeesContainer span:first-child {
    font-size: 18px;
    font-weight: 700;
  }
  .totalTaxesAndFeesContainer span:last-child {
    width: 36%;
  }
  .paymentScheduleContainer > div p {
    font-size: 16px;
  }
  .paymentScheduleContainer > div {
    gap: 0.3rem;
  }
  .termAPRContainer {
    gap: 1rem;
    padding: 0.9rem 0.7rem;
  }
  .termContainer span,
  .APRContainer span {
    font-size: 15px;
  }
  .monthMilesPriceContainer > p > span {
    font-size: 15px;
  }
  .SubTotalContainer,
  .totalTaxesAndFeeWrapper {
    box-shadow: none;
  }
  .salePriceContainer .fieldContainerLabel,
  .totalDownContainer .fieldContainerLabel,
  .totalTaxesContainer .fieldContainerLabel,
  /* .ServiceContractContainer .fieldContainerLabel, */
  .allTaxesContainer .fieldContainerLabel,
  .paymentScheduleContainer .fieldContainerLabel {
    top: -1.2rem;
    font-size: 23px;
    font-weight: 600;
    background-color: #ebeaea;
  }

  .totalTaxesAndFeeWrapper {
    /* border: 1px solid #d1823a; */
    border-radius: 10px;
  }

  .paymentScheduleContainer {
    padding: 1rem;
  }
  .finalBoxContainer {
    padding: 0rem;
    background-color: #ebeaea;
  }
  .SubTotalContainer,
  .totalTaxesAndFeeWrapper {
    margin-bottom: 0.7rem;
  }
  .totalTaxesAndFeesContainer span:first-child {
    font-weight: 500;
    font-size: 23px;
  }
  .generateFormBtnDesktopContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
  }
  .generateFormBtnDesktopContainer > button {
    border: none;
    background-color: #d1823a;
    color: white;
    padding: 0.4rem 0.7rem;
    font-weight: 500;
    font-size: 23px;
    border-radius: 10px;
    box-shadow: inset 0 0 8px 2px #938d8d;
    width: 100%;
  }
  .generateFormBtnMobile {
    display: none;
  }
  .SubTotalContainer,
  .totalTaxesAndFeeWrapper {
    /* padding: 0.4rem 1rem; */
  }

  .downContainer > p {
    font-weight: 500;
    font-size: 15px;
  }
  /* .gapFontStyleDesktop {
    font-size: 27px !important;
    font-weight: 700 !important;
  } */
  .SubTotalContainer span:first-child {
    font-weight: 700;
    font-size: 28px;
  }
  .totalTaxesAndFeesContainerDesktop > span:first-child {
    font-weight: 500 !important;
    font-size: 23px !important;
  }
  .monthMilesPriceContainer > p > input {
    font-size: 17px;
    font-weight: 500;
  }
  .serviceGapWrapper {
    padding: 1rem;
    gap: 0.6rem;
  }
  .leftContainerPartTwo {
    width: 50%;
    border-radius: 0;
  }

  .leftContainer .leftContainerPartOne {
    width: 50%;
    /* border-right: 2px solid #d1823a; */
  }
  .serviceGapWrapper .gapFontStyleDesktop {
    font-size: 19px;
    font-weight: 500;
  }
  .amountFinancedContainer {
    border: none;
    padding-bottom: 1rem;
    border-bottom: 2px solid #d1823a;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 1rem;
  }
  .amountFinancedContainer .totalTaxesAndFeesContainerDesktop span:last-child {
    background-color: #d1823a;
    color: white;
  }
  .termAPRContainer {
    margin-bottom: 2rem;
  }
  .totalTaxesAndFeesContainer span:last-child {
    font-size: 22px;
    width: 40%;
    background-color: #d1823a;
    color: white;
  }
  .SubTotalContainerDesktop {
    padding: 0.5rem 1rem;
  }
  .totalTaxesAndFeesContainer {
    border: 1px solid #d1823a;
    border-radius: 10px;
  }
  .labelPopoutHeadertext {
    font-size: 32px;
  }
  .selectSaleTypeDesktop {
    width: 18%;
    font-size: 20px;
  }
  .salesInventoryTaxContainerMobile .salesTaxContainer > span,
  .salesInventoryTaxContainerMobile .InventoryTaxContainer > span {
    font-size: 21px;
  }
  .salesTaxContainer > input,
  .InventoryTaxContainer > input {
    font-size: 19px;
  }
}

@media (min-width: 1800px) {
  .paymentScheduleContainer > div p {
    font-size: 14px;
    font-weight: 600;
  }
  .rightContainer {
    width: 44%;
  }
  .totalTaxesAndFeesContainer span:first-child {
    font-weight: 700 !important;
    font-size: 27px !important;
  }
  .downContainer input {
    font-size: 17px;
    font-weight: 500;
  }
  .paymentScheduleContainer > div p {
    font-size: 17px;
  }
  .termContainer input,
  .APRContainer input {
    width: 30%;
    font-size: 17px;
  }
  .totalTaxesContainer {
    gap: 0.5rem;
    padding: 1rem;
  }
  .termContainer span,
  .APRContainer span {
    font-size: 20px;
  }
  .monthMilesPriceContainer > p > span {
    font-size: 17px;
  }
  .editBtnContainer button {
    font-size: 17px;
    padding: 0.4rem 1.9rem;
  }
  .downContainer > p {
    font-size: 20px;
  }
  .taxesContainer > p,
  .downContainer > p {
    font-size: 20px;
  }
  .SubTotalContainer span:last-child {
    width: 32%;
  }
  .termAPRContainer {
    padding: 1rem;
  }

  .container .leftContainer {
    gap: 1rem;
  }
}
