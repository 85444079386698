.reset_button_container{
    display: flex;
    justify-content: end;
    
}

.reset_button_container button{
  border: none;
  background-color: #d1823a;
  width: 150px;
  color: white;
  border-radius: 10px;
}

.reset_button_container span{
    /* background-color: #e7a162 !important; */
    color: white;
    font-weight: 700;
    font-size: larger;
    padding: 0.5rem 1.5rem;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.card_header{
    margin-top: -2.5rem;
}
.card_header span{
    background-color: #e7a162 !important;
    color: white;
    font-weight: 700;
    font-size: x-large;
    padding: 1rem;
}
.cardContainer {
  border: 2px solid orange;

  margin-left: 1rem;
  padding: 2rem;
  transition: width 0.3s ease-in-out;
  position: relative;
}
.cardContainer.expanded {
  width: 82vw;
}
.cardContainer.collapsed {
  width: 93vw;
}
.columnContainer{
  display: flex;
  gap: 2rem;
}
.columnContainer .col{
  /* border: 2px solid red; */
  flex: 1;
  /* display: flex;
  flex-direction: column;
  gap: 1rem; */
}


/* Input container component styles starts here */
.input_wrapper {
  /* border: 2px solid red; */
  flex: 1;
  margin-top: 1rem;
}
.input_header {
  margin-bottom: 0px;
  /* font-weight: 500; */
  /* font-size: x-large; */
}
.input_container {
  /* border-bottom: 2px solid black; */
  position: relative;
  background-color: #FCE8CD !important;
  display: flex;
  align-items: center;
}
.input_container input {
  border: none;
  outline: none;
  background-color: transparent;
  /* padding: 0.5rem; */
}
.input_container input:focus {
  border: none;
  outline: none;
}
.input_container span {
  position: absolute;
  right: 5px;
}
/* Input container component styles ends here */
.ApplyButtonWrapper {
  flex: 1;
  /* border: 2px solid blue; */
  display: flex;
  justify-content: end;
  align-items: end;
}
.ApplyButtonWrapper button{
    background-color: #e7a162;
    border: none;
    color: white;
    font-weight: 500;
    padding: 0.2rem 1rem;
    font-size: larger;
}
