.wrapper {
  /* height: 100vh; */
  width: 100%;
}
.container {
  height: 100%;
  /* padding: 3rem 0rem; */
}
.container .header {
  text-align: center;
  font-weight: 700;
  font-size: 33px;
  margin-bottom: 1rem;
}
.container .inputField {
  border-radius: 10px;
  border: none;
  padding: 0.7rem;
  font-size: larger;
  font-weight: 500;
  outline: none;
  margin-bottom: 1rem;
  box-shadow: 0px 4px 9px lightgray;
}
.container .btnSubmit {
  width: 100%;
  box-shadow: 0px 4px 9px lightgray;
  border: none;
  outline: none;
  border-radius: 10px;
  font-weight: 500;
  font-size: 29px;
  /* margin-bottom: 1.5rem; */
  padding: 0.3rem;
  position: relative;
}
/* Blink Animaition */
.container .btnSubmit.blink_now {
  animation: blink 800ms linear infinite;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Animated Car moving */
.btnSubmit img {
  height: 40px;
  position: absolute;

  left: 5%;
  top: 20%;
}
.btnSubmit.blink_now img {
  /* Move the image across the button */
  animation: car_move 0.7s linear infinite;
}
@keyframes car_move {
  0% {
    left: 5%;
  }
  25% {
    left: 25%;
  }
  50% {
    left: 50%;
  }
  75% {
    left: 75%;
  }
  100% {
    left: 90%;
  }
}
/* Cross line */
.OrCrossLine {
  border-bottom: 1px solid black;
  position: relative;
  margin: 3rem 0;
}
.OrCrossLine span {
  position: absolute;
  right: 43%;
  background-color: white;
  padding: 1rem;
  top: -22px;
  color: grey;
  font-weight: 500;
}

/* Query Model Header */
.queryModelHeader {
  width: 100%;
  text-align: center;
  font-weight: 700;
}
.queryModelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}
.queryModelContainer button {
  border: none;
  width: 100%;
  color: white;
  text-align: center;
  padding: 0.3rem;
  border-radius: 10px;
  font-size: x-large;
  background-color: grey;
}
.queryModelOrangeBtn {
  background-color: #d1823a !important;
}
@media (min-width: 720px) {
  .queryModelContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1800px) {
  .container .inputField {
    font-size: xx-large;
  }
  .container .btnSubmit {
    font-size: 35px;
  }
}
@media (min-width: 2400px) {
  .container .inputField {
    font-size: 40px;
  }
  .container .btnSubmit {
    font-size: 40px;
  }
}
