.container{
    display: flex;
    padding: 3rem;
    font-family: "Alata";
}

.verificationContainer{
    display: flex;
    border: 1px solid #d1823a;
    border-radius: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    gap: 1rem;
}

.optBuyer{
    background-color: antiquewhite;
    box-shadow: lightgrey 0px 3px 5px 1px;
    border: 2px #d1823a dashed;
    border-radius: 6px;
    text-align: center;
    height: 35px;
    outline: none;
}


@keyframes flip {
    0% {
        transform: scaleX(1); /* Original position */
    }
    50% {
        transform: scaleX(-1); /* Flipped position */
    }
    100% {
        transform: scaleX(1); /* Back to original position */
    }
}

.checkedImg img {
    width: 100px;
    height: 100px;
    animation: flip 1s forwards; /* Apply the flip animation */
}

.resendVerification button{
    background-color: #D1823A;
    color: white;
    border-radius: 6px;
    border: none;
    width: 200px;
    height: 35px;
    box-shadow: lightgrey 0px 3px 5px 1px;
}
@media (min-width: 300px) and (max-width: 600px) {
    .container{
        display: flex;
        margin-top: 3rem;
        padding: 0px;
        font-family: "Alata";
    }
}