.fav_card_container{
    /* border: 2px solid red; */
    width:  100% !important;
    display: flex;
    padding: 0 1rem 0 0.4rem !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
   

}
.card{
    /* border : 6px solid red !important; */
    height : 100%;
}
.car_image_container{
    /* border: 2px solid black; */
    height: 30% !important;
    height: 30% !important;
}
.car_image_container  img{
height: 50% !important;
}
.fav_card_container .fav-cart, .fav_card_container .fav-heading, .fav_card_container .fav-price, .fav_card_container .fav-button-container{
    /* border: 2px solid green; */
    height: 90% !important;
}
.fav_card_container .fav-cart {
    flex: 0.25;
}
.fav_card_container .fav-button-container{
    flex :  0.15;
}
.fav_card_container .fav-heading {
flex : 0.25;
 /* border: 2px solid blue; */
}
.fav_card_container .fav-price{
    flex : 0.5;
 /* border: 2px solid green; */
}

.fav-heading{
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    gap: 0.5rem;
}
.fav-price{
    
    display: flex;
    flex-direction: column;
    padding: 0 ;
    padding-top: 1rem;
    gap: 0.7rem;
    padding-right: 0 !important;
}
.fav-heading h2, .fav-price h2{
    font-weight: 700;
}
.fav-price h2{
    /* border :2px solid red; */
    padding-left: 2.4rem;
    font-size: x-large;
}
.fav-heading p, .fav-price p{
    font-size: larger !important;
    font-weight: 500 !important;
}
.car_engine_stats{
    display: flex;
    gap: 0.4rem;
    align-items: center;
    padding: 0 0.3rem;
    /* border : 2px solid red */
}
.extra_engine_stats{
    display: flex;
    align-items: center;
}
.messenger_container{
    padding-left:2.8rem;
    margin-top: 0.5rem;
}
.messenger_container img{
    margin-right: 1rem;
}
.messege_input_send_container{
    /* border: 2px solid red; */
    display: flex;
    font-size: x-large;
    gap: 0.2em;
    justify-content: space-evenly;
    padding-right:  2rem !important;
}
.messege_input_send_container input{
    flex: 0.6;
    padding: 1.5rem 2rem !important;
    font-size: larger !important;
    font-weight: 500 !important;
    /* border: 2px solid black; */
    background-color: rgba(128, 128, 128, 0.342) !important;
}
.messege_input_send_container input::placeholder{
    font-size: large;
    font-weight: 400 !important;
}
.messege_input_send_container button{
    flex: 0.3;
    font-weight: 600;
    /* border: 2px solid brown !important; */
}
.fav-button-container{
    display: flex !important;
    flex-direction: column !important;
    /* padding:  rem; */
    padding-top: 3%;
}
.fav-button-container span button{
    /* border: 4px solid blue; */
    width: 100% !important;
    flex: 1;
    padding: 0.7rem 0.5rem ;
    display: flex;
    align-items: center;
   justify-content: center;
   font-size: large;
   font-weight: 700;
}
#fav-button1 {
    font-weight: 700;
    padding: 0.7rem 0rem ;
}