.SavedDealerLocationWrapper{
    /* border: 2px solid red; */
    /* background-color: gray; */
    margin-top: 2rem;
}
.SavedDealerLocationContainer{
padding: 1rem 2rem;
}

.SavedDealerLocationCard{
    position: relative;
}
.PrimaryLocation{
    position: absolute;
    right: 20%;
    top: -1rem;
    background-color: #e6a162;
    color : white;
    padding: 0.3rem 0.6rem;
    font-size: x-large;
    font-weight: 800;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    
}
.SavedDealerLocationCardText{
    flex: 0.5;
}
.SavedDealerLocationheader{
    /* border: 2px solid red; */
    /* background-color: gray; */
    font-weight: 500;
    font-size: larger;
    
}
.SavedDealerLocationheader span{
    /* border: 2px solid #e6a162; */
    border: 2px solid gray;
    border-radius: 0.2rem;
    padding: 0.2rem 0.4rem;
}
.SavedDealerLocationheader.hovered span {
    border: 2px solid #e6a162;
}
.SavedDealerLocationCard_auth{
    display: flex;
    /* border: 2px solid red; */
    gap: 0.2rem;
}
.SavedDealerLocationCard{
    display: flex;
    margin-top: 0 !important;
    justify-content: space-between;
    border: 3px solid gray;
    /* border: 3px solid #e6a162; */
    border-radius: 1rem;
    position: relative;
    flex: 0.98;
    padding: 2rem 0;
    padding-bottom: 0px;
}
.SavedDealerLocationCard.hovered{
    border: 3px solid #e6a162;
}
.SavedDealerLocationCardText{
    /* border: 2px solid blue; */
    font-weight: 500;
    font-size: xx-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
}
.SavedDealerLocationCardheaderText{
    font-size: 33px;
    font-weight: 700;
}
.SavedDealerLocationCardStreetNo{
    font-weight: 700 !important;
    font-size: 25px;
}
.SavedDealerLocationCardImage{
    /* border: 2px solid red; */
    height: 165px;
    flex: 0.5;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}
.SavedDealerLocationCardImage img{
    max-width: 100%;
    max-height: 100%;
    
}
.auth_icons{
    font-size: x-large;
    flex: 0.02;
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: end;
    /* align-items: center; */
}
.auth_icons .deleteIcon{
    border: 3px solid red;
    border-radius: 50%;
    color: red;
    padding: 0.6rem;
    display: grid;
    place-content: center;
}
.auth_icons .editIcon{
    border: 3px solid #e6a162;
    border-radius: 50%;
    color : #e6a162;
    padding: 0.6rem;
    display: grid;
    place-content: center;
}