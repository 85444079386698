.crmMwrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}
.crmMcontainer {
  /* border: 1px solid black; */
  width: 100%;
  background-color: rgb(246 220 199);
  border-radius: 10px;
  box-shadow: 3px 3px 3px lightgrey;
  position: relative;
}
.crmMNumbering {
  position: absolute;
  top: 41%;
  left: -1.4rem;
  background-color: lightgrey;
  border-radius: 50%;
  padding: 0.4rem;
}
.crmMHeader {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  border-bottom: 1px solid rgb(186, 104, 29);
  padding: 0.2rem 0.5rem;
}
.crmMHeader > span:first-child {
  color: rgb(186, 104, 29);
  font-size: 10px;
}
.crmMHeader > span:last-child {
  font-weight: 500;
  font-size: 11px;
}
.crmMPersonalWrapper {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.6rem 0.2rem;
}
.crmMPersonalDetails {
  display: flex;
  flex-direction: column;
  flex: 0.8;
  gap: 0.6rem;
}
.crmMPersonalDetails .crmMDetail {
  margin-bottom: 0;
  font-size: 11px;
  display: flex;
  font-weight: 700;
}
.crmMDetail span:first-child {
  color: rgb(186, 104, 29);
  flex: 0.35;
}
.crmMDetail span:last-child {
  flex: 0.6;
}
.crmMBtnContainer {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.crmMBtnContainer button {
  font-size: 11px;
  border: none;
  background-color: rgb(186, 104, 29);
  color: white;
  width: 100%;
  border-radius: 5px;
  padding: 0.2rem;
}
@media (min-width: 375px) {
  .crmMHeader > span:first-child {
    font-size: 12px;
  }
  .crmMHeader > span:last-child {
    font-size: 13px;
  }
  .crmMPersonalDetails .crmMDetail {
    font-size: 13px;
  }
}
@media (min-width: 420px) {
  .crmMcontainer {
    /* border: 1px solid black; */
    width: 95%;
  }
  .crmMHeader > span:first-child {
    font-size: 13px;
  }
  .crmMHeader > span:last-child {
    font-size: 14px;
  }
  .crmMPersonalDetails .crmMDetail {
    font-size: 14px;
  }
  .crmMBtnContainer button {
    font-weight: 500;
    font-size: 13px;
  }
  .crmMBtnContainer {
    gap: 0.4rem;
  }
}
@media (min-width: 520px) {
  .crmMHeader > span:first-child {
    font-size: 14px;
  }
  .crmMHeader > span:last-child {
    font-size: 15px;
  }
  .crmMPersonalDetails .crmMDetail {
    font-size: 15px;
  }
  .crmMBtnContainer button {
    font-weight: 500;
    font-size: 17px;
  }
  .crmMPersonalWrapper {
    padding: 0.6rem 1.2rem;
  }
  .crmMHeader {
    padding: 0.2rem 1rem;
  }
  .crmMNumbering {
    left: -1.8rem;
  }
}
