.wrapper {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: auto;
}
.container {
  width: 100%;
}
.header {
  display: flex;
  align-items: center;
  font-size: large;
  padding: 0.5rem;
  font-size: larger;
  font-weight: 500;
  box-shadow: 0px 4px 8px -1px rgb(192, 187, 187);
  margin-bottom: 2rem;
}
.header span:first-child {
  flex: 0.5;
}
.header span {
  font-size: large;
}
.containerBody {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 87vh;
}

.containerBody .recepientName {
  font-size: x-large;
  font-weight: 600;
}
.recepietAmt p {
  height: 200px;
  width: 200px;
  /* border: 1px solid black; */
  display: grid;
  place-content: center;
  border-radius: 50%;
  font-size: xx-large;
  font-weight: 700;
  color: #d1823a;
  background-color: #eb72021c;
  box-shadow: 3px 7px 6px 0px #c9bdbd9c;
}
.recepietSendBtn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.recepietSendBtn button {
  background-color: #d1823a;
  color: white;
  width: 133%;
  border: none;
  font-size: larger;
  font-weight: 500;
  padding: 0.4rem 0;
  border-radius: 10px;
}
