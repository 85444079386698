.sell_data_form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  background-color: #ebeaea;

  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}
.sell_data_form button {
  background-color: #e3b68c;
  color: white;
  border: none;      
  padding: 0.1rem 1.7rem;
  border-radius: 5px;
  /* cursor: pointer; */
  font-size: 20px;
  margin-right: 1rem;
  box-shadow: inset 0px 0px 9px #d1823a, inset 0px 0px 18px #d1823a;
  font-weight: 500;
  font-size: 25px;
  width: auto;
}

.sell_data_form img {
  width: 24px; /* Adjust size as needed */
  height: 24px;
}
.emptyDataMessage {
  color: red;
  cursor: pointer;
  font-size: small;
  text-align: center;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .sell_data_form {
    position: static;
    margin-bottom: 1rem;
  }
}
@media (min-width: 1440px) {
  .emptyDataMessage {
    font-size: medium;
  }
}
