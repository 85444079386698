.wrapper {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.crossContainer {
  display: flex;
  justify-content: end;
  font-size: 30px;
  width: 100%;
  padding: 1rem;
  z-index: 2;
}
.lightColorBubble {
  background-color: rgb(243 223 196 / 38%);
  border-radius: 50%;
  position: absolute;
  right: -37px;
  top: -55px;
  height: 108px;
  width: 103px;
  /* top: -10%; */
  z-index: -1;
}
.container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header {
  text-align: center;
  font-weight: 500;
}
.btnSubmit {
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
}
.btnSubmit span {
  background-color: white;
  margin-top: -1rem;
}
.termsPrivacyContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  margin-top: 1rem;
}
.termsPrivacyContainer a{
  color: black;
  text-decoration: underline;
  border-bottom: 1px solid black;
}
@media (min-width: 420px) {
  .termsPrivacyContainer {
    font-size: 14px;
  }
}

@media (min-width: 720px) {
  .wrapper {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .crossContainer {
    padding: 0 1rem;
    margin-bottom: 0;
  }
  .termsPrivacyContainer {
    font-size: 11px;
    font-weight: 500;
  }
}
@media (min-width: 1440px) {
  .container {
    padding-top: 0;
    padding: 2rem;
  }
  .crossContainer {
    padding: 0 2rem;
  }
  .termsPrivacyContainer {
    font-size: 12px;
  }
}
@media (min-width: 1800px) {
  .termsPrivacyContainer {
    font-size: 13px;
  }
}
