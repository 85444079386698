.popOutHeader {
  font-size: x-large;
  font-weight: 600;
}
.popOutBody {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
  width: 100%;
}
.popOutBodyLeft {
  flex: 0.6;
}
.popOutBodyRight {
  flex: 0.4;
  display: flex;
  justify-content: center;
}
.personalDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.details span {
  font-size: large;
  font-weight: 500;
}
.details input {
  background-color: #ffc7a2;
  border: none;
  outline: none;
  padding: 0.7rem 0.5rem;
  border-radius: 10px;
}
.assetInfoDetailsContainer {
  background-color: #fff5ee;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 15px;
}
.assetInfoDetailsContainer p {
  margin-bottom: 0;
  font-size: larger;
}
.assetInfoDetailsContainer img {
  max-width: 100%;
  max-height: 100%;
  height: 200px;
  width: 200px;
  border-radius: 15px;
}
.assetInfo2,
.assetInfo3,
.assetInfo4 {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  flex: 1;
  padding-left: 1rem;
  font-size: x-large;
  font-weight: 600;
  gap: 1rem;
  border-right: 3px solid #e8a262;
}

.assetInfo4 {
  border-right: none;
}
.assetInfo2 span:first-child,
.assetInfo3 span:first-child,
.assetInfo4 span:first-child {
  color: #e8a262;
}
.downloadAppBtn {
  background-color: #c77020;
  color: white;
  padding: 0.4rem 1rem;
  border: none;
  border-radius: 10px;
}

/* Right side of header */
.messageContainer {
  width: 100%;
  border: 2px solid lightgrey;
  border-radius: 15px;
  overflow: hidden;
}
.messageheader {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  font-weight: 600;
  font-size: larger;
  align-items: center;
  box-shadow: 3px 3px 5px lightgrey;
}
.messageheader span:nth-child(2) {
  background-color: #c77020;
  border-radius: 50%;
  color: white;
  padding: 0.2rem;
  display: grid;
  place-content: center;
}
.messageBody {
  width: 100%;
  height: 20rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.leftContainer {
  padding-right: 40%;
}
.leftContainer .message {
  background-color: #ffc7a2;
}
.rightContainer .message {
  background-color: rgb(241, 241, 241);
}
.rightContainer {
  padding-left: 40%;
}
.message {
  border: 2px solid lightgrey;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: -2px 6px 9px -1px lightgrey;
  padding: 0.4rem;
}
.message span:first-child {
  font-weight: 600;
}
.messageInputContainer {
  display: flex;
  height: 100%;
}
.messageInputContainer span {
  background-color: #c77020;
  color: white;
  font-size: large;
  flex: 0.35;
  text-align: center;
}
.messageInputContainer input {
  border: none;
  padding: 0.5rem;
}
