#btn-buyer-payment .nav-tabs .nav-item {
  width: 0px !important;
  /* height: 100px !important;  */
  border: none;
  padding: 0px !important;
}

.tab-content {
  > .active {
    display: block;
    width: auto;
  }
}
.main-container {
  padding: 0%;
  margin: 0%;
}

.main-input-fields {
  width: 95%;
  height: 90%;
  /* display: flex; */
}

.input-contant-container {
  height: 100%;
  /* min-width: 90%; */
  /* border: 3px solid #e8a262; */
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

#btn-buyer-payment {
  display: inline-block;
  /* border: 1px solid #e8a262; */
  /* width: 98% !important; */
}

#btn-buyer-payment .nav-tabs .nav-item a {
  width: 100% !important;
}

.nav-link.disabled {
  color: gray !important;
  pointer-events: none;
  cursor: default;
}
.dealerSellTabContent {
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 5rem;
}
@media (min-width: 375px) and (max-width: 720px) {
  #btn-buyer-payment .nav-tabs .nav-item a {
    width: 77% !important;
  }
}
@media (min-width: 455px) and (max-width: 720px) {
  #btn-buyer-payment .nav-tabs .nav-item a {
    width: 77% !important;
    padding: 1rem 0;
  }
  .parentTabNav .tabArrowIcon {
    right: -15px !important;
    top: 19px !important;
    font-size: larger;
  }
  #btn-buyer-payment .nav-tabs .nav-item .nav-link.not-active,
  #btn-buyer-payment .nav-tabs .nav-item .nav-link.active {
    font-size: larger !important;
  }
}
@media screen and (max-width: 600px) {
  #btn-buyer-payment {
    display: flex;
    flex-direction: column;
  }

  #sell-add-input {
    width: 200px;
  }

  #btn-buyer-payment button {
    width: 40px;
    height: 50px;
    font-size: 7px;
    background-color: white;
    color: #d1823a;
    border: 1px solid #d1823a;
  }

  #btn-buyer-payment button:hover {
    background-color: #d1823a;
    color: white;
  }
}

#btn-gob-ttl {
  /* float: right; */
  display: flex;
  margin-right: 2%;
  margin-bottom: 2%;
  justify-content: flex-end;
}

#btn-gob-ttl button {
  background-color: #e8a262;
  color: white;
  border: none;
  margin-left: 10px;
  height: 40px;
  width: 200px;
}

#btn-gob-ttl button svg {
  min-height: 40px !important;
}

.input-user-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.form-label {
  font-weight: bold;
  font-size: 15px;
}

.form-border,
.form-select {
  border: none !important;
  border-bottom: 2px solid #e8a262 !important;
  border-radius: 0px !important;
}

.trade-foot {
  padding: 2%;
  border: 3px solid #e8a262;
  border-radius: 10px;
}

.footer {
  height: 130px;
  width: 100%;
  background-color: rgba(230, 230, 230, 0.932);
  border-top: 1px solid #e8a262;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

/* Style for form input fields */
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* border-radius: 0.25rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Style for form select dropdowns */
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-bottom: 2px solid #e8a262;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Style for form group */
.form-group {
  padding: 2% 5%;
}

/* Style for form row */
.form-row {
  margin-right: -5px;
  margin-left: -5px;
}

/* Style for form group columns */
.form-group .col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.input-user-container .form-group label {
  font-weight: 700;
}

/* Style for form group inputs */
.input-user-container .form-group input,
.input-user-container .form-group select {
  border: none;
  border-bottom: 2px solid #e8a262 !important;
  border-radius: 0px !important;
  /* box-shadow: 0px 4px 3px rgb(179, 179, 179); */
}

.num-state {
  box-sizing: border-box;
  display: flex;
}

/* Style for form button */
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Style for button with icon */
.btn span {
  display: flex;
  align-items: center;
}

/* Style for checkbox container */
.checkbox-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  padding-left: 1.25rem;
}

/* Style for checkmark */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: #fff;
  border: 1px solid #ced4da;
}

/* Style for active checkmark */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style for active checkbox */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style for active checkmark icon */
.checkbox-container .checkmark:after {
  left: 0.3rem;
  top: 0.1rem;
  width: 0.4rem;
  height: 0.7rem;
  /* border: solid #007bff; */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Style for Trad In Modal */
/* .custom-modal {
    max-width: 700px !important;
}

.custom-modal .modal-content {

    width: 100%;
}

.custom-modal .modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.custom-modal .modal-body {
    padding: 2rem;
} */

.custom-modal .form-label {
  font-weight: bold;
  color: #333;
}

.custom-modal .form-control {
  /* border: none; */
  background-color: #f8f9fa;
  padding: 0.3rem !important;
  font-size: 1rem;
  color: #333;
}

.custom-modal .form-control[readonly] {
  background-color: #fff;
}

.custom-modal .form-select {
  padding: 0.3rem !important;
  font-size: 1rem;
}

.custom-modal .btn-primary {
  background-color: #e8a262;
  border-color: #e8a262;
}

.custom-modal .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.custom-modal .btn {
  margin-right: 0.5rem;
}

.half-border {
  border: none !important;
  border-bottom: 2px solid #e8a262 !important;
  border-radius: 0px !important;
}

#mileageIn {
  /* background-color: #f2caa6 !important; */
  background-color: rgba(240, 150, 31, 0.22) !important;
}

.tab-conten {
  /* min-width: 66vw; */
}

.bg-input input {
  background-color: rgba(240, 150, 31, 0.22) !important;
  border-radius: 0px;
  /* padding-left: 20% !important; */
  font-size: 15px;
  width: 91%;
  font-weight: bolder;
  letter-spacing: 2px;
}
#btn-buyer-payment .nav-tabs .nav-item .nav-link {
  box-sizing: border-box;
  border: none !important;
  color: white;
  font-weight: 700;
  height: inherit;
  /* padding-top: 30%; */
  text-align: center;

  display: flex;
  flex-direction: column;
}
.parentTabNav {
  display: flex;
  gap: 1.5rem;
}
.navItemParent {
  position: relative;
}
.parentTabNav .tabArrowIcon {
  position: absolute;
  right: -18px;
  top: 6px;
}
/* .parentTabNav .tabArrowIcon:last-child {
  display: none;
} */

.nav-item .nav-link .numberNavTab {
  background-color: #e5e6e7;
  border-radius: 50%;
}
.textNavTab {
  color: black;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
}
.textNavTabA {
  display: none;
}
#btn-buyer-payment .nav-tabs .nav-item .nav-link.active {
  background-color: #e8a262;
  color: white !important;
  border-radius: 50%;
  font-size: small;
}
#btn-buyer-payment .nav-tabs .nav-item .nav-link.not-active {
  background-color: rgb(228, 228, 228);
  color: white !important;
  border-radius: 50%;
  font-size: small;
}

/* .allItemsDropDownToggle {
  width: 11%;
} */
/* .searchItemsInput {
  width: 50%;
} */
.vehicleFilterGroup {
  display: flex !important;
  /* flex-direction: column; */
  align-items: center !important;
  gap: 1rem;
}
.vehicleListFilterFirstRow,
.vehicleListFilterThirdRow {
  display: flex;
  width: 100%;
  justify-content: center;
}

.vehicleListFilterFirstRow {
  gap: 0.1rem;
}
.vehicleListFilterFirstRow > button {
  /* flex: 0.1; */
  border-radius: 0;
  font-size: small;
}
.vehicleListFilterFirstRow > button:first-child {
  border-top-left-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
  color: white;
}
.vehicleListFilterFirstRow > button:nth-child(2) {
  border-right: 1px solid white !important;
  color: white;
}
.vehicleListFilterFirstRow > button:last-child {
  flex: 0.1;
  font-size: small;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  color: white;
  padding: 0 !important;
}
.vehicleListFilterFirstRow > input {
  flex: 1;
  border-radius: 0;
  /* width: auto !important; */
  padding: 0 0 0 3px !important;
  box-shadow: inset 0 0 9px 2px rgb(209, 130, 58);
}
.vehicleListFilterSecondRow,
.vehicleListFilterThirdRow {
  gap: 0.3rem;
}
.vehicleListFilterSecondRow button {
  color: white;
  width: 30%;
}

.vehicleListFilterThirdRow button {
  font-size: 12px;
  padding: 8px;
  width: 33% !important;
  background-color: rgb(232, 162, 98);
  border-radius: 6px;
  color: white;
  border: none;
}
.VLresetBtn {
  display: none;
}

@media (min-width: 370px) {
  .vehicleFilterGroup {
    width: 100% !important;
  }
  .vehicleListFilterFirstRow {
    width: 100% !important;
  }
  .vehicleListFilterFirstRow > input {
    width: auto !important;
  }
  .vehicleListFilterFirstRow > button {
    padding: 0.3rem;
  }
}
@media (min-width: 720px) {
  #btn-buyer-payment .nav-tabs .nav-item {
    width: 10% !important;
    /* height: 100px !important;  */
    border: 1px solid #e8a262;
    padding: 0px !important;
  }
  #btn-buyer-payment .nav-tabs .nav-item .nav-link {
    box-sizing: border-box;
    border: none !important;
    color: #e8a262;
    font-weight: 700;
    height: inherit;
    /* padding-top: 30%; */
    text-align: center;
  }

  .numberNavTabA,
  .textNavTab {
    display: none;
  }
  .textNavTabA {
    display: block;
  }
  #btn-buyer-payment .nav-tabs .nav-item .nav-link.active {
    border-radius: 0;
  }
  #btn-buyer-payment .nav-tabs .nav-item .nav-link.not-active {
    border-radius: 0;
    background-color: white;
    color: #d1823a !important;
  }
  .tabArrowIcon {
    display: none;
  }
  .parentTabNav {
    gap: 0;
    width: 70% !important;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    overflow: hidden;
    border: 1px solid #d1823a;
    border-bottom: 0;
  }
  #act-search {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
  }
  .vehicleListFilterSecondRow,
  .vehicleListFilterThirdRow {
    gap: 1.3rem;
  }
  .vehicleListFilterThirdRow button {
    font-size: 17px;
    font-weight: 500;
  }
}

@media (min-width: 1024px) {
  .vehicleFilterGroup {
    padding: 0 2rem;
  }
  .vehicleListFilterFirstRow {
    width: auto !important;
    flex: 1;
    margin-right: 1px;
  }
  .vehicleListFilterFirstRow button {
    padding: 0rem 0.5rem !important;
  }

  .vehicleListFilterSecondRow,
  .vehicleListFilterThirdRow {
    width: auto;
    margin-right: 1px;
  }
  .vehicleFilterGroup {
    gap: 0rem;
  }
  .vehicleListFilterSecondRow,
  .vehicleListFilterThirdRow {
    gap: 0.1rem;
    height: 55px;
  }
  .vehicleListFilterSecondRow button,
  .vehicleListFilterThirdRow button {
    border-radius: 0rem;
    width: auto;
  }
  .vehicleListFilterSecondRow button {
    padding: 0.85rem !important;
  }

  .vehicleListFilterThirdRow {
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .VLresetBtn {
    display: block;
  }

  .vehicleListFilterSecondRow,
  .vehicleListFilterThirdRow {
    width: 30% !important;
    align-items: center;
    justify-content: start;
    gap: 0.2rem;
  }
  .vehicleListFilterFirstRow > input {
    width: 50% !important;
    padding: 0.43rem !important;
    height: 41px;
  }
  .vehicleListFilterFirstRow {
    width: 40% !important;
  }
  .vehicleListFilterThirdRow select {
    border-radius: 7px !important;
  }
  .vehicleListFilterThirdRow select,
  .vehicleListFilterThirdRow button {
    border-radius: 0px !important;
    height: 41px;
  }
  .vehicleListFilterThirdRow .VLresetBtn {
    border-radius: 0px !important;

    padding: 5px !important;
    border-top-right-radius: 21px !important;
    border-bottom-right-radius: 21px !important;
  }
  .vehicleListFilterThirdRow button {
    padding: 7.5px;
    width: 31% !important;
  }
  .vehicleListFilterThirdRow button {
    font-size: 16px;
  }
}
@media screen and (min-width: 1440px) {
  .vehicleFilterGroup {
    padding: 0 4rem;
    gap: 0.1rem;
  }
  .vehicleListFilterSecondRow button,
  .vehicleListFilterThirdRow button {
    border-radius: 0rem;
    width: auto;
  }
}
@media screen and (min-width: 1800px) {
  .vehicleFilterGroup {
    /* padding: 0 16rem; */
  }
  .vehicleListFilterFirstRow > input {
    padding: 0.93rem !important;
  }
  .vehicleListFilterSecondRow button,
  .vehicleListFilterThirdRow button {
    height: 56px;
    font-weight: 500;
    font-size: 20px;
  }
  .vehicleListFilterFirstRow > button:first-child {
    font-size: 20px;
    font-weight: 500;
  }
  .vehicleListFilterThirdRow select,
  .vehicleListFilterThirdRow button {
    height: 56px;
  }
  .vehicleListFilterSecondRow,
  .vehicleListFilterThirdRow {
    height: 56px;
  }
  .vehicleListFilterFirstRow {
    height: 56px;
  }
  .vehicleListFilterFirstRow > input {
    height: 56px;
  }
}

.tab-content {
  /* min-height: 90vh !important; */
  /* border: 2px solid #ffa500; */
}
/* width responsivity according to the sidebar width */
.wrapper_expanded {
  /* border: 2px solid red; */
  width: 75vw;
}
.wrapper_collapsed {
  /* border: 2px solid blue; */
  width: 93vw;
}
@media (min-width: 1440px) {
  .wrapper_expanded {
    /* border: 2px solid red; */
    width: 84vw;
  }
  .wrapper_collapsed {
    /* border: 2px solid blue; */
    width: 93vw;
  }
  #btn-buyer-payment .nav-tabs .nav-item .nav-link.active {
    font-size: larger;
  }
  #btn-buyer-payment .nav-tabs .nav-item .nav-link.not-active {
    font-size: larger;
  }
}
@media (min-width: 2560px) {
  .wrapper_expanded {
    /* border: 2px solid red; */
    width: 91vw;
  }
  .wrapper_collapsed {
    /* border: 2px solid blue; */
    width: 97vw;
  }
}
