.wrapper {
  width: 100vw;
}
.container {
  width: 100%;
  padding: 0.9rem;
}
.header {
  font-size: xx-large;
  font-weight: 600;
  font-family: "Times New Roman", Times, serif;
}
.headerType {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.6rem;
}
.headerType span {
  border: 1px solid black;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
}
.cardContainer {
  display: flex;
  flex-direction: column;
 
}
.card {
  font-size: small;
  font-weight: 600;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}
.carImage {
  /* border: 2px solid red; */
  max-width: 100%;
  min-width: 100%;
}
.carDetails {
  /* border: 1px solid black; */
  padding: 0.4rem 0.6rem;
}
.carName {
  margin-bottom: 2px;
  margin-top: 0.5rem;
  padding: 0.4rem 0.6rem;
}
.carExtraInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.car_Prize {
  display: flex;
  align-items: center;
  justify-content: end;
  color: blue;
  font-size: large;
  margin-top: 0;
}
 .smallCardContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
@media (min-width: 420px) {
  .card {
    font-size: large;
    font-weight: 600;
  }
}
@media (min-width: 720px) {
  .card {
    font-size: larger;
    font-weight: 700;
  }
  .carDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
  }
  .carExtraInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
  .car_Prize {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: center;
    flex: 0.3;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .bigCard {
    display: none;
  }
  .cardContainer {
  }
  .smallCardContainer {
    display: grid;

    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .carDetails {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0rem;
    /* border: 2px solid red; */
  }

  .carExtraInfo {
    /* border: 1px solid black; */
    width: 100%;
  }
  .car_Prize {
    width: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: end;
  }
}
@media (min-width: 1440px) {
  .container{
    padding: 3rem;
  }
  .smallCardContainer {
    display: grid;

    gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
/* @media (min-width: 2204px) {
  .cardContainer {
    display: flex;
    flex-direction: row !important;
    width: 100%;
  }
  .bigCard {
    display: block;
    flex: 0.5;
  }
  .smallCardContainer {
    display: grid;

    gap: 0.3rem;
    grid-template-columns: repeat(3, 1fr);
    flex: 1;
  }
  .smallCardContainer .carImage {
    max-width: 50%;
    max-height: 50%;
  }
  .smallCardContainer .card {
    min-width: 100%;
    min-height: 100%;
      justify-content: flex-start;
  }
} */
@media (min-width: 2204px) {
  .cardContainer {
    display: flex;
    flex-direction: row !important;
    width: 100%;
    gap: 2rem; /* Gap between big card and small card container */
  }
  .bigCard {
    display: block;
    flex: 0.6; /* Take up the same space as the smallCardContainer */
  }
  .smallCardContainer {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem; /* Gap between small cards */
    row-gap: 3rem;
  }
  .smallCardContainer .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .bigCard .card {
    height: 100%; /* Make the big card fill its container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
  }
  .carImage {
    width: 100%;
    height: 100%; /* Fixed height for consistency */
    object-fit: cover;
  }
  .bigCard .carImage {
    height: 83%; /* Larger image for the big card */
  }
  .carName {
    padding: 0.5rem;
    font-weight: bold;
  }
  .carDetails {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .carExtraInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .bigCard .carExtraInfo,
  .bigCard .carName,
  .bigCard .car_Prize {
    font-size: xx-large;
  }
  .smallCardContainer .carExtraInfo,
  .smallCardContainer .carName,
  .smallCardContainer .car_Prize {
    font-size: x-large;
  }

  .header {
    font-size: 50px;
    font-weight: 700;
    font-family: "Times New Roman", Times, serif;
  }
  .headerType {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.6rem;
    font-size: xx-large;
  }
  .headerType span {
    border: 1px solid black;
    padding: 0.3rem 0.6rem;
    border-radius: 5px;
  }
}
