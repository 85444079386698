/* .tabs-container {
    border: 4px solid #f8a058;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
  }
  
  .tab-buttons {
    display: flex;
    width: 50%;
  }
  
  .tab-buttons button {
    background-color: #f88f51;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 15px;
    flex: 1;
    transition: background-color 0.3s;    
  }
  
  .tab-buttons button:hover {
    background-color: #ddd;
  }
  
  .tab-buttons button.active {
    background-color: #ccc;
  }
  
  .tab-content {
    padding: 15px;
  }

  .scrollable-paragraph-container {
    width: 100%; 
    height: 700px; 
    overflow-y: scroll; 
  }
  
  .scrollable-paragraph {
    padding: 10px;
  } */

/* -------------------------------------Tabs Start--------------------------------------------------------------- */
  /* Style for tabs */
  .tabs {
    /* border: 2px solid #ccc; */
    border-radius: 4px;
    overflow: hidden;
    /* width: 50%; */
  }
  
  .tab-buttons {
    display: flex;
  }
  
  .tab-buttons button {
    background-color: #f1f1f1;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    /* flex: 1; */
    transition: background-color 0.3s ease;
  }
  
  .tab-buttons button:hover {
    background-color: #ddd;
  }
  
  .tab-buttons button.active {
    background-color: #d1823a;
    font-weight: 700;
    font-size: large;
    color: #fff;
    /* width: 50%; */
    /* margin-top: 30px ; */
  }
  
  .tab-content {
    padding: 20px;
    
  }
  
  /* Media screen for responsiveness */
  @media screen and (max-width: 600px) {
    .tab-buttons {
      flex-direction: column;
    }
  
    .tab-buttons button {
      flex: none;
    }
  }
  
  
  /* .rainbow {
    background-image: -webkit-gradient( linear, left top, right top, color-stop(0, #22f), color-stop(0.1, #f22), color-stop(0.13, #ff2), color-stop(0.45, #f22), color-stop(0.5, #22f),color-stop(0.70, #2f2), color-stop(0.2, #ff2), color-stop(1, #f22) );
    background-image: gradient( linear, left top, right top, color-stop(0, #22f), color-stop(0.15, #f22), color-stop(0.13, #ff2), color-stop(0.45, #f22), color-stop(0.6, #22f)),color-stop(0.75, #2f2), color-stop(0.9, #ff2), color-stop(1, #f22) );
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
  } */
  /*----------------------------------google market place Start----------------------------------------*/
  .rainbow {
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0, #22f),
      color-stop(0.15, #f22),
      color-stop(0.13, #ff2),
      color-stop(0.45, #f22),
      color-stop(0.6, #22f),
      color-stop(0.75, #2f2),
      color-stop(0.9, #ff2),
      color-stop(1, #f22)
    );
    background-image: linear-gradient(
      to right,
      #22f,
      #f22 15%,
      #ff2 13%,
      #f22 45%,
      #22f 60%,
      #2f2 75%,
      #ff2 90%,
      #f22 100%
    );
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  .Darkslogo{
    border: 2px solid black;
    padding: 4px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  /* Media screen code */
  @media screen and (max-width: 768px) {
    .Darkslogo {
      width: 50%; /* Adjust the width for smaller screens */
      height: auto; /* Maintain aspect ratio */
    }
  }
                                                        /*-----------------------------------radio button Start-------------------------------------*/
  .containers {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .containers input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 16%;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .containers:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #1f1e20;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .containers input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .containers .checkmark:after {
       top: 9px;
      left: 9px;
      width: 0px;
      height: 0px;
      border-radius: 50%;
      background: white;
  }
  /* Media screen code */
  @media screen and (max-width: 768px) {
    .containers {
      font-size: 18px;
    }
  }