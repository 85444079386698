.CardWrapper {
  /* border: 2px solid red; */
  height: 365px;
  /* width: 400px; */
  box-shadow: 5px 7px 10px lightgrey;
  border-radius: 15px;
}

.CardWrapper:hover {
  transform: scale(1.05);
  transition: transform ease-in 0.3s;
}

.CardContainer {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 15px;
}
.CardContainer > .domainData{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(235 228 228 / 57%);
  color: rgb(51 240 19);;
  font-size: 29px;
  font-weight: 600;
  text-align: center;
  z-index: 2;
  backdrop-filter: blur(8px);
  padding: 0.5rem;
}

.CardContainer img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  transform: scale(0.9);
  background-size: 100% 100%;
  z-index: 1;
}

.hidDataContainer {
  position: absolute;
  /* bottom: -20%; */
  bottom: 0;
  width: 100%;
  display: flex;
  transition: bottom 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.btnContainer {
  width: 100%;
}
.unActivePopupChoicesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.unActivePopupChoicesWrapper p {
  font-weight: 500;
}
.unActivePopupChoicesWrapper .popupBtnContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.popupBtnContainer button {
  border: none;
  width: 30%;
  color: white;
  background-color: #d1823a;
  border-radius: 7px;
  padding: 0.4rem 0.8rem;
  font-weight: 500;
  font-size: large;
}
.hidCheckBoxContainer {
  display: flex;
  width: 100%;
  background-color: rgba(206, 201, 201, 0.726);
}
.hidCheckBoxContainer p {
  margin-bottom: 0;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  padding: 0.3rem;
  gap: 0.3rem;
}
.hidCheckBoxContainer p input,
.hidCheckBoxContainer p label {
  cursor: pointer;
}
.hidCheckBoxContainer p:first-child {
  border-right: 1px solid white;
}

.btnContainer button {
  flex: 1;
  border: none;
  font-size: 10px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  width: 33.33%;
}

.btnContainer button:nth-child(2n + 1) {
  color: white;
  background-color: #e8a262;
  box-shadow: inset 1px 0px 7px 5px #ca8b03;
}

.btnContainer button:nth-child(2n) {
  background-color: black;
  color: white;
}

.btnContainer button a {
  color: white;
}

.CardContainer:hover .hidDataContainer {
  bottom: 0;
}
.cardNumber {
  font-weight: 500;
  text-align: center;
  font-size: large;
  margin-top: 0.5rem;
}

@media (min-width: 420px) {
  .btnContainer button {
    font-size: 14px;
  }
}
@media (min-width: 720px) {
  .btnContainer button {
    font-size: 13px;
  }
}
@media (min-width: 1024px) {
  .btnContainer button {
    font-size: 10px;
  }
}
@media (min-width: 1440px) {
  .btnContainer button {
    font-size: 18px;
  }
}
