/* ExtraFeatureDesktop.css */
.efdWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem;
  border-bottom: 11px solid #f2f2f2;
}

.slider-container {
  width: 100%;
  max-width: 600px;
  height: 113px !important;
}
.efdWrapper > h1 {
  text-align: left;
  padding-left: 0.2rem;
  width: 100%;
}

.efdContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  /* padding: 5px; */
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 10px;
  height: 90px;
  /* margin-right: 5px; */
}
.efdContainerDesktop {
  display: flex !important;
  flex: 0 0 28.33%;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  /* padding: 5px; */
  text-align: center;
  border: 2px solid lightgrey;
  /* border-radius: 10px; */
  height: 150px;
  /* margin-right: 5px; */
}

.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 10px; */
}

.efdImage {
  max-width: 34px;
  max-height: 100px;
  object-fit: contain;
  aspect-ratio: 3/4;
}

.efdTitle {
  font-size: 9px;
  /* margin-top: 10px; */
}

/* Slick Slider Custom Styles */
.slick-dots li button:before {
  color: #007bff;
}

.slick-prev,
.slick-next {
  color: #007bff !important;
}
.slick-prev,
.slick-next {
  font-size: 24px;
  color: #000; /* Change color as needed */
  z-index: 1;
  /* border: 1px solid black; */
}
.slick-slide {
  margin: 0 4px; /* Adjust the margin to increase the gap */
}

.slick-list {
  margin: 0 -4px; /* Compensate for the margin added to the slides */
}
/* Custom Arrow Styling */
.custom-arrow {
  position: absolute;
  top: 23%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  /* background-color: rgba(0, 0, 0, 0.5); Example background */
  /* color: white; Example text color */
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.custom-prev-arrow {
  left: -25px; /* Adjust position as needed */
}

.custom-next-arrow {
  right: -25px; /* Adjust position as needed */
}

/* Desktop */
.efdContainerDesktop .efdImage {
  max-width: 45px;
  max-height: 100px;
  object-fit: contain;
  aspect-ratio: 3 / 4;
}
.efdContainerDesktop .efdTitle {
  font-size: 16px;
  font-weight: 500;
}
.efdContainerDesktop .efdDesc {
  font-size: 10px;
  font-weight: 500;
  width: 80%;
}
.slider-track2 {
  gap: 4rem;
  padding: 1rem;
}
@media (min-width: 1440px) {
  .efdContainerDesktop {
    flex: 0 0 30.33%;
    height: 190px;
    /* margin-right: 5px; */
  }
  .efdContainerDesktop .efdTitle {
    font-size: 20px;
  }
  .efdContainerDesktop .efdDesc {
    font-size: 12px;
  }
  .efdContainerDesktop .efdImage {
    max-width: 50px;
  }
}
@media (min-width: 1800px) {
  .efdContainerDesktop {
    flex: 0 0 29.33%;

    height: 175px;
    /* margin-right: 5px; */
  }
  .efdContainerDesktop .efdTitle {
    font-size: 23px;
  }
  .efdContainerDesktop .efdDesc {
    font-size: 15px;
  }
  .efdContainerDesktop .efdImage {
    max-width: 60px;
  }
  .slider-track2 {
    gap: 7rem;
    padding: 1rem;
  }
}
