.vehicle-specs {
    display: flex;
    justify-content: space-between;
  }
  
  .spec-item {
    flex: 1; /* To evenly distribute items within each row */
    margin-right: 20px; /* Adjust margin as needed */
  }

  /* Define the card container */
.card-preview-container {
    width: 100%;
    margin: 0%;
    border: 2px solid #d1823a;
    padding: 1%;
    height: auto; /* Adjust this value as needed */
  }

  .card-preview-container {
    overflow-y: auto; /* This will add a vertical scrollbar if needed */
    overflow-x: hidden;
    max-height: 600px; /* Example height, adjust as needed */
}