.web_footer{
    display: flex;
    justify-content:space-evenly;
    background-color: rgb(82, 86, 100);
    color: white;
    align-items: flex-start;
    padding-top: 2%;
}

.web_address{
    list-style: none;
}

.web_footer_main{
    background-color: rgb(82, 86, 100);
    height: 35vh;
}

.web_copyright{
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}