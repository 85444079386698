.wrapper {
  padding: 0.4rem 0;
}

.container {
  /* border: 2px solid orange; */
  /* padding: 0 0.5rem; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 3rem;
  grid-auto-flow: column dense;
  /* gap: 0.6rem; */
}
.modalContainer {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 3rem;
  grid-auto-flow: column dense;
  border: #d1823a 1px solid;
  border-radius: 10px;
  padding: 4px;
  /* gap: 0.6rem; */
}
.pricefeesInputRelative {
  position: relative;
}
.pricefeesInputRelative .box_b_none_edit_add_button_big_Screen {
  display: none;
  position: absolute;
  top: 3rem;
  right: 40%;
}
.inputContainer {
  margin-bottom: 0.4rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.inputContainer:nth-child(-n + 10) {
  grid-column: 1;
}

.inputContainer:nth-child(n + 11):nth-child(-n + 20) {
  grid-column: 1;
}
/* .inputContainer:nth-child(n+11):nth-child(-n+20) {
    grid-column: 2;
  } */

.inputContainer .headerInputLabel {
  flex: 1;
}

.inputContainer .headerInputLabel:focus {
  border: 1px solid grey;
}

.inputContainer .inputLabelContainer {
  width: 40%;
  flex: 1;
  font-weight: 500;
  font-size: 14px;
  display: flex !important;
  align-items: center;
  gap: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  justify-content: space-between !important;
  margin-bottom: 0 !important;
}
.inputContainer .inputLabelContainerPopup {
  width: 40%;
  flex: 1;
  font-weight: 500;
  font-size: 11px;
  display: flex !important;
  align-items: center;
  gap: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between !important;
  margin-bottom: 0 !important;
}
.labelContainer {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}
.box_b_none_edit_add_button_small_screen_container {
  border: #d1823a 1px solid;
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.inputLabelContainerPopup .labelContainer span:first-child {
  border: 1px solid #d1823a;
  background-color: #f7dece;
  padding: 0.1rem 0.2rem;
  border-radius: 4px;
}

.editDeleteContainer {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #777472f7;
  font-size: larger;
}
.editDeleteContainer span:nth-child(2),
.editDeleteContainer span:nth-child(3) {
  cursor: pointer;
}

.inputContainer .inputField {
  flex: 0.35;
  border: none;
  border: none;
  box-shadow: 2px 2px 2px gray;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  padding: 4px;
  background-color: #fbeadf;
  color: #d1823a;
  border-radius: 10px;
}

.inputContainer input:focus {
  outline: none;
  border: none;
}
.box_b_none_edit_input_container {
  position: relative;
  width: 30%;
}
.box_b_none_edit_dollar_absolute_sign {
  position: absolute;
  left: 0.5rem;
  top: 15%;
}
.labelOrderIndexInput {
  height: 21px;
  width: 21px !important;
  padding: 0 !important;
  font-size: small;
}
.labelOrderIndexInput:focus {
  border: 1px solid black !important;
}
.labelPopoutHeaderDropDown {
  display: none;
}
.boxContainer .labelPopoutHeaderDropDown {
  display: block;
}
.labelPopoutHeaderContainer span {
  font-size: larger;
  color: #d1823a;
  width: 100%;
  text-align: center;
}
.labelPopoutHeaderDropDown {
  /* flex: 0.5;
    width: 50%; */
  margin: 0 !important;
  flex: 0.5;
}
.boxContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
}

@media (min-width: 420px) {
  .wrapper {
    padding: 0.4rem;
  }
  .container {
    row-gap: 0.5rem;
  }
}

@media (min-width: 720px) {
  .inputContainer .inputLabelContainer {
    font-size: 17px;
    /* color: #696666; */
  }
  .inputContainer .inputField {
    font-size: 19px;
    font-weight: 500;
  }
  .wrapper {
    padding: 1rem;
  }
}
@media (min-width: 1024px) {
  .inputContainer .inputLabelContainer {
    font-size: 16px;
  }
}
@media (min-width: 1298px) {
  .wrapper {
    /* padding: 1rem 0 0 3rem; */
  }
  .box_b_none_edit_add_button_small_screen_container {
    display: none;
  }
  .boxContainer .labelPopoutHeaderDropDown {
    display: none;
  }
  .container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    grid-auto-flow: column dense;
    /* gap: 0.6rem; */
  }

  .inputContainer:nth-child(n + 11):nth-child(-n + 20) {
    grid-column: 2;
  }
  .inputLabelContainerPopup .labelContainer span:first-child {
    padding: 0.1rem 0.4rem;
  }

  .pricefeesInputRelative {
    border-bottom: #d1823a 1px solid;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .pricefeesInputRelative .box_b_none_edit_add_button_big_Screen {
    display: block;
    position: absolute;
    top: 4rem;
    right: 40%;
  }
  .modalContainer {
    border: none;
    padding: 0;
  }
}
@media (min-width: 1440px) {
  .labelContainer {
    gap: 0.5rem;
  }
  .inputContainer .inputLabelContainer {
    font-size: 14px;
  }
  .inputContainer .inputLabelContainerPopup {
    font-size: 17px;
  }
}
