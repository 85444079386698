.dealer_welcome_modal_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(47 44 44 / 66%);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 1;
}

.dealer_welcome_modal_container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border: 1px solid black;
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    height: 60vh;
    width: 40%;
    /* margin-bottom: 20%; */
}

.buton_container {
    display: flex;
    gap: 2rem;
}

.buton_container button {
    border: none;
    color: white;
    background-color: #e8a262;
    font-weight: 600;
    padding: 1rem;
    border-radius: 0.2rem;
    width: 18rem;
    font-size: larger;
}

.skip_button_container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 3rem;
    font-size: larger;
}

.skip_button_container p {
    margin-right: 2rem;
    color: blue;
    /* text-decoration: underline; */
    cursor: pointer;
}

.skip_button_container_skip {
    display: flex;
    justify-content: center;
    align-items:center;
    margin-top: 7rem;
    font-size: larger;
}

.skip_button_container_skip p {
    color: blue;
    cursor: pointer;
}

.skip_button_container_status {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skip_button_container_status p {
    margin-top: 0px;
    margin-bottom: 0px;
}






.status-con {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
}

.status-stages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.msg>* {
    font-size: .9rem;
    text-align: center;
}

.guide {
    background-color: #827dbf !important;
}