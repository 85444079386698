.soldPaymentTableWrapper {
  width: 100%;
  padding: 0.5rem;
}
.soldPaymentPrintDeleteContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
}
.soldPaymentPrintDeleteContainer button {
  border: none;
}
.soldPaymentPrintDeleteContainer button:first-child {
  background-color: #d1823a;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  color: white;
  font-weight: 500;
}
.soldPaymentPrintDeleteContainer button:last-child {
  color: rgb(248, 48, 48);
  background-color: transparent;
  font-size: x-large;
}
.soldPaymentTableWrapper .soldPaymentTable {
  border: 1px solid #d1823a;
  width: 100%;
}
.soldPaymentTable {
  border-radius: 10px 10px 0 0;
}
.soldPaymentTable > thead > tr > th {
  background-color: #d1823a;
  color: white;
  border: 1px solid white;
  font-weight: 400 !important;
  font-size: 11px !important;
  text-align: center;
  padding: 0.3rem 0;
}
.soldPaymentTable > tbody > tr:nth-child(odd) td {
  background-color: #ffecdb;
}
.soldPaymentTable > tbody > tr:nth-child(even) td {
  background-color: rgb(238 236 236 / 31%);
}
.soldPaymentTable > tbody > tr > td {
  border: 1px solid white;
  font-weight: 500 !important;
  font-size: 13px !important;
  text-align: center;
  padding: 0.3rem 0;
}
.soldPaymentTable > tbody > tr > td:last-child {
  text-align: left;
  padding-left: 0.2rem;
}
.soldEmptyRow td {
  /* padding: 0.3rem; */
  color: transparent;
}
.paymentTableFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #d1823a;
  border-radius: 0 0 10px 10px;
  font-weight: 500;
  padding: 0.2rem;
  margin-bottom: 0.5rem;
}
.paymentTableFooter > span span:first-child {
  color: white;
}
.saveBtnContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
}
.saveBtnContainer label {
  font-size: small;
  font-weight: 500;
}
.saveBtnContainer label > input {
  margin-right: 1rem;
}
.saveBtnContainer button {
  background-color: #d1823a;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  border: none;
  padding: 0.5rem 1rem;
}


