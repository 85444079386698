/* Container and Row Adjustments */
.containerFluid {
    padding: 20px;
}

.row {
    margin: 0;
    padding: 0;
}

/* Card Styles */
.card {
    height: 100% !important;
    border: none;
    box-shadow: 0 4px 8px rgba(143, 143, 143, 0.521) !important;
    border-radius: 5px;
    background: linear-gradient(0.25turn, #f5f5f5, #ffb36da1);
}

.cardTitle {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 15px;
}

.cardText {
    font-size: .9rem;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
}

/* Right Section Styles */
.rightSection {
    text-align: center;
}

.rightSection img {
    max-width: 100%;
    border-radius: 10px;
    /* margin-bottom: 20px; */
}

/* Payment Overview Table */
.table {
    margin: 0;

}

.table>* {
    box-sizing: border-box;
}

/* .table th,
.table td {
    text-align: center;
} */

.table th {
    background-color: #f8f9fa;
}

.table td {
    background-color: #fff;
}

/* Button Styles */
.buttonRow {
    margin-top: 20px;
}

.buttonRow .btn {
    min-width: 100px;
}

.buttonRow .btnPrimary {
    background-color: #007bff;
    border-color: #007bff;
}

.buttonRow .btnSecondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.arrowUp {
    position: sticky;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e8a262;
    /* Adjust the color as needed */
}

/* Adjust the position of the card to make room for the arrow */
.cardWithArrow {
    position: relative;
    /* padding-top: 30px; */
    /* Adjust as needed to make space for the arrow */
}
