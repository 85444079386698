/* Css for contact Page  */

.contactContent {
    font-size: .93rem;
    width: 70%;
    color: gray;
    /* padding-left: 5%; */
    display: flex;
    justify-content: space-between;
}

.contactContent div {
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;

}

.contactIcons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;
}

.contactContent div p {
    margin-bottom: 0px;
    white-space: nowrap;
    /* Prevents text from wrapping to the next line */
    overflow: hidden;
    /* Hides the overflowed text */
    text-overflow: ellipsis;
    /* Adds "..." at the end of the text */
    /* max-width: 150px; */
    /* Adjust the max-width as needed */
    /* Optional: To ensure that the text doesn’t stretch the container */
    flex-shrink: 1;
}

.addContact {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 2%;
    top: 1%;
    border: 1px solid #fcc98e;
    background-color: #fcc98e;
    border-radius: 50%;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.addContact svg {
    width: 100%;
    height: 100%;
    background-color: #fcc98e;
    color: white;
    font-weight: 700;

}

.uploadPro {
    height: 15vh;
    display: flex;
    justify-content: center;
}

.profileLable {
    box-sizing: border-box;
    width: 70%;
    height: 100%;
    border-radius: 10px;
}

.profileLable img {
    border-radius: inherit;
}

.contactLable {
    width: 95%;
    border: 1px solid lightgray;
    /* color: rgb(0, 174, 255); */
}

.contactInput {
    outline: none;
    border: none;
    border-bottom: 2px solid lightgray;
}

.contactInput:focus {
    outline: none;
}

.customTextarea {
    width: 100%;
    height: 85px;
    font-family: monospace;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: black;
}

.saveContect {
    width: 40px;
    height: 40px;
    /* position: absolute;
    right: 2%;
    top: 1%; */
    border: 1px solid #fcc98e;
    background-color: #fcc98e;
    border-radius: 50%;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.saveContect svg {
    width: 100%;
    height: 100%;
    background-color: #fcc98e;
    color: white;
}