.container{
    display: flex;
    padding: 3rem;
    font-family: "Alata";
}

.verificationContainer{
    display: flex;
    border: 1px solid #d1823a;
    border-radius: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    gap: 1rem;
    width: 100%;
}
.settingContentTextInput{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.rightInput{
    display: flex;
    box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
    border-radius: 8px;
}

.rightInput input{
    border: none;
    height: 3rem;
    border-radius: 8px;
    letter-spacing: 2px;
    font-size: large;
    font-weight: 600;
    background-color: #F6DFCF;
    /* box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8); */
}
.rightInput span {
    background-color: #d1823a;
    padding: 0.5rem;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: large;
    cursor: pointer;
  }
@media (min-width: 300px) and (max-width: 600px) {
    .container{
        display: flex;
        margin-top: 3rem;
        padding: 0px;
        font-family: "Alata";
    }
    .settingContentTextInput{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    .leftText{
        width: 100%;
        }
        
   .rightInput{
            width: 100%;
            display: flex;
        }
}