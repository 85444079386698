

.heading{
    text-align: center;
    border-top: 2px solid #e6e2e2;
}
.filterCard{
    text-align: center;
    border-bottom: 2px solid #e6e2e2;
}
.countingContainer{
    border-bottom: 2px solid #e6e2e2;
}
.counting{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 2%;
    margin-right: 2%;
}
.active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.active p{
    font-size: 18px;
    font-weight: 600;
    color: black;
}
.active h2{
    color: #d1823a;
}
.cardContainer{
    display: flex;
    margin-top: 20px;
    margin-left: 2%;
    margin-right: 2%;
}
.card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 200px;
    width: 100%;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.cardImageContainer{
    display: flex;
    flex-direction: column;
}

.cardImage {
    width: 200px;
    height: 200px; /* adjust the height as needed */
    border-radius: 1rem;
}

.cardImage img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover; /* ensures the image covers the div without distortion */
}

.cardImageText {
    bottom: 10px;
    left: 10px;
    color: black;
    padding: 5px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.cardImageText span {
    font-size: 14px;
    font-weight: bold;
}

.cardText {
    position: relative;
    text-align: left;
    width: 100%;
    padding-top: 1rem;
    padding-left: 10px;
}
.mileageCount{
    display: flex;
    gap: 10px;
}
.cardText h2 {
    font-size: 18px;
    margin: 10px 0;
}

.cardText p {
    position: absolute;
    top: 0;
    right: 0;
    color: #d1823a;
}

.rightArrowClick{
    position: absolute;
    width: 30PX;
    height: 30px;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    font-size: 18px;
    text-align: center;
    background-color: #d1823a;
    color: white;
    box-shadow: 1px 2px 3px 2px grey;
}
/*---------------------------------------- for filter------------------------------------------- */
.filterCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .filterIcons{
    background-color: #d1823a;
    width: 40px;
    height: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 26px;
  }
  .searchContainer {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .searchInput {
    width: 100%;
    padding: 8px 12px;
    padding-right: 30px; /* Space for the search icon */
    border: 1px solid #d1823a;
    border-radius: 4px;
    box-shadow: inset 0px 0px 13px 2px #d1823a;
  }
  
  .searchIcon {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
  
  .searchIcon i {
    font-size: 16px; /* Adjust size of the icon */
  }
@media (min-width: 500px) and (max-width: 600px) {
    .vehicleName{
        font-size: 15px !important;
    }
  }

  @media (min-width: 300px) and (max-width: 500px) {
    .cardImage {
        width: 150px;
        height: 150px; /* adjust the height as needed */
        border-radius: 1rem;
    }
    .cardImageText span {
        font-size: 10px;
        font-weight: bold;
    }
    .cardText h2 {
        font-size: 10px;
        margin: 5px 0;
    }
    .card{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 150px;
        width: 100%;
        border: 1px solid grey;
        border-radius: 10px;
        padding: 0.5rem;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
    .cardText p {
        position: absolute;
        top: 0;
        right: 0;
        color: #d1823a;
        font-size: 10px;
    }
    .active p{
        font-size: 10px;   
     }
  }
  /*  for filter in mobile screen -----------------------------------------------*/
  .filterContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: -300px; /* Start the filter off-screen */
    width: 359px;  /* Fixed width for the filter container */
    height: 100%;
    justify-content: start;
    background-color: white;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
  .filterContainer > :last-child {
    margin-top: auto; /* Push the last child to the bottom */
  }
  .filterContainer.active {
    transform: translateX(-300px); /* Slide the container into view by 300px */
  }
  
  .filterHeader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    color: grey;
    z-index: 1100;
  }
  
  .closeButton:hover {
    color: red; /* Optional: adds a hover effect */
  }
  .yeraFilter{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid grey;
  }
  .yeraFilter h4{
    margin-left: 10px;
    font-family: 'Alata';
  }

.makeFilter{
    padding-left: 11px;
    border-top: 1px solid grey;
    padding-top: 11px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.makeFilter input{
    border: none;
    background-color: #f1eaea;
    border-radius: 5px;
}
.makeFilter h4{
    font-family: 'Alata';
}

.modelFilter{
    padding-left: 11px;
    padding-top: 11px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.modelFilter input{
    border: none;
    background-color: #f1eaea;
    border-radius: 5px;
}
.modelFilter h4{
    font-family: 'Alata';
}
.bodyTypeFilter{
    padding-left: 11px;
    padding-top: 11px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.bodyTypeFilter input{
    border: none;
    background-color: #f1eaea;
    border-radius: 5px;
}
.bodyTypeFilter h4{
    font-family: 'Alata';
}
.sortByFilter{
    padding-left: 11px;
    padding-top: 11px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.sortByFilter input{
    border: none;
    background-color: #f1eaea;
    border-radius: 5px;
}
.sortByFilter h4{
    font-family: 'Alata';
}

.container_make{
    display: flex;
    flex-wrap: wrap;
    max-height: 150px;
    overflow-y: auto;
    /* border: 1px solid #ccc; */
    padding: 5px;
}
.item_make{
    width: 50%;
    box-sizing: border-box;
    padding: 5px; 
    text-align: center;
    border: 1px solid #ddd; 
    margin-bottom: 5px; 
    font-family: "Alata";
    font-weight: 400;
    color: grey;
}
.filterFooterContainer{
    width: 100%;
}
.footerContant{
    padding: 1rem;
    gap: 1rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid grey;
}
.footerContant_btn1{
    border: 1px solid black;
    background-color: transparent;
    padding: 5px;
    width: 100px;
    font-family: 'Alata';
    font-weight: 400;
    color: black;
    border-radius: 5px;
}
.footerContant_btn2{
    border: none;
    background-color: #d1823a;
    font-family: 'Alata';
    font-weight: 400;
    width: 100px;
    padding: 5px;
    color: black;
    border-radius: 5px;
}
/* for designning of publish page */
.publishContainer{
    padding-top: 5px;
    width: 100%;
    border-top: 2px solid grey;
}
.publishContainer button{
    position: absolute;
    background-color: transparent;
    top: 16%;
    left: 2%;
    border: none;
}
.publishVehicleName{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1%;
    font-family: 'Alata';
    border-bottom: 2px solid grey;
    padding-bottom: 1%;
}