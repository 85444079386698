.card {
  background: rgb(231, 231, 231);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  border: 2px solid black !important;
}

.wrapper_card_product {
  /* border: 2px solid red !important; */
  border: 1px solid black;
}

.wrapper_card_product:hover {
  background-color: rgb(219, 214, 214);
}

.card_container {
  display: flex;
  padding: 0 !important;
  border: 1px solid gray;
  background-color: white;
}

.wrapper_card_product:hover .card_container {
  background-color: rgb(219, 214, 214);
}

.card_left {
  flex: 0.2 !important;
  /* border: 2px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-image-box {
  /* border: 2px solid black; */
  flex: 1;
  display: flex;
  width: 280px;
  height: 160px;
  max-width: 100% !important;
  padding-top: 1rem !important;
  background-color: #ebebeb;
  border-radius: 5px;
}

.card_left img {
  /* border: 3px solid pink; */
  width: 100% !important;
  max-width: 100% !important;
  /* flex: 1 ; */
}

.card_center {
  flex: 0.8 !important;
  /* border: 2px solid red; */
  padding-top: 0.6rem;
  display: flex;
}

.center_primary {
  /* border: 2px solid black; */
  flex: 70% !important;
}

.center_secondary {
  /* border: 2px solid black; */
  flex: 23% !important;
  padding-bottom: 0.3rem;
}

.center_secondary h5 {
  /* border: 2px solid blue; */
  /* text-align: right; */
}

.call_seller_button_container {
  /* border: 2px solid black; */
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: end;
  gap: 0.4rem;
}

.product-g-btn,
.product-S-btn {
  width: 70%;
  border-radius: 3px;
  text-align: center;
}

.contact_message_section {
  /* border: 2px solid red; */
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}

.input_contact_message_section {
  display: flex;
  margin: 0;
  gap: 0.6rem;
  margin-top: 0 !important;
}

.input_contact_message_section input {
  background-color: rgb(201, 197, 197);
  border: none;
  outline: none;
  border-radius: 0.4rem;
}

.input_contact_message_section input::placeholder {
  font-weight: 500;
}

.input_contact_message_section button {
  border: none;
  background-color: #d1823a;
  color: white;
}

.card_right {
  flex: 0.2 !important;
  /* border: 2px solid blue; */
  /* padding-top: 0.5rem; */
}

.product_share_like {
  width: 30px;
  height: 30px;
  position: absolute;
  top: .5rem;
  right: 3%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.product_share_like span {
  background-color: black;
  border-radius: 55%;
  padding: 0.05rem 0.35rem;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.interior_exterior_color_container {
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
  /* gap: 0.3rem; */
  margin: 0.6rem 0;
}

.interior_exterior_color_container span {
  /* border: 1px solid gray; */
  /* padding: 0.1rem 0.8rem; */
  /* border-radius: 15px; */
  font-weight: 500;
}

.product-g-btn,
.product-T-btn,
.product-E-btn,
.product-S-btn {
  /* border: 2px solid red; */
  display: flex;
  justify-content: start;
  /* gap:1rem; */
  gap: 0.5rem;
  font-size: medium;
  font-weight: 500;
  padding: 0.3rem 1rem;
}

.product_listing_messenger {
  /* border: 2px solid red; */
  display: flex;
  justify-content: start;
  align-items: center;
}

.product_listing_messenger span:nth-child(2) {
  font-weight: 500;
}

.product-message {
  width: 90%;
  /* margin-left: 10%; */
  /* border: 2px solid red; */
}

.year_make_model_text {
  font-size: xx-large;
  font-weight: 700;
  /* border: 2px solid blue; */
}

.vin_extras_container {
  /* border: 2px solid blue; */
  display: flex;
  gap: 2rem;
  margin: 0 !important;
  padding: 0;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
}

.seller_section_product_listing {
  margin-top: 1rem;
}

.seller_section_product_listing label {
  align-items: center !important;
  /* margin-top: -0.4rem; */
}

.seller_report_icons_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.seller_report_icons {
  width: 40px;
}

.social_media_container {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  right: -30px;
}

.tyre_condition_container {
  /* border: 2px solid red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  column-gap: 3px;
  font-size: small;
}

.tyre_fl_container,
.tyre_fr_container,
.tyre_bl_container,
.tyre_br_container {
  border-radius: 50%;
  border: 4px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bottom_items_container {
  border: 1px solid darkslategray;
  display: flex;
  /* border: 2px solid red; */
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px;
}

.wrapper_card_product:hover .bottom_items_container {
  background-color: rgb(219, 214, 214);
}

@media screen and (max-width: 1500px) {

  .product-g-btn,
  .product-S-btn {
    padding: 0.3rem 0.8rem;
    width: 70% !important;
  }

  .center_primary h4 {
    font-size: 1.4rem;
  }

  .center_secondary h5 {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 1300px) {

  .product-g-btn,
  .product-S-btn {
    padding: 0.3rem;
    width: 86%;
  }

  .center_primary h4 {
    font-size: 1.3rem;
  }

  .center_secondary h5 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1100px) {

  .product-g-btn,
  .product-S-btn {
    padding: 0.1rem;
    width: 90%;
  }
}

