.dealerBuyerInfoWrapper {
  box-shadow: inset 0 0 5px 5px rgb(232, 162, 98);
  padding: 2rem 0.5rem;
}
.main-container-state-data {
  display: flex;
  justify-content: space-between;
}
.buyerHeaderWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_buyer {
  background-color: #fce8cf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #747171;
  border-radius: 5px;
  height: auto;
  /* padding-left: 19%; */
  text-align: center !important;
  width: 100%;
}

.goto_next_page_btn {
  background-color: #d1823a;
  color: white;
  border: none;
  border-radius: 5px;
  width: 80px;
  height: 30px;
  font-weight: 700;
}

.goto_next_page_btn:hover {
  background-color: lightgray;
  color: black;
}
.BuyingCarDetails {
  display: flex;
  flex-direction: column;
}
.BuyingCarNumbers {
  display: flex;
  justify-content: space-between;
}

/* buyer header options */
.buyerHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.buyerEntityCoBuyerContainer {
  display: flex;
  margin-right: 0.3rem;
  gap: 0.3rem;
}
/* Buyer content  */
.checkbox-container1 {
  font-size: small;
  font-weight: 500;
}
.buyerResetIcon {
  height: 20px;
}
.goto_next_page_btn {
  font-size: 12px;
  width: auto;
  padding: 0.3rem 0.7rem;
}

.BIBuyerLine{
  border: 1px solid #d1823a;
}
.buyerAddressParent {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.nameEntityContainer {
  gap: 0.5rem;
}
.allIdContainer {
  gap: 0.7rem;
  margin: 1rem 0;
}
.buyerNameLabel {
  font-size: 11px;
  font-weight: bold;
}
.cityStateZipCodeFrame {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.address2CountyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}
.emailPhoneContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  
}
.emailPhoneContainer input {
  width: 100%;
}
.emailPhoneContainer input {
  padding: 3px !important;
}

.sameAddressLabel {
  font-size: 10px;
  font-weight: 600;
  width: 70%;
}
.BIphoneNumberInput{
  width: 100%;
  margin-bottom: 1rem;
}
@media (min-width: 370px) {
  .buyerEntityCoBuyerContainer {
    gap: 1.3rem;
  }
}

@media (min-width: 720px) {
  .BIphoneNumberInput{
    width: 25% !important;
  }
  .dealerBuyerInfoWrapper {
    box-shadow: inset 0 0 5px 5px rgb(232, 162, 98);
    padding: 2rem 1rem;
  }
  .BuyingCarDetails {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;
    box-shadow: 0px 8px 9px -2px rgba(0, 0, 0, 0.25);
  }
  .BuyingCarNumbers {
    gap: 1rem;
  }
  .buyerEntityCoBuyerContainer {
    gap: 2.3rem;
  }
  .buyerHeaderContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
  .checkbox-container1 {
    font-size: medium;
  }
  .nameEntityContainer {
    gap: 6rem;
  }
  .buyerNameLabel {
    font-size: 16px;
  }
  .allIdContainer {
    gap: 5.7rem;
  }
  .buyerNameLabel {
    font-size: 16px;
  }
  .buyerAddressParent {
    flex-direction: row;
    gap: 0.7rem;
  }
  .cityStateZipCodeFrame {
    gap: 0rem;
  }

  .buyerAddressInput {
    padding: 9px !important;
  }
  .emailPhoneContainer input {
    font-size: small;
    font-weight: 700;
  }
  .buyerCityStateZipCodeZipCodeFrame {
    width: 30%;
  }
  .pac-container {
    width: 50% !important;
  }
}
@media (min-width: 1024px) {
  .BuyingCarDetails {
    padding: 1.4rem 0;
    font-size: large;
  }
  .BuyingCarNumbers {
    gap: 1.5rem;
  }
  .buyerNameLabel {
    font-size: 18px;
  }
  .sameAddressLabel {
    font-size: 15px;
  }
}
@media (min-width: 1440px) {
  .BuyingCarDetails {
    padding: 1.4rem 0;
    font-size: x-large;
  }
  .BuyingCarNumbers {
    gap: 1.5rem;
  }
  .checkbox-container1 {
    font-size: large;
  }
  .goto_next_page_btn {
    font-size: large;

    padding: 0.5rem 1.9rem;
    display: grid;
    place-content: center;
  }
  .buyerContainer {
    padding: 0 3.5rem !important;
  }
  .nameEntityContainer {
    gap: 15rem;
  }
  .allIdContainer {
    gap: 14.7rem;
  }
  .pac-container {
    width: 33% !important;
  }
  .BIphoneNumberInput{
    width: 21% !important;
  }
}
@media (min-width: 1800px) {
  .buyerContainer {
    padding: 0 12rem !important;
  }
  .nameEntityContainer {
    gap: 19.5rem;
  }
  .allIdContainer {
    gap: 19.4rem;
  }
  .pac-container {
    width: 30% !important;
  }
  .BIphoneNumberInput{
    width: 19% !important;
  }
}
@media (min-width: 2240px) {
  .goto_next_page_btn {
    font-size: larger;

    padding: 1.4rem 1.9rem;
  }
  .checkbox-container1 {
    font-size: x-large;
  }
  .pac-container {
    width: 25% !important;
  }
}
