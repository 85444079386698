/* .container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(140deg, rgb(255, 255, 255), rgb(255, 255, 255));
  } */
  
  .calculator {
    padding: 0px;
    border-radius: 10px;
    background-color: #949494;
    box-shadow: 
        inset 0 0 22px #949494; /* Inner shadow */
  }
  
  .input_calculator{
    border: none;
    outline: 0;
    width: 27px;
    height: 27px;
    padding: 0px !important;
    font-size: 18px;
    background-color: #d9d9d9;
    box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.6);
    margin: 12px;
    border-radius: 10px;
    color: #060606;
    font-weight: bolder;
    cursor: pointer;
  }
  
  /* form input[type="button"]:hover {
    background-color: rgb(255, 94, 0);
  } */
  
  form .display {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0px 15px 0px;
  }
  
  form .display input {
    text-align: left;
    flex: 1;
    font-size: 40px;
    padding: 5px 10px;
    background-color: #949494;
    color: white;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 2px solid grey;
    /* border-radius: 10px; */
  }
  
  form input.equal{
    width: 123px;
  }

  @media screen and (max-width: 600px) {
    .input_calculator{
      border: none;
      outline: 0;
      width: 39px;
    height: 39px;
    font-size: 18px;
      background-color: #d9d9d9;
      box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.6);
      margin: 2px;
      border-radius: 10px;
      color: #060606;
      font-weight: bolder;
      cursor: pointer;
    }
    .btn-parent-container{
      margin-left: 2%;
    }
  }
  @media screen and (max-width: 400px) {
    .input_calculator{
      border: none;
      outline: 0;
      width: 39px;
      height: 39px;
      font-size: 18px;
      background-color: #d9d9d9;
      box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.6);
      margin: 2px;
      border-radius: 10px;
      color: #060606;
      font-weight: bolder;
      cursor: pointer;
    }
    .btn-parent-container{
      margin-left: 2%;
    }
  }

  .display {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .display input {
    padding-right: 30px; /* Add some space to the right of the input */
  }
  
  .close-icon {
    position: absolute;
    right: 10px; /* Position the X icon to the right */
    cursor: pointer;
    font-size: 29px; /* Adjust the size of the X icon */
    color: white; /* Set the color of the X icon */
  }
  
  .close-icon:hover {
    color: red; /* Change the color when hovering over the X */
  }