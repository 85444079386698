.login_feature_wrapper {
    /* border: 2px solid blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #515558;
    height: 100%;
  }
  .login_feature_container {
    /* border: 2px solid white; */
    width: 80%;
  }
  .input_and_icon{
border-bottom: 2px solid white;
margin: 0.5 0;
  }
  .input_and_icon input{
    background-color: transparent;
    color: white;
  }
  .input_and_icon input::placeholder{
    background-color: transparent;
    color: white;
  }