.wrapper {
  /* border: 2px solid #d1823a; */
  height: 100%;
  width: 100%;
  background-color: white;
  /* border: 2px solid #d1823a; */
  /* padding: 0.3rem; */
  /* box-shadow: inset 0px 0px 9px 3px #d1823a; */
}
.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.resetEditButtonContainer {
  display: flex;
  margin-bottom: 0.5rem;
  border: none;
  justify-content: flex-end;
  padding-right: 2rem;
  margin-right: 0.5rem;
  gap: 1rem;
  align-items: center;
}
.resetEditButtonContainer > button:first-child {
  border: none;
  background-color: transparent;
  color: #d1823a;
  font-size: 30px;
}
.resetEditButtonContainer > button:last-child {
  background-color: #e3b68c;
  color: white;
  border: none;
  padding: 0.1rem 1.7rem;
  border-radius: 5px;
  /* cursor: pointer; */
  font-size: 20px;
  margin-right: 1rem;
  box-shadow: inset 0px 0px 9px #d1823a, inset 0px 0px 18px #d1823a;
  font-weight: 500;
}
.contentContainer .content_left,
.contentContainer .content_right {
  background-color: #ebeaea;
}
.contentContainer .content_left {
  border-radius: 10px;
}

.contentContainer .content_right {
  border-radius: 10px;
}

.labelPopoutHeaderContainer {
  width: 100% !important;

  align-items: center;
  display: flex !important;
}
.labelPopoutHeaderDropDown {
  display: none;
}
.boxContainer .labelPopoutHeaderDropDown {
  display: block;
}
.labelPopoutHeaderContainer > span {
  font-size: larger;
  color: #d1823a;
  width: 50%;
  text-align: center;
}
.labelPopoutHeaderDropDown {
  /* flex: 0.5;
  width: 50%; */
  margin: 0 !important;
  /* flex: 0.5; */
}
.boxContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.box_b_none_edit_add_button_small_screen_container {
  border: #d1823a 1px solid;
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.salesInventoryTaxContainer {
  display: none;
}
.salesInventoryTaxContainerMobile {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.salesTaxContainer,
.InventoryTaxContainer {
  display: flex;
  font-size: large;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0;
}

.salesTaxContainer > input,
.InventoryTaxContainer > input {
  width: 50%;
  box-shadow: inset 0px 0px 9px 3px lightgrey;
  border: none;
  outline: none;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
}
.salesInventoryTaxContainerMobile .salesTaxContainer > span,
.salesInventoryTaxContainerMobile .InventoryTaxContainer > span {
  width: 50%;
  font-weight: 500;
  font-size: 15px;
}

@media (min-width: 420px) {
  .contentContainer .content_right {
    padding: 0 0.7rem;
  }
}
@media (min-width: 720px) {
  .wrapper {
    border: 2px solid #d1823a;
    box-shadow: inset 0px 0px 9px 3px #d1823a;
    padding: 1rem;
  }
}

@media (min-width: 1024px) {
  .salesInventoryTaxContainer {
    display: flex;
    flex-direction: row;
  }
  .salesInventoryTaxContainerMobile {
    display: none;
  }
  .wrapper {
    border: 2px solid #d1823a;
    padding: 1rem;
    box-shadow: inset 0px 0px 9px 3px #d1823a;
  }
  .contentContainer {
    flex-direction: row;
    width: 100%;
    gap: 1rem;
  }
  .contentContainer .content_left {
    flex: 0.65;
    border-radius: 14px;
  }

  .contentContainer .content_right {
    flex: 0.35;
    border-radius: 14px;
    padding-top: 1rem;
  }

  .noInventoryContainer {
    /* border: 2px  solid red; */
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 24rem;
  }
  .noInventoryContainer img {
    height: 120px;
    width: 170px;
    border: 1px solid grey;
  }
  .noInventoryContainer p {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: xx-large;
    font-weight: 700;
    transition: transform ease-in-out 0.4s;
  }
  .noInventoryContainer p :hover {
    transform: scale(1.05);
  }
  .box_b_none_edit_add_button_small_screen_container {
    display: none;
  }
  .labelPopoutHeaderContainer {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
  }
  .labelPopoutHeaderDropDown {
    display: block;
  }
  .boxContainer .labelPopoutHeaderDropDown {
    display: none;
  }
  .resetEditButtonContainer > button:last-child {
    margin-right: 0rem;
  }
}
@media (min-width: 1440px) {
  .wrapper {
    padding: 2rem;
  }
}

.selectSaleType {
  background-color: #d1823a;
  color: white;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 2px 4px 1px #807c78;
}

.percentSymbolSalesTax {
  position: absolute;
  /* right: 47%; */
  left: 31%;
  font-weight: bold;
  font-size: 12px;
  pointer-events: none;
}

@media (min-width: 1800px) {
  .contentContainer .content_left {
    flex: 0.7;
    border-radius: 14px;
  }

  .contentContainer .content_right {
    flex: 0.3;
    border-radius: 14px;
    padding-top: 1rem;
  }
}
