.dealer_facilities_header_wrapper {
  /* border: 2px solid red; */
  /* background-color: #515558; */
}
.dealer_facilities_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 4rem; */
  padding: 1rem 2rem;
}
.facilties_logo_container_dealer{
/* border: 2px solid blue; */
font-weight: 700;
font-size: 33px;
}
.facilties_logo_container_dealer a span:nth-child(2n+1){
  color: #e8a262;
  margin-right: 0.4rem;
}
.facilties_logo_container_dealer a span:nth-child(2n){
  color: black;
  margin-right: 0.4rem;
}

.dealer_facilities_header_container p {
  color: white;
  /* background-color: #e8a262; */
  /* font-weight: 700; */
  /* padding: 0.7rem 1.2rem; */
  /* align-self: flex-end; */
  border-radius: 0.3rem;
  /* font-size: larger; */
  font-size: medium;
  padding: 0.1rem 0.7rem;
  /* margin-bottom: -0.3rem; */
  /* border: 2px solid red; */
}
.dealer_credibility_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dealer_credibility_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.credibility_header {
  text-align: center;
  font-weight: 600;
  font-size: large;
  padding: 1rem 0;
  text-shadow: 2px 2px 2px gray;
}
.credibility_areas {
  display: flex;
  gap: 0.5rem;
}
.credibility_areas p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  padding: 1rem 0.4rem;
  border-radius: 0.5rem;
  gap: 0.3rem;
  width: 70px;
}
.check_icon {
  color: rgb(153, 22, 22);
  font-size: larger;
  text-align: center;
}
.credibility_areas .credibility_areas_text {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: small;
}
.credibility_areas_text span:nth-child(2) {
  font-weight: 600;
}
@media (min-width: 720px) {
  .dealer_facilities_header_container {
    padding: 1rem 4rem;
    /* border: 2px solid blue; */
  }
  .dealer_facilities_header_container img {
    height: 50px;
    width: 90px;
  }
  .dealer_facilities_header_container p {
    font-weight: 500;
    padding: 0.4rem 1.2rem;
    align-self: flex-end;
    font-size: large;
    margin-bottom: 0;
    /* margin-top: -1.5rem; */
  }
  /* Dealer Credibility Area */

  .dealer_credibility_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .credibility_header {
    font-weight: 700;
    font-size: xx-large;
    padding: 1rem 0;
    text-shadow: 2px 2px 2px gray;
  }
  .credibility_areas {
    display: flex;
    gap: 1rem;
  }
  .credibility_areas p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    padding: 1rem 0.4rem;
    border-radius: 0.5rem;
    gap: 0.3rem;
    width: 150px;
  }
  .check_icon {
    color: rgb(153, 22, 22);
    font-size: xx-large;
    text-align: center;
    margin-bottom: 1rem;
  }
  .credibility_areas .credibility_areas_text {
    text-align: center;
    font-size: large;
  }
  .credibility_areas .credibility_areas_text span {
    padding: 0 !important;
  }
  .credibility_areas_text span:nth-child(2) {
    font-weight: 600;
  }
}

@media (min-width: 1024px) {
    .credibility_header {
        margin-bottom: 4rem;
      }
    .credibility_areas {
        display: flex;
        gap: 3rem;
      }
      .credibility_header {
        font-weight: 800;
        font-size: 50px;
        padding: 1rem 0;
      }
      /*  */
      .credibility_areas p {
       gap: 1rem;
        width: 200px;
        height: 250px;
        border-right: 3px solid black;
    border-bottom: 3px solid black;
      }
      .check_icon {
        color: rgb(153, 22, 22);
        font-size: xx-large;
        text-align: center;
      }
      .credibility_areas .credibility_areas_text {
        
        text-align: center;
        font-size: x-large;
      }
      .credibility_areas_text span:nth-child(2) {
        font-weight: 600;
      }
}
