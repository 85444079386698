.sidebar {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    height: 87vh;
}

.emailPro select {
    background-color: white;
    padding: 0px;
    margin-bottom: 15%;
    color: gray;
}

.slot {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10%;
}

.slot>div {
    font-size: .75rem;
    width: 16%;
    margin: 2%;
    padding: 1%;
    border: 1px solid #e8a262;
    text-align: center;
    border-radius: 3px;
}

.slot .selected {
    background-color: gray;
    border: 1px solid gray;
    color: white;
}

.dayWeekCon {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 10%;
}

.day {
    width: 32% !important;
    text-align: center;
    border: 1px solid #e8a262;
    background-color: #e8a262;
}

.week {
    margin: 0px 2%;
}

.week,
.month {
    width: 32% !important;
    text-align: center;
    border: 1px solid #e7c19c;
    background-color: #e7c19c;
}



.am {
    margin: 1%;
    padding: 1% 3%;
    background-color: #e8a262;
    color: white;
    font-weight: 700;
    text-align: center;
    font-size: large;
}

.pm {
    margin: 1%;
    padding: 1% 3%;
    border: 1px solid #e8a262;
    font-weight: 700;
    text-align: center;
    font-size: large;
}

.selectedSlot {
    height: 7vw;
    display: flex;
    justify-content: space-around;

}

.selectedDay,
.selectedTime {
    width: 7vw;
    height: 7vw;
    font-size: larger;
    font-weight: 700;
    color: white;
    background-color: #e7c19c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.selectedDate {
    font-size: larger;
    font-weight: 700;
    color: gray;
}

.selectedTime span {
    color: black !important;
    font-size: .7rem;
}



.custom-day-view>* {
    background-color: greenyellow !important;
    border: 2px solid red !important;
    color: violet !important;
}

.eventCon {}

.eventHead {
    background-color: #ffdaafda;
    padding: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* text-align: center; */
}

.eventHead svg {
    color: #e8a262;
    /* margin-right: 10%; */
}

.eventList {
    list-style: none;
    padding: 5% 2%;
}

.event {
    color: rgb(21 0 183);
    font-size: .9rem;
    /* text-align: center; */
}







.day-content {
    padding: 8px;
    cursor: pointer;
    text-align: center;
}

.current-date {
    background-color: blue;
    color: white;
}

.selected-date {
    background-color: lightblue;
    color: black;
}

.day-header {
    font-weight: bold;
}

.selected-date-display {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}