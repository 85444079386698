.wrapper {
  /* border: 1px solid red; */
  width: 100vw;
  margin-bottom: 2rem;
}
.container {
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.imgContainer {
  height: 300px;
  width: 100%;
}
.imgContainer img {
  max-height: 390px;
  width: 100%;
}
.context {
  padding: 0.5rem;
  font-size: medium;
  font-weight: 500;
}
.context p:nth-child(1),
.context p:nth-child(3) {
  padding: 0.5rem 0.3rem;
  font-size: x-large;
  font-weight: 600;
  margin-bottom: 0;
}

@media (min-width: 720px) {
  .container {
  
    gap: 3rem;
  }
  .imgContainer {
    height: 500px;
    width: 85%;
  }
  .imgContainer img {
    max-height: 100%;
    width: 100%;
  }
  .context {
    padding: 0.5rem;
    font-size: medium;
    font-weight: 500;
    width: 85%;
  }
  .context p:nth-child(1),
  .context p:nth-child(3) {
    padding: 0.5rem 0.3rem;
    font-size: x-large;
    font-weight: 600;
    margin-bottom: 0;
  }
}
@media (min-width: 1440px) {

    .container {
      /* border: 1px solid black; */
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 2rem;
      margin-bottom: 2rem;
    }
    .imgContainer {
     object-fit: contain;
      flex: 0.7;
    }
    .imgContainer img {
     
    }
    .context {
      padding: 0.5rem;
      /* font-size: xx-large; */
      font-weight: 700;
      flex: 1;
      padding: 0 5rem;
    }
    .context p{
      margin-bottom: 1rem;
    }
    .context p:nth-child(1),
    .context p:nth-child(3) {
      padding: 0.5rem 0.3rem;
      /* font-size: 45px; */
      font-weight: 800;
      margin-bottom: 0;
    }
  }
@media (min-width: 2200px) {

  .container {
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    margin-bottom: 2rem;
  }
  .imgContainer {
   object-fit: cover;
    flex: 0.7;
    
  }
  .imgContainer img {
   height: 1000px;
  }
  .context {
    padding: 0.5rem;
    font-size:30px;
    font-weight: 700;
    flex: 1;
    padding: 0 5rem;
  }
  .context p{
    margin-bottom: 1rem;
  }
  .context p:nth-child(1),
  .context p:nth-child(3) {
    padding: 0.5rem 0.3rem;
    font-size:35px;
    font-weight: 800;
    margin-bottom: 0;
  }
}
