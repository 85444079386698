.header {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  color: #d1823a;
  font-family: Alata;
}
.bodyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Alata;
}
.ImageTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.ImageTextContainer img {
  height: 100px;
  width: 100px;
}
.ImageTextContainer span {
  font-weight: 500;
  font-size: 15px;
}
.messageContent {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}
