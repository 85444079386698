:root {
  --orange: rgb(232, 162, 98);
}

.v-list-head {
  width: 50%;
  border: 3px solid rgb(232, 162, 98);
  color: rgb(232, 162, 98);
  text-align: center;
  border-radius: 5px;
}

/* .outer-box {
    border: 1px solid rgb(232, 162, 98);

} */

.no-list {
  width: 90%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: rgb(187, 187, 187);
  margin: 5%;
}

.no-list h2,
.no-list h2 a {
  color: #2063b2 !important;
  font-size: 3rem !important;
}

.no-list h2 a {
  text-decoration: none !important;
}

.no-list h2 a:hover {
  color: #2776cf !important;
}

.search-all,
.search-btn {
  background-color: var(--orange) !important;
  border-radius: 0px !important;
}

.orange-btn {
  background-color: var(--orange) !important;
  border-radius: 3px;
}

.orange-btn svg {
  height: 30px !important;
  color: white !important;
  width: 100% !important;
}

.card-parent {
  /* width: 88%; */

  /* max-height: 65vh !important; */
  /* overflow-y: scroll; */
  /* margin-right: 10px; */
}

/* .card-parent::-webkit-scrollbar {
  width: 7px;
  /* width of the scrollbar 
}

.card-parent::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  /* color of the thumb 
  border-radius: 6px;
  /* roundness of the thumb 
}

.card-parent::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  /* color of the track 
} */

.v-card {
  min-height: 120px !important;
  box-sizing: border-box;
  border: 2px solid var(--orange);
  border-radius: 5px !important;
  color: black;
}

.v-card:hover {
  background-color: #eae9e9;
  box-shadow: 0px 5px 5px #cccccc;
}

.v-card img {
  height: 120px !important;
  width: 140px !important;
}

.divide {
  display: inline-block;
  min-width: 5px;
  height: 80%;
  margin-left: 5%;
  margin-right: 5%;
  background-color: black;
  border-radius: 10px;
}

a {
  text-decoration: none !important;
}