.taxCertificateWrapper {
  width: 100%;
  /* height: 98vh; */
  /* overflow-x: scroll; */
}
.taxCertificateContainer {
  width: 100%;
  padding: 0rem 0.8rem;
}
.business_address_2 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.business_address_2 p {
  flex: 1;
  margin-bottom: 0rem;
}
.business_address_3 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* gap: 0.2rem; */
  margin-bottom: 0.5rem;
  border-bottom: #d1823a 1px solid;
}
.business_address_3:last-child {
  border-bottom: none !important;
}

.business_address_3 > p {
  flex: 1;
  margin-bottom: 0rem;
}
.business_address_3 > p:last-child {
  flex: 1;
  margin-bottom: 1.5rem;
}

.business_address_3 > .cityStateZipContainer > p > span {
  padding-left: 0.3rem;
}
.addressComponent label > span {
  color: slategrey;
  font-size: 12px;
  font-weight: 700;
}

.input_Field_Container {
  position: relative;
}
.input_Field_Container > span {
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
  top: 0rem;
}
/* City State ZipCode */

.input_label {
  font-weight: 600;
}

.input_text_field {
  padding: 0.3rem;
  background-color: #f6dfcf;
  box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
  border-radius: 5px;
  height: 3rem;
  border: none;
  outline: none;
  font-weight: bolder;
  border-radius: 15px;
}

.input_text_field:focus {
  border: none;
  outline: none;
}

/* seller discription css */

.input_text_field_seller {
  padding: 0.3rem;
  background-color: #f6dfcf;
  color: black;
  box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
  border-radius: 5px;
  height: 3rem;
  border: none;
  outline: none;
  font-weight: bolder;
  text-align: left;
  padding: 10px;
  height: 100px;
  resize: none;
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.business_address_3 .addressComponent:nth-child(2) {
  margin-bottom: 0.4rem;
}
/* For Sub Address */
.cityStateZipContainer {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 0.3rem;
}
.cityStateZipContainer > p {
  margin-bottom: 0;
}
.cityStateZipContainer > p > span {
  color: slategrey;
  font-size: 12px;
  font-weight: 700;
}
/* Sub Address design */
.subAddressComponent > input {
  padding: 0.3rem;
  background-color: #f6dfcf;
  box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);

  height: 3rem;
  border: none;
  outline: none;
  font-weight: bolder;
}

.input_text_field_seller:focus {
  border: none;
  outline: none;
}

.generate_btn {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.generate_btn button {
  color: white;
  border-radius: 5px;
  background-color: #d1823a;
  padding: 0.5rem 0.9rem;
  width: 147px;
  border: none;
  /* width: auto; */
  font-weight: 500;
}

.generate_btn button:hover {
  background-color: lightgray !important;
  color: black !important;
}
.viewHistoryButton{
  display: flex;
  justify-content: flex-end;
  gap:1rem;
}
.viewHistoryButton button{
  border: none;
  background-color: #d1823a;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
}
@media (min-width: 420px) {
  .addressComponent label > span,
  .cityStateZipContainer > p > span {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .addressComponent label > span,
  .cityStateZipContainer > p > span {
    font-size: 17px;
  }
  .backButtonTaxCerti button{
    border: none;
    background-color: #D7996D;
    color: white;
    width: 100px;
    border-radius: 5px;
    box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.1);
  }
  .backButtonTaxCerti button svg{
    font-size: 30px !important;
  }
  .business_address_2 p {
    flex: auto;
    margin-bottom: 0rem;
    display: flex;
    flex-direction: column;
  }
  .business_address_2 {
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  .business_address_2 > .addressComponent:first-child {
    width: 42%;
  }
  .business_address_2 > .addressComponent:nth-child(2) {
    width: 25%;
  }
  .business_address_2 > .addressComponent:first-child {
    width: 35%;
  }
  .business_address_3 > p {
    flex: auto;
  }
  .business_address_3 {
    flex-direction: row;
    gap: 1.1rem;
  }
  .cityStateZipContainer {
    flex-direction: row;
  }
  .cityStateZipContainer > .subAddressComponent:first-child input {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .cityStateZipContainer > .subAddressComponent:last-child input {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .business_address_3 .addressComponent:first-child {
    width: 16%;
  }
  .business_address_3 .addressComponent:nth-child(2) {
    width: 4%;
  }
  .business_address_3 .addressComponent:last-child {
    width: 20%;
  }
  .cityStateZipContainer {
    gap: 0rem;
    width: 35%;
  }
  .business_address_3 {
    margin-bottom: 0.5rem;
  }
  .generate_btn {
    position: static;
  }
  /* .input_seller_width {
    width: 48% !important;
  } */
}
@media (min-width: 1440px) {
  .taxCertificateWrapper {
    padding: 0rem 6rem;
  }
}
@media (min-width: 1800px) {
  .business_address_2 {
    margin-bottom: 2.5rem;
  }
}
/* -----------------------------for history table------------------------------------------------- */
.history_mobile {
  display: block;
}

.history {
  display: none;
}

@media (min-width: 995px) {
  .history_mobile {
    display: none;
  }
  
  .history {
    display: block;
  }
}
.backButtonTaxCerti{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.backButtonTaxCerti button{
  border: none;
  background-color: #D7996D;
  color: white;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.1);
}
.taxHistoryTxt{
  display: flex;
  justify-content: center;
  color: #D7996D;
}
@media (max-width: 426px) {
.historyTableMain{
  padding: 26px;
}
}
