.form {
  /* outline: 0; #eec7c7*/
  background: #eec7c7;
  width: 100%;
  border: 0;
  /* margin: 0 0 15px; */
  /* padding: 15px; */
  /* box-sizing: border-box; */
  border-radius: 10px;
  /* font-size: 14px; #c57a40*/
}

.form .search_btn {
  outline: 0;
  background: #041a94;
  width: 100%;
  border: 0;
  padding: 11px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 10px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
}

/* ****************************************************************************** */

/* Basic styles for the form container  #8b7c72*/
.navbar {
  background-color: white;
}

/* .bb{
  background-color: #f09d65;
 } */
.form {
  /* position: relative;
    display: flex;
    align-items: center;
    width: 50%; */
  outline: 0;
  /* background: #665d5dc4; */
  width: 40%;
  /* border: 5px solid #f09d65; */
  /* margin: 0 0 15px; */
  padding: 2px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
}

.form input {
  /* width: 100%; */
  outline: none;
  margin: 0 20px 0 0;
  background: #665d5dc4;
}

/* Style for the search icon */
.form i.fa-search {
  margin-right: 10px;
  color: #f8f5f5;
}

/* Style for the input field */
.form input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 78%;
}

/* Style for the microphone icon on the left */
.form .left-pan i.fa-microphone {
  margin-left: 10px;
  color: #f8f2f2;
  cursor: pointer;
}

.btn-outline-dark {
  width: 180px;
}

.btns {
  /* display: inline-block; */
  /* font-weight: 400; */
  /* line-height: 1.5; */
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #c57a40;
  border: 1px solid transparent;
  padding: 0.685rem 0.75rem !important;
  font-size: 1rem;
  border-radius: 10px 10px 10px 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0 0 0 15px;
}

.dac_logo_for_tablet img {
  /* margin-right: -11px; */
  position: absolute;
  left: -26px;
}

/* Media query for responsiveness */
@media screen and (max-width: 600px) {
  .form {
    width: 60%;
    flex-direction: column;
    align-items: stretch;
  }

  .form i.fa-search,
  .form .left-pan i.fa-microphone {
    margin: 0;
  }

  .form input[type="text"] {
    margin-top: 1px;
  }
}

.main {
  width: 50%;
  /* margin: 50px auto; */
}

.has-search .form-control {
  padding-left: 2.375rem;
}

/* .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
} */

.form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  margin: 5px 0 0 720px;
  /* margin: 50 auto; */
  /* margin-right: 50px; */
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  .main {
    width: 50%;
  }
}

.bbbbbb {
  border-radius: 50px;
  width: 20%;
  margin: 0 20px 0 0;
}

.dddd {
  background-color: #c57a40;
}

.bbbbbb input {
  background-color: #c57a40;
}

/* ------------------------------------ */
/* .bbbbbb {
  width: 100%;
}

.bbbbbb .input-group {
  display: flex;
  align-items: center;
}

.bbbbbb .dropdown-menu {
  min-width: unset;
}

.bbbbbb .dropdown-menu a {
  color: #000;
}

@media screen and (max-width: 768px) {
  .bbbbbb {
    width: 100%;
  }
} */

/* ------------------------------------ */

/* .form-control-feedback span i {
  font-size: 16px;
  margin: 0 1px;
} */

.form-control-feedback span i.fa-microphone {
  margin-left: 3px;
}

/* --------------------navbar input icon start----------------------- */

.form1 {
  position: relative;
  width: 110%;
}

.form1 .fa-search {
  position: absolute;
  top: 20px;
  left: 20px;
}

.form1 span {
  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;
}

.form1 .fa-search {
  position: absolute;
  top: 12px;
  left: -68px;
}

.left-pan1 i.fa-search {
  transform: translateY(-50%);
  color: #0a0a0a;
}

.form1 .left-pan i.fa-microphone {
  margin-left: 10px;
  cursor: pointer;
  color: black;
}

.left-pan {
  padding-left: 7px;
  color: #070505;
}

.left-pan i {
  padding-left: 10px;
  color: #070505;
}

.form-input {
  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
}

.form-input:focus {
  box-shadow: none;
  border: none;
}

.form1 input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .form1 {
    width: 100%;
  }
}

/* ------------------------button dropdown list---------------------------------------*/
.d-flex {
  position: relative;
}

.gt {
  width: 112%;
}

.form-control.form-input {
  border-radius: 0;
  /* Remove border radius */
  border-color: transparent;
  /* Remove default border color */
  box-shadow: none;
  /* Remove default box shadow */
}

/* Remove active border */
.form-control.form-input:focus {
  border-color: transparent;
  box-shadow: none;
}

.togbtn {
  width: 32px;
  border: none;
  background-color: rgb(107, 99, 99);
  color: white;
  border-radius: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid #d1823a;
}

.navserch {
  width: 32px;
  border: none;
  background-color: rgb(107, 99, 99);
  color: white;
  border-radius: none;
  /* border-right: 1px solid #d1823a; */
}

#vtlsbr {
  border-left: 1px solid #d1823a;
  height: 20px;
  margin-top: 12px;
}

.navmick {
  width: 32px;
  border: none;
  background-color: rgb(107, 99, 99);
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.form1 {
  position: relative;
  background-color: #ffffff;
}

.dropdown-list {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  z-index: +1;
}

/* .dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block;
} */

/* .dropdown-menu li {
  padding: 5px 10px;
} */
.dropdown-list li {
  padding: 5px 10px;
}

@media screen and (max-width: 768px) {
  .togbtn {
    width: 10%;
  }

  .gt {
    width: 100%;
  }
}

.gt {
  border: 1px solid #d1823a;
  border-radius: 5px;
}

/* .form-input::placeholder {
  color: white; 
  background-color: rgb(91 94 96) !important; 
} */
.search-input::placeholder {
  color: lightgray;
  /* Set placeholder text color */
}

/* --------------------navbar input icon End-----------------------


/* Navbar upper list items */

#navupper-list:hover {
  color: #f7f2f5;
  text-decoration: underline;
  cursor: pointer;
}

#navupper-list-ios-android:hover {
  text-decoration: underline;
}

.login_button {
  cursor: pointer;
}

/* ------------------------------------------------------------------------------------------------- */
.parentUser {
  display: flex;
  align-items: baseline !important;
}

.parentContainer {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center !important;
  flex: 1;
  gap: 20px;
}

.childContainer {
  position: absolute;
  width: 100vw;
  left: 0%;
  height: auto;
  top: 0;
  background-color: white;
  z-index: 1 !important;
  color: black;
  overflow: hidden;
}

.dmsBtnContainer {
  display: none;
}

@media (min-width: 720px) {
  .childContainer {
    position: absolute;
    /* width: 150%; */
    width: 45%;
    left: 55% !important;
    height: auto;
    top: 40px;
    background-color: white;
    z-index: 9999 !important;
  }
}

@media (min-width: 1024px) {
  .childContainer {
    position: absolute;
    width: 35%;
    left: 65% !important;
    /* right: -20%; */
    height: auto;
    top: 50px;
    background-color: white;
    z-index: 1 !important;
    border-radius: 5px;
  }
}

@media (min-width: 1440px) {
  .dmsBtnContainer {
    display: flex;
  }
  .childContainer {
    width: 29%;
    left: 71% !important;
    top: 80px;
    background-color: white;
    border: 1px solid black;
  }
  .user_section_for_tablet {
    /* margin-top: -20px; */
  }

  .parentUser {
    justify-content: center !important;
    gap: 0 !important;
  }
}

.GrandparentContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-self: flex-end;
  padding: 0;
  gap: 0 !important;
  width: 30%;
  color: white;
  border: 2px solid yellow;
  box-sizing: border-box;
}

.GrandparentContainer > p {
  flex: 1;
  align-self: flex-end;
}

.GrandparentContainer .parentContainer {
  height: 100% !important;
  flex: 1;
}

.userWrapper {
  flex: 1 !important;
  width: 100%;
}

.userWrapper .child_user_wrapper {
  width: 100%;
}

.parent_user_details {
  display: flex;
  justify-content: space-around;
  flex: 1 !important;
}

.parent_user_details > p {
  align-self: flex-end;

  margin: 5px;
  margin-left: -60%;
}

.child_user_details {
  display: flex;
  /* flex-direction: column; */

  position: absolute;
  top: 0;
  width: 30%;
  display: flex;
  justify-content: center;
  right: 20px;
  padding-top: 15px;
  height: 200px !important;
  z-index: 9999 !important;
}

.user_details {
  margin-top: -55px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.user_absolute {
  position: absolute;
  top: 100px;
}

.user_name {
  display: flex;
  align-items: center;
  width: 140%;
  justify-content: center;
}

.user_name span {
  display: flex;
  align-items: flex-start;
  margin-top: -10px;
  margin-left: 5px;
}

.user_actions {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  gap: 3px;
}

.user_actions a {
  text-decoration: none;
  background-color: #c57a40;
  color: white;
  text-align: center;
  padding: 5px;
}

.user_actions button {
  background-color: #c57a40;
  color: white;
  text-align: center;
  padding: 5px;
  border: none;
}

.parent_nav_a {
  display: flex;
}

.nav_one_a,
.nav_one_b {
  border: 2px solid red;
}

.parent_mobile_detail {
  display: flex;
  align-items: center;
}

.mobile_icons {
  font-size: 20px;
  margin-right: 5px;
}

.parent_mobile_detail span {
  font-size: small;
  font-weight: 500;
}

#navbarSupportedContent {
  /* border: 2px solid black; */
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ham_menu_items {
  /* border: 2px solid red; */
}

.user_section_for_tablet {
  display: none;
}

@media (min-width: 720px) {
  .parent_nav_a {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    /* border: 1px solid black; */
    background-color: rgba(227, 227, 227, 255);
  }

  .ham_menu_items {
    display: flex !important;
  }
}

/* New Section starts */
.parent_nav_a {
  display: flex;
  flex-direction: column;
  /* box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.6); */
  background-color: white;
}

.mobile_apps {
  display: none !important;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  /* background: #6b6363; */
  /* border: 2px solid rgb(206, 219, 206); */
  /* padding-top: 10px; */
}

.mobile_apps p {
  display: flex;
  align-items: center !important;
  gap: 7px;
  color: white;
  font-weight: 500;
  /* border: 2px solid white; */
  font-size: larger;
}

.tab_nav_items {
  /* border: 2px solid red; */
  display: none;
}

.tab_nav_items a {
  color: white;
}

.ham_user {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 7px;
  /* background: #6b6363; */
  /* border: 2px solid pink; */
  width: 100%;
}

.ham_items {
  display: flex;
  padding: 5px 10px;
  flex: 0.4;
  color: black;
  font-size: larger;
  /* border: 2px solid green; */
}

.ham_items > span {
  font-size: larger;
}

.logo_for_mobile {
  /* border: 2px solid pink; */
  flex: 0.9;
  font-weight: bold;
  text-align: center;
  padding-top: 0.5rem;
}
@media (min-width: 374px) {
  .logo_for_mobile {
    font-size: larger;
  }
}
.logo_for_mobile span:nth-child(2n + 1) {
  color: #e8a262;
}
.logo_for_mobile span:nth-child(2n) {
  color: black;
}

.absolute_nav_items_a {
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  transition: left ease-in 1s;
  flex-direction: column !important;
  z-index: 9999;
  left: 0;
  top: 70px;
  gap: 5px;
  color: white;
  font-weight: 500;
  padding: 5px;
}

.absolute_nav_items_a span {
  padding: 5px 20px;
  background-color: #c57a40;
}

.absolute_nav_items_a p {
  padding: 5px 20px;
  background-color: #c57a40;
  margin: 0 !important;
  display: flex;
  gap: 5px;
  align-items: center;
}

.absolute_nav_items_a a {
  color: white;
}

.user_section_for_mobile {
  flex: 0.5;
  /* border: 2px solid green; */
}

.user_auth {
  display: flex;
  justify-content: end;
  padding: 5px 10px;
  font-weight: 600;
}

.user_action_mobile {
  /* border: 2px solid red; */
  display: flex;
  justify-content: end;
  color: white;
  font-weight: 500;
  font-size: small;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 0 10px;
}

.sign_in_sign_up_button_mobile {
  width: 250%;
  font-size: x-large;
  text-align: right;
}

.user_action_mobile_items {
  position: absolute;
  z-index: 9999;
  background-color: white;
  top: 40px;
  right: -10%;
  gap: 3px !important;
  padding: 3px;
  font-size: large;
  width: 250%;
}

.user_action_mobile_items p {
  background-color: #c57a40;
  padding: 3px;
  align-items: center;
  margin: 3px !important;
}

.user_action_mobile_items a {
  color: white;
  text-decoration: none;
}

.parent_nav_b {
  /* background-color: #6b6363; */
  display: flex;
  align-items: center !important;
  justify-content: center;
  padding: 5px 8px;
}

.parent__search {
  flex: 1;
  display: flex;
  align-items: center !important;
}

.wrapper_search {
  display: flex;
  align-items: center !important;
  flex: 1;
  padding: 0 !important;
  justify-content: end !important;
}

.parent_dropdown_search {
  /* width: 20%; */
  /* height: 30%; */
  display: none;
  position: relative;
}

#search_dropdown_category {
  /* height: 20%; */
}

.absolute_dropdown_search_items {
  position: absolute;
  z-index: 9999;
  top: 0;
}

.input_search_field {
  flex: 1;
  display: flex;
  align-items: center;
  /* border: 1px solid orange; */
  border-radius: 10px;
  color: white;
  padding: 5px;
  background-color: rgb(197, 190, 190);
}

.input_search_field input {
  flex: 0.95;
  background-color: transparent;
  color: white;
  padding: 5px;
  border: none !important;
}

.input_search_field input::placeholder {
  color: black;
  text-align: center;
}

.input_search_field input:focus {
  outline: none;
  border: none;
}

.microphone_container {
  display: flex;
  justify-content: center;
  flex: 0.1;
  height: 100% !important;
  color: black;
}

.search_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.1;
  color: black;
}

.dac_logo_for_tablet {
  display: none;
}

.user_div_for_tablet {
  display: none;
}

.user_actions_after_login_tablet {
  display: none;
}
.dealer_sidebar_home_Parent {
  top: 62px;
  position: absolute;
  z-index: 99;
}
.NavbarCurrentPage {
  display: none;
}

/* Tablet design starts */
@media (min-width: 720px) {
  .logo_for_mobile {
    display: none;
  }

  .dealer_sidebar_home_Parent {
    top: 103px;
    position: absolute;
    z-index: 99;
  }
  .tab_nav_items {
    display: flex;
    flex: 1;
    padding-top: 3px;
    /* visibility: hidden; */
  }

  .parent_nav_a {
    display: flex;
    flex-direction: column;
    /* box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.6); */
    background-color: white;
    width: 100%;
    /* background-color: rgba(227, 227, 227, 255); */
    border-bottom: 11px solid #f2f2f2;
  }

  .ham_user {
    flex: 1.3;
    display: flex;

    /* border: 2px solid pink; */
    align-items: center !important;
    padding: 0 10px 0 0 !important;
    /* color: white; */
    padding-top: 10px !important;
  }

  .ham_items {
    display: none;
  }

  .tab_nav_items {
    /* visibility: hidden; */
    display: flex;
    justify-content: space-between;
    flex: 0.85;
    /* padding: 5px 35px; */
    /* border: 2px solid red; */
  }

  .mobile_apps {
    display: flex !important;
    flex: 1;
    /* justify-content: space-around; */
    /* padding: 0 1.5rem !important; */
    align-items: center !important;
    /* background: #6b6363; */
    padding-top: 10px !important;
    font-size: x-large;
  }

  .mobile_apps p {
    /* font-size: xx-large; */
  }

  .mobile_apps p span {
    display: none;
  }

  .user_section_for_mobile {
    display: none;
  }

  .user_related_action_tablet {
    display: flex !important;
    justify-content: flex-end;
    padding: 0 5px;
    flex: 1;
    color: black;
    gap: 0.8rem;
    /* border: 2px solid green */
  }

  .user_related_action_tablet p {
    /* display: none; */
  }

  .user_section_for_tablet {
    display: flex;
    flex: 1;
    justify-content: end;
    /* margin-top: -20px; */
    /* border: 2px solid white; */
  }

  .sign_in_signup_tablet {
    font-size: large;
    margin-top: 18px;
    margin-left: 30px;
    color: white;
    /* border: 2px solid blue !important; */
    padding: 0.3rem;
    background-color: transparent;
    font-weight: 600;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
  }

  /* Nav section B styling for Tablet starts here */
  .parent_nav_b {
    display: flex;
    justify-content: space-between;
    align-items: baseline !important;
    padding: 0 15px;
  }

  .dac_logo_for_tablet {
    display: flex;
    flex: 1;
    /* border: 2px solid red; */
    /* padding-left: 15px; */
    gap: 1rem;
  }

  .dac_logo_for_tablet img {
    height: 100%;
    width: 100%;
    /* margin-top: -10px; */
    width: 30px;
    margin-right: -5px;
    left: 1px;
  }

  .dac_logo_for_tablet a {
    display: flex;
    color: white;
    gap: 0.3;
    font-size: x-large;
    font-weight: 500;
  }

  .dac_logo_for_tablet a span {
    margin-right: 0.4rem;
    /* font-weight: 700; */
  }

  /* .dac_logo_for_tablet a span:nth-child(2n-1) {
    color: #c57a40;
  }

  .dac_logo_for_tablet a span:nth-child(2n) {
    color: black;
  } */
  .dac_logo_for_tablet a {
    color: black;
    display: flex;
  }
  .dac_logo_for_tablet a span {
    color: black;
    display: flex;
    align-items: baseline;
    position: relative;
    padding-left: 2rem;
  }
  .parent__search {
    flex: 0.7;
    box-sizing: border-box;
    padding-top: 10px !important;
    /* border: 2px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper_search {
    padding-bottom: 0 !important;
    padding-top: 10px !important;
  }

  .parent_dropdown_search {
    display: block;
    border: 1px solid orange;
    height: 100% !important;
    padding: 10px;
    margin-top: -16px;
    border-radius: 10px 0 0 10px;
    color: white;
    display: flex;
    gap: 310px;
  }

  .user_div_for_tablet {
    display: block;
    /* border: 2px solid blue; */
    color: white;
    /* flex: 0.1; */
  }

  .absolute_dropdown_search_items {
    color: black;
    top: 60px;
    left: 0;
    width: 290%;
  }

  .absolute_dropdown_search_items p {
    /* border: 2px solid yellow; */
    padding: 5px;
    background-color: #d1823a;
    color: white;
    padding: 5px;
    margin: 0 !important;
    font-weight: 500;
  }

  .input_search_field {
    flex: 1;
    border-radius: 10px;
  }

  .user_div_for_tablet {
    position: relative;
  }

  .user_actions_after_login_tablet {
    position: absolute;
    top: 100px;
    right: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: 2px solid #d1823a;
    background-color: white;
    padding: 5px;
  }

  .user_actions_after_login_tablet p {
    background-color: #d1823a;
    color: white;
    margin: 0 !important;
    padding: 3px;
    font-weight: 500;
    /* color: black; */
    /* background-color: white; */
  }

  .user_actions_after_login_tablet p a {
    /* color: black; */
    color: white;
    text-decoration: none;
  }

  .dealer_section_for_big_laptop {
    display: none !important;
  }

  .dealer_section_for_tablet {
    display: flex;
    justify-content: space-around;
    font-size: x-large;
    gap: 3rem;
  }

  .user_icon_name_tablet {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    flex-direction: row;
    /* padding-top: 15px; */
    padding-bottom: 0 !important;
    height: fit-content !important;
  }

  .user_icon_name_tablet p {
    /* border: 2px solid black; */
    font-size: small;
  }

  .user_icon_name_tablet img {
    /* margin-top: 19px !important; */
    /* border-radius: 50%; */
  }

  .dealer_sidebar_home_Parent {
    /* border: 1px solid red; */
    /* position: absolute !important; */
    top: 100px;
    z-index: 1 !important;
    background-color: white;
    transition: left 300ms ease-in;
  }

  .dealer_sidebar_home_Parent.ShowSidebar {
    left: 0;
  }

  .dealer_sidebar_home_Parent.HideSidebar {
    left: -50%;
    /* display: none; */
  }
}

@media (min-width: 1024px) {
  .tab_nav_items {
    flex: 0.5;
    /* border: 2px solid yellow; */
    margin-bottom: 0;
  }
  .dac_logo_for_tablet a {
    display: flex;
    /* color: white; */
    /* gap: 0.3; */
    font-size: x-large;
    font-weight: 500;
    align-items: baseline;
  }
  .user_related_action_tablet {
    /* border: 2px solid red; */
    padding-top: 10px;
  }

  /* .user_related_action_tablet img {
    display: none;
  } */

  .user_related_action_tablet p {
    display: block;
    margin-top: 10px;
  }

  .sign_in_signup_tablet {
    /* border: 2px solid blue !important; */
    margin-left: 100px !important;
  }

  .parent_nav_b {
    /* border: 2px solid red; */
  }

  .dac_logo_for_tablet {
    display: flex;
    align-items: center;
    flex: 1;
    height: 80% !important;
    padding-left: 15px;
  }

  .dac_logo_for_tablet img {
    height: 55px;
    width: 100%;
    margin-top: -15px !important;
  }

  .user_icon_name_tablet p {
    font-size: large;
    cursor: pointer;
  }

  .user_icon_name_tablet img {
    cursor: pointer;
  }
  .dac_logo_for_tablet
    .dealer_sidebar_home_btn_container
    .dealer_sidebar_home_btn {
    /* border: 1px solid black; */
    padding: 0.3rem;
    padding-bottom: 0.55rem;
    border-radius: 50%;
    padding-right: 0.65rem;
    padding-left: 0.65rem;
  }
  .dac_logo_for_tablet img {
    margin-right: -11px;
    position: absolute;
    left: -1px;
    top: 3px;
    width: 41px;
  }
  .NavbarCurrentPage {
    display: block;
    font-size: 23px;
    font-weight: 700;
    color: #d1823a;
    font-family: "Alata";
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1440px) {
  .user_section_for_tablet {
    flex: 0.7;
    /* padding: 0 15px; */
  }
  .mobile_apps {
    display: flex !important;
    flex: 0.3;
    /* justify-content: space-around; */
    padding: 0 1.5rem !important;
    align-items: center !important;
    /* background: #6b6363; */
    padding-top: 10px !important;
    font-size: x-large;
  }
  .user_section_for_tablet {
    flex: 0.5;
  }

  .user_related_action_tablet {
    /* border: 2px solid red; */
    padding-top: 10px !important;
    display: flex;
    align-items: center !important;
  }

  .user_related_action_tablet {
    /* border: 2px solid yellow; */
    font-weight: 500;
    font-size: large;
  }

  .noti_dropdown {
    width: 11vw;
    height: 15vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border: 1px solid gray;
    background-color: lightgray;
    border-radius: 5px;
    width: 20rem;
  }

  /* .notification {
    height: 50px;
    width: 100% !important;
    padding: 7px;
    border-bottom: 1px solid gray !important;
  } */

  .noti_head {
    color: black;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  .noti_head:hover {
    color: #d1823a;
    box-shadow: 0 0 1px #ccc;
    -webkit-transition: all 0.7s ease-in-out;
    border: 0px;
    font-size: 17px;
  }

  .noti_con {
    display: block !important;
    font-size: small;
    color: black !important;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  .user_related_action_tablet p {
    display: none;
    cursor: pointer;
    margin: 0;
  }

  .user_related_action_tablet img {
    display: block;
  }

  /* This user_related_action_tablet2 is for web preview Navbar */
  .user_related_action_tablet2 {
    /* border: 2px solid red; */
    padding-top: 10px !important;
    display: flex;
    align-items: center !important;
    font-weight: 500;
    font-size: large;
    gap: 3rem;
  }

  .user_related_action_tablet2 p {
    display: block;
    cursor: pointer;
    margin: 0;
    color: black;
  }

  .user_related_action_tablet2 img {
    display: none;
  }

  .user_related_action_tablet2 img:nth-child(1) {
    display: block;
  }

  .user_icon_name_tablet .avatar_image_icon {
    display: block !important;
    margin-top: -0.7rem;
  }

  .parent_nav_b {
    padding: 0 40px;
  }

  .mobile_apps p span {
    display: block;
    font-size: large;
  }

  .mobile_apps {
    flex: 0.4;
  }

  .user_related_action_tablet {
    padding: 0 10px;
    flex: 0.5;
    font-weight: 600;
    gap: 1rem;
  }

  .dac_logo_for_tablet {
    display: flex;
    flex: 1;
    /* border: 2px solid red; */
    padding-left: 0;
    gap: 1.5rem;
  }

  .dac_logo_for_tablet
    .dealer_sidebar_home_btn_container
    .dealer_sidebar_home_btn {
    /* border: 1px solid black; */
    padding: 0.3rem;
    padding-bottom: 0.55rem;
    border-radius: 50%;
    padding-right: 0.65rem;
    padding-left: 0.65rem;
  }

  .dac_logo_for_tablet img {
    width: 80% !important;
  }

  .input_search_field input::placeholder {
    font-weight: 600;
    font-size: larger;
    color: black;
  }

  .dealer_section_for_big_laptop {
    display: flex !important;
  }

  .dealer_section_for_big_laptop a {
    /* background-color: rgba(227, 227, 227, 255); */
    color: black !important;
    font-size: x-large;
    font-weight: 500;
    width: 100%;
  }

  .dealer_section_for_big_laptop button {
    /* background-color: rgba(227, 227, 227, 255); */
    color: black !important;
  }

  .dealer_section_for_tablet {
    display: none;
  }

  .sign_in_signup_tablet {
    margin-left: 35px !important;
  }

  .dac_logo_for_tablet img {
    height: 60px;
    width: 80%;
  }

  .user_icon_name_tablet {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    flex-direction: row;
    /* padding-top: 15px; */
    gap: 0rem;
    padding-bottom: 0 !important;
    height: fit-content !important;
  }

  .user_actions_after_login_tablet {
    right: 1%;
  }

  .user_actions_after_login_tablet p {
    background-color: #d1823a;
    color: white;
    /* color: black !important; */
    margin: 0 !important;
    padding: 3px 25px;
    font-weight: 500;
  }
  .container-nav button {
    font-weight: 500;
  }
  .beforeLoginRightSideItems {
    width: 100%;
    /* border: 1px solid black; */
    display: flex !important;
    justify-content: flex-end;
    gap: 6rem;
  }
  .dac_logo_for_tablet img {
    /* margin-right: -11px; */
    position: absolute;
    left: -26px;
  }
}

@media (min-width: 1800px) {
  .childContainer {
    width: 25%;
    left: 75% !important;
  }
  .dac_logo_for_tablet img {
    /* margin-right: -11px; */
    position: absolute;
    left: -28px;
    top: 0;
  }
  .NavbarCurrentPage{
    font-size: 34px;
  }
}
@media (min-width: 2460px) {
  .mobile_apps,
  .mobile_apps span {
    font-size: xx-large;
    font-weight: 600;
  }

  .tab_nav_items {
    font-size: x-large;
    font-weight: 600;
  }

  .sign_in_signup_tablet {
    margin-left: 600px !important;
    font-size: x-large;
    font-weight: 600;
  }

  .user_related_action_tablet {
    /* border: 2px solid green; */
    padding: 0 3.5rem;
    font-size: larger;
  }

  .user_icon_name_tablet p {
    font-size: x-large;
  }

  .childContainer {
    width: 20%;
    left: 80% !important;
    top: 60px;
    background-color: white;
    border: 1px solid black;
  }

  .user_actions_after_login_tablet {
    right: 3.5%;
    top: 180px;
  }

  .sign_in_signup_tablet {
    margin-left: 230px !important;
  }
  .childContainer {
    width: 22%;
    left: 78% !important;
  }
}

.dealer_navbar_extras {
  color: white !important;
}

@media (min-width: 2560px) {
  .user_div_for_tablet form a,
  .user_div_for_tablet form button {
    font-size: x-large;
    font-weight: 800;
  }

  .user_div_for_tablet form a i,
  .user_div_for_tablet form button i {
    font-size: x-large;
    font-weight: 800;
  }
}

.container-nav {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 1rem;
  /* padding-right: 3rem; */
  margin-bottom: 0.5rem;
  /* border:  1px solid black; */
}

.container-nav a {
  color: white;
  border-radius: 5px;
  background-color: #d1823a;
  padding: 0.5rem 0.9rem;
  text-align: center;
  width: 167px;
}

.container-nav a:hover {
  background-color: lightgray !important;
  color: black !important;
}

.container-nav button {
  color: white;
  border-radius: 5px;
  background-color: #d1823a;
  padding: 0.5rem 0.9rem;
  width: 147px;
  /* width: auto; */
  font-weight: 500;
}

.container-nav button:hover {
  background-color: lightgray !important;
  color: black !important;
}
.notification-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.number-noti {
  background-color: #c57a40;
  color: white;
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
  display: inline-block; /* Allows width and height to work */
  text-align: center; /* Centers text horizontally */
  line-height: 20px; /* Centers text vertically */
}

.noti_con_img img {
  width: 50px;
  height: 50px;
}

.noti_head_first {
  text-align: center;
  color: #c57a40;
  font-weight: bold;
}

.noti_con svg {
  bottom: 2px;
}
