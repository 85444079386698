.wrapper {
  /* padding: 1rem 0; */
  padding-bottom: 0;
}

.container {
  /* border: 2px solid orange; */
  padding: 0.5rem 0.7rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 3rem;
  grid-auto-flow: column dense;
  /* gap: 0.6rem; */
}
.modalContainer {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 3rem;
  grid-auto-flow: column dense;
  border: #d1823a 1px solid;
  border-radius: 10px;
  padding: 4px;
  /* gap: 0.6rem; */
}
.pricefeesInputRelative {
  position: relative;
}

.pricefeesInputRelative .editDeleteDesktopContainer {
  display: none;
  position: absolute;
  top: 3rem;
  /* right: 40%; */
}
.inputContainer {
  margin-bottom: 0.4rem;
  gap: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.inputContainer:nth-child(-n + 10) {
  grid-column: 1;
}

.inputContainer:nth-child(n + 11):nth-child(-n + 20) {
  grid-column: 1;
}
/* .inputContainer:nth-child(n+11):nth-child(-n+20) {
    grid-column: 2;
  } */

.inputContainer .headerInputLabel {
  flex: 1;
  font-size: 12px;
  background-color: #e3b790;
  border: none;
  outline: none;
  padding: 0.3rem;
  font-weight: 500;
  border-radius: 8px;
}

.inputContainer .headerInputLabel:focus {
  /* border: 1px solid grey; */
  font-size: 12px;
  background-color: #e3b790;
  border: none;
  outline: none;
  padding: 0.3rem;
  font-weight: 500;
  border-radius: 8px;
}

.inputContainer .inputLabelContainer {
  width: 70%;
  /* flex: 1 1; */
  font-weight: 500;
  font-size: 12px;
  display: flex !important;
  align-items: center;
  gap: 0.7rem;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  justify-content: space-between !important;
  margin-bottom: 0 !important;
}
.inputContainer .inputLabelContainerPopup {
  width: 87%;

  font-weight: 500;
  font-size: 11px;
  display: flex !important;
  align-items: center;
  gap: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  justify-content: space-between !important;
  margin-bottom: 0 !important;
}
.labelContainer {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.inputLabelContainerPopup .labelContainer span:first-child {
  border: 0.1px solid #d1823a;
  background-color: #f7dece;
  padding: 0.1rem 0.2rem;
  border-radius: 4px;
}

.editDeleteContainer {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #777472f7;
  font-size: larger;
}
.editDeleteContainer span:nth-child(2),
.editDeleteContainer span:nth-child(3) {
  cursor: pointer;
}

.inputContainer .inputField {
  flex: 0.35;
  border: none;
  border: none;
  box-shadow: 2px 2px 2px gray;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  padding: 7px;
  box-shadow: inset 0 0 7px #9d9a9a, 2px 2px 5px #9d9a9a;
  border-radius: 10px;
  /* border: 1px solid #aea8a8; */
}

.inputContainer input:focus {
  outline: none;
  border: none;
}
.box_b_none_edit_input_container {
  position: relative;
  /* flex: 0.3; */
  width: 35%;
}
.box_b_none_edit_dollar_absolute_sign {
  position: absolute;
  left: 0.4rem;
  top: 18%;
  font-size: 13px;
}
.labelOrderIndexInput {
  height: 21px;
  width: 21px !important;
  padding: 0 !important;
  font-size: small;
}
.labelOrderIndexInput:focus {
  border: 1px solid black !important;
}

@media (min-width: 420px) {
  .inputContainer .inputLabelContainerPopup {
    font-size: 13px;
  }
  .modalContainer {
    padding: 12px;
    gap: 0.4rem;
    column-gap: 2rem;
  }
}
@media (min-width: 1024px) {
  .inputContainer .inputLabelContainer {
    font-size: 14px;
  }
  .wrapper {
    border-top: 1px solid lightgrey;
    padding-top: 1rem;
    /* padding: 1rem 0 0 3rem; */
  }

  /* If editStatus is true 
   .container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    grid-auto-flow: column dense;
     gap: 0.6rem; 
  } */

  /* If editStatus is false */
  .container {
    display: flex;
    flex-direction: column;
  }

  .inputContainer:nth-child(n + 11):nth-child(-n + 20) {
    grid-column: 2;
  }
  .inputLabelContainerPopup .labelContainer span:first-child {
    padding: 0.1rem 0.4rem;
  }

  .pricefeesInputRelative {
    /* border-bottom: #d1823a 1px solid; */
    /* padding-bottom: 1rem; */
    /* margin-bottom: 1rem; */
  }

  .pricefeesInputRelative .editDeleteDesktopContainer {
    display: flex;
    width: 100%;
    border-top: 1px solid #d1823a;
    justify-content: center;
    padding-top: 1rem;
  }
  .editDeleteDesktopContainer button {
    border: none;
    padding: 0.3rem 1.5rem;
    border-radius: 10px;
    box-shadow: 3px 3px 3px lightgrey;
    background-color: #f7dece;
    font-size: 18px;
    font-weight: 500;
  }
  .editDeleteDesktopContainer .box_b_none_edit_add_button_big_Screen {
    position: static;
  }
  .modalContainer {
    border: none;
    padding: 0;
  }
  .modalContainer {
    column-gap: 2rem;
    row-gap: 0.7rem;
  }
  .inputContainer .inputField {
    font-size: 13px;
    padding: 6px;
  }
  .box_b_none_edit_input_container {
    width: 27%;
  }
  .inputContainer {
    gap: 0.6rem;
  }
  .inputContainer .inputField {
    border-radius: 7px;
  }
}
@media (min-width: 1440px) {
  .labelContainer {
    gap: 0.5rem;
  }
  .inputContainer .inputLabelContainer {
    font-size: 15px;
  }
  .inputContainer .inputLabelContainerPopup {
    font-size: 17px;
  }
  .inputContainer .inputField {
    font-size: 15px;
  }
  .modalContainer {
    column-gap: 3rem;
    row-gap: 1.1rem;
  }
  .box_b_none_edit_dollar_absolute_sign {
    font-size: 15px;
    font-weight: 500;
  }
  .box_b_none_edit_input_container {
    width: 20%;
  }
}
@media (min-width: 1800px) {
  .inputContainer .inputLabelContainer {
    font-size: 17px;
    font-weight: 600;
  }
  .inputContainer .inputField {
    font-size: 18px;
  }
  .modalContainer {
    column-gap: 4rem;
    row-gap: 1.3rem;
  }
  .box_b_none_edit_input_container {
    width: 22%;
  }
  .box_b_none_edit_dollar_absolute_sign {
    font-size: 16px;
  }
}
