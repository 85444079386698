.wrapper {
  width: 100%;
  font-family: "Alata";
  padding: 0.6rem;
}

.container {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 4rem;
  padding: 0.3rem;
}


@media (min-width: 370px) {
  .wrapper {
    padding: 1rem;
  }
}
@media (min-width: 420px) {
  .container {
    padding: 0.3rem 1rem;
  }
}
@media (min-width: 720px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1800px) {
  .container {
    padding: 0 4rem;
    gap: 5rem;
  }
}
