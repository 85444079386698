.wrapper {
  width: 100%;
  margin-bottom: 1rem;
}
.container {
  width: 100%;
  padding: 6px;
  border-radius: 10px;
  background-color: #de83301f;
  box-shadow: 3px 3px 4px grey;
}
.row1 {
  width: 100%;
  display: flex;
  font-size: small;
  color: grey;
  font-weight: 500;
  border-bottom: 1px solid #d1823a;
  margin-bottom: 0.5rem;
}
.row1TransactionDateContainer {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}
.row1DueDateContainer {
  display: flex;
  justify-content: space-between;
  flex: 0.5;
  align-items: center;
  position: relative;
}
.row1DueDateContainer > p:first-child {
  display: flex;
  flex-direction: column;
}
.row1DueDateContainer p:last-child .absoluteContainer {
  position: absolute;
  font-size: 10px;
  top: 2.5rem;
  right: 0;
  background-color: white;
  border-radius: 5px;
}
.absoluteContainer p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid grey;
  justify-content: space-around;
  font-size: 10px;
  color: black;
  padding: 0.3rem;
}
.absoluteContainer p span {
  color: black !important;
  font-size: 15px !important;
}
.row1DueDateContainer p:last-child span {
  font-size: medium;
}
.row1TransactionDateContainer span:last-child,
.row1DueDateContainer p:first-child span:last-child {
  font-size: medium;
  font-weight: 500;
  color: black;
}

.row1DueDateContainer > p:last-child span {
  font-size: 25px;
  font-weight: 500;
  color: grey;
}
.row2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.row2PaymentLateContainer,
.row3PaymentNotesContainer {
  display: flex;
  gap: 0.5rem;
}

.row2PaymentLateContainer .paymentContainer,
.row2PaymentLateContainer .latePaymentContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.row2PaymentLateContainer .latePaymentContainer {
  justify-content: space-between;
}
.paymentContainer p:first-child,
.latePaymentContainer p:first-child {
  font-size: 12px;
  font-weight: 500;
  color: grey;
}
.paymentContainer {
  /* gap: 0.8rem; */
}
.paymentContainer p:last-child {
  background-color: #b5651d;
  color: white;
  border-radius: 5px;
  flex: 1;
  text-align: center;
  box-shadow: 0 2px 4px 0px #4a1a1ab5;
}
.row3PaymentNotesContainer .paymentContainer {
  display: flex;
}
.row3PaymentNotesContainer p {
  margin-bottom: 0;
}
.row3PaymentNotesContainer .paymentContainer,
.row3PaymentNotesContainer .latePaymentContainer {
  flex: 1;
}
.row3PaymentNotesContainer .paymentContainer p:last-child {
  color: black;
  font-weight: 500;
  background-color: transparent;
  box-shadow: none;
  flex: auto;
}
.row3PaymentNotesContainer .paymentContainer p:first-child {
  flex: auto;
}
.paymentContainer p:first-child {
  flex: 1;
}
.paymentContainer p:last-child,
.latePaymentContainer p:last-child {
  font-weight: 500;
}
.row3PaymentNotesContainer .paymentContainer p:last-child,
.row3PaymentNotesContainer .latePaymentContainer p:last-child {
  font-weight: 500;
  font-size: small;
}

.row2PaymentLateContainer p {
  margin-bottom: 0;
}
.absoluteKey {
  display: none;
}

@media (min-width: 375px) {
  /* .row2PaymentLateContainer,
  .row3PaymentNotesContainer {
    gap: 1rem;
  } */
  .wrapper {
    padding-left: 2rem;
    position: relative;
  }
  .row1 {
    padding: 0.3rem 0.5rem;
  }
  .absoluteKey {
    position: absolute;
    left: 0;
    top: 46%;
    height: 27px;
    width: 27px;
    display: grid;
    place-content: center;
    /* border: 1px solid; */
    border-radius: 50%;
    background-color: lightgrey;
    font-weight: 500;
  }
  .row2PaymentLateContainer,
  .row3PaymentNotesContainer {
    display: flex;
    gap: 1rem;
  }
  .row2 {
    gap: 1rem;
  }
  .container {
    padding: 10px;
  }
}
@media (min-width: 500px) {
  .row2PaymentLateContainer,
  .row3PaymentNotesContainer {
    gap: 1.5rem;
  }
  .row2 {
    padding: 0.3rem 0.5rem;
  }
  .paymentContainer p:first-child,
  .latePaymentContainer p:first-child {
    font-size: 15px;
  }
  .row1 {
    font-size: 15px;
  }
  .row2 {
    gap: 1rem;
  }
  .paymentContainer p:last-child,
  .latePaymentContainer p:last-child {
    font-size: 20px;
  }
  .row3PaymentNotesContainer .paymentContainer p:last-child,
  .row3PaymentNotesContainer .latePaymentContainer p:last-child {
    font-size: 15px;
  }
}
@media (min-width: 650px) {
  .absoluteKey {
    left: -18px;
    height: 37px;
    width: 37px;
    font-size: larger;
  }
}
