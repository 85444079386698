.wrapper {
  width: 100%;
  border: 2px solid #d1823a;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.container {
  width: 100%;
  height: 100%;
  /* padding-left: 10rem; */
  padding: 0.5rem;
}
.header {
  display: flex;
  gap: 2rem;
  font-size: x-large;
  font-weight: 500;
  align-items: center;
  margin-bottom: 3rem;
}
.header span:nth-child(1) {
  border-radius: 50%;
  height: 60px;
  width: 60px;

  display: grid;
  place-content: center;
  font-size: large;
  background: linear-gradient(to bottom, #4567b7 0%, #8e44ad 50%, #c154c1 100%);
  color: white;
}
.cardDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  gap: 0.8rem;
}
.cardDetailsContainer span {
  font-size: 24px;
  font-weight: 500;
}
.cardDetailsContainer img {
  height: 200px;
  width: 80%;
  object-fit: cover;
  box-shadow: 4px 4px 4px rgb(167, 161, 161);
}
.radioContainers {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  align-items: start;
  padding: 1rem 0rem;
  gap: 0.6rem;
}
.radioContainers .radioLabel {
  display: flex;
  gap: 0.3rem;
}
.radioLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radioLabel input[type="radio"] {
  appearance: none;
  border: 1px solid grey;
  height: 15px;
  width: 15px;
  border: 1px solid black;
  border-radius: 50%;
  position: relative;
}
.radioLabel input[type="radio"]:checked::before {
  content: "";
  background-color: #d1823a;
  position: absolute;
  left: 50%;
  transform: translate(-51%, -51%);
  top: 50%;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.inputContainers {
  display: flex;
  gap: 0.7rem;
}
.inputContainers input {
  flex: 0.3;
}
.inputContainers input:focus {
  outline: none;
}

.inputContainers select {
  flex: 0.1;
  font-size: x-large;
  text-align: center;
  padding: 0;
}
.inputContainers span {
  font-size: xx-large;
  font-weight: 500;
  padding-bottom: 0;
}
.inputContainers button {
  border: none;
  background: white;
  /* marginl */
  margin-top: -2rem;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 0.5rem;
}

.link_icon {
  transition: transform 0.2s, color 0.2s; /* Smooth transition */
}

.link_icon:hover {
  transform: scale(1.1); /* Scale up the icon */
  color: #007bff; /* Change color on hover */
  cursor: pointer; /* Ensure cursor indicates it's clickable */
}
.NextBtnContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.3rem;
}
.NextBtnContainer .backBtn {
  display: none;
}
.NextBtnContainer button {
  border: none;
  background-color: #d1823a;
  color: white;
  border-radius: 7px;
  padding: 0.2rem 1rem;
}
.backBtnContainer {
  display: flex;
  justify-content: start;
  padding-left: 0.4rem;
  font-size: large;
}

.domainInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
}
.domainInfoContainer .stepHeader {
  font-weight: 500;
  margin-bottom: 0;
}
.domainInfoContainer input {
  border: none;
  outline: none;
  background-color: rgb(238, 237, 237);
  border-radius: 10px;
  padding: 0.3rem;
}

.resultContainer {
  display: flex;
  flex-direction: column;
}
.resultMsgContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.resultMsgContainer > span {
  color: darkgrey;
}
.resultBtnContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.resultBtnContainer button {
  background-color: lightgrey;
  border-radius: 5px;
  border: 1px solid grey;
  width: 35%;
  padding: 0.1rem 0.8rem;
}
.backToThemeCardsBtnContainer {
  display: flex;
  justify-content: start;
}
.backToThemeCardsBtnContainer button {
  background-color: #d1823a;
  border: none;
  color: white;
  border-radius: 7px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

@media (min-width: 370px) {
  .radioContainers {
    font-size: 17px;
    padding: 1rem 0.3rem;
  }
}

@media (min-width: 420px) {
  .radioContainers {
    padding: 1rem 0.5rem;
  }
  .radioContainers .radioLabel {
    gap: 0.5rem;
  }
  .NextBtnContainer {
    padding-right: 2.3rem;
  }
  .domainInfoContainer {
    padding: 0 1rem;
  }
  .domainInfoContainer .stepHeader {
    font-size: larger;
  }
  .domainInfoContainer input {
    font-size: 17px;
    padding: 0.4rem;
  }
  .NextBtnContainer {
    padding-right: 0rem;
  }

  .NextBtnContainer button {
    font-size: 19px;
  }
}
@media (min-width: 720px) {
  .radioContainers {
    padding: 1rem 4rem;
    font-size: larger;
  }
  .radioContainers .radioLabel {
    gap: 1rem;
  }
}
@media (min-width: 1440px) {
  .radioContainers {
    padding: 1rem 4rem;
    font-size: 28px;
    gap: 3.5rem;
    padding-left: 0;
  }
  .radioContainers .radioLabel {
    gap: 1rem;
  }
  .dnsDefaultPageContainer {
    display: flex;
    flex-direction: column;
  }
  .pageContent {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .cardDetailsContainer {
    width: 40%;
  }
  .NextBtnContainer button {
    font-size: 25px;
  }
  .cardDetailsContainer img {
    height: 325px;
    width: 100%;
  }
  .cardDetailsContainer span {
    font-size: 32px;
  }

  .radioLabel input[type="radio"] {
    height: 20px;
    width: 20px;
  }
  .NextBtnContainer .backBtn {
    display: block;
  }
  .domainInfoContainer {
    width: 40%;
    gap: 2rem;
  }
  .domainInfoContainer .stepHeader {
    font-size: 26px;
  }
  .domainInfoContainer input {
    font-size: 25px;
  }
  .NextBtnContainer {
    justify-content: space-between;
  }
  .DefaultBtnContainer {
    display: flex;
    justify-content: flex-end;
  }
  .backBtnContainer {
    display: none;
  }
  .resultContainer {
    padding-left: 55%;
    font-size: 23px;
  }
}
@media (min-width: 1800px) {
  .radioContainers {
    padding: 1rem 4rem;
    font-size: 28px;
    gap: 3.5rem;
    padding-left: 0;
  }
  .radioContainers .radioLabel {
    gap: 1rem;
  }
  .dnsDefaultPageContainer {
    display: flex;
    flex-direction: column;
  }
  .pageContent {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .cardDetailsContainer {
    width: 35%;
  }
  .NextBtnContainer button {
    font-size: 25px;
  }
  .cardDetailsContainer img {
    height: 325px;
    width: 100%;
  }
  .cardDetailsContainer span {
    font-size: 32px;
  }

  .radioLabel input[type="radio"] {
    height: 20px;
    width: 20px;
  }
  .NextBtnContainer .backBtn {
    display: block;
  }
  .domainInfoContainer {
    width: 40%;
    gap: 2rem;
  }
  .domainInfoContainer .stepHeader {
    font-size: 26px;
  }
  .domainInfoContainer input {
    font-size: 25px;
  }
  .NextBtnContainer {
    justify-content: space-between;
  }
  .DefaultBtnContainer {
    display: flex;
    justify-content: flex-end;
  }
  .backBtnContainer {
    display: none;
  }
  .resultContainer {
    padding-left: 55%;
    font-size: 23px;
  }
}
