.crmTable {
    width: 100%;
    border-collapse: collapse;
  }
  .crmTablehead {
    background-color: #d1823a;
    color: white;
    width: 100%;
    border-radius: 10px;
  }
  .crmTablehead tr > th {
    border-right: 1px solid white;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    font-size: medium;
  }
  .crmTablehead tr > th:first-child {
    width: 3%;
  }
  .crmTablehead tr > th:last-child,
  .crmTablehead tr > th:nth-last-child(3) {
    width: 15%;
  }
  .crmTablehead tr > th:nth-last-child(2) {
    width: 25%;
  }
  
  .crmTablehead tr > th:nth-last-child(5) {
    width: 17%;
  }
  
  .crmTablehead tr > th:nth-last-child(4),
  .crmTablehead tr > th:nth-last-child(6) {
    width: 7%;
  }
  /* Body of the table starts here */
  .crmTable tbody tr {
    text-align: center;
    background-color: #fbeadf;
  }
  .crmTable tbody tr:hover {
    background-color: #ebb593;
  }
  .crmTable tbody tr > td {
    text-align: left;
    padding: 0.5rem 0.1rem;
    font-weight: 500;
    border: 1px solid white;
    padding-left: 0.3rem;
  }
  .crmTable tbody tr > td:nth-child(1),
  .crmTable tbody tr > td:nth-child(2) {
    text-align: center;
  }
  .crmTable tbody tr > td:last-child {
      text-align: center;
  }
  .crmTable tbody tr > td button {
    background-color: #d1823a;
    font-weight: 500;
    border: none;
    outline: none;
    /* padding: 0.1rem 0.3rem; */
    color: white;
    width: 36%;
    border-radius: 5px;
    padding: 0.2rem 0 0.2rem 0;
    margin-right: 0.3rem;
  }
  .crmTable tbody tr > td button:nth-child(3) {
    width: 10%;
  }
  .emailContainer {
    position: relative;
  }
  .emailContainer span:nth-child(2) {
    position: absolute;
    right: 8%;
    top: 20%;
  }
  .otpInputContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 17%;
    padding-right: 17%;
    margin: 10px 0;
    gap: 2rem;
  }
  .otpInputContainer input {
    background-color: #F6DFCF;
  }
  .otpInputBox {
    width: 47px;
    height: 55px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .otpResult {
    margin-top: 10px;
    font-size: 18px;
    padding-left: 42%;
    /* padding-right: 17%; */
  }
  .otpResult_resent{
    padding-left: 17%;
  }
  .otpResult button{
    background-color: #E6A162;
    box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
    border-radius: 5px;
    align-items: center;
    border: none;
    color: white;
    width: 150px;
    height: 40px;
  }
  .otpResult button:hover{
    background-color: #d1823a;
    box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
  }
  

  .container>table>thead>tr>*{
    background-color: #d7996d;
      color: white;
      font-size: .8rem;
      text-wrap: nowrap;
      padding: 5px;
      text-align: center;
      border-width: 0px !important;
  }
  .tableFooterRow td{
    background-color: #d7996d !important;
    color: white;
    height: 2rem;
  }
  
  .tableRowData:nth-child(odd) > td {
    background-color: #f8e2cd !important;
  }

  /*---------------- contact pop-up design--------------- */
  .contact_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .MessangerPartWrapper {
    width: 50%;
    display: flex;
    padding: 0 1rem;
    justify-content: center;
  }
  .MessangerPartContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .MessangerPartContainer {
    border: 1px solid #cbcbcb;
    box-shadow: 5px 1px 7px #cbcbcb;
    border-radius: 10px;
  }
  .MessangerHeader {
    width: 100%;
    box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .MessangerHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
  }
  .MessangerHeader span:first-child {
    font-size: large;
    font-weight: 500;
  }
  .MessangerHeader span:last-child {
    /* border: 1px solid black; */
    height: 20px;
    width: 20px;
    display: grid;
    place-content: center;
    padding: 0.7rem;
    border-radius: 50%;
    background-color: #d1823a;
    color: white;
  }
  .MessangerBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: small;
    gap: 1rem;
    padding: 0.5rem;
    height: 100%;
  }
  .MessangerBody .LeftBody {
    width: 100%;
    padding-right: 10%;
  }
  .MessangerBody .LeftBody .message {
    background-color: #eeb9a542;
    box-shadow: 3px 3px 7px #bdafaf;
  }
  
  .MessangerBody .RightBody {
    width: 100%;
    padding-left: 10%;
  }
  .MessangerBody .RightBody .message {
    background-color: rgba(216, 212, 212, 0.445);
    box-shadow: 3px 3px 7px #bdafaf;
  }
  .MessangerInput {
    width: 100%;
    display: flex;
    position: relative;
    box-shadow: -1px -4px 4px #bdafaf;
  }
  .MessangerInput span {
    position: absolute;
    right: 0.5rem;
    color: #d1823a;
    top: 10%;
    font-size: larger;
  }
  .MessangerInput input {
    border: none;
    outline: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0.5rem;
  }
  /*  contact left section */
  .left_side_contact_container{
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .leftSectionContact{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
  .contactNameHead{
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  .contactNameHead div{
     flex: 1;
  }
  .contactInput{
    background-color: #f6dfcf;
    box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
    border: none;
    border-radius: 5px;
    height: 2.5rem;
  }
  .leftSectionContact label{
    font-size: large;
    font-weight: 600;
  }
  .saveButton{
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }
  .saveButton button{
     border: none;
     border-radius: 5px;
     background-color: #d1823a;
     box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
     color: white;
     width: 100px;
     height: 40px;
  }
  @media (min-width: 1440px) {
   .MessangerPartContainer {
      gap: 1.5em;
    }
    .MessangerBody {
      font-size: 18px;
    }
  }
  @media (min-width: 1800px) {
    .MessangerPartWrapper {
      width: 50%;
    }
    .MessangerPartContainer {
      gap: 2.5em;
    }
  }