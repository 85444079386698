/* .rcorners4 {
    border-radius: 100px;
    background: #059c00;
    padding: 20px; 
    width: 20%;
    height: 70px;
    flex-wrap: wrap;
  }  */

  /* .rcorners5 {
    border-radius: 400px;
    background: #e40202;
    padding: 20px; 
    width: 100%;
    height: 100px;
    margin-left: 50px;
  }  */

  /* .rcorners4 {
    background-color: green;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    text-align: center;
    padding-top: 10px;
  }

  .rcorners5 {
    background-color: red;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    text-align: center;
    padding-top: 10px;
    margin-left: 50px;
  } */

  .rcorners4,
.rcorners5 {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    text-align: center;
    padding-top: 10px;
    color: aliceblue;
    cursor: pointer;
    /* padding-left: 207px; */
    /* margin-left: 150px; */
}

.rcorners4 {
    background-color: green;
}

.rcorners5 {
    background-color: red;
    margin-left: 50px;
}

@media screen and (max-width: 600px) {
    .rcorners4,
    .rcorners5 {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    
    .rcorners5 {
        margin-left: 20px;
        /* margin-left: 1px; */
    }
}
