.wrapper {
  width: 97%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
}
.container {
  width: 80%;
  border: 1px solid black;
  padding-left: 2rem;
}
.header {
  font-size: xx-large;
  font-weight: 500;
}
.sideBarItems {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  border-right: 2px solid black;
  margin-right: 2rem;
}
.sideBarItems span {
  width: 80%;
  border: 1px solid darkgrey;
  padding: 1rem 1.5rem;
  font-size: larger;
  font-weight: 500;
  box-shadow: 4px 4px 2px 2px rgb(202, 200, 200);
}
.sideBarItems span:hover {
  box-shadow: 4px 4px 2px 2px grey;
  background-color: #c7c2c2;
  color: white;
  font-weight: 500;
}
.stepsHero {
  display: flex;
}
.stepsHero img {
  flex: 1;
  width: 25%;
  max-height: 500px;
}
.verify_domain_status{
  margin-top: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verify_domain_status_btn{
  margin-top: 5%;
  text-align: center;
}
.verify_domain_status_btn button{
  border: none;
   width: 200px;
   height: 40px;
   border-radius: 10px;
   font-weight: bold;
}

.verify_domain_status_btn button:hover{
    background-color: #d1823a !important;
    color: white !important;
}
.verify_domain_circle{
  width: 15px; /* Adjust size as needed */
  height: 15px; /* Adjust size as needed */
  background-color: green;
  border-radius: 50%;
  display: inline-block; /* Keep it inline with other text */
}
.verify_domain_circle_b{
  width: 15px; /* Adjust size as needed */
  height: 15px; /* Adjust size as needed */
  background-color: red;
  border-radius: 50%;
  display: inline-block; /* Keep it inline with other text */
}
/* Link Wrapper */
.stepsLinkWrapper {
  /* border: 1px solid black; */
  display: flex;
  padding-top: 1rem;
}
.stepsLinkContainer {
  flex: 0.8;
}
.stepsLinkContainer p {
}
.stepsLinkContainer p:nth-child(1) {
  color: blue;
  text-align: center;
}
.stepsLinkContainer p:nth-child(2),
.stepsLinkContainer p:nth-child(3) {
  display: flex;
  justify-content: center;
}
.stepsLinkContainer p:nth-child(2) span,
.stepsLinkContainer p:nth-child(3) span {
  background-color: rgb(199, 194, 194);
  text-align: center;
  padding: 0.3rem 0.6rem;
}
/*  */
.stepsLinkNavigaion {
  flex: 1;
}
.stepsLinkNavigaion p:nth-child(1) {
  color: black;
  text-align: center;
}
.stepsLinkNavigaion p:nth-child(1) {
  color: black;
  text-align: center;
  font-size: 50px;
}
.stepsLinkNavigaion p:nth-child(2) {
  text-align: right;
  padding-right: 2rem;
  font-weight: 500;
  font-size: larger;
}

.link_icon {
  transition: transform 0.2s, color 0.2s; /* Smooth transition */
}

.link_icon:hover {
  transform: scale(1.1); /* Scale up the icon */
  color: #007bff; /* Change color on hover */
  cursor: pointer; /* Ensure cursor indicates it's clickable */
}

/* preview btn */

.preview_live_btn{
  margin-top: 5%;
  text-align: center;
}
.preview_live_btn button{
  border: none;
   width: 200px;
   background-color: green;
   color: white;
   height: 40px;
   border-radius: 10px;
   font-weight: bold;
}

.preview_live_btn button:hover{
    background-color: rgb(130, 158, 130);
    color: white !important;
}

.active_steps {
  background-color: #696767; 
  color: white;
}