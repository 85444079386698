.dealer_homepage_container {
  display: flex;
  position: relative;
  /* height: 100vh; */

}
.dealer_layout_sidebar {
  border: 1px solid orange;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  left: 0;
}
.dealer_layout_content {
  /* border: 1px solid red; */
  width: 100%;
}
.sidebar_wrapper {
  /* transition: all ease-in-out 1s; */
}
.dealer-sidebar {
  /* transition: width 0.2s ease; */
  overflow: hidden;
}
.menu-button {
  border-radius: 50%;
  border: 2px solid red;
  padding: 0.4rem;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 10px; */
  margin-left: 23px;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.dealer-sidebar.collapsed {
  width: 10vw; /* Adjust as needed */
  width: 100%;
}

.dealer-sidebar.expanded {
  width: 68vw; /* Adjust as needed */
  background-color: rgba(227, 227, 227, 255);
}
.dealer_sidebar_input_container {
  position: relative;
}
.dealer_sidebar_input_container span {
  position: absolute;

  left: 3px;
  top: 3px;
}
.dealer_sidebar_input_container input {
  background-color: inherit;
  box-shadow: inset 0 0 11px #e7bb93;

  border: none;
  outline: none;
  padding: 5px 3px;
  padding-left: 22px;
}
.dealerDashBoardSideBar {
  position: relative;
  top: -1rem;
}
.dlMobileHeader{
  width: 100%;
  text-align: center;
  color: #d1823a;
  margin-bottom: 1rem;
  font-family: "Alata";
}
@media (min-width: 345px) {
  .dealer-sidebar.expanded {
    width: auto; /* Adjust as needed */
  }
}
@media (min-width: 720px) {
  .dealer-sidebar.expanded {
    width: auto; /* Adjust as needed */
  }
  .dealerDashBoardSideBar {
    top: -0.5rem;
    z-index: 15 !important;
  }
}

/* Hide text in collapsed state */
.dealer-sidebar.collapsed .item-name,
.dealer-sidebar.collapsed .item-name-wrapper {
  display: none;
}

/* Show only icons in collapsed state */
.dealer-sidebar.collapsed .side_bar_item_container {
  justify-content: center;
}
@media (min-width: 650px) {
  .dealer-sidebar.collapsed {
    /* width: 80px; Adjust as needed */
    width: 11vw; /* Adjust as needed */
  }

  .dealer-sidebar.expanded {
    /* width: 250px; Adjust as needed */
    /* width: 32vw; Adjust as needed */
    width: auto;
  }
}
@media (min-width: 1024px) {
  .dealer-sidebar.collapsed {
    /* width: 80px; Adjust as needed */
    width: 8vw; /* Adjust as needed */
  }
  .dlMobileHeader{
    display: none;
  }
}
@media (min-width: 1440px) {
  .dealer-sidebar.collapsed {
    /* width: 80px; Adjust as needed */
    width: 6vw; /* Adjust as needed */
  }
}
@media (min-width: 1800px) {
  .dealer-sidebar.collapsed {
    /* width: 80px; Adjust as needed */
    width: 4vw; /* Adjust as needed */
  }
}
@media (min-width: 2160px) {
  .dealer-sidebar.collapsed {
    /* width: 80px; Adjust as needed */
    width: 3.5vw; /* Adjust as needed */
  }
}
@media (min-width: 2560px) {
  .dealer-sidebar.collapsed {
    /* width: 80px; Adjust as needed */
    width: 3vw; /* Adjust as needed */
  }
}
@media (min-width: 2900px) {
  .dealer-sidebar.collapsed {
    /* width: 80px; Adjust as needed */
    width: 2.5vw; /* Adjust as needed */
  }
}

/*  */
