.dealerDashBoard_Wrapper {
  height: 100vh;
  display: grid;
  place-items: center;
}
.dealerDashBoard_Container {
  display: flex;
}

.dropdown-toggle-dash {
  background-color: white !important;
  color: black;
  text-align: left !important;
  box-shadow: inset 0 0 22px #d1823a; /* Inner shadow */
  border: 1px solid #d1823a !important; /* Optional: keep the border transparent */
}

.drpdwn-menu {
  box-shadow: inset 0 0 22px #e7bb93; /* Inner shadow */
  border: 1px solid #e7bb93 !important; /* Optional: keep the border transparent */
}

.item-data {
  font-family: inherit !important;
  font-size: 15px !important;
}
