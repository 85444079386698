.wrapper {
  /* border: 2px solid #d1823a; */

  padding: 0.47rem 0.4rem;
  font-family: "Alata", serif;
}
.container {
  /* border: 2px solid red; */
  /* padding: 1rem 1rem; */
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  border-bottom: 2px solid #f0c8a3;
  padding-bottom: 1rem;
}
.calContainer {
  position: absolute;
  top: 2rem;
  z-index: 9999 !important;
  width: 60%;
  right: 0;
}
.inputFieldContainer {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  align-items: center;
}
.inputFieldContainer_payment {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-top: 0px;
  align-items: center;
}
.inputField {
  /* flex: 0.2; */
  padding: 10px;
  height: 35px;
  border: none;
  box-shadow: 4px 4px 4px #c9c4c4;
  text-align: center;
  font-weight: 500;
  font-size: large;
  background-color: #fbdfbd;
}
.salesData {
  flex: 0.225;
  padding: 10px;
  height: 35px;
  border: none;
  box-shadow: 4px 4px 4px #c9c4c4;
  text-align: center;
  font-weight: 600;
  font-size: x-large;
  background-color: rgba(235, 80, 80, 0.822);
  border-bottom: 2px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.dueData {
  /* flex: 0.37; */
  padding: 10px;
  height: 35px;
  border: none;
  box-shadow: 4px 4px 4px #c9c4c4;
  text-align: center;
  font-weight: 600;
  font-size: 19px;
  background-color: #fbdfbd;

  display: flex;
  align-items: center;
  justify-content: center;

  /* flex: 0.7; */
  border-radius: 10px;
}
.dueDataTotalFees {
  padding: 10px;
  height: 35px;
  border: none;
  box-shadow: 4px 4px 4px #c9c4c4;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  background-color: #b77437;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
  color: white;
  border-radius: 10px;
}

.dueDataTotalFees > span {
  margin-right: 0.2rem;
}
.dueDataDate {
  flex: 0.53;
  padding: 10px;
  height: 35px;
  border: none;
  box-shadow: 1px 2px 2px gray;
  text-align: center;
  font-weight: 600;
  font-size: x-large;
  background-color: #fbdfbd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 10px;
  overflow: hidden;
}
.dueDataCash {
  /* flex: 0.67; */
  width: 47%;
  padding: 10px;
  height: 35px;
  border: none;
  box-shadow: 4px 4px 4px #c9c4c4;
  text-align: center;
  font-weight: 600;
  font-size: 19px;
  background-color: #e8a262;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 12px;
}
.dueDataCash > span {
  margin-right: 0.2rem;
}
.payment_method {
  width: 47%;
  background-color: #e3a26800;
  box-shadow: 4px 4px 4px #c9c4c4;
  color: black;
  padding: 2px;
  border-radius: 10px !important;
  height: 38px;
  background-color: #fbdfbd;
  outline: none;
  font-size: 14px;
}
.paymentMethodHeader {
  width: 51%;
  font-weight: 500;
  font-size: 14px;
}

/* Date Header */

.dateHeader {
  flex: 0.69;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}
.netAfterDataContainer {
  /* border: 1px solid #d1823a; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
}
.netAfterDataContainer > span {
  font-weight: 700;
  font-size: 16px;
  width: 50%;
}
.netAfterDataContainer > p {
  margin-bottom: 0;
}
.netTotalDUEContainer {
  /* border: 1px solid #d1823a; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 0.5rem; */
}
.netTotalDUEContainer > span {
  font-weight: 700;
  font-size: 18px;
  width: 50%;
}
.balanceDueHeader {
  font-weight: 700;
  font-size: 18px;
  width: 50%;
}
.netTotalDUEContainer > p {
  margin-bottom: 0;
  width: 47%;
  background-color: #e8a262;
  color: white;
  box-shadow: 4px 4px 4px #c9c4c4;
}
.balanceDueData {
  width: 47%;
  background-color: #e8a262;
  color: white;
  padding: 10px;
  height: 35px;
  border: none;
  box-shadow: 4px 4px 4px #c9c4c4;
  text-align: center;
  font-weight: 600;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-bottom: 0;
}
.balanceDueData {
  width: 46%;
}

@media (min-width: 420px) {
  .paymentMethodHeader {
    font-size: 15px;
  }
  .netAfterDataContainer > p {
    position: relative;
  }
  .netAfterDataContainer > p > span {
    position: absolute;
    left: 1.4rem;
  }
  .netTotalDUEContainer > p {
    position: relative;
  }
  .netTotalDUEContainer > p > span {
    position: absolute;
    left: 1rem;
  }
  .balanceDueData {
    position: relative;
  }
  .balanceDueData > span {
    position: absolute;
    left: 1.4rem;
  }
  .balanceDueData {
    width: 47%;
  }
}
@media (min-width: 720px) {
  .container {
    gap: 1rem;
  }
  .netAfterDataContainer > span {
    font-size: 23px;
  }
  .dueDataTotalFees {
    font-size: 23px;
  }
  .paymentMethodHeader {
    font-size: 18px;
  }
  .payment_method {
    font-size: 20px;
  }
  .dueDataTotalFees {
    width: 45%;
  }
  .netTotalDUEContainer > p {
    width: 45%;
  }
  .payment_method {
    width: 45%;
  }
  .balanceDueData {
    width: 45%;
  }
  .dueDataCash {
    width: 45%;
  }
}
@media (min-width: 1024px) {
  .container {
    gap: 0.5rem;
  }
  .netAfterDataContainer > span {
    font-size: 18px;
    width: 48%;
  }
  .dueDataTotalFees {
    font-size: 16px;
  }
  .paymentMethodHeader {
    font-size: 16px;
  }
  .payment_method {
    font-size: 15px;
  }
  .container {
    padding-bottom: 0.7rem;
  }
  .dueData {
    font-size: 19px;
  }
  .netAfterDataContainer {
    padding-bottom: 0rem;
  }
  .balanceDueData {
    font-size: 16px;
  }
  .inputFieldContainer {
    margin-top: 0rem;
  }
  .wrapper {
    padding-top: 0;
  }
}
@media (min-width: 1440px) {
  .container {
    gap: 1rem;
  }
  .dueDataTotalFees {
    font-size: 22px;
  }
  .paymentMethodHeader {
    font-size: 18px;
  }
  .payment_method {
    font-size: 19px;
  }
  .netAfterDataContainer > span {
    font-size: 19px;
  }
  .dueDataCash {
    font-size: 22px;
  }
  .balanceDueData {
    width: 46%;
    font-size: 22px;
  }
  .netTotalDUEContainer > span {
    font-size: 23px;
  }
  .balanceDueHeader {
    font-size: 19px;
  }
  .dueData {
    font-size: 22px;
  }
}
@media (min-width: 1800px) {
  .container {
    gap: 1rem;
  }
  .dueDataTotalFees {
    font-size: 22px;
  }
  .paymentMethodHeader {
    font-size: 18px;
  }
  .payment_method {
    font-size: 19px;
  }
  .netAfterDataContainer > span {
    font-size: 19px;
  }
  .dueDataCash {
    font-size: 22px;
  }
  .balanceDueData {
    width: 45%;
  }
  .wrapper {
    padding: 0.77rem 0.4rem;
  }
}
