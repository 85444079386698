.website_four_nav {
    background-color: grey;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.website_four_child {
    cursor: pointer;
}

.website_four_child_login {
    display: flex;
    cursor: pointer;
    justify-content: center;
    width: 150px;
    border: 2px solid white;
    border-radius: 5px;
    margin-right: 10px;
}

.website_four_child_login_listing {
    display: flex;
    flex-direction: row;
}

.website_four_cat_all {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.website_four_cat_all span {
    cursor: pointer;
}

.website_four_cat_logo {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.additional_content {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.website_four_cat_logo_title img {
    width: 30px;
    height: 30px;
}
.website_four_cat_logo_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.website_four_btn_u_n{
    margin-top: 3%;
    display: flex;
    margin-right: 5%;
    justify-content: flex-end;
    gap: 15px;
    padding-bottom: 2%;
}

.website_four_btn_u_n button{
    width: 100px;
    border: none;
}

#btn_used{
    background-color: grey;
    border-radius: 5px;
    color: white;
}

#btn_new{
    /* background-color: grey; */
    border-radius: 5px;
    color: black;
    border: 1px solid grey;
}

.website_four_carduser{
    background-color: black;
    padding-left: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-left: 5%;
    margin-bottom: 5%;
    border-radius: 10px;
}

.website_four_carduser button{
    background-color: #d1823a;
    border: none;
    color: white;
    border-radius: 5px;
}

.web_img_container {
    position: relative;
    margin-top: 5%;
    background-size: cover; /* Optional: to cover the entire container */
    background-position: center; /* Optional: to center the image */
    height: 80vh;
}

.web_img_txt{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 20rem;
    left: 5rem;
}

.web_img_txt_a{
    display: flex;
    flex-direction: row;
    gap: 1%;
}

.web_img_txt_a span{
    background-color: rgb(82, 86, 100);
    width: 50%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: white;
}
.web_img_txt_b span{
    margin-top: 10px;
    background-color: rgb(82, 86, 100);
    width: 100%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: white;
}

.web_contact_info{
    margin-top: 10px;
    margin-bottom: 5%;
    background-color: rgb(82, 86, 100);
    color: white;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1%;
}

.vl {
    border-left: 2px solid white;
    height: 50px;
  }

.big_card{
    width: 41rem;
}
.smalla_card{
    width: 15rem;
}

.smallb_card{
    width: 25rem;
}

.smallc_card{
    width: 10rem;
}
  /* media query for responsive */
  @media (min-width:1200px) and (max-width:1550px) {
    .big_card{
        width: 31rem;
    }
    .big_card img{
        height: 36rem;
    }
    .smalla_card{
        width: 10rem;
    }
    
    .smallb_card{
        width: 15rem;
    }
    
    .smallc_card{
        width: 5rem;
    }
    .smallc_card img{
        height: 4rem;
    }
}