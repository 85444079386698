.login_feature_wrapper {
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #515558;
  height: 80vh;
}

.login_feature_container {
  /* border: 2px solid white; */
  width: 80%;
  height: 89%;
}

.login_feature_container button {
  background-color: rgb(243, 223, 196);
  border: none !important;
  border-radius: 10px;
  padding: 3px 0;
  width: 100%;
  font-weight: 500;
  font-size: large;
  position: relative;
  margin: 1rem 0;
}

.login_feature_container button.blink_now {
  animation: blink 800ms linear infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.login_feature_container .animated_car_icon {
  height: 40px;
  position: absolute;

  left: 5%;
}

.login_feature_container button.blink_now .animated_car_icon {
  /* Move the image across the button */
  animation: car_move 0.7s linear infinite;
}

@keyframes car_move {
  0% {
    left: 5%;
  }

  25% {
    left: 25%;
  }

  50% {
    left: 50%;
  }

  75% {
    left: 75%;
  }

  100% {
    left: 90%;
  }
}

.dealer_auth_image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  padding: 2rem;
}

.dealer_social_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  /* border: 2px solid blue; */
}

.dealer_social_container div {
  width: 100%;
}

.welcome_message {
  text-align: center;
  font-size: 49px;
  color: #e8a262;
  /* margin-top: -56px; */
  margin-bottom: 8rem;
  font-size: xx-large;
  font-weight: 700;
  width: 100%;
  /* letter-spacing: 2px; */
}

.dealer_social_child {
  display: flex;
  border: 2px solid red;
}

.sign_in_message {
  font-weight: 500;
  font-size: xx-large;

  width: 100%;
  letter-spacing: 1px;
  /* margin: 0; */
}

.email_username_label {
  font-weight: 500;
  font-size: x-large;

  width: 100%;
  /* letter-spacing: 3px; */
  margin-bottom: 0.7rem;
}

.forgot_pass_dealer_login {
  display: flex;
  justify-content: end;
}

.forgot_pass_dealer_login a {
  color: white;
}

.dealer_email_container {
  border-bottom: 1px solid white;
  padding: 7px 0;
  display: flex;
  color: white;
  margin: 0.5rem 0;
}

.dealer_email_container input {
  border: none;
  background-color: transparent;
  font-size: x-large;
  font-weight: 600;

  padding: 3px 0px;
  text-align: left;
  margin: 0.5rem 0;
}

.dealer_email_container span {
  font-size: xx-large;
}

.dealer_email_container input:focus {
  border: none;
  outline: none;
}

.dealer_email_container input::placeholder {
  color: white;
  font-size: larger;
}
@media (min-width: 2560px) {
  .dealer_email_container input {
    font-size: 40px;
    font-weight: 700;
    margin: 0.5rem 0;
  }

  .dealer_email_container span {
    font-size: 50px;
  }

  .dealer_email_container input::placeholder {
    color: white;
    font-size: 40px;
    font-weight: 700;
  }
}

.submit_button_container {
  display: flex;
  justify-content: center;
}

.submit_button_container button {
  background-color: #d1823a;
  color: white;
  padding: 0.5rem;
  font-weight: 600;
  margin-top: 0.5rem;
  width: 100%;
  border-radius: 0.4rem;
  font-size: x-large;
}

/* //fn btn */
.dealer_fb_container {
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  font-size: large;
  width: 100%;
}
.dealer_fb_container img {
  position: absolute;
  left: 2.3rem;
  top: 0.7rem;
  margin: 0 !important;
}
.dealer_fb_container .my-facebook-button-class {
  border-radius: 40px;
  background-color: transparent;
  width: 100%;
  padding: 0 !important;
}
.dealer_fb_container:hover {
  background-color: rgb(211, 208, 208);
  transition: transform ease-in-out 0.2s;
  transform: scale(1.05);
  cursor: pointer;
}
