.bussiness_location_card_wrapper {
  /* border: 2px solid blue; */
  /* width: 96%; */
  margin-top: 3rem;
}

.bussiness_location_card_container {
  border: 1px solid black;
  width: 100%;
  /* padding: 1.5rem; */
  position: relative;
  /* padding-top: 4.5rem; */
  border-radius: 0.5rem;
}

.location_card_header_wrapper {
  display: flex;
  justify-content: center;
}

.location_card_header {
  display: flex;
  justify-content: space-between;
  /* border: 2px solid green; */
  background-color: #e6a162;
  box-shadow: 0px 6px 6px -3px rgba(14, 13, 13, 0.8);
  color: white;
  /* padding: 0.6rem; */
  align-items: center;
  font-weight: 600;
  /* padding-top: 0.4rem; */
  padding: 0.5rem 0.8rem;
  margin-top: -1rem;
  width: 99%;
}

.location_card_header p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.location_card_header p:nth-child(2) {
  /* border: 2px solid red; */
  padding: 0.4rem;
  background-color: rgba(128, 128, 128, 0.363);
}

.location_card_main_1 {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  gap: 2rem;
  /* background-color: rgba(168, 165, 165, 0.178); */
}

.location_card_main_1_inputs {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
}

.card_upload_image_container {
  border: 1px solid black;
  max-height: 300px;
  /* height: 300px; */
  /* width: 350px; */
  border-radius: 10px;
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card_upload_image_container img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

.card_upload_image_container span {
  font-size: 60px;
  position: absolute;
  width: 100%;
  /* border: 2px solid red; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(0px);
  background-color: rgba(165, 163, 163, 0.459); */
  backdrop-filter: blur(0px);
  background-color: rgb(141 141 141 / 20%);
  color: white;
  transition: all ease-in-out 1s;
}

.input_label {
  font-weight: 600;
}

.input_text_field {
  padding: 0.3rem;
  background-color: #f6dfcf;
  box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
  border-radius: 5px;
  height: 3rem;
  border: none;
  outline: none;
  font-weight: bolder;
}

.input_text_field:focus {
  border: none;
  outline: none;
}

/* Address line starts here */
.location_card_main_2 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}



.business_address_1,
.business_address_2 {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.business_address_1 p,
.business_address_2 p {
  flex: 1;
}

.dealer_address_line_1_parent {
  position: relative;

}

.dealer_address_line_1_parent >  span:first-child {
  position: absolute;
  font-size: x-large;
  color: orange;
  right: 0.7rem;
  top: 1.6rem;
}
.dealer_address_line_1_parent >  span:last-child {
  position: absolute;
  font-size: x-large;
  color: orange;
  right: 0.4rem;
  top: 1.6rem;
}

/* Dealer Contact Information Starts */
.dealer_contact_information_parent {
  padding: .7rem 1.5rem;
}

.dealer_contact_information_header {
  font-size: large;
  font-weight: bold;
}

.dealer_location_saver {
  display: flex;
  justify-content: flex-end;
}

.saveBtn {

  border: 1px solid gray;
  color: black;
  font-weight: 500;
  padding: 0.3rem 1rem;
  cursor: not-allowed;
}

.saveBtn.dealer_location_saver_orange_bg {
  border: none;
  background-color: #e6a162;
  color: white;
}

.zipCodeLocation {
  flex: 0.3 !important;
  /* border: 2px solid red; */
  width: 20%;
}

.addresstwo {
  /* border: 2px solid black; */
  flex: 0.3 !important;
}

.stateLocation {
  /* border: 2px solid black; */
  flex: 0.3 !important;
}