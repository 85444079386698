.wrapper {
margin: 1rem 0;
}

.container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  padding: 0 0.3rem;
  align-items: center;
  font-size: large;
  font-weight: 500;
}
.logo {
  margin-bottom: 0;
}
.mobileAbsoluteLogo {
  margin: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.mobileAbsoluteLogo svg {
  display: block;
}

.mobileAbsoluteLogo.active {
  transform: rotate(90deg);
}
/* Mobile menu items */
.menuMobileItems {
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  top: 4rem;
  right: 0rem;
  position: absolute;
  width: 50%;
  /* border: 1px solid white; */
  padding: 0 0.3rem;
  z-index: 2;
  color: white;
  font-weight: 600;
}
.menuMobileItems span{
  background-color: rgba(26, 25, 25, 0.692);
  padding: 0.3rem 0.5rem;
}
.menuTabItems,
.DealerTabItems {
  display: none;
}
@media (min-width: 720px) {
  .menuMobileItems {
    width: 23%;
  }
}
@media (min-width: 1024px) {
  .container {
    font-size: larger;
    font-weight: 600;
  }
  .mobileAbsoluteLogo {
    display: none;
  }
  .menuTabItems {
    display: flex;
    gap: 3rem;
    /* border: 1px solid red; */
  }

  .menuTabItems p {
    margin-bottom: 0 !important;
  }
  .DealerTabItems {
    display: flex;
    gap: 1rem;
    /* border: 1px solid black; */
  }
  .DealerTabItems p {
    margin-bottom: 0 !important;
    border: 1px solid black;
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
  }
}
@media (min-width: 1440px) {
   .container{
    font-weight: 700;
    padding: 0 2rem;
   }
  .menuTabItems {
    display: flex;
    gap: 6rem;
  }

  .DealerTabItems {
    display: flex;
    gap: 3rem;
  }
  .DealerTabItems {
    display: flex;
    gap: 0.5rem;
  }
}
@media (min-width: 2000px) {
   .container{
    font-weight: 700;
    font-size: xx-large;
   
   }
  .menuTabItems {
    display: flex;
    gap: 8rem;
  }

 
  .DealerTabItems {
    display: flex;
    gap: 1rem;
  }
}
