.wrapper {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  box-shadow: inset 0px 0px 13px 2px #d1823a;
  padding: 2rem;
}
.container {
  width: 100%;
}
.header {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}
.header button {
  background-color: #d1823a;
  color: white;
  border: none;
  padding: 0.4rem 1rem;
  border-radius: 10px;
  font-size: larger;
  font-weight: 500;
}
.header >span {
  color: maroon;
  font-size: 27px;
}
.tableWrapper {
  /* border: 1px solid black; */
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.tableWrapper > table {
  border-radius: 10px !important;
  overflow: hidden;
}
.tableWrapper > table > thead > tr > * {
  background-color: #d1823a !important;
  border-right: 1px solid white !important;

  text-align: center;
}
.tableWrapper > table > thead > tr > th:first-child {
  width: 7%;
}
.tableWrapper > table > thead > tr > th:last-child {
  width: 15%;
}
.tableWrapper > table > tbody > tr > * {
  font-weight: 500;
  text-align: center;
}
.tableWrapper > tbody > tr:nth-of-type(odd) > * {
  background-color: #c4a4869a !important;
}

.tableWrapper > tfoot > tr > p {
  display: flex;
  width: 50vw;
}

.tableRowData:nth-child(odd) > td {
  background-color: #f8e2cd;
}
.tableRowData:nth-child(even) > td {
  background-color: rgb(243 243 243);
}
.tableRowData2:nth-child(odd) > td {
  background-color: #f8e2cd;
}
.tableRowData2:nth-child(even) > td {
  background-color: rgb(243 243 243);
}

/* .tableRowData:nth-child(even) > td:first-child {
  background-color: white;
} */

.tableRowData {
  /* display: flex;
  align-items: center; Center the checkbox vertically */
}

.tableRowData input {
  margin-right: 10px; /* Add some space between the checkbox and the data */
}
/* Edit email cotnainer */
.ContextContainer {
  position: absolute;
  top: 0.5rem;
  border: 1px solid grey;
}
.ContextContainer p {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}
.ContextContainer p:hover {
  background-color: rgba(240, 241, 241, 0.815);
}
.ContextContainer p {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.ContextContainer p:first-child {
  border-bottom: 1px solid lightgrey;
}

.tableFooter {
  width: 100%;
  background-color: #d1823a !important;
  display: flex;
  padding: 01rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.tableFooter p {
  flex: 0.5;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-weight: 500;
  font-size: large;
}
.tableFooter > p:first-child > span span:first-child,
.tableFooter > p:last-child >  span:first-child {
  color: white;
}
