/* .container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
} */

.formGroup {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 0 !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
  
  .formGroup label {
    width: auto;
    /* border: 1px solid green; */
    /* border: 2px solid red; */
    padding: 0 !important;
  
    color: darkgrey;
  }
  
  .formGroup input:focus {
    box-shadow: none;
  }
  
  .formGroup input {
    background-color: #fce8cf;
    padding: 10px;
    border: none !important;
    /* border-radius: 10px; */
  }
  
  #lighOranegBackgroundColor {
    background-color: #fce8cf !important;
    padding: 9px !important;
    border-radius: 10px;
    font-size: small;
  }
  #greyBackground {
    background-color: transparent !important;
  }
  
  .formGroup .locationDetectionIcon {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
  
  .inlineFields input {
    /* flex: 1; */
    padding: 7px !important;
    border: none;
    background-color: #fce8cf !important;
    width: 100% !important;
  }
  
  .idType {
    display: flex;
    padding: 0px !important;
    width: 100%;
    box-shadow: 0px 3px 5px 1px lightgrey;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .idType button {
    /* width: 40px;
    padding: 7px !important; */
    border-radius: 0px;
    color: white;
  }
  
  .idType input {
    flex: 1;
    padding: 5px !important;
    border: none;
    background-color: #fce8cf !important;
    font-size: 12px;
    font-weight: 700;
  }
  
  .addressInput {
    /* border: 1px solid blue; */
    width: 100%;
    /* padding-left: 0; */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: lightgrey 0px 3px 5px 1px;
  }
  .addressInput input {
    font-size: small;
    font-weight: 500;
  }
  
  .inlineFields {
    display: flex;
    /* gap: 10px; */
    width: 100%;
  }
  .inlineFields input {
    font-size: small;
    font-weight: 700;
  }
  @media (min-width: 720px) {
    .subAddress1,
    .subAddress2,
    .subAddress3 {
      flex: 1;
    }
  }
  
  @media (min-width: 720px) {
    .formGroup label {
      margin: 0px 10px;
      /* border: 1px solid green; */
      /* border: 2px solid red; */
      padding: 0 !important;
    }
    .idType input {
      font-size: small;
      font-weight: 700;
    }
    .inlineFields input {
      font-size: small;
      font-weight: 700;
    }
    .addressInput input {
      font-size: small;
      font-weight: 700;
    }
    .idType {
      display: flex;
      padding: 0px !important;
      width: 100%;
      box-shadow: 0px 3px 5px 1px lightgrey;
      height: 40px;
      border-radius: 10px;
    }
    .idType button {
      padding: 0.4rem !important;
      border-radius: 0px 10px 10px 0px;
      font-size: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .idType input {
      border-radius: 10px 0 0 10px;
    }
    .addressInput {
      border-radius: 10px !important;
      overflow: hidden;
      box-shadow: lightgrey 0px 4px 3px 0px;
    }
  
    .noBorderRadius1.addressInput {
      border-radius: 10px 0 0 10px !important;
    }
    .noBorderRadius2.addressInput {
      border-radius: 0px !important;
    }
  
    .noBorderRadius3.addressInput {
      border-radius: 0 10px 10px 0 !important;
    }
    .buyerAddressFormgroup {
      width: 40%;
    }
    .buyerCountyFormgroup {
      width: 15%;
    }
    .buyerEmailFormgroup {
      width: 72%;
      padding-left: 27%;
    }
  }
  @media (min-width: 1440px) {
    .idType input,
    .inlineFields input,
    .addressInput input,
    #lighOranegBackgroundColor {
      font-size: large;
    }
    .buyerEmailFormgroup {
      width: 67%;
      padding-left: 33%;
    }
  }
  