.wrapper {
  height: 300px;
  width: 100vw;
  margin-bottom: 1rem;
}
.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.container img {
  width: 100%;
  height: 100%;
}
.textContainer {
  position: absolute;
  color: white;
  text-align: center;
  font-size: large;
  font-weight: 600;
}
.textContainer p:nth-child(3) span {
  background-color: rgb(219, 219, 6);
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 20px;
}
@media (min-width: 720px) {
  .wrapper {
    height: 500px;
    width: 100vw;
    font-size: xx-large;
    font-weight: 700;

    margin-bottom: 1.3rem;
  }
  .textContainer {
    position: absolute;
    color: white;
    text-align: center;
    font-size: x-large;
    font-weight: 700;
  }
}
@media (min-width: 1024px) {
  .wrapper {
    height: 500px;
    width: 100vw;
  }
  .container {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: xx-large;
    font-weight: 700;
  }

  .textContainer {
    position: absolute;
    color: white;
    text-align: left;
    font-size: xx-large;
    font-weight: 700;
    padding-left: 2rem;
  }
  .textContainer p:nth-child(3) span {
    background-color: rgb(219, 219, 6);
    color: white;
    padding: 0.2rem 0.6rem;
    border-radius: 20px;
  }
}
@media (min-width: 1440px) {
  .wrapper {
    height: 500px;
    width: 100vw;

    margin-bottom: 2rem;
  }
}

@media (min-width: 2000px) {
  .wrapper {
    height: 700px;
    width: 100vw;

    margin-bottom: 2.4rem;
  }
  .container {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: xx-large;
    font-weight: 700;
  }

  .textContainer {
    position: absolute;
    color: white;
    text-align: left;
    font-size: 50px;
    font-weight: 700;
    padding-left: 2rem;
  }
  .textContainer p:nth-child(3) span {
    background-color: rgb(219, 219, 6);
    color: white;
    padding: 0.2rem 0.6rem;
    border-radius: 20px;
  }
}
