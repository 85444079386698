.crmTable {
  width: 100%;
  border-collapse: collapse;
}
.crmTablehead {
  background-color: #d1823a;
  color: white;
  width: 100%;
  border-radius: 10px;
}
.crmTablehead tr > th {
  border-right: 1px solid white;
  padding: 10px;
  text-align: center;
  font-weight: 500;
  font-size: medium;
}
.crmTablehead tr > th:first-child {
  width: 3%;
}
.crmTablehead tr > th:last-child,
.crmTablehead tr > th:nth-last-child(3) {
  width: 15%;
}
.crmTablehead tr > th:nth-last-child(2) {
  width: 25%;
}

.crmTablehead tr > th:nth-last-child(5) {
  width: 17%;
}

.crmTablehead tr > th:nth-last-child(4),
.crmTablehead tr > th:nth-last-child(6) {
  width: 7%;
}
/* Body of the table starts here */
.crmTable tbody tr {
  text-align: center;
  background-color: #fbeadf;
}
.crmTable tbody tr:hover {
  background-color: #ebb593;
}
.crmTable tbody tr > td {
  text-align: left;
  padding: 0.5rem 0.1rem;
  font-weight: 500;
  border: 1px solid white;
  padding-left: 0.3rem;
}
.crmTable tbody tr > td:nth-child(1),
.crmTable tbody tr > td:nth-child(2) {
  text-align: center;
}
.crmTable tbody tr > td:last-child {
  text-align: center;
}
.crmTable tbody tr > td button {
  background-color: #d1823a;
  font-weight: 500;
  border: none;
  outline: none;
  /* padding: 0.1rem 0.3rem; */
  color: white;
  width: 36%;
  border-radius: 5px;
  padding: 0.2rem 0 0.2rem 0;
  margin-right: 0.3rem;
}
.crmTable tbody tr > td button:nth-child(3) {
  width: 10%;
}
.emailContainer {
  position: relative;
}
.emailContainer span:nth-child(2) {
  position: absolute;
  right: 8%;
  top: 20%;
}
.otpInputContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  gap: 2rem;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
}
.otpScreenReSentOtp {
  display: flex;
  justify-content: space-between;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.otpInputContainer input {
  /* background-color: #F6DFCF; */
  border: #d1823a dashed;
}
.otpInputBox {
  width: 20rem;
  height: 55px;
  font-size: 20px;
  text-align: center;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otpResult {
  margin-top: 10px;
  font-size: 18px;
  padding-left: 42%;
  /* padding-right: 17%; */
}
.otpResult_resent {
  padding-left: 17%;
}
.otpResult button {
  background-color: #e6a162;
  box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
  border-radius: 5px;
  align-items: center;
  border: none;
  color: white;
  width: 150px;
  height: 40px;
}
.otpResult button:hover {
  background-color: #d1823a;
  box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
}

.container > table > thead > tr > * {
  background-color: #d7996d;
  color: white;
  font-size: 0.8rem;
  text-wrap: nowrap;
  padding: 5px;
  text-align: center;
  border-width: 0px !important;
}
.tableFooterRow td {
  background-color: #d7996d !important;
  color: white;
  height: 2rem;
}

.tableRowData:nth-child(odd) > td {
  background-color: #f8e2cd !important;
}
.crmSheetRowDeleteBtn {
  color: #d1823a;
  background-color: transparent;
  font-size: x-large;
  margin-left: 0.5rem;
}
