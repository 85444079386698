.pLMDCardWrapper {
  width: 100%;
}
.pLMDCardContainer {
  width: 100%;
  height: 100%;
  border: 1px solid lightgrey;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 1px 5px 4px lightgrey;
}
.pLMDImageContainer {
  width: 100%;
  position: relative;
}
.pLMDImageContainer img {
  width: 100%;
  /* object-fit: cover; */
  height: 300px;
  box-shadow: 4px 4px 4px lightgrey;
}
.pLMDAbsoluteItem {
  position: absolute;
  width: 100%;
  display: flex;
  bottom: 0;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 0.4rem;
}
.pLMDAbsoluteItem > span {
  width: 43%;
  background-color: #c8b674de;
  color: white;
  font-weight: 500;
  text-align: center;
  padding: 0.3rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 10px;
}
.pLMDCardContent {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.6rem;
}
.pLMDCardContent p {
  margin-bottom: 0;
}
.pLMDCardContent .pLMDCarName {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
  align-items: center;
  margin-bottom: 0.4rem;
}
.pLMDCardContent .pLMDCarName span:first-child {
  font-size: 13px;
  color: darkslategray;
  font-weight: 500;
}
.pLMDCardContent .pLMDCarName span:last-child {
  font-size: smaller;
  color: darkslategray;
  font-weight: 500;
}
.pLMDCarFeatures {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.4rem !important;
}
.pLMDCarFeatures > span {
  display: flex;
  flex-direction: column;
  font-size: large;
  align-items: center;
}
.pLMDCarFeatures > span {
  flex: 0.3;
}
.pLMDCarFeatures > span span:first-child {
  font-size: large;
}
.pLMDCarFeatures > span span:last-child {
  font-size: 9px;
  color: darkslategray;
  height: 40px;
}
.pLMDCarFinance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: large;
  font-weight: 500;
  padding: 0 0.7rem;
}
.pLMDCarFinance > span:last-child {
  color: #d1823a;
}
.checkAvailailityContainer {
  font-size: x-large;
  background-color: lightgrey;
}
.checkAvailailityHeader {
  text-align: center;
  color: #d1823a;
  font-weight: 700;
}
.cAContactHeader {
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  flex-direction: column;
  align-items: center;
}
.cAContactHeader > span:last-child {
  color: white;
  background-color: black;
  border-radius: 20px;
  padding: 0.3rem 1rem;
}
.cAorBorderContainer {
  border-bottom: 1px solid grey;
  position: relative;
}
.cAorBorderContainer > span {
  position: absolute;
  right: 50%;
  top: -1.4rem;

  color: #d1823a;
  font-weight: 600;
  background-color: lightgrey;
}
.cATextContainer {
  font-size: large;
  margin-top: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.cATextContainer > input {
  width: 35% !important;
  border: none;
  margin-right: 1.3rem;
  border-radius: 10px;
  padding-left: 0.4rem !important;
  font-size: small !important;
}
.cAYearMakeModelText {
  text-align: center;
  font-size: large;
  margin-top: 1.5rem;
  font-weight: 600;
}
.cAInputBoxWrapper {
  width: 95%;
  height: 80%;
  background-color: white;
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.cAInputBoxWrapper > label {
  font-size: 12px;
  font-weight: 500;
}
.cAInputBoxHeader {
  font-size: medium;
  margin-top: 1.5rem;
  font-weight: 600;
}
.cAInputBoxContainer {
  display: flex;
  font-size: large;
  font-weight: 600;
}
.cAInputBoxColumn {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.cAInputBoxColumn input {
  margin-right: 1rem;
}
.cAInputBoxColumn label {
  font-size: 12px;
}
.cABoxWrapper {
  display: flex;
  justify-content: center;
}
.mdcCheckAvailMessagBox {
  width: 100%;
  background-color: lightgrey;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  padding: 0.3rem;
}
.cASendBtnContainer {
  display: flex;
  justify-content: end;
  align-items: center;

  width: 40%;
}
.cASendBtnContainer button {
  background-color: #d1823a;
  border: none;
  padding: 0.5rem;
  border-radius: 10px;
}
.cAlabelAgreement {
  font-size: small;
  font-weight: 600;
}
@media (min-width: 420px) {
  .pLMDAbsoluteItem > span {
    font-size: medium;
  }
  .pLMDCardContent .pLMDCarName span:first-child {
    font-size: 18px;
  }
  .cAContactHeader {
    flex-direction: row;
  }
  .cAInputBoxColumn label {
    font-size: 17px;
  }
  .cATextContainer > input {
    padding: 0.5rem !important;
    margin-bottom: 0.2rem;
  }
  .cAlabelAgreement {
    font-size: medium;
  }
}
@media (min-width: 720px) {
  .cASendBtnContainer button {
    padding: 0.5rem 1rem;
  }
  .cATextContainer > input {
    margin-bottom: 0.5rem;
  }
  .cAInputBoxWrapper > label {
    font-size: 14px;
    font-weight: 500;
  }
}
