/* FormsComponent.css */
.forms-container {
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.forms-header {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
  /* border: 1px solid #ddd; */
  /* padding-bottom: 10px; */
  position: absolute;
  top: -68px;

}

.forms-header>* {
  background-color: #e8a262 !important;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.forms-header h3 {
  margin: 0;
  color: white;
  padding: 3%;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-top: 10px;
}

.form-actions .btn {
  margin-left: 10px;
}

.form-check {
  margin-bottom: 10px;
}

.form-check-label {
  font-weight: 500;
}

.form-option{
  background-color: rgb(233, 233, 233);
  padding: 5px;
  border-radius: 10px;
  border-bottom: 1px solid black;
  box-shadow: 0px 3px 5px rgb(179, 179, 179);
}


