.hh {
  height: 50px;
  transition: background-color 0.3s ease;
}

.hh:hover {
  background-color: #ebd6c3;
}

.ffsdbr {
  font-weight: normal;
  font-family: sans-serif;
}

.hh span {
  position: relative;
}

.toggle_button_container {
  display: flex;
  justify-content: center;
  padding: 0;
  border-bottom: none;
  transition: width 0.3s ease-in-out;
}

.toggle_button_container.open-width {
  width: 15.05%;
}

.toggle_button_container.closed-width {
  width: 4%;
}

.toggle_button_container-text{
  width: 14%;
  border: 2px solid #d1823a;
}
.Dashboard{
  
}
.menu-button-user {
  border-radius: 50%;
  padding: 0.4rem;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btn-sidebar{
  margin-left: 4rem;
}
.menu-button-user:hover {
  background-color: #f0f0f0;
}

.big_image .bggcor {
  width: 268px !important;
}

.image_container {
  position: relative;
}

/* Sidebar transition */
.sidebar {
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 250px;
}

.sidebar.closed {
  width: 80px;
  transform: translateX(0);
}

.small_image_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
  height: 170px;
  transition: opacity 0.3s ease-in-out;
}

.small_image_container img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin: 3rem 0;
  border: 2px solid rgb(0, 0, 0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.menu-title {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
  transform: translateX(0);
}

.sidebar.closed .menu-title {
  opacity: 0;
  transform: translateX(-20px);
}

.tooltip_text {
  position: absolute;
  top: 0;
  left: 100px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 0;
  transform: translateX(-10px);
}

.sidebar.closed .image_container:hover .tooltip_text {
  opacity: 1;
  transform: translateX(0);
}

.profileDetails {
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s ease-in-out;
}

.sidebar.closed .profileDetails {
  opacity: 0;
}

.toggle_button {
  position: absolute !important;
  top: 1px;
  right: 23px;
  color: #515558;
  border-radius: 50%;
  background-color: white !important;
  font-size: 20px;
  border: none !important;
  transition: transform 0.3s ease-in-out;
}

.sidebar.closed .toggle_button {
  transform: rotate(180deg);
}

/* Permanent expansion styles */
.sidebar.permanent-open {
  width: 250px !important;
  transform: translateX(0) !important;
}

.sidebar.permanent-closed {
  width: 80px !important;
  transform: translateX(0) !important;
}

.sidebar.permanent-open .menu-title,
.sidebar.permanent-open .profileDetails {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.sidebar.permanent-closed .menu-title,
.sidebar.permanent-closed .profileDetails {
  opacity: 0 !important;
  transform: translateX(-20px) !important;
}

.sidebar.permanent-closed .small_image_container img {
  transform: scale(0.8);
}

.sidebar.permanent-open .toggle_button,
.sidebar.permanent-closed .toggle_button {
  transform: rotate(0deg) !important;
}