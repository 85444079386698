/* Sidebar.css */
.sidebar {
  border: 2px solid #4e4f53;
  /* border-radius: 16px 0 0 0; */
  min-height: calc(100vh - 60px);
  background: #fff;
  background-color: var(--sidebar-color);
  color: var(--font-color);
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  /* padding: 15px; */
  width: 261px;
  /* width: 15%; */
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
}

/* Media  query for side bar */
/* @media (max-width:768px) and (min-width:1024px) {
  .sidebar {
    border: 2px solid #4e4f53;
    min-height: calc(100vh - 60px);
    background: #fff;
    background-color: var(--sidebar-color);
    color: var(--font-color);
    font-family: "Nunito", sans-serif;
    font-weight: 500;
    width: 350px;
    z-index: 11;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
}
#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.3s ease-in-out;
}
} */

/* @media only screen and (min-width: 768px) {
  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -18px;
    transition: margin 0.3s ease-in-out;
  }
  .sidebar {
    border: 2px solid #4e4f53;
    min-height: calc(100vh - 60px);
    background: #fff;
    background-color: var(--sidebar-color);
    color: var(--font-color);
    font-family: "Nunito", sans-serif;
    font-weight: 500;
    width:300px;
    z-index: 11;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
}

} */
.wrapper_search-dash-nav {
  display: flex;
  align-items: center !important;
  flex: 1 1;
  padding: 0;
  justify-content: space-evenly !important;
  width: 90%;
}

.input_search_field-dash-nav input::placeholder {
  font-weight: 600;
  font-size: larger;
  text-align: center;
  color: black;
}

.input_search_field-dash-nav {
  flex: 1;
  display: flex;
  align-items: center;
  /* border: 1px solid orange; */
  border-radius: 10px;
  color: white;
  padding: 5px;
  background-color: rgb(197, 190, 190);
  padding-left: 5%;
}

.input_search_field-dash-nav input {
  flex: 0.95;
  background-color: transparent;
  color: white;
  padding: 5px;
  border: none !important;
}

.microphone_container-dash-nav {
  display: flex;
  justify-content: center;
  flex: 0.1;
  height: 100% !important;
  border-left: 1px solid black;
  color: black;
  padding-left: 7px;
}


.input_search_field-dash-nav input:focus {
  outline: none;
  border: none;
}

/* Media  query for side bar */

.navbar .navbar-menu-wrapper {
  background: #ffffff;
  background-color: var(--bg-color);
  color: var(--font-color);
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  color: #6C7383;
  padding-right: 2.375rem;
  padding-left: 2.375rem;
  width: calc(100% - 235px);
  height: 60px;
}

.dashboardHeader {
  background: #4e4f53 !important;
  border-radius: 30px 30px 0px 0px;
  padding: 50px;
}


.sidebar .nav:not(.sub-menu) {
  /* margin-top:25px; */
  margin-top: 1.45rem;
  /* margin-left: 1rem; */
  /* margin-right: 1rem; */
  /* height: 500px; */
}

.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: 60px;
}

.sidebar .nav .nav-item .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  white-space: nowrap;
  padding: 0.8125rem 1.937rem 0.8125rem 1rem;
  color: #000;
  border-radius: 8px;
  -webkit-transition-duration: 0.45s;
  -moz-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
}

li.nav-item.nav-profile.dropdown a img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  /* margin-left: 25px; */
}

.sidebar .nav.sub-menu {
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  /* padding: 0.25rem 0 0 3.07rem; */
  /* background: #E8A262; */
  background-color: #ffeacba2;
  padding-bottom: 12px;
}

.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: 60px;
}

/* navbar ----------------------------------------------*/
.navbar {
  /* font-weight: 400;
    transition: background 0.25s ease;
    -webkit-transition: background 0.25s ease;
    -moz-transition: background 0.25s ease;
    -ms-transition: background 0.25s ease;
    -webkit-box-shadow: 0px 5px 21px -5px #CDD1E1;
    -moz-box-shadow: 0px 5px 21px -5px #CDD1E1;
    box-shadow: 0px 5px 21px -5px #CDD1E1; */
  /* background-color: #E8A262; */
  color: black;
}

/* ====================================================================================================== */

/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
body,
button {
  font-family: "Inter", sans-serif;
}
:root {
  --offcanvas-width: 270px;
  --topNavbarHeight: 56px;
}
.sidebar-nav {
  width: var(--offcanvas-width);
}
.sidebar-link {
  display: flex;
  align-items: center;
}
.sidebar-link .right-icon {
  display: inline-flex;
}
.sidebar-link[aria-expanded="true"] .right-icon {
  transform: rotate(180deg);
}
@media (min-width: 992px) {
  body {
    overflow: auto !important;
  }
  main {
    margin-left: var(--offcanvas-width);
  }

  .offcanvas-backdrop::before {
    display: none;
  }
  .sidebar-nav {
    -webkit-transform: none;
    transform: none;
    visibility: visible !important;
    height: calc(100% - var(--topNavbarHeight));
    top: var(--topNavbarHeight);
  }
} */

/*---------.------------------------MyProfile Start---------------------------------------------------------*/



.dropdown-menu {
  position: absolute;
  top: 100%;
  /* Position the dropdown below the button */
  left: 0;
  /* Align the dropdown with the left edge of the button */
  z-index: 1000;
  /* Ensure dropdown appears above other elements */
}

/* for nav search bar */
#inside-nav-search {
  border: 2px solid #6d6b69;
  border-radius: 10px;
  margin-left: 80px;
}

#input-search {
  /* //background-color: rgb(81 85 88) !important; */
  color: black !important;
  border: none;
}

#input-search::placeholder {
  color: rgb(177, 169, 169);
  /* Change this to your desired color */
}

/* for nav search bar end*/
.mssd {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: auto;
  margin-right: 0px;
  margin-top: 0px;
}

.cdb :hover {
  /* background-color: #cf6e0d; */
  /* color: #fff; */

  transition: background-color 0.3s ease;
}

.cdb a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-decoration: none;
}

.cdb i {
  height: 50px;
  font-size: 50px;
  margin: 20px;
}



#data-nav {
  /* background-color: #E8A262; */
  width: 12%;
  height: 100px;
  padding-left: 0px;
  padding-right: 0PX;
}

#data-nav-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid black;
  margin-top: 15px;
}

#data-nav-link {
  height: 100px;
}

/* Media query for responsiveness */


@media (max-width: 600px) {
  .mssd {
    width: 80%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .cdb {
    width: 50%;
    height: 50%;
    margin-bottom: 10px;
  }

  #data-nav {
    background-color: #E8A262;
    width: 12%;
    height: 60px;
    padding-left: 0px;
    padding-right: 0PX;
  }

  #data-nav-link {
    height: 60px;
  }

  #data-nav-img {
    height: 25px;
    width: 25px;
    margin-top: 15px;
  }

  #data-nav p {
    font-size: 7px;
  }

  #row-sec-nav {
    width: 320px;
  }
}

@media only screen and (min-width:540px) and (max-width:767px) {
  .mssd {
    width: 80%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .cdb {
    width: 50%;
    height: 50%;
    margin-bottom: 10px;
  }

  #data-nav {
    background-color: #E8A262;
    width: 12%;
    height: 60px;
    padding-left: 0px;
    padding-right: 0PX;
  }

  #data-nav-link {
    height: 60px;
  }

  #data-nav-img {
    height: 25px;
    width: 25px;
    margin-top: 15px;
  }

  #data-nav p {
    font-size: 7px;
  }

  #row-sec-nav {
    width: 500px;
  }
}

@media only screen and (min-width:768px) and (max-width:820px) {
  .mssd {
    width: 80%;
    margin-left: 10px;
    margin-top: 10px;
  }

  .cdb {
    width: 50%;
    height: 50%;
    margin-bottom: 10px;
  }

  #data-nav {
    width: 12%;
    height: 60px;
    padding-left: 0px;
    padding-right: 0PX;
  }

  #data-nav-link {
    height: 60px;
  }

  #data-nav-img {
    height: 25px;
    width: 25px;
    margin-top: 15px;
  }

  #data-nav p {
    font-size: 10px;
  }

  #row-sec-nav {
    width: 100%;
  }
}

@media only screen and (min-width:821px) and (max-width:1650px) {
  .mssd {
    width: 59%;
    margin-left: 40px;
    margin-top: 0px;
  }

  .cdb {
    width: 50%;
    height: 50%;
    margin-bottom: 10px;
  }

  #data-nav {
    width: 12%;
    height: 60px;
    padding-left: 0px;
    padding-right: 0PX;
    padding-top: 20px;
  }

  #data-nav-link {
    height: 60px;
  }

  #data-nav-img {
    height: 25px;
    width: 25px;
    margin-top: 15px;
  }

  #data-nav p {
    font-size: 10px;
  }

  #row-sec-nav {
    width: 100%;
  }
}

#row-sec-nav {
  display: flex;
  justify-content: flex-end;
}

/*-------------------------------------- MyProfile End--------------------------------------- */


/* customer  deatails page------------------------------------------------------ */

@media (min-width: 300px) and (max-width: 500px) {
  #cust-tbl {
    font-size: 10px;
    font-weight: bold;
  }

  #cust-tbl-hed {
    font-size: 15px;
  }
}

@media (min-width: 300px) and (max-width:600px) {
  #cust-profile-image {}

  #choose-file {}

  #imagechoosebutton {}

  #uploadimg {}

}







/* NavbarWithSidebar.css --------------------------------Sidemenu Start Togglebtn----------------------------------------------------------*/
#sdbrtxt {
  color: black;
  font-size: larger;
  font-weight: bolder;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -16.5rem;
  transition: width 0.3s ease-in-out;
  position: relative;
  border: 1px solid #E8A262 !important;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}



#page-content-wrapper {
  /* min-width: 100%; */
  transition: margin 0.3s ease-in-out;
}

.toggled #sidebar-wrapper {
  margin-left: 0;
  /* width: 50%; */
}


.no_toggled #sidebar-wrapper {
  margin-left: 0;
  width: 11%;
}

@media (min-width : 800px) {
  .no_toggled #sidebar-wrapper {
    margin-left: 0;
    width: 8%;
  }
}

@media (min-width : 1440px) {
  .no_toggled #sidebar-wrapper {
    margin-left: 0;
    width: 5%;
  }
}

@media (min-width : 1650px) {
  .no_toggled #sidebar-wrapper {
    margin-left: 0;
    width: 4%;
  }
}

@media (min-width : 2650px) {
  .no_toggled #sidebar-wrapper {
    margin-left: 0;
    width: 3%;
  }
}

/* NavbarWithSidebar.css --------------------------------Sidemenu End Togglebtn----------------------------------------------------------*/
/* ----------------ul button hover start----------------*/
/* .bggcor{
  background-color: #f7e6d2;
} */
.bggcor .sps {
  height: 10px;
}

/* .bggcor .hov:hover{
  background-color: #E8A262;
} */

/* -----------------myprofile ----*/
/* .nav-link:hover {
  background-color: #E8A262;
  transition: background-color 0.3s ease;
  padding: 5px;
  padding-top: 5px;
} */

.nav-link:hover {
  color: #E8A262;
  transition: color 0.3s ease;
}

/*---------------------toggle btn start-------------------------------*/
.btn-togg {
  border-radius: 8px;
  transition: all 0.3s ease;
  /* width: 50px;  */
  /* height: 40px;  */
  /* border-radius: 55px;  */
}

.btn-togg:hover {
  background-color: #0c7ae7;
  color: #daffc1;
  /* width: 50px;  */
  /* height: 40px;  */
  border-radius: 10px;
}

/*---------------------toggle btn End-------------------------------*/
/* ----------------ul button hover end----------------*/

/*------------------------------------------------------------detail form page start----------------------------------------*/
.my-profile-box img {
  height: 200px;
  width: 25%;
  border-radius: 20px;
  box-shadow: 2px 4px 8px 1px #cccccc;
}

.image-upload {
  height: 200px;
  width: 25%;
  display: flex;
  justify-content: center;
  background: #b8b7b7db;
  align-items: center;
  border: 1px solid #c7c7c7;
  border-radius: 20px;
  margin-left: 15px;
  box-shadow: 2px 4px 8px 1px #cccccc;
}

label.image-upload-field i {
  font-size: 70px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {

  .my-profile-box img,
  .image-upload {
    height: 100px;
    width: 25%;
    margin-left: 10px;
  }

  label.image-upload-field i {
    font-size: 50px;
  }
}

@media screen and (max-width: 480px) {

  .my-profile-box img,
  .image-upload {
    height: 100px;
    width: 40%;
    margin-left: 5px;
  }

  label.image-upload-field i {
    font-size: 30px;
  }
}

#customer-otp-btn {
  background-color: #d16501;
  color: white;
  border: none;
  height: 30px;
}

#hide-view-table-btn {
  background-color: #d1823a;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100px;
}

#bottom-save-btn {
  background-color: #d1823a;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100px;
  height: 40px;
}


/*------------------------------------------------------------detail form page End----------------------------------------*/

/* ---------------------------------------------Delete btn start------------------------------------------- */
/* .my-profile-boxer {
  position: relative;
  display: inline-block;
} */

.delete-icon {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  margin: 15px 250px;
  /* transform: translate(-50%, -50%); */
  cursor: pointer;
  color: gray;
  font-size: 10px;
  /* text-align: center; */
  /* display: none; */
}

.delete-icon i {
  text-align: center;
  /* margin-top: 2px; */
  padding-top: 7px;
  border-radius: 7px;
}

.delete-icon span i {
  background-color: white;
  width: 250%;
  height: 30px;
  /* margin: 0 0 0 5px; */
  /* padding-right: 10px; */
}

/* .my-profile-box:hover .delete-icon {
  display: block;
} */

/* Responsive styles */
@media screen and (max-width: 768px) {
  .delete-icon {
    /* margin: 10px 100px; */
    font-size: 8px;
  }
}

@media screen and (max-width: 480px) {
  .delete-icon {
    margin: 5px 110px;
    font-size: 6px;
  }
}

/* ---------------------------------------------Delete btn End------------------------------------------- */

/*--------------------------------------------------input Filed Start------------------------------------ */
/* .input-fields {
  position: relative;
}

.input-fields input {
  border: none;
  border-bottom: 2px solid #3498db;
  border-radius: 0;
  box-shadow: none; 
  padding: 8px 0;
  outline: none; 
}

.input-fields input:focus {
  border-bottom-color: #007bff; 
} */

.disable-scroll {
  /* overflow: hidden; */
}

/* Default styles for input fields */

.input-fields {
  position: relative;
}

.input-fields input {
  border: none;
  /* border-bottom: 2px solid #e8a262; */
  border-radius: 0;
  box-shadow: none;
  padding: 8px 0;
  outline: none;
  font-size: 15px;
  font-weight: bolder;
  letter-spacing: 2px;
}

.input-fields input:focus {
  border-bottom-color: #a2a7ac;
}

/* Responsive styles using media queries */

@media (max-width: 576px) {

  .input-fields input {

    padding: 6px 0;
  }
}

@media (min-width: 577px) and (max-width: 768px) {

  .input-fields input {

    padding: 7px 0;
  }
}

/* Add more media queries for larger screens if needed */
/* @media (min-width: 769px) {

  .input-fields input {
    
  }
} */
/*--------------------------------------------------input Filed End------------------------------------ */

.btns-orange {
  width: 70%;
  background-color: #d16501;
  padding: 7px 26px;
  border: 1px solid #e8a262;
  border-radius: 10px;
  /* margin: 0 0 0 120px; */
}

/* Media query for screens with a maximum width of 600px */
@media screen and (max-width: 600px) {
  .btn-orange {
    width: 100%;
    /* margin-right: 200px;  */
  }
}

.btn-oranges {
  background-color: #E8A262;
  padding: 7px 16px;
  border: 1px solid #e8a262;
  font-size: 20px;
  /* border-radius: 10px; */
}

.btns-oranges {
  width: 30%;
  background-color: #d16501;
  padding: 7px 26px;
  border: 1px solid #e8a262;
  border-radius: 10px;
  /* margin: 0 0 0 70px; */
  /* margin-right: 30px; */
}

/* .main-panel{
  margin: 0 150px 0 150px;
} */

/* -====--------------------------------------------------------------Dropdown List Start--------------------------------------------------------------------------------*/

/* Dropdown styles */

.dropdown {
  /* max-width: 13em; */
  /* max-width: 100%; */
  margin: 15px auto 0;
  position: relative;
  /* width: 100%; */
}

.dropdown-btn {
  background: #fad9be;
  font-size: 20px;
  width: 100%;
  border: none;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 0.5em;
  /* border-radius: 0.5em; */
  cursor: pointer;
}

.arrow {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 16px solid #000000;
  transition: transform ease-in-out 0.3s;
}





.dropdown-content {
  list-style: none;
  position: absolute;
  top: 1rem;
  width: 100%;
  visibility: hidden;
  overflow: hidden;
}

.dropdown-content li {
  /* background: #2f3238; */
  /* border-radius: 0.5em; */
  position: relative;
  /* left: 100%; */
  transition: 0.7s;
  transition-delay: calc(60ms * var(--delay));
}

.dropdown:focus-within .dropdown-content li {
  left: 0;
}

.dropdown:focus-within .dropdown-content {
  visibility: visible;
}

.dropdown:focus-within .dropdown-btn>.arrow {
  transform: rotate(180deg);
}

/* .dropdown-content li:hover {
  background: #1d1f24;
} */

.dropdown-content li a {
  display: block;
  padding: 0.7em 0.5em;
  color: #fff;
  margin: 0.1em 0;
  text-decoration: none;
}

.dropps {
  margin-top: 150px;
}

.Setting {
  margin-top: 90px;
}


/* .btss {
  margin: 0 50px 0 25px;
} */
/* -====--------------------------------------------------------------Dropdown List End--------------------------------------------------------------------------------*/
#uploadimg {
  margin-top: 10px;
  border: none;
  background-color: #d1823a;
  color: white;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin-left: 10px;
}

#imagechoosebutton {
  border: none;
  background-color: #d1823a;
  color: white;
  width: 95px;
}

.file-input-wrapper {
  position: relative;
  display: inline-block;
  padding-left: 25px;
}

input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

#choose-file {
  display: inline-block;
  padding: 8px 16px;
  background-color: #d1823a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}



#choose-file {
  display: inline-block;
  padding: 8px 16px;
  /* background-color: #d1823a; */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.full_address_container {
  /* border : 2px solid red; */
  position: relative;
  background-color: white;
}

.location_button {
  position: absolute;
  bottom: 0.2rem;
  right: 1.5rem;
  font-size: xx-large;
  color: #d1823a;
}

.yes_no_button_container {
  display: flex;
}

.yes_no_button_container p {
  padding: 2px 15px;
  cursor: pointer;
  font-size: small;
  font-weight: 500;
}

.parent_wrappers {
  display: flex;
  gap: 1rem;
}

.contact_verification_child_left {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  font-weight: 500;
}

.contact_information_verification_wrapper {
  border: 1px solid gray;
  flex: 1;
  padding: 1rem;
  border-radius: 2rem;
}

.contact_information_verification_Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact_information_verification_Container .header {
  text-align: center;
  font-weight: 800;
  font-size: larger;
  /* text-shadow: gray 1px 0 10px;; */
}

.contact_information_verification_Container .child_container {
  display: flex;
  /* border: 2px solid black; */
  padding: 1rem 2.5rem;
  justify-content: space-between;
}

.child_container .verified_icon {
  color: green;
  font-size: x-large;
}

.child_container .close_icon {
  color: red;
  font-size: x-large;

}

.table_wrapper_ip {
  /* border: 2px solid green; */
  padding: 0px !important;
}

.save_button_container {
  /* border: 2px solid green; */
  width: 100% !important;
  justify-content: end !important;
  padding: 0 !important;
  display: flex;
}

.save_button_container button {
  border: 2px solid red
}

.table_data_row {
  border: 4px solid red;
}

.profile_verify_container {
  /* border: 2px solid red; */
  display: flex !important;
  padding: 0 5rem;

}

.profile_image_container {
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative
}

.profile_image_container img {
  flex: 1.5;
  max-height: 200px;
  max-width: 250px;
  border-radius: 1rem;
}

.profile_image_container p {
  font-size: large;
  font-weight: 500;
}

.verify_image {
  /* border: 2px solid black; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
}

.verify_image img {
  height: 70px;
  width: 120px;
  /* border: 2px solid black; */


}

.my-profile-box .profile_image {
  height: 200px;
  width: 25%;
  border-radius: 20px;
  box-shadow: 2px 4px 8px 1px #cccccc;
}

.delete_button {
  position: absolute;
  top: 60px;
  left: 180px;
  font-size: x-large;
  color: gray;
}

.image-upload {
  height: 200px;
  width: 25%;
  display: flex;
  justify-content: center;
  background: #b8b7b7db;
  align-items: center;
  border: 1px solid #c7c7c7;
  border-radius: 20px;
  margin-left: 15px;
  box-shadow: 2px 4px 8px 1px #cccccc;
}


/*------------------------------------------------------------ nav bar dashboard use img ------------------------------------------------------------------------*/
.user_action_mobile_items-dash {
  position: absolute;
  z-index: 9999;
  background-color: white;
  top: 74px;
  right: -10%;
  gap: 3px !important;
  padding: 3px;
  font-size: large;
  width: 250%;
}

.user_action_mobile_items-dash p {
  background-color: #c57a40;
  padding: 3px;
  align-items: center;
  margin: 3px !important;
}

.user_action_mobile_items-dash a {
  color: white;
  text-decoration: none;
}

#dash-logout {
  padding-left: 42px;
}


/*----------------------------------- dashborad nav bar responsive----------------------------------- */
@media only screen and (min-width:1650px) {
  #data-nav {
    padding-top: 40px;
    width: 150px;
  }

  .navimg {
    padding-top: 30px;
  }
}


@media (min-width:1000px) and (max-width:1300px) {

  .wrapper_search-dash-nav {
    display: flex;
    align-items: center !important;
    flex: 1 1;
    padding: 0;
    justify-content: flex-start;
    width: 90%;
  }

  #sdbrtxt {
    color: black;
    font-weight: bold;
    font-size: 10px;
  }

  #data-nav {
    width: 100px;
  }

  #data-nav h5 {
    font-size: 10px;
    padding-top: 20px;
  }

  .navimg {
    padding-top: 19px;
  }

  .input_search_field-dash-nav input::placeholder {
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: black;
  }
}

@media (min-width:1300px) and (max-width:1650px) {

  .wrapper_search-dash-nav {
    display: flex;
    align-items: center !important;
    flex: 1 1;
    padding: 0;
    justify-content: flex-start;
    width: 90%;
  }

  .addinventory {
    margin-right: 10px;
  }

  #sdbrtxt {
    color: black;
    font-weight: bold;
    font-size: 15px;
  }

  #data-nav {
    width: 100px;
  }

  #data-nav h5 {
    font-size: 15px;
    padding-top: 20px;
  }

  .navimg {
    padding-top: 19px;
  }

  .input_search_field-dash-nav {
    margin-bottom: 0px;
  }

  .input_search_field-dash-nav input::placeholder {
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: black;
  }

  .notification h5 {
    width: 120px;
  }
}