.mainContainer{
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 4rem;
}
.containerLeft{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.containerRight{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.headLeft{
    display: flex;
    justify-content: space-between;
    font-family: "Alata";
}
.headRight{
    font-family: "Alata";
}
.headLeft :last-child{
    color: #D1823A;
}
.headLeftBox{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #D1823A;
    border-radius: 6px;
    padding: 1rem;
    font-family: "Alata";
}
.headRightBox{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #D1823A;
    border-radius: 6px;
    padding: 1rem;
    font-family: "Alata";
    height: 86%;
}
.contentName{
    display: flex;
    justify-content: space-between;
}
.contentName span{
    width: 50%;
    color:#6c6c6c;
}
.contentNameRight{
    display: flex;
    justify-content: space-between;
}
.contentNameRight span{
    width: 50%;
    color:#6c6c6c;
}
.containerRight h6{
    width: 50%;
}
.contentName input{
    width: 50%;
    padding: 7px;
    border-radius: 6px;
    font-size: large;
    font-weight: 400;
    border: none;
    outline: none;
    background-color: #FCE8CF;
    box-shadow: lightgrey 0px 3px 5px 1px;
}

@media (min-width: 300px) and (max-width: 800px) {
    .mainContainer{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 3rem;
    }
    .containerLeft{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .containerRight{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
  }
 