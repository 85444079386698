.sale_type_data span {
  color: white;
}

.sale_type_data:hover,
.sale_type_data:nth-child(5):hover {
  /* transition: 0.1s; */
  background-color: #e8a262;
}

#Retail {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

#Finance {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.saleTypeWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 10px 10px rgb(232, 162, 98);
  padding: 2rem 0.8rem;
}
.saleTypeWrapper .BuyingCarDetails {
  background-color: #fce8cf;
  padding: 1rem 0.8rem;
  color: #6a5f5f;
  font-weight: 500;
  width: 100%;
}
.saleTypeContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  border: none;
  gap: 0.4rem;
  margin-top: 5rem;
  /* padding: 0 1.5rem; */
}
.sale_type_data,
.sale_type_data:nth-child(5) {
  background-color: #f5ddc7;
  border-radius: 10px;
  height: 95px;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  cursor: pointer;
  /* padding-left: 11px; */
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.sale_type_data:nth-child(5) {
  margin: 0 55%;
}
.saleTypeItemInput {
  flex: 0.3;
  display: none;
}
.saleTypeItemLabel {
  font-weight: 500;
  font-size: 19px;
  /* flex: 1; */
}
@media (max-width: 400px) {
  .saleTypeContainer {
    padding: 0;
  }
}
@media (min-width: 700px) {
  .saleTypeContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding: 0 8.5rem; */
    width: 60%;
  }
  .sale_type_data,
  .sale_type_data:nth-child(5) {
    background-color: #f5ddc7;
    height: 65px;
    display: flex;
  }
  .sale_type_data:nth-child(5) {
    margin: 0;
  }
  .saleTypeItemInput {
    flex: 0.6;
    display: block;
  }
  .saleTypeItemLabel {
    font-weight: 500;
    font-size: 30px;
    flex: 1;
  }
}
@media (min-width: 1024px) {
  .saleTypeContainer {
    width: 45%;
  }
  .sale_type_data {
    background-color: #f5ddc7;
    height: 65px;
    display: flex;
  }
  .sale_type_data:nth-child(5) {
    margin: 0;
  }
  .saleTypeItemInput {
    flex: 0.6;
    display: block;
  }
  .saleTypeItemLabel {
    font-weight: bold;
    font-size: 36px;
    flex: 1;
  }
}
@media (min-width: 1024px) {
  .saleTypeContainer {
    width: 65%;
  }
  .sale_type_data {
    background-color: #f5ddc7;
    height: 65px;
    display: flex;
  }
  .sale_type_data:nth-child(5) {
    margin: 0;
  }
  .saleTypeItemInput {
    flex: 0.6;
    display: block;
  }
  .saleTypeItemLabel {
    font-weight: bold;
    font-size: 36px;
    flex: 1;
  }
}
@media (min-width: 1440px) {
  .saleTypeContainer {
    width: 45%;
  }
}
@media (min-width: 2240px) {
  .saleTypeContainer {
    width: 45%;
  }
  .sale_type_data,
  .sale_type_data:nth-child(5) {
    background-color: #f5ddc7;
    height: 95px;
    display: flex;
  }

  .saleTypeItemLabel {
    font-weight: bold;
    font-size: 46px;
    flex: 1;
  }
}
