.crmTable {
    width: 100%;
    border-collapse: collapse;
  }
  .crmTablehead {
    background-color: #d1823a;
    color: white;
    width: 100%;
    border-radius: 10px;
  }
  .crmTablehead tr > th {
    border-right: 1px solid white;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    font-size: medium;
  }
  .crmTablehead tr > th:first-child {
    width: 3%;
  }
  .crmTablehead tr > th:last-child,
  .crmTablehead tr > th:nth-last-child(3) {
    width: 15%;
  }
  .crmTablehead tr > th:nth-last-child(2) {
    width: 25%;
  }
  
  .crmTablehead tr > th:nth-last-child(5) {
    width: 17%;
  }
  
  .crmTablehead tr > th:nth-last-child(4),
  .crmTablehead tr > th:nth-last-child(6) {
    width: 7%;
  }
  /* Body of the table starts here */
  .crmTable tbody tr {
    text-align: center;
    background-color: #fbeadf;
  }
  .crmTable tbody tr:hover {
    background-color: #ebb593;
  }
  .crmTable tbody tr > td {
    text-align: left;
    padding: 0.5rem 0.1rem;
    font-weight: 500;
    border: 1px solid white;
    padding-left: 0.3rem;
  }
  .crmTable tbody tr > td:nth-child(1),
  .crmTable tbody tr > td:nth-child(2) {
    text-align: center;
  }
  .crmTable tbody tr > td:last-child {
      text-align: center;
  }
  .crmTable tbody tr > td button {
    background-color: #d1823a;
    font-weight: 500;
    border: none;
    outline: none;
    /* padding: 0.1rem 0.3rem; */
    color: white;
    width: 36%;
    border-radius: 5px;
    padding: 0.2rem 0 0.2rem 0;
    margin-right: 0.3rem;
  }
  .crmTable tbody tr > td button:nth-child(3) {
    width: 10%;
  }
  .emailContainer {
    position: relative;
  }
  .emailContainer span:nth-child(2) {
    position: absolute;
    right: 8%;
    top: 20%;
  }
  .otpInputContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 17%;
    padding-right: 17%;
    margin: 10px 0;
    gap: 2rem;
  }
  .otpInputContainer input {
    background-color: #F6DFCF;
  }
  .otpInputBox {
    width: 47px;
    height: 55px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .otpResult {
    margin-top: 10px;
    font-size: 18px;
    padding-left: 42%;
    /* padding-right: 17%; */
  }
  .otpResult_resent{
    padding-left: 17%;
  }
  .otpResult button{
    background-color: #E6A162;
    box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
    border-radius: 5px;
    align-items: center;
    border: none;
    color: white;
    width: 150px;
    height: 40px;
  }
  .otpResult button:hover{
    background-color: #d1823a;
    box-shadow: 0px 6px 6px -3px rgba(199, 186, 186, 0.8);
  }
  

  .container>table>thead>tr>*{
    background-color: #d7996d;
      color: white;
      font-size: .8rem;
      text-wrap: nowrap;
      padding: 5px;
      text-align: center;
      border-width: 0px !important;
  }
  .tableFooterRow td{
    background-color: #d7996d !important;
    color: white;
    height: 2rem;
  }
  
  .tableRowData:nth-child(odd) > td {
    background-color: #f8e2cd !important;
  }
  
  /* user Pop-up for view button */
  .main_container_user{
    display: flex;
    flex-direction: column;
    gap: 2%;
  }
  /* upper section */
  .container_user_top{
  display: flex;
  flex-direction: row;
  gap: 2rem;
  }
  .container_user_top_left{
   width: 50%;
   padding: 0.5rem;
   border-radius: 10px;
   box-shadow: rgb(228 154 15 / 12%) 0px 2px 4px 0px, rgb(201 166 46 / 32%) 0px 2px 16px 0px;
   }
   .container_user_bottom_left{
    display: flex;
    flex-direction: column;
    width: 65%;
   padding: 2.5rem;
   border-radius: 10px;
   box-shadow: rgb(228 154 15 / 12%) 0px 2px 4px 0px, rgb(201 166 46 / 32%) 0px 2px 16px 0px;
   }
  .container_user_top_right{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .container_user_bottom_right{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .container_user_top_right_upper{
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
.user_top_right_box{
  width: 24%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: rgb(228 154 15 / 12%) 0px 2px 4px 0px, rgb(201 166 46 / 32%) 0px 2px 16px 0px;
}
.user_top_right_box span:nth-child(2) {
  margin-top: 17%;
  font-size: large;
  font-weight: 500;
  color: #d1823a;
}
.user_top_right_box span:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  color: grey;
}
/* for bottom */
.user_top_right_boxb{
  width: 29%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: rgb(228 154 15 / 12%) 0px 2px 4px 0px, rgb(201 166 46 / 32%) 0px 2px 16px 0px;
}
.user_top_right_boxb span:nth-child(2) {
  margin-top: 17%;
  font-size: large;
  font-weight: 500;
  color: #d1823a;
}
.user_top_right_boxb span:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  color: grey;
}
/* for botttom end */
  .container_user_top_right_lower{
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  /* bottom section */
  .container_user_bottom{
    border-top: 1px solid #d1823a;
    gap: 2rem;
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    padding-top: 1%;
  }
  .crmSheetRowDeleteBtn {
    color: #d1823a;
    background-color: transparent;
    font-size: x-large;
    margin-left: 0.5rem;
  }
  
  