.wrapper {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  /* padding: 0.3rem; */

  /* height: 6rem; */
  border-radius: 10px;
  /* box-shadow: 3px 6px 3px lightgray; */
  margin-bottom: 1rem;
  border-radius: 12px;
  font-family: "Alata", serif;
}

.container {
  /* border: 2px solid blue; */
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
}
.imgDataDetailsContainer {
  display: flex;
  flex-direction: column;
  /* padding: 0.3rem; */
  /* border-bottom: 1px solid #d1823a; */
  gap: 0.3rem;
  /* margin-bottom: 0.5rem; */
  background-color: #fbd4bd82;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 1px 1px 6px lightgray;
}
.imgContainer {
  /* border: 1px solid black; */
  /* width: 100%;
  max-width: 100%; */
  /* padding: 0.2rem 0.6rem; */
  width: 26%;
  display: grid;
}
.imgContainer img {
  width: 100%;
  height: 85px;
  /* object-fit: contain; */
  max-width: 100%;
  margin: auto;
  border-radius: 10px;
}

.vin_name_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 0 0.5rem;
  flex: 1;
  /* border: 2px solid blue; */
  width: 80%;
}

.vin_name_container {
  display: flex;
  flex-direction: column;
  margin: 0;
  /* border-left: 3px solid black;
  border-right: 3px solid black; */
  align-items: center;
  justify-content: center;
  /* margin: 0 0.5rem;
  padding: 0.5rem; */
  /* border: 2px solid blue; */
  flex: 1;
  color: black;
  font-weight: 500;
  font-size: large;
}

.price_type_container {
  display: flex;
  /* border: 1px solid green; */
  justify-content: space-around;
  height: 80%;
  /* flex: 1; */
  /* padding: 0 0.3rem; */
  gap: 0.3rem;
}

.expanded_price_type_container {
  flex-direction: column;
}

/* .price_type_container div {
  display: flex;
  flex-direction: column;
  flex: 1;
} */

.price_container span:nth-child(1),
.type_container span:nth-child(1) {
  /* font-weight: 700; */
  /* padding: 0.3rem; */
  display: grid;
  place-content: center;
}

.price_container span:nth-child(2),
.type_container span:nth-child(2) {
  /* font-weight: 700; */
  /* padding: 0.3rem; */
  display: grid;
  place-content: center;
}

.price_container span:nth-child(1) span {
  display: none;
}

.price_container span {
  font-weight: 600;
  /* border: 2px solid rgb(245, 13, 13); */
  background-color: grey;
  cursor: pointer;
  border-radius: 10px;
  padding: 0.2rem;
  color: white;
  text-align: center;
  flex: 1;
}

.type_container span {
  font-weight: 600;
  border: 2px solid rgb(245, 125, 13);
  background-color: rgba(231, 163, 117, 0.774);
  padding: 0.2rem;
  color: white;
  text-align: center;
}

/* For hiding */
.hide_header {
  display: none;
}

.inputFieldContainer {
  /* border: 2px solid yellow; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: end;
  padding-bottom: 1rem;
}

.txtbutton_saleprice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: 500;
  font-size: medium;
  /* padding-left: 0.5rem; */
}
.txtbutton_saleprice p {
  width: 100%;
  padding: 0.4rem 0.3rem;
  cursor: pointer;
  background-color: #d1823a;
  color: white;
  /* border-top-left-radius: 11px; */
  /* margin-left: auto;
  margin-right: auto; */
  /* border-bottom-right-radius: 11px; */
  margin-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.txtbutton_saleprice select {
  flex: 1;
  background-color: #d1823a;
  color: white;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 500;
  font-size: large;
  /* padding: 1rem; */
}
.txtbutton_saleprice select:focus {
  border: none;
  outline: none;
}

.vin_name_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1%;
  justify-content: start;
}

.extraSelectedCarOptionsContainer {
  display: flex;
  flex-direction: column;
}
.extraSelectedCarOptionsRelative {
  position: relative;
}
.extraSelectedCarOptionsAbsolute {
  position: absolute;
  color: white;
  right: 0.3rem;
  top: 5px;
}
.clickedSpan {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  border-radius: 3px;
  padding-right: 1rem;
  padding: 0.4rem 1.5rem;
  color: white;
  text-align: center;
  flex: 1;
  background-color: #db872c;
  border-radius: 15px;
  font-size: small !important;
  box-shadow: 3px 3px 5px 0px #938f8f;
  /* Change to your desired color */
}

.clickedSpanb {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  /* padding: 0.4rem 1.5rem; */
  color: white;
  text-align: center;
  flex: 1;
  background-color: #a39898;
  text-wrap: nowrap;
  font-size: 15px;
  padding: 0.2rem;
  box-shadow: 0px 3px 4px 0px #8c8484;
  /* Change to your desired color */
}

.yearMake {
  /* font-weight: 100px; */
  font-size: 12px;
  /* width: 400px !important; */
}
.vin_Container {
  font-size: 12px;
}

.date_saleprice {
  display: flex;
  flex-direction: row;
  width: 41%;
  gap: 6%;
}

.date_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.inputField {
  background-color: #fbdfbd;
}
.date_input {
  border-bottom-right-radius: 5px;
  background-color: #fbdfbd;
  box-shadow: 1px 2px 2px gray;
}
.buyerNameContainer {
  display: flex;
  flex-direction: column;
}
.buyerNameContainer span:first-child {
  color: lightgrey;
  font-size: small;
}
.buyerNameContainer span:last-child {
  padding: 0 0.3rem;
  font-size: 15px;
}
/* Date and Sale Type Container  style*/
.dateSaleTypeContainer {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #d1823a; */
  padding: 0.5rem;
}

.sscModalBtnContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.2rem 2rem;
  padding: 0.3rem;
}
.sscSecondBox {
  border: 2px solid #d1823a;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 6px lightgray;
  padding-bottom: 0.5rem;
}
.dateContainer,
.saleTypeContainer {
  display: flex;
  flex-direction: column;
  width: 44.5%;
  margin-bottom: 0;
}
.dateContainer label,
.saleTypeContainer label {
  color: #8a8181;
  font-size: 14px;
}
.dateSaleTypeContainer input {
  background-color: #fbd4bd82;
  border: none;
  box-shadow: 0px 3px 4px 0px #8c8484;
  font-size: small;
  text-align: center;
  font-weight: 500;
  padding: 0.3rem 0.2rem;
  border-radius: 7px;
  outline: none;
}
.dateSaleTypeContainer select {
  background-color: #fbd4bd82;
  padding: 4px;
  box-shadow: 3px 3px 4px 0px #8c8484;
  border-radius: 7px;
  font-size: small;
  text-align: center;
  font-weight: 500;
  padding: 0.3rem 0.2rem;
  outline: none;
}
.carContentContainer {
  display: flex;
  padding: 0.3rem;
  align-items: start;
  gap: 0.4rem;
}
.SCPContainer {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.SCPContainerLine1 {
  display: flex;
  align-items: start;
  gap: 0.4rem;
  justify-content: space-between;
  flex-direction: column;
}
.SCPSubContainerLine1 {
  width: 100%;
}
.SCPSubContainerLine2 {
  width: 100%;
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
}
.SCPSubContainerLine3,
.SCPSubContainerLine4 {
  width: 100%;
}
.SCPpopoutLabel {
  font-weight: 500;
}
.SCPpopoutInput {
  background-color: #fce8cf;
  border: none;
  outline: none;
  font-weight: 500;
  padding: 0.3rem;
  border-radius: 10px;
  box-shadow: 3px 4px 4px lightgrey;
}

@media (min-width: 375px) {
  .vin_name_wrapper {
    flex: auto;
  }
  .yearMake {
    font-size: 14px;
  }
  .vin_Container {
    font-size: 14px;
  }
  .clickedSpanb {
    font-size: 17px;
    box-shadow: 0px 3px 4px 0px #8c8484;
  }
}

@media (min-width: 420px) {
  .vin_name_wrapper {
    flex: auto;
  }
  .yearMake {
    font-size: 16px;
  }
  .vin_Container {
    font-size: 16px;
  }
  .dateSaleTypeContainer > input {
    font-size: 15px;
  }
  .dateSaleTypeContainer > select {
    font-size: 15px;
  }
  .clickedSpanb {
    font-size: 17px;
  }
  .price_type_container {
    /* padding: 0 0.9rem; */
    gap: 0.5rem;
  }
  .buyerNameContainer span:last-child {
    font-size: 17px;
  }
  .imgContainer img {
    height: 100px;
  }
  .SCPpopoutInput {
    font-size: 20px;
  }
  .SCPContainerLine1,
  .carContentContainer,
  .SCPContainer,
  .SCPSubContainerLine2 {
    gap: 0.8rem;
  }
}
/* for 720 size */
@media (min-width: 720px) {
  .price_type_container {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    height: auto;
  }

  .vin_name_wrapper {
    justify-content: space-evenly;
  }
  .vin_name_container {
    /* align-items: center; */
    /* flex: 0; */
  }
  .clickedSpanb {
    padding: 0.2rem 1rem;
  }
  .imgContainer img {
    height: 150px;
  }
  .vin_name_wrapper {
    flex: auto;
  }
  .sscModalBtnContainer {
    gap: 5.6rem;
    padding: 0.3rem 1rem;
  }
  .yearMake {
    font-size: 19px;
  }
  .vin_Container {
    font-size: 19px;
  }
  .dateSaleTypeContainer > input {
    font-size: 20px;
  }
  .dateSaleTypeContainer > select {
    font-size: 20px;
  }
  .clickedSpanb {
    font-size: 20px;
  }
  .price_type_container {
    padding: 0.6rem 0rem;
    gap: 0.5rem;
  }
  .buyerNameContainer span:last-child {
    font-size: 15px;
  }
  .buyerNameContainer span:first-child {
    font-size: 15px;
  }
  .imgDataDetailsContainer {
    gap: 1rem;
    /* padding: 0.5rem 1rem; */
  }
  .txtbutton_saleprice p {
    padding: 0.5rem 1rem;
  }
  .dateSaleTypeContainer {
    padding: 0.65rem 1rem;
  }
  .dateSaleTypeContainer {
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid #d1823a; */
    padding: 0.5rem 1rem;
  }
  .dateContainer label,
  .saleTypeContainer label {
    color: #8a8181;
    font-size: 22px;
  }

  .dateSaleTypeContainer input {
    font-size: 19px;
  }
  .dateSaleTypeContainer select {
    font-size: 19px;
  }
  .dateContainer,
  .saleTypeContainer {
    width: 43.5%;
  }
}
/* for 1024 size */
@media (min-width: 1024px) {
  .txtbutton_saleprice p {
    width: auto;
    max-width: 100%;
    border-radius: 0;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .txtbutton_saleprice {
    font-size: 20px;
  }
  .container {
    flex-direction: row;
  }

  .container img {
    /* height: 5.5rem; */
    width: 160px;
    margin: auto;
    /* object-fit: contain; */
  }

  .vin_name_container {
    /* flex: 0.7; */
    font-size: 20px;
    /* border: 2px solid red; */
    display: flex;
    font-weight: 500;
  }

  .expanded_price_type_container {
    flex-direction: column;
    margin-bottom: 0;
  }

  .hide_header {
    display: block;
  }

  .inputFieldContainer {
    padding-bottom: 0.5rem;
  }
  .vin_name_wrapper {
    flex-direction: row;
    align-items: start;
  }
  .vin_name_container {
    align-items: start;
  }
  .imgContainer {
    width: auto;
    /* height: 12.5rem; */
    /* border: 1px solid black; */
    gap: 1rem;
    /* padding: 0.5rem 1rem; */
  }
  .wrapper {
    margin-bottom: 1rem;
    /* height: 12rem; */
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .clickedSpan {
    font-size: medium;
  }
  .clickedSpanb {
    /* padding: 0.4rem 1.5rem; */
  }
  .dateSaleTypeContainer > select {
    position: absolute;
    width: 20%;
    top: 0;
    right: 0;
  }

  .dateSaleTypeContainer > input {
    position: absolute;
    top: 5px;
    right: 23%;
    width: 17%;
  }
  .dateSaleTypeContainer > label {
    display: block;
    position: absolute;
    top: 12px;
    right: 39%;
    width: 10%;
    font-weight: 500;
  }
  .buyerNameContainer {
    flex-direction: row;
  }
  .buyerNameContainer span:first-child {
    font-size: 15px;
    color: white;
  }
  .clickedSpanb {
    font-size: 18px;
    box-shadow: 3px 3px 5px 0px #938f8f;
    background-color: #a9a0a0;
  }
  .txtbutton_saleprice p {
    padding: 0.2rem 1rem;
    box-shadow: 3px 3px 3px 0px #938f8f;
  }
  .container {
    justify-content: space-between;
    gap: 1rem;
  }
  .price_type_container {
    padding: 0;
    gap: 0.5rem;
    padding-bottom: 0 !important;
  }
  .sscModalBtnContainer {
    gap: 1rem 0.9rem;
    padding: 0.3rem;
  }
  .imgDataDetailsContainer {
    padding: 0rem;
    /* flex: 1; */
    border-bottom: none;
    border-radius: 10px;
    width: 62%;
  }
  .clickedSpan {
    height: auto;
    border-radius: 10px;
    font-size: 16px !important;
    flex: auto;
    width: auto;
  }
  .dateSaleTypeContainer {
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
  }
  .dateContainer,
  .saleTypeContainer {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
  .dateContainer label,
  .saleTypeContainer label {
    font-size: 19px;
    width: 50%;
  }
  .dateSaleTypeContainer input {
    font-size: 19px;
    width: 50%;
  }
  .dateSaleTypeContainer select {
    font-size: 19px;
    width: 50%;
  }
  .sscSecondBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 36%;
  }
  .SCPContainerLine1 {
    flex-direction: row;
  }
  .SCPSubContainerLine1 {
    width: 37%;
  }
  .SCPSubContainerLine2 {
    width: 63%;
    flex-direction: row;
  }
  .SCPSubContainerLine3 {
    width: 40%;
  }
  .SCPSubContainerLine4 {
    width: 60%;
  }
  .SCPpopoutLabel{
    color: #8a8181;
  }
}

/* for 1440px size */
@media (min-width: 1440px) {
  .txtbutton_saleprice p {
    width: auto;
  }
  .buyerNameContainer span:first-child {
    font-size: 17px;
    color: white;
  }
  .buyerNameContainer span:last-child {
    font-size: 17px;
    color: white;
  }
  .vin_name_container {
    /* flex: 0.35; */
    /* font-size: x-large; */
    margin: 0 0.5rem;
    padding: 0.5rem;
    /* border: 2px solid yellow; */
    /* height: 80%; */
    display: flex;
  }

  .vin_name_container span {
    text-align: left;
    text-wrap: nowrap;
  }

  .vin_name_container .vin_Container {
    padding: 0;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  /* .vin_name_container span:nth-child(1){
   font-size: large;
   font-weight: bolder;
  } */

  .expanded_price_type_container {
    flex-direction: column;
  }

  .hide_header {
    display: block;
  }

  .price_type_container {
    flex: 0.5;
  }

  .price_type_container {
    padding: 0 0.3rem;
    /* font-size: x-large; */
    font-weight: 500;
  }

  .price_container span:nth-child(1) span {
    display: block;
  }

  .inputFieldContainer {
    padding-bottom: 2rem;
  }
  .txtbutton_saleprice {
    font-weight: 600;
    font-size: large;
    /* padding-left: 1.5rem; */
  }

  .price_type_container {
    gap: 5rem;
  }
  .dateSaleTypeContainer > input {
    right: 23%;
    width: 11%;
  }
  .dateSaleTypeContainer > label {
    top: 10px;
    right: 31%;
    width: 10%;
    font-size: 20px;
  }
  .dateSaleTypeContainer {
    padding: 1rem 2.5rem;
  }
  .sscModalBtnContainer {
    gap: 1rem 3rem;
    padding: 0.3rem 1.9rem;
  }

  .sscSecondBox {
    width: 35.5%;
  }
  .imgDataDetailsContainer {
    width: 63%;
  }
  .carContentContainer {
    padding: 0.3rem 1rem;
  }
  .clickedSpanb {
    border-radius: 9px;
  }
}

@media (min-width: 1800px) {
  .buyerNameContainer span:first-child {
    font-size: 19px;
  }
  .buyerNameContainer span:last-child {
    font-size: 19px;
  }
  .txtbutton_saleprice {
    font-weight: 700;
    font-size: larger;
    /* padding: 0 2.5rem; */
  }

  .vin_name_container .vin_Container {
    font-size: 23px;
    font-weight: 500;
  }

  .price_type_container {
    gap: 1rem;
  }
  .dateSaleTypeContainer > select {
    width: 13%;
  }
  .price_type_container {
    flex: 0.3;
  }

  .clickedSpanb {
    font-size: 22px;
  }
  .yearMake {
    font-size: 27px;
    font-weight: 700;
  }
  .dateContainer label,
  .saleTypeContainer label {
    font-size: 24px;
  }
  .dateSaleTypeContainer input {
    font-size: 22px;
  }
  .dateSaleTypeContainer select {
    font-size: 22px;
  }
  .sscSecondBox {
    width: 30.5%;
  }
  .imgDataDetailsContainer {
    width: 68.5%;
  }
  .dateSaleTypeContainer {
    padding: 1rem 3.5rem;
  }
  .sscModalBtnContainer {
    padding: 0.3rem 3rem;
  }
}
@media (min-width: 2560px) {
  .container img {
    width: 100%;
    /* height: 100%; */
    margin: auto;
  }

  .price_type_container {
    flex: 0.35;
    /* font-size: xx-large; */
    /* font-weight: 600; */
  }
  .dateSaleTypeContainer > input {
    right: 23%;
    width: 11%;
  }
  .dateSaleTypeContainer > label {
    top: 10px;
    right: 28%;
    width: 10%;
    font-size: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .wrapper {
    /* border: 2px solid red; */
    display: flex;
    padding: 0.3rem;
    /* background-color: rgba(240, 233, 233, 0.712); */
    /* height: 10rem; */
  }

  .clickedSpan {
    font-weight: 200;
    cursor: pointer;
    /* width: 142px; */
    height: 25px;
    border-radius: 10px;
    padding: 0.2rem;
    color: white;
    text-align: center;
    flex: 1;
    background-color: #db872c;
    font-weight: 500;
    /* Change to your desired color */
  }

  .clickedSpanb {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    width: 142px;
    width: 100%;
    height: 30px;
    cursor: pointer;
    border-radius: 9px;
    padding: 0.3rem;
    color: white;
    text-align: center;
    flex: 1;
    background-color: #a39898;

    font-weight: 500;
    /* Change to your desired color */
  }

  .price_type_container {
    gap: 10px !important;
  }
}
