.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: 40vh;
  width: 100%;
}

.leftSide,
.rightSide {
  width: 48%;
}

.leftSide {
  border-right: 1px solid #ddd;
  padding-right: 10px;
}

.rightSide {
  padding-left: 10px;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.inputContainer {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 15px;
  width: 100%;
  position: relative;
}

.messageInput {
  flex: 1;
  border: none;
  padding: 10px;
  font-size: 16px;
  outline: none;
}

.iconButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.iconButton svg {
  border: 1px solid;
  border-radius: 50%;
  font-size: 30px;
  padding: 5px;
}

.emojiButton {
  background: none;
  border: none;
  border-radius: 50%;
  font-size: 25px;
  cursor: pointer;
  padding: 10px;
}

.emojiButton svg {
  border-radius: inherit;
}

.sendMessageButton {
  background: none;
  border-radius: 50%;
  background-color: #d1823a;
  box-shadow: 0px 4px 50px 9px rgba(0, 0, 0, 0.1);
  height: 50px;
  width: 50px;
  cursor: pointer;
  /* margin-top: 23px; */
  padding: 5px;
  /* margin-left: 10px; */
}

.sendMessageButton svg {
  width: 100%;
  height: 100%;
}

.emojiPickerContainer {
  position: absolute;
  right: 8%;
  z-index: 10;
}

.emojiPickerContainer input {
  padding-left: 37px;
}

.chatBox {
  /* max-height: 50vh; */
  /* min-height: 50vh; */
  height: 50vh;
  overflow-y: auto;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.sentMessage {
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-end;
  /* text-align: right; */
}

.sentMessage>div:first-child {
  text-align: right;
}

.sentMessage .msgContent {
  background-color: #dcf8c6;
  border-radius: 10px;
  padding: 10px;
}

.receivedMessage {
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-start;
  text-align: left;
}

.msgContent {
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}